import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export default function DiscardConfirmModal({ isOpen, onConfirm, onCancel }: Props) {
  const buttons = [
    <Button key="confirm" buttonColor="danger" variant="primary" onClick={onConfirm}>
      <FormattedMessage
        id="f6b64620-52c1-44e4-9684-d7ffd6639919"
        defaultMessage="Discard changes"
      />
    </Button>,
    <Button key="cancel" onClick={onCancel} variant="tertiary" buttonColor="dark">
      <FormattedMessage id="bfd47970-b25d-4f36-91af-52f8b7bd54b1" defaultMessage="Cancel" />
    </Button>,
  ];

  if (!isOpen) {
    return null;
  }

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="08141026-2cf6-49df-8629-46be336ace9b"
          defaultMessage="Discard changes"
        />
      }
      buttons={buttons}
    >
      <FormattedMessage
        id="daa8bcdd-27d8-46a7-8168-7983ea904dcc"
        defaultMessage="Are you sure you want to discard all your changes?"
      />
    </WorkflowModal>
  );
}
