import "./details.scss";

import type { ComponentProps, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import BinaryToggle from "common/form/inputs/binary_toggle";

type HeaderProps = {
  children: ReactNode;
};
type RowProps = {
  label: ReactNode;
  children: ReactNode;
  largeChild?: boolean;
};

type ToggleProps = {
  value: boolean;
  automationId?: string;
  disabled?: ComponentProps<typeof BinaryToggle>["disabled"];
  onChange?: ComponentProps<typeof BinaryToggle>["onChange"];
  customLabel?: ReactNode;
  "aria-labelledby": string;
};

export function DetailsRowHeader({ children }: HeaderProps) {
  return <div className="NotaryDetailsRowHeader">{children}</div>;
}

export function DetailsRow({ children, label, largeChild }: RowProps) {
  return (
    <div className={`NotaryDetailsRow${largeChild ? " with-large-child" : ""}`}>
      <label>{label}</label>
      {children}
    </div>
  );
}

export function BinaryStatusWithToggle({
  value,
  onChange,
  automationId,
  customLabel,
  disabled,
  "aria-labelledby": ariaLabelledBy,
}: ToggleProps) {
  return (
    <div className={`NotaryDetailsToggle${value ? " on" : ""}`}>
      {onChange && (
        <BinaryToggle
          onChange={onChange}
          value={value}
          automationId={automationId}
          disabled={disabled}
          aria-labelledby={ariaLabelledBy}
        />
      )}
      {customLabel || (
        <FormattedMessage
          id="1e03247c-613f-4a0f-b6e7-d541108eeb33"
          defaultMessage="{value, select, true {Enabled} other {Disabled}}"
          values={{ value }}
        />
      )}
    </div>
  );
}
