import { useParams } from "react-router-dom";

import SettingsCard from "admin_portal/system_settings/settings_card";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import { useQuery } from "util/graphql";
import { SettingGroupName } from "graphql_globals";

import OrgFlags, {
  type OrgFlags_node_Organization as Organization,
} from "./org_flags_query.graphql";

function AdminCompanyDetailsOrgFlags() {
  const { globalID } = useParams();
  const { hasPermissionFor } = usePermissions();
  const { data, loading } = useQuery(OrgFlags, {
    variables: {
      organizationId: globalID!,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.node as Organization | null;
  const settings = organization?.settingGroups[0].settings;

  const hardAssignmentSettingIsEnabled = settings?.some(
    (setting) =>
      setting.name === "allow_hard_notary_assignment" && setting.values[0]?.value === "true",
  );

  const hasEditFlagsPerm = hasPermissionFor("editOrgFlagsUpdateForm");

  return (
    <SettingsPageWrapper>
      <SettingsHeader title="Organization Flags" />
      {settings?.map((setting) => {
        const isHardAssignmentOverflowEnabled =
          hardAssignmentSettingIsEnabled ||
          setting.name !== "hard_notary_assignment_overflow_in_seconds";
        const canEdit = hasEditFlagsPerm && isHardAssignmentOverflowEnabled;
        return (
          <SettingsCard
            groupName={SettingGroupName.ORGANIZATION}
            setting={setting}
            canEdit={canEdit}
            orgId={globalID}
            key={setting.name}
          />
        );
      })}
    </SettingsPageWrapper>
  );
}

export default AdminCompanyDetailsOrgFlags;
