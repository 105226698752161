import "./index.scss";
import { useState } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import FormRow from "common/form/elements/row";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import Button from "common/core/button";
import TextField from "common/form/fields/text";
import TextAreaField from "common/form/fields/text_area";
import FormGroupErrors from "common/form/group_errors";
import WorkflowModal from "common/modals/workflow_modal";

import AdminToolSection from "../admin_tool_section";
import SettingsUpdaterMutation, {
  type SettingsUpdater,
  type SettingsUpdaterVariables,
} from "./settings_updater_mutation.graphql";

type FormValues = {
  groupName: string;
  name: string;
  reason: string;
  value: string;
};

type ContainerProps = InjectedFormProps<FormValues>;
type InnerProps = ContainerProps & {
  updateSettings: ReturnType<typeof useMutation<SettingsUpdater, SettingsUpdaterVariables>>;
};

type StatusState =
  | { status: "none" }
  | { status: "success" }
  | { status: "error"; message: null | string };

function SettingsUpdateTool(props: InnerProps) {
  const { handleSubmit, reset, invalid } = props;
  const [status, setStatus] = useState<StatusState>({ status: "none" });

  const save = async (formValues: FormValues) => {
    try {
      await props.updateSettings({
        variables: {
          input: {
            name: formValues.name,
            groupName: formValues.groupName,
            value: formValues.value,
            reason: formValues.reason,
          },
        },
      });
      reset();
      setStatus({ status: "success" });
    } catch (e) {
      setStatus({ status: "error", message: (e as Error).message });
    }
  };

  return (
    <>
      <AdminToolSection title="Settings Updater">
        <div className="SettingsUpdateTool">
          <FormRow>
            <FormattedMessage
              id="28b04d27-a4dd-46d3-8301-a06324dafd22"
              defaultMessage="Group Name"
              tagName="label"
            />
            <TextField
              placeholder="Group Name"
              name="groupName"
              useStyledInput
              data-automation-id="settings-group-name"
            />
            <FormGroupErrors
              fields={["groupName"]}
              errorClassName="SettingsUpdateTool--errorMessage"
            />
          </FormRow>

          <FormRow>
            <FormattedMessage
              id="a3ca1f50-3c81-4742-aca8-b197c62906d2"
              defaultMessage="Name"
              tagName="label"
            />
            <TextField
              placeholder="Name"
              name="name"
              useStyledInput
              data-automation-id="settings-name"
            />
            <FormGroupErrors fields={["name"]} errorClassName="SettingsUpdateTool--errorMessage" />
          </FormRow>

          <FormRow>
            <FormattedMessage
              id="a38a4dfd-7f8a-452c-9696-691bc635b3b2"
              defaultMessage="Reason"
              tagName="label"
            />
            <TextField
              placeholder="Reason for change"
              name="reason"
              useStyledInput
              data-automation-id="settings-reason"
            />
            <FormGroupErrors
              fields={["reason"]}
              errorClassName="SettingsUpdateTool--errorMessage"
            />
          </FormRow>

          <FormRow>
            <FormattedMessage
              id="f17f2e36-6a4a-4822-8062-0660772b1220"
              defaultMessage="Value"
              tagName="label"
            />
            <TextAreaField
              name="value"
              useStyledInput
              placeholder="Value (as JSON)"
              data-automation-id="settings-value"
            />
          </FormRow>

          <Button
            disabled={invalid}
            automationId="settings-save-button"
            buttonColor="action"
            variant="primary"
            onClick={handleSubmit(save)}
          >
            <FormattedMessage id="7da50a7b-db17-4e50-8538-678dd26b72dc" defaultMessage="Save" />
          </Button>
        </div>
      </AdminToolSection>

      {status.status === "error" && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="19651c9b-e56e-44ba-8d6a-7e8ca76d6c78"
              defaultMessage="JSON was Invalid"
            />
          }
          buttons={[
            <Button
              key="dismiss"
              automationId="dismiss-admin-settings-tool-modal"
              onClick={() => setStatus({ status: "none" })}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage id="188b3d49-e79e-4d69-86e5-36a53ac9d446" defaultMessage="Close" />
            </Button>,
          ]}
          footerSeparator={false}
        >
          {status.message !== "forbidden" && (
            <FormattedMessage
              id="ebae13bc-8c38-4671-8bf1-8e08c8df66ac"
              defaultMessage="Make sure to format your JSON as valid JSON or pass an empty string to reset setting."
            />
          )}
          {status.message === "forbidden" && (
            <FormattedMessage
              id="0b234212-7437-41fe-abba-ff57d6cb64c7"
              defaultMessage="You do not have permission to update this setting"
            />
          )}
        </WorkflowModal>
      )}

      {status.status === "success" && (
        <WorkflowModal
          title={
            <FormattedMessage id="80365606-3d02-447c-913c-38d774ccb493" defaultMessage="Success!" />
          }
          buttons={[
            <Button
              key="dismiss"
              automationId="dismiss-admin-settings-tool-modal"
              onClick={() => setStatus({ status: "none" })}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage id="6031c26e-0306-43dd-95ca-55869989be58" defaultMessage="Close" />
            </Button>,
          ]}
          footerSeparator={false}
        >
          <FormattedMessage
            id="04a7574a-eafd-48b7-a603-d8d3d7eaa47f"
            defaultMessage="You have successfully updated your settings."
          />
        </WorkflowModal>
      )}
    </>
  );
}

function SettingsUpdateToolContainer(props: ContainerProps) {
  const settingsUpdaterMutation = useMutation(SettingsUpdaterMutation);
  return <SettingsUpdateTool {...props} updateSettings={settingsUpdaterMutation} />;
}

export default reduxForm<FormValues>({
  form: "settingsUpdaterForm",
  validate: composeValidators(
    validatePresence({
      field: "groupName",
      label: "Group Name",
    }),
    validatePresence({
      field: "name",
      label: "Name",
    }),
    validatePresence({
      field: "reason",
      label: "Reason for change",
    }),
  ),
})(SettingsUpdateToolContainer);
