import { useState } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import ActionButton from "common/core/action_button";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMutation } from "util/graphql";
import { OrganizationAccountProvisionStatuses as ProvisionStatus } from "graphql_globals";

import Styles from "./index.module.scss";
import SendOwnerInviteEmail from "./send_owner_invite_email_mutation.graphql";

type Props = {
  provisionStatus: Omit<ProvisionStatus, "COMPLETE">;
  organizationId: string;
  refetch: () => void;
  canProvisionOrg: boolean;
};

export function EmailVerificationBanner({
  canProvisionOrg,
  provisionStatus,
  organizationId,
  refetch,
}: Props) {
  const [mutationStatus, setMutationStatus] = useState<null | "error">(null);
  const sendOwnerInviteEmail = useMutation(SendOwnerInviteEmail);

  const save = async () => {
    try {
      await sendOwnerInviteEmail({ variables: { input: { organizationId } } });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: "Email verification successfully sent",
      });
      refetch();
    } catch {
      setMutationStatus("error");
    }
  };

  return (
    <>
      <AlertMessage className={Styles.alertBanner} kind="warning">
        <div className={Styles.alertBannerInner}>
          {provisionStatus === ProvisionStatus.NOT_SENT ? (
            <FormattedMessage
              id="987b5e86-dda9-407a-9df0-1e08071d1141"
              defaultMessage="Owner has not yet been invited"
            />
          ) : (
            <FormattedMessage
              id="b1377d08-6cf4-4673-8201-742063d7e64a"
              defaultMessage="Owner has not yet verified their email"
            />
          )}
          {canProvisionOrg && (
            <ActionButton onClick={save}>
              {provisionStatus !== ProvisionStatus.SENT ? (
                <FormattedMessage
                  id="40fdc706-2db1-4834-a350-6715ae8473d2"
                  defaultMessage="Send email invite"
                />
              ) : (
                <FormattedMessage
                  id="e426930e-2c4b-4482-9aad-faa8bf97b8bf"
                  defaultMessage="Re-send email invite"
                />
              )}
            </ActionButton>
          )}
        </div>
      </AlertMessage>
      {mutationStatus === "error" && <MutationErrorModal onClick={() => setMutationStatus(null)} />}
    </>
  );
}
