import "./index.scss";

import { useNavigate, useParams } from "react-router-dom";

import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery, useMutation } from "util/graphql";

import Targeting from "./targeting";
import Groups from "./groups";
import History from "./history";
import Settings from "./settings";
import Header from "../header";
import AdminExperimentQuery, {
  type AdminExperiment_experiment_Experiment as Experiment,
} from "./experiment_query.graphql";
import UpdateExperimentMutation from "./update_experiment_mutation.graphql";

type Props = {
  experiment: Experiment;
};

function AdminExperimentDetails(props: Props) {
  const navigate = useNavigate();
  const { name, description, groups, active, auditTrail } = props.experiment;

  const updateExperimentMutate = useMutation(UpdateExperimentMutation);

  function goBackToList() {
    navigate("/experiments");
  }

  return (
    <div className="AdminExperimentDetails">
      <Header title={name} onBack={goBackToList} canCopy />
      {description && <p className="AdminExperimentDetails--description">{description}</p>}
      <TabRow>
        <TabButton
          title="Targeting"
          content={
            <Targeting
              name={name}
              groups={groups}
              active={active}
              updateExperimentMutate={updateExperimentMutate}
            />
          }
          automationId="targeting-tab"
        />
        <TabButton
          title="Groups"
          content={
            <Groups name={name} groups={groups} updateExperimentMutate={updateExperimentMutate} />
          }
          automationId="groups-tab"
        />
        <TabButton
          title="History"
          content={<History auditTrail={auditTrail} />}
          automationId="history-tab"
        />
        <TabButton
          title="Settings"
          content={
            <Settings
              name={name}
              onBack={goBackToList}
              updateExperimentMutate={updateExperimentMutate}
            />
          }
          automationId="settings-tab"
        />
      </TabRow>
    </div>
  );
}

function AdminExperimentDetailsContainer() {
  const experimentId = useParams().id!;

  const { data, loading } = useQuery(AdminExperimentQuery, {
    variables: { experimentId },
  });
  const experiment = data?.experiment as Experiment;

  if (loading) {
    return <LoadingIndicator />;
  }

  return <AdminExperimentDetails experiment={experiment} />;
}

export default AdminExperimentDetailsContainer;
