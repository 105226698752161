import PropTypes from "prop-types";

import AudioVideoSettingsContainer from "common/video_conference/audio_video_settings/container";
import WebcamItem from "common/video_conference/audio_video_settings/webcam_item";
import MicrophoneItem from "common/video_conference/audio_video_settings/microphone_item";
import SpeakerItem from "common/video_conference/audio_video_settings/speaker_item";

import PhoneItem from "./phone_item";
import ButtonItem from "./button_item";

function AudioVideoSettings({ renderMessaging, onChangeDevices, onSubmit, selectedDevices }) {
  const webcamItem = (
    <WebcamItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} autoFocus />
  );
  const microphoneItem = (
    <MicrophoneItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} />
  );
  const speakerItem = (
    <SpeakerItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} />
  );
  const phoneItem = (
    <PhoneItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} />
  );
  const footer = <ButtonItem onClick={onSubmit} selectedDevices={selectedDevices} />;

  return (
    <AudioVideoSettingsContainer
      webcamItem={webcamItem}
      phoneItem={phoneItem}
      microphoneItem={microphoneItem}
      speakerItem={speakerItem}
      footer={footer}
      renderMessaging={renderMessaging}
    />
  );
}

AudioVideoSettings.propTypes = {
  onChangeDevices: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderMessaging: PropTypes.func,
  selectedDevices: PropTypes.shape({
    webcam: PropTypes.string,
    phone: PropTypes.string,
    microphone: PropTypes.string,
    speaker: PropTypes.string,
  }).isRequired,
};

export default AudioVideoSettings;
