import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { DetailsRow, DetailsRowHeader, BinaryStatusWithToggle } from "common/notary/details";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { CAPACITY_STATUS_LABELS, condensedStatusLabel } from "admin_portal/notaries/util";
import {
  NotaryProfileInvalidFields,
  NotaryActivationStatus,
  type NotaryCapacityType,
} from "graphql_globals";

import type { NotaryCapacities_capacities as NotaryCapacity } from "./index_fragment.graphql";

type ByotCapacity = Omit<NotaryCapacity, "type"> & {
  type: NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS;
};

const MESSAGES = defineMessages({
  statusLabel: {
    id: "1b6fcddd-3b8b-4d9a-a371-ea766e040f45",
    defaultMessage: "More details about notary organization capacity status",
  },
});

function ByotCapacityDetails({ capacity }: { capacity: ByotCapacity }) {
  const intl = useIntl();
  const missingPayment = capacity.validation.invalidFields.some(
    (v) =>
      v === NotaryProfileInvalidFields.INVALID_PAYMENT ||
      v === NotaryProfileInvalidFields.MISSING_STRIPE_CONNECT,
  );
  return (
    <>
      <DetailsRowHeader>
        <FormattedMessage id="1fcf98cb-b423-45d5-a026-d879c11f59f2" defaultMessage="NST Details" />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <>
            <span id="capacity-status-toggle">
              <FormattedMessage
                id="9ab0cc9e-1113-4c4f-9dd1-2b7de43f6548"
                defaultMessage="Capacity Status"
              />
            </span>
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(MESSAGES.statusLabel)}
            >
              <FormattedMessage
                id="6552af00-e0e3-4a7c-856a-ebb144ac8be0"
                defaultMessage="NST capacity is automatically approved when required profile fields are completed, including a valid payment account. Note: Notaries must also have a compliant profile that is not disabled."
              />
            </Tooltip>
          </>
        }
      >
        <BinaryStatusWithToggle
          value={capacity.status === NotaryActivationStatus.ACCEPTED}
          aria-labelledby="capacity-status-toggle"
          customLabel={
            <>
              {condensedStatusLabel(capacity.status)} ({CAPACITY_STATUS_LABELS[capacity.status]})
            </>
          }
        />
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage
            id="1f029edf-0e1a-4b46-932a-7cc2c7f0ea5f"
            defaultMessage="Payment Info"
          />
        }
      >
        <FormattedMessage
          id="1a7ae19b-2ce6-4097-abae-7642baec68fc"
          defaultMessage="{missingPayment, select, true {Missing} other {Configured}}"
          values={{ missingPayment }}
        />
      </DetailsRow>
    </>
  );
}

export default ByotCapacityDetails;
