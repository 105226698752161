import { useCallback, useState, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { SearchField } from "common/core/search_field";
import Button from "common/core/button";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { StandardTable } from "common/table";
import TableRow from "common/table/row";
import { MailtoLink } from "common/core/mailto_link";
import Link from "common/core/link";
import { useQuery } from "util/graphql";
import { UserRole } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import { LongFormattedDateTime } from "common/core/format/date";
import { CAPACITY_TYPE_LABELS } from "admin_portal/notaries/util";

import AdminUserSearchToolQuery, {
  type AdminUserSearchTool_viewer_queriedUsers_edges_node as User,
} from "./index_query.graphql";
import Styles from "./index.module.scss";

const NO_USERS_FOUND = (
  <p className={Styles.noUsersMessage}>
    <FormattedMessage
      id="d067145f-6b90-4091-b38d-9b2ecce69de1"
      defaultMessage="No users exactly matched the criteria you searched. Check to make sure you've entered a complete email address, user name or user/meeting/transaction gid."
    />
  </p>
);

const MESSAGES = defineMessages({
  singleUseAccountLabel: {
    id: "07b6eadb-92e0-4ed7-bfb6-57f6fec1b8b8",
    defaultMessage: "Show single use accounts",
  },
  searchLabel: {
    id: "046772cf-09f5-4238-852a-6f6e4c4d42eb",
    defaultMessage: "Search for a user",
  },
});

function encodeNotaryCapacities(notaryProfile: User["notaryProfile"]): ReactNode {
  const caps = notaryProfile?.capacities
    .flatMap((cap) => [CAPACITY_TYPE_LABELS[cap.type], " + "])
    .slice(0, -1);
  return caps?.length ? caps : "No notary capacities";
}

function renderRoles(user: User, canViewDetails: boolean) {
  const { roles, organization } = user;
  return (
    <div className={Styles.userItemRoleList}>
      {(roles as UserRole[]).map((role) => {
        if (canViewDetails && role.includes("ORGANIZATION")) {
          return (
            <Link
              key={role}
              to={`/companies/${organization!.id}/details`}
              title={`${organization!.name} (${organization!.id})`}
            >
              {role}
            </Link>
          );
        } else if (canViewDetails && role === UserRole.CUSTOMER) {
          return (
            <Link key={role} to={`/users/${user.id}`}>
              {role}
            </Link>
          );
        } else if (canViewDetails && role === UserRole.NOTARY) {
          return (
            <span key={role}>
              <Link to={`/notary?email=${encodeURIComponent(user.email!)}`}>
                {encodeNotaryCapacities(user.notaryProfile)}
              </Link>
              {" ("}
              <Link to={`/analytics/page/1?notary=${user.firstName}+${user.lastName}`}>
                <FormattedMessage
                  id="afd37ece-6d78-44bd-9215-4a308ef65546"
                  defaultMessage="Analytics"
                />
              </Link>
              {")"}
            </span>
          );
        }
        return <span key={role}>{role}</span>;
      })}
    </div>
  );
}

function renderDisabledAtMessage(user: User) {
  return (
    <div className={Styles.disabledAtMessageContainer}>
      <div>
        <FormattedMessage id="a239f3d7-f931-4ed5-8caa-504203790b4a" defaultMessage="Disabled" />:
      </div>
      <div>
        <LongFormattedDateTime value={user.disabledAt} />
      </div>
    </div>
  );
}

function renderRow(user: User, canViewDetails: boolean) {
  return (
    <TableRow
      key={user.id}
      automationId={`user-row-${user.id}`}
      cells={[
        { children: user.systemId },
        {
          children: canViewDetails ? (
            <Link automationId={`user-details-link-${user.email}`} to={`/users/${user.id}`}>
              {user.id}
            </Link>
          ) : (
            user.id
          ),
        },
        { children: <MailtoLink emailAddress={user.email!} />, className: "ellipsis" },
        {
          children: canViewDetails ? (
            <Link to={`/users/${user.id}`}>{user.documentBundlesAsCustomer?.length || 0}</Link>
          ) : (
            user.documentBundlesAsCustomer?.length || 0
          ),
        },
        { children: renderRoles(user, canViewDetails) },
        { children: user.customerProfile?.id || user.notaryProfile?.id },
        { children: user.firstName, className: "ellipsis" },
        { children: user.lastName, className: "ellipsis" },
        { children: user.singleUse ? "yes" : "no" },
        { children: !user.isActive ? renderDisabledAtMessage(user) : "active" },
      ]}
    />
  );
}

function UserSearchTool() {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQuery = searchParams.get("userSearch") || "";
  const [usersQuery, setUsersQuery] = useState(urlQuery);
  const [includeSingleUse, setIncludeSingleUse] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const { data, loading } = useQuery(AdminUserSearchToolQuery, {
    variables: { query: urlQuery, includeSingleUse },
    skip: !urlQuery,
  });
  const handleToggleClick = useCallback((value: boolean) => {
    setIncludeSingleUse(value);
  }, []);

  const canViewDetails = hasPermissionFor("userDetails");
  const handleSearch = () => setSearchParams({ userSearch: usersQuery });
  const queriedUsers = data?.viewer.queriedUsers;

  return (
    <div className={Styles.userSearch}>
      <div className={Styles.searchBar}>
        <SearchField
          className={Styles.searchField}
          onChange={({ value }) => setUsersQuery(value)}
          onSearch={handleSearch}
          value={usersQuery}
          placeholder="Enter a meeting / transaction GID, or a user's full email address, full name, last name or GID / ID. Draft transactions are not fully supported."
          aria-label={intl.formatMessage(MESSAGES.searchLabel)}
          noIcon
        />
        <div className={Styles.singleUseControl}>
          <BinaryToggle
            label={intl.formatMessage(MESSAGES.singleUseAccountLabel)}
            onChange={handleToggleClick}
            value={includeSingleUse}
          />
        </div>
        <Button
          buttonColor="action"
          variant="primary"
          onClick={handleSearch}
          isLoading={loading}
          automationId="admin-user-search-button"
        >
          <FormattedMessage id="87ca847d-bae1-4d59-ba6c-e042ec25b794" defaultMessage="Search" />
        </Button>
      </div>
      {queriedUsers?.totalCount ? (
        <div className={Styles.table}>
          <StandardTable
            headings={[
              "ID",
              "GID",
              "Email Address",
              "TX Count",
              "Roles",
              "Profile ID",
              "First Name",
              "Last Name",
              "Single Use?",
              "Status",
            ]}
            automationId="user-search-tool-table"
          >
            {queriedUsers.edges.map(({ node }) => renderRow(node, canViewDetails))}
          </StandardTable>
        </div>
      ) : queriedUsers ? (
        NO_USERS_FOUND
      ) : null}
    </div>
  );
}

export default UserSearchTool;
