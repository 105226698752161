import "./dashboard.scss";

import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import Content from "admin_portal/experiments/content";
import { QueryWithLoading } from "util/graphql/query";
import { LongFormattedDateTime } from "common/core/format/date";
import FormattedBoolean from "common/core/format/boolean";

import ExperimentsViewerQuery, {
  type ExperimentsViewer_viewer_experiments as Experiments,
} from "./experiments_viewer_query.graphql";

type Props = {
  experiments: Experiments[];
};

function Dashboard({ experiments }: Props) {
  const navigate = useNavigate();
  return (
    <Content>
      <table className="AdminExperimentsDashboard">
        <thead>
          <tr className="AdminExperimentsDashboard--header--row">
            <th className="AdminExperimentsDashboard--header--cell">Name</th>
            <th className="AdminExperimentsDashboard--header--cell">Active</th>
            <th className="AdminExperimentsDashboard--header--cell">Created</th>
            <th className="AdminExperimentsDashboard--header--cell">Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {experiments.map((experiment, index) => {
            const rowCx = classnames("AdminExperimentsDashboard--row", {
              "AdminExperimentsDashboard--row--border": index !== experiments.length - 1,
            });
            return (
              <tr key={`experiment-${experiment.name}`} className={rowCx}>
                <td
                  className="AdminExperimentsDashboard--cell link"
                  onClick={() => {
                    navigate(`/experiments/${experiment.id}`);
                  }}
                >
                  {experiment.name}
                </td>
                <td className="AdminExperimentsDashboard--cell">
                  <FormattedBoolean value={experiment.active} />
                </td>
                <td className="AdminExperimentsDashboard--cell">
                  <LongFormattedDateTime value={experiment.createdAt} />
                </td>
                <td className="AdminExperimentsDashboard--cell">
                  <LongFormattedDateTime value={experiment.updatedAt} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Content>
  );
}

export default function DashboardContainer() {
  return (
    <QueryWithLoading query={ExperimentsViewerQuery}>
      {({ data }) => {
        const viewer = data!.viewer;
        return <Dashboard experiments={viewer.experiments} />;
      }}
    </QueryWithLoading>
  );
}
