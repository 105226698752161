import { useParams } from "react-router-dom";

import { ModalScrollContent } from "common/core/modal_scroll_content";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import Video from "./video";
import MeetingQuery from "./meeting_video.graphql";

export default function Container() {
  // signer portal uses globalID, but we expect bundle to passed in from transaction details
  const { globalID } = useParams();
  const { data, loading } = useQuery(MeetingQuery, {
    variables: { meetingId: globalID || "" },
  });

  if (loading || !data) {
    return (
      <ModalScrollContent>
        <div className="NotarizationDetails--emptyContainer">
          <LoadingIndicator />
        </div>
      </ModalScrollContent>
    );
  }

  const { meeting } = data;

  if (meeting?.__typename !== "Meeting") {
    throw new Error(`Expected a meeting, got ${meeting?.__typename}`);
  }
  return <Video meeting={meeting} />;
}
