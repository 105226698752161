import { FormattedMessage } from "react-intl";
import { useNavigate, useMatch } from "react-router-dom";

import { EVENT } from "constants/analytics";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";
import { redirectToSubdomain } from "util/application_redirect";
import { segmentTrack } from "util/segment";
import { SETTINGS_PATH, SIGN_PATH } from "util/routes";
import { GET_STARTED_PATH } from "common/proof_frame/path";

import { NavButton } from ".";

function SignButton() {
  const navigate = useNavigate();
  const settingsMatch = useMatch({ path: SETTINGS_PATH, end: false });
  const getStartedMatch = useMatch({ path: GET_STARTED_PATH, end: false });

  const IS_BUSINESS_PORTAL = CURRENT_PORTAL === APPS.BUSINESS;

  const isActive = !IS_BUSINESS_PORTAL && !settingsMatch && !getStartedMatch;

  const handleSign = () => {
    if (IS_BUSINESS_PORTAL) {
      segmentTrack(EVENT.CLICKED_TO_ENTER_SIGN_FLOW);
      redirectToSubdomain(AppSubdomains.customer, SIGN_PATH);
    } else {
      navigate(SIGN_PATH);
    }
  };

  return (
    <NavButton onClick={handleSign} active={isActive} automationId="portal-switcher-sign">
      <FormattedMessage id="ee8496a9-e10f-4163-9f87-c153b237945b" defaultMessage="Sign" />
    </NavButton>
  );
}

export { SignButton };
