import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useDispatch } from "redux/util";
import { forgotPassword } from "redux/actions/authentication";
import Apps from "constants/applications";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import {
  FormattedFieldError,
  FieldErrorMessage,
  isAriaInvalid,
  emailPatternValidation,
  defaultRequiredMessage,
} from "common/core/form/error";
import ContentDivider from "common/core/content_divider";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import Link from "common/core/link";
import { Paragraph, Heading } from "common/core/typography";
import {
  TITLE_PRICING_URL,
  BUSINESS_PRICING_URL,
  NOTARY_MARKETING_RESOURCE_URL,
  PROOF_LENDER_PRICING_URL,
} from "constants/marketing";
import { getAuthenticationTypes } from "common/account/login/util";
import type { AuthCode } from "common/account/google_signin_button";
import { redirectUrl } from "util/application_redirect";
import TosMessage from "common/account/login/tos_message";

import ProofCard from "../card";
import type { PasswordScreen, MfaData } from "./password";
import { type SSOScreen, ErrorMessage as SamlErrorMessage } from "./saml";
import Styles from "./index.module.scss";
import { FooterLink, Continue, LoginHeader, SignInWithGoogle } from "./common";
import type { ResetPasswordScreen } from "./reset_password";
import type { MfaScreen } from "./mfa";

export type FormValues = {
  email: string;
  password: string;
};
export type EmailScreen = {
  type: "email";
  email?: string;
};
type NextScreen = SSOScreen | PasswordScreen | ResetPasswordScreen | MfaScreen;
type Props = {
  email?: string;
  onGoogleSignIn: (authCode: AuthCode) => void;
  onPasswordLogin: (values: FormValues) => Promise<MfaData | undefined>;
  onNextScreen: (screen: NextScreen) => void;
  hideFooter?: boolean;
};

const MESSAGES = defineMessages({
  emailLabel: {
    id: "65624fc5-a0a4-42ea-b7b1-595415053328",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "befc012e-9046-460f-b2c9-f16515ed0f34",
    defaultMessage: "Password",
  },
  invalidError: {
    id: "690bf7c4-9970-448e-8688-d5f56f2ad81b",
    defaultMessage: "Email or password is invalid",
  },
});

const LABEL_MESSAGES = defineMessages({
  emailLabel: {
    id: "94fb6364-09a7-4ab1-8b51-a5f95c897580",
    defaultMessage: "Account email address",
  },
});

function isGoogleSignInEnabled(ssoRequired: boolean) {
  // Google login code in backend is buggy for accounts that aren't signer or organization. It has to do with passing
  // the account type to our google auth endpoint, which only covers the signer/organization cases. Bad.
  return (
    !ssoRequired &&
    CURRENT_PORTAL !== Apps.NOTARY &&
    CURRENT_PORTAL !== Apps.TITLE_AGENCY &&
    CURRENT_PORTAL !== Apps.LENDER &&
    CURRENT_PORTAL !== Apps.ADMIN
  );
}

export default function EmailScreenCard({
  onGoogleSignIn,
  email,
  onPasswordLogin,
  onNextScreen,
  hideFooter = false,
}: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ssoRequired = searchParams.get("sso") === "required";
  const [samlError, setSamlError] = useState<string | null>(searchParams.get("samlError"));
  const [authTypeError, setAuthTypeError] = useState<boolean>(false);

  const isOktaPluginLogin =
    (document.referrer.startsWith("https://notarize.okta.com") ||
      document.referrer.startsWith("https://proof.okta.com")) &&
    !searchParams.get("samlError");

  const { handleSubmit, register, formState, setFocus, setError } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      email,
      password: "",
    },
  });

  const { errors, isSubmitted } = formState;

  const onEmailSubmit = async (formValues: FormValues) => {
    if (isOktaPluginLogin) {
      try {
        const mfaData = await onPasswordLogin({
          email: formValues.email,
          password: formValues.password,
        });
        if (mfaData) {
          onNextScreen({
            type: "mfa",
            authOptions: mfaData.auth_options,
            email: formValues.email,
            password: formValues.password,
          });
        }
      } catch {
        setError("password", {
          message: intl.formatMessage(MESSAGES.invalidError),
        });
      }
      return;
    }
    try {
      const response = await getAuthenticationTypes({ email: formValues.email });
      if (response.authenticationTypes.includes("sso")) {
        setSamlError(null);
        onNextScreen({
          type: "sso",
          email: formValues.email,
          ssoProvider: response.ssoProvider!,
          passwordAuthEnabled: response.authenticationTypes.includes("password"),
        });
      } else if (response.authenticationTypes.includes("email")) {
        dispatch(forgotPassword(formValues.email));
        onNextScreen({ type: "reset_password", email: formValues.email });
      } else {
        onNextScreen({ type: "password", email: formValues.email, password: formValues.password });
      }
    } catch {
      setAuthTypeError(true);
    }
  };

  const invalid = isSubmitted && !!(errors.email || errors.password);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <ProofCard
      header={<LoginHeader liveRegion="polite" />}
      body={
        <>
          <div className={Styles.formContainer}>
            <form onSubmit={handleSubmit(onEmailSubmit)} noValidate>
              <div className={Styles.formContainer}>
                <StyledTextInput
                  aria-invalid={isAriaInvalid(invalid)}
                  label={intl.formatMessage(MESSAGES.emailLabel)}
                  aria-label={intl.formatMessage(LABEL_MESSAGES.emailLabel)}
                  data-automation-id="email-field"
                  type="email"
                  {...register("email", {
                    required: defaultRequiredMessage(intl),
                    pattern: emailPatternValidation(intl),
                  })}
                />
                {/* Hidden for Autofill/Password Managers, but not Okta Plugin: RGB-2216, SE-3293
              -- It is **required** that the following input has attribute type="password"
              -- for the Okta Plugin to be able to enter the user's password
              */}
                {isOktaPluginLogin && (
                  <div className={Styles.passwordSection}>
                    <StyledTextInput
                      label={intl.formatMessage(MESSAGES.passwordLabel)}
                      aria-label={intl.formatMessage(MESSAGES.passwordLabel)}
                      autoComplete="current-password"
                      aria-invalid={isAriaInvalid(errors.password)}
                      type="password"
                      {...register("password")}
                    />
                  </div>
                )}
                {invalid && (
                  <FormattedFieldError
                    className={Styles.formError}
                    inputName="password"
                    error={errors.email || errors.password}
                  />
                )}
              </div>

              <Continue />
              {authTypeError && (
                <FieldErrorMessage
                  className={Styles.formError}
                  inputName="email"
                  message="* Invalid email"
                />
              )}
            </form>
            <TosMessage size="small" />
          </div>

          {samlError && <SamlErrorMessage errorMsg={samlError} />}

          {isGoogleSignInEnabled(ssoRequired) && (
            <>
              <ContentDivider />
              <SignInWithGoogle onGoogleSignIn={onGoogleSignIn} />
            </>
          )}
        </>
      }
      footer={
        !hideFooter && (
          <>
            <Heading level="h3" textStyle="subtitleSmall" className={Styles.footerSection}>
              <FormattedMessage
                id="33d63131-7270-4c10-bf35-798e5112d690"
                defaultMessage="Don't have an account? Get started below."
              />
            </Heading>
            <Paragraph className={Styles.footerSection}>
              <FormattedMessage
                id="5e5a6bd1-023d-4330-99d8-795ed799a8c3"
                defaultMessage="<link>Notarize a document</link> in 15 minutes. Anywhere, anytime."
                values={{
                  link: (txt) => (
                    <Link href={redirectUrl(AppSubdomains.customer, "/signup/upload")}>{txt}</Link>
                  ),
                }}
              />
            </Paragraph>
            <div className={Styles.footerSection}>
              <FormattedMessage
                id="828668a5-36ca-4b14-9c01-2f04e27b64fb"
                defaultMessage="Explore our business, real estate, and notary offerings."
              />
              <Paragraph className={Styles.footerSubSection}>
                <FooterLink className={Styles.footerPortalLink} href={BUSINESS_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Businesses"
                  />
                </FooterLink>
                <FooterLink className={Styles.footerPortalLink} href={TITLE_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Title agents"
                  />
                </FooterLink>
                <FooterLink className={Styles.footerPortalLink} href={PROOF_LENDER_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Mortgage lenders"
                  />
                </FooterLink>
                <FooterLink
                  className={Styles.footerPortalLink}
                  href={NOTARY_MARKETING_RESOURCE_URL}
                >
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Notaries"
                  />
                </FooterLink>
              </Paragraph>
            </div>
          </>
        )
      }
    />
  );
}
