import { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";

import { SearchField } from "common/core/search_field";

import Styles from "./proof_reason_codes.module.scss";
import { useProofReasonCodes } from "./context";

const MESSAGES = defineMessages({
  searchLabel: {
    id: "321bbc37-673d-4125-becc-9e37c2fca9bc",
    defaultMessage: "Search for reason codes",
  },
});

export default function CodeFilter() {
  const intl = useIntl();

  const { searchFilter, filterCodes } = useProofReasonCodes();

  const handleSearchChange = useCallback((input: { value: string }) => {
    filterCodes(input.value);
  }, []);

  return (
    <SearchField
      className={Styles.searchField}
      value={searchFilter}
      onChange={handleSearchChange}
      aria-label={intl.formatMessage(MESSAGES.searchLabel)}
    />
  );
}
