import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { Payer } from "graphql_globals";

type Props = {
  payer: Payer;
};

function FormattedPayer({ payer }: Props) {
  switch (payer) {
    case Payer.CUSTOMER:
      return (
        <FormattedMessage
          id="c7c83e5a-eec2-493b-a9b5-7f6d15b74392"
          defaultMessage="Pay by Customer"
        />
      );
    case Payer.ORGANIZATION:
    case Payer.ORGANIZATION_ACH:
      return (
        <FormattedMessage
          id="6ac5e550-fbb4-40b6-86dd-3641d9c1106e"
          defaultMessage="Pay by Company"
        />
      );
    case Payer.NOTARIZE:
      return (
        <FormattedMessage
          id="1ce1e83e-babd-4ee4-a2c4-7ef2e370df75"
          defaultMessage="Pay by Invoice"
        />
      );
    default:
      return <>{payer}</>;
  }
}

export default memo(FormattedPayer);
