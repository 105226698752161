import { useState, useEffect } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { OrganizationTypeEnum } from "graphql_globals";
import TextField from "common/form/fields/text";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";

import type { FullAdminOrganizationDetails_organization_Organization_owner as Owner } from "../details_query.graphql";
import UpdateContactEmailMutation from "./update_contact_email_mutation.graphql";

type FormValues = { contactEmail: string };
type Props = {
  organization: {
    id: string;
    organizationType: OrganizationTypeEnum;
    contactEmail: string | null;
    nonRealEstateContactEmail: string | null;
    owner: Owner | null;
  };
};
type InnerProps = Props & InjectedFormProps<FormValues, Props>;

function ContactEmailForm(props: InnerProps) {
  const [canPersistContactEmail, setCanPersistContactEmail] = useState(false);
  const [contactEmailChangeInProgress, setContactEmailChangeInProgress] = useState(false);
  const updateContactEmailMutateFn = useMutation(UpdateContactEmailMutation);
  const ownerEmail = props.organization.owner?.email;
  const mortgageOrg = [
    OrganizationTypeEnum.LENDER,
    OrganizationTypeEnum.TITLE_AGENCY,
    OrganizationTypeEnum.TITLE_UNDERWRITER,
  ].includes(props.organization.organizationType);
  const contactEmail =
    (mortgageOrg
      ? props.organization.contactEmail
      : props.organization.nonRealEstateContactEmail) || "";
  useEffect(() => {
    props.initialize({ contactEmail });
  }, []);

  const persistContactEmail = (values: FormValues) => {
    setContactEmailChangeInProgress(true);
    updateContactEmailMutateFn({
      variables: {
        input: {
          organizationId: props.organization.id,
          contactEmail: values.contactEmail,
        },
      },
    }).finally(() => {
      setContactEmailChangeInProgress(false);
      setCanPersistContactEmail(false);
    });
  };

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="41b0124a-6115-4495-abc1-bc7f1f1057a2"
          defaultMessage="Contact Email"
        />
      </SettingsTitle>
      <form autoComplete="off" onSubmit={props.handleSubmit(persistContactEmail)}>
        <Card
          footer={
            canPersistContactEmail && (
              <Button
                type="submit"
                buttonColor="action"
                variant="primary"
                disabled={contactEmailChangeInProgress}
              >
                <FormattedMessage
                  id="665fc945-2e67-47d3-898e-417dab080aa0"
                  defaultMessage="Save contact email"
                />
              </Button>
            )
          }
        >
          <CardHeading>
            <FormattedMessage id="a6bff0fe-a0ee-435f-b20f-fd055e1235b6" defaultMessage="Email" />
          </CardHeading>
          <TextField
            useStyledInput
            placeholder={!mortgageOrg && ownerEmail}
            id="contactEmail"
            name="contactEmail"
            onChange={() => setCanPersistContactEmail(true)}
          />
        </Card>
      </form>
    </>
  );
}

export default reduxForm<FormValues, Props>({ form: "contactEmailForm" })(ContactEmailForm);
