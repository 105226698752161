import { useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import classNames from "classnames";

import { HookFormPassword } from "common/account/password/password";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import { useA11y } from "common/accessibility";
import { useSelector } from "redux/util";
import TosMessage from "common/account/login/tos_message";

import ProofCard from "../card";
import { BackButton, LoginHeader, Continue, FooterButton } from "./common";
import Styles from "./index.module.scss";
import type { MfaScreen, MfaAuthOption } from "./mfa";
import type { ForgotPasswordScreen } from "./forgot_password";
import type { EmailScreen } from "./email";

export type FormValues = {
  password: string;
};

const MESSAGES = defineMessages({
  emailLabel: {
    id: "b9f407a9-5708-492d-9f8a-763b4d246e3e",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "9b84ddd1-0053-46b9-ba31-b94272047ad3",
    defaultMessage: "Password",
  },
  backButtonAriaLabel: {
    id: "db04da04-83e0-4182-8a77-3977a3a98f53",
    defaultMessage: "Back to email input",
  },
  invalidError: {
    id: "0bb4c6ab-ec27-4cfd-b981-fe1ebf12791c",
    defaultMessage: "Email or password is invalid",
  },
  forgotPasswordLabel: {
    id: "01ae0cd6-4583-4fad-869a-bcc48215cb53",
    defaultMessage: "Forgot password?",
  },
});

export type PasswordScreen = {
  type: "password";
  email: string;
  password?: string;
};

export type MfaData = {
  auth_options: MfaAuthOption[];
};

type Props = {
  password?: string;
  email: string;
  withEmailReminder?: boolean;
  onNextScreen(screen: MfaScreen | EmailScreen | ForgotPasswordScreen): void;
  onPasswordLogin: (values: FormValues & { email: string }) => Promise<MfaData | undefined>;
};

export default function PasswordScreenCard({
  onPasswordLogin,
  password,
  withEmailReminder,
  email,
  onNextScreen,
}: Props) {
  const intl = useIntl();
  const [loginLocked, setLoginLocked] = useState(false);
  const form = useForm<FormValues>({ mode: "all", defaultValues: { password: password || "" } });
  const { handleSubmit, register, formState, setFocus, setError } = form;
  const { errors, isSubmitted } = formState;
  const errorId = useA11y().useRegisteredId("password");
  const authentication = useSelector((state) => state.authentication);

  const submitHandler = async (values: FormValues) => {
    if (loginLocked) {
      return;
    }
    setLoginLocked(true);
    try {
      const mfaData = await onPasswordLogin({ email, password: values.password });
      if (mfaData) {
        onNextScreen({
          type: "mfa",
          authOptions: mfaData.auth_options,
          email,
          password: values.password,
        });
      }
    } catch {
      // We expect a success to redirect us. We don't need or want to unlock.
      setLoginLocked(false);
      setFocus("password");
      setError("password", {
        type: authentication.error.type,
        message: intl.formatMessage(MESSAGES.invalidError),
      });
    }
  };

  return (
    <ProofCard
      header={<LoginHeader />}
      body={
        <>
          <form onSubmit={handleSubmit(submitHandler)} noValidate>
            {withEmailReminder && (
              <StyledTextInput
                disabled
                aria-invalid="false"
                value={email}
                label={intl.formatMessage(MESSAGES.emailLabel)}
              />
            )}
            <HookFormPassword
              setFocus={setFocus}
              label={intl.formatMessage(MESSAGES.passwordLabel)}
              registerProps={register("password", { required: defaultRequiredMessage(intl) })}
              invalid={Boolean(errors.password && isSubmitted)}
              error={isSubmitted ? errors.password : undefined}
              aria-describedby={authentication.error.error ? errorId : undefined}
            />
            <Continue disabled={loginLocked} />
            <TosMessage size="small" />
          </form>
        </>
      }
      footer={
        <div className={classNames(Styles.footerCentered)}>
          <BackButton
            onClick={() => onNextScreen({ type: "email", email })}
            aria-label={intl.formatMessage(MESSAGES.backButtonAriaLabel)}
          />
          <FooterButton
            variant="tertiary"
            buttonColor="action"
            onClick={() => {
              onNextScreen({ type: "forgot_password", email });
            }}
          >
            {intl.formatMessage(MESSAGES.forgotPasswordLabel)}
          </FooterButton>
        </div>
      }
    />
  );
}
