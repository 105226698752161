import { FormattedMessage } from "react-intl";
import { useNavigate, useMatch } from "react-router-dom";

import CreateOrganizationMutation from "util/apollo_graphql/mutations/create_organization/mutation.graphql";
import { useMutation } from "util/graphql";
import { EVENT } from "constants/analytics";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";
import { redirectToSubdomain } from "util/application_redirect";
import { captureException } from "util/exception";
import { segmentTrack } from "util/segment";
import { EASYLINK_DASHBOARD_PATH, TEMPLATES_DASHBOARD_PATH, TRANSACTION_PATH } from "util/routes";

import type { AccountHoverMenuUser as User } from "../user_fragment.graphql";
import { NavButton } from ".";

function SendManageButton(props: { user: User; className?: string }) {
  const navigate = useNavigate();
  const createOrganizationMutateFn = useMutation(CreateOrganizationMutation);

  const IS_BUSINESS_PORTAL = CURRENT_PORTAL === APPS.BUSINESS;

  const isSendActive = Boolean(useMatch({ path: TRANSACTION_PATH, end: false }));
  const isEasylinkActive = Boolean(useMatch({ path: EASYLINK_DASHBOARD_PATH, end: false }));
  const isTemplatesActive = Boolean(useMatch({ path: TEMPLATES_DASHBOARD_PATH, end: false }));

  const isActive = isSendActive || isEasylinkActive || isTemplatesActive;

  const handleSend = () => {
    if (IS_BUSINESS_PORTAL) {
      navigate(TRANSACTION_PATH);
    } else {
      segmentTrack(EVENT.CLICKED_TO_ENTER_SEND_FLOW);
      const operation = props.user.organization
        ? Promise.resolve()
        : createOrganizationMutateFn({ variables: { input: { userId: props.user.id } } });
      return operation
        .catch(() => {
          captureException(new Error("Error creating signer organization from header"));
        })
        .finally(() => {
          segmentTrack(EVENT.CREATE_SIGNER_ORGANIZATION_FROM_HEADER);
          redirectToSubdomain(AppSubdomains.business, TRANSACTION_PATH);
        });
    }
  };

  return (
    <NavButton
      onClick={handleSend}
      active={isActive}
      className={props.className}
      automationId="portal-switcher-send"
    >
      <FormattedMessage id="127e9b1e-9d09-48da-a808-af956656d0fb" defaultMessage="Send & manage" />
    </NavButton>
  );
}

export { SendManageButton };
