import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { SearchField } from "common/core/search_field";
import Button from "common/core/button";
import Link from "common/core/link";
import { StandardTable } from "common/table";
import TableRow from "common/table/row";
import { Query } from "util/graphql/query";

import TransactionSearchToolQuery from "./index_query.graphql";

class TransactionSearchTool extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: props.initSearchTerm };
  }

  onSearchAndShowTransactions = () => {
    this.props.setSearchParams({ transactionSearch: this.state.searchTerm });
  };

  render() {
    const {
      viewer: { queriedTransactions },
      viewer,
      isLoading,
    } = this.props;
    const { searchTerm } = this.state;

    let queriedTransactionsDisplay = (
      <p className="no-transactions-message">
        <FormattedMessage
          id="b3d84276-1bd3-4a4a-a56d-8e2afe57d5ce"
          defaultMessage="No transactions found."
        />
      </p>
    );

    if (viewer.queriedTransactions && queriedTransactions.totalCount > 0) {
      const rows = queriedTransactions.edges.map(({ node: tx }) => {
        return (
          <TableRow
            key={tx.id}
            automationId={`transaction-row-${tx.id}`}
            cells={[
              {
                children: (
                  <Link to={`/companies/${tx.organization.id}/transactions/${tx.id}/summary`}>
                    {tx.id}
                  </Link>
                ),
              },
              { children: tx.detailedStatus },
              {
                children: tx.customerSigners.map((signer) => {
                  return (
                    <div key={signer.id} className="multiline">
                      {signer.firstName} {signer.lastName} ({signer.email})
                    </div>
                  );
                }),
              },
              {
                children: (
                  <Link to={`/companies/${tx.organization.id}/transactions`}>
                    {tx.organization.name}
                  </Link>
                ),
              },
            ]}
          />
        );
      });

      queriedTransactionsDisplay = (
        <div className="display">
          <StandardTable
            headings={["GID", "Status", "Signers", "Organization"]}
            automationId="transaction-search-tool-table"
          >
            {rows}
          </StandardTable>
        </div>
      );
    }

    return (
      <div className="TransactionSearchTool">
        <div className="search-bar">
          <SearchField
            className="search-field"
            onChange={(q) => this.setState({ searchTerm: q.value })}
            onSearch={this.onSearchAndShowTransactions}
            value={searchTerm}
            placeholder="Enter a transaction GID, file / loan number or signer email / last name. Draft transactions are supported."
            aria-label="Search for a transaction"
            noIcon
          />
          <Button
            buttonColor="action"
            variant="primary"
            onClick={this.onSearchAndShowTransactions}
            isLoading={isLoading}
            automationId="admin-transaction-search-button"
          >
            Search
          </Button>
        </div>
        {!isLoading && queriedTransactionsDisplay}
      </div>
    );
  }
}

TransactionSearchTool.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
    queriedTransactions: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            detailedStatus: PropTypes.string,
            organization: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
            customerSigners: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
              }),
            ),
          }),
        }),
      ),
    }),
  }).isRequired,
  isLoading: PropTypes.bool,
  initSearchTerm: PropTypes.string,
  setSearchParams: PropTypes.func.isRequired,
};

function TransactionSearchToolContainer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get("transactionSearch") || "";
  return (
    <Query query={TransactionSearchToolQuery} variables={{ searchTerm }} skip={!searchTerm}>
      {({ data, loading }) => (
        <TransactionSearchTool
          viewer={!loading && data ? data.viewer : {}}
          setSearchParams={setSearchParams}
          initSearchTerm={searchTerm}
          isLoading={loading}
        />
      )}
    </Query>
  );
}

export default TransactionSearchToolContainer;
