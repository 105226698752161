import { FormattedMessage } from "react-intl";
import { useState } from "react";
import classNames from "classnames";

import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import ProcessingVideo from "common/details/meeting/videos/processing_video";
import { durationString } from "util/meeting";
import { CheckboxGroup } from "common/core/form/option";
import Checkbox from "common/form/fields/checkbox";
import ActionButton from "common/core/action_button";
import FormGroupErrors from "common/form/group_errors";

import type { QualityControlDocumentBundle_meetings_edges_node as Meeting } from "./quality_control_document_bundle_fragment.graphql";
import Styles from "./index.module.scss";

function MeetingInfo({ meeting }: { meeting: Meeting }) {
  return (
    <DeprecatedDetailGrid>
      <div className={Styles.video}>
        <ProcessingVideo src={meeting.video?.recording?.url} />
      </div>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage
              id="759e51bd-4b77-47a4-9492-2c148a11e6e8"
              defaultMessage={"Meeting Date & Time"}
            />
          </div>
        }
      >
        {durationString(meeting)}
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage
              id="9b736edb-7374-44e5-b937-2b8c298c3136"
              defaultMessage="Video Service Provider"
            />
          </div>
        }
      >
        {meeting.video?.provider}
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage
              id="08e2fdb8-2211-4f35-9175-e871ea34b389"
              defaultMessage="Video Session Id"
            />
          </div>
        }
      >
        {meeting.video?.id}
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGrid>
  );
}

function MeetingQuestions({ meetingId }: { meetingId: string }) {
  return (
    <div className={Styles.questions}>
      <CheckboxGroup
        groupName="experiment"
        label={
          <div className={Styles.questionTitle}>
            <FormattedMessage
              id="1e3fd481-ad39-495c-a0f1-eb6aafb2ba2f"
              defaultMessage="Video Issues"
            />
          </div>
        }
      >
        <div className={Styles.checkboxes}>
          <Checkbox
            label={
              <FormattedMessage id="698338b4-0dd6-4319-9a8b-3897681d2bd5" defaultMessage="None" />
            }
            value="none"
            name={`videoIssues[${meetingId}].none`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="4da908a6-2728-4265-b3e4-bdaa5b43b305"
                defaultMessage="Notary didn't request signer's consent to being recorded"
              />
            }
            name={`videoIssues[${meetingId}].notaryDidNotRequestSignerConsentToRecording`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="06f7547e-6be9-4ee7-8ca2-f50edb66633f"
                defaultMessage="Notary didn't announce type of notarial act performed (FL)"
              />
            }
            name={`videoIssues[${meetingId}].notaryDidNotAnnounceTypeOfNotarialActPerformedFL`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="3f746484-f4c9-4365-a4c5-45ea886efc50"
                defaultMessage="Notary didn't prompt signer to choose notarial act"
              />
            }
            name={`videoIssues[${meetingId}].notaryDidNotPromptSignerToChooseNotarialAct`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="6d5de263-8794-4a21-8ed2-f88d4f0618a5"
                defaultMessage="Signer didn't acknowledge that they pre-signed the document"
              />
            }
            name={`videoIssues[${meetingId}].signerDidNotAknowledgeTheyPresignedTheDocument`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="d12193f4-eb1f-4a24-9bee-c3167f3be9af"
                defaultMessage="Signer didn't take oath"
              />
            }
            name={`videoIssues[${meetingId}].signerDidNotTakeOath`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="b5e4b44d-7b2f-4736-bc2f-91bb9b89f42c"
                defaultMessage="Audio connection not maintained for duration of session"
              />
            }
            value="audio_connection_not_maintained_for_duration_of_session"
            name={`videoIssues[${meetingId}].audioConnectionNotMaintainedForDurationOfSession`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="d51b9849-85eb-441d-9820-cbc9fd5f57f4"
                defaultMessage="Video connection not maintained for duration of session"
              />
            }
            name={`videoIssues[${meetingId}].videoConnectionNotMaintainedForDurationOfSession`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="d51b9849-85eb-441d-9820-cbc9fd5f57f4"
                defaultMessage="Signer didn't confirm that they knowingly and voluntarily signed the document (FL)"
              />
            }
            name={`videoIssues[${meetingId}].signerDidNotConfirmTheyVoluntarilySignedTheDocFL`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="62022684-1976-41ab-828d-1a34821c7a92"
                defaultMessage="Witness(es) signed without actually witnessing the signing"
              />
            }
            name={`videoIssues[${meetingId}].witnessSignedWithoutActuallyWitnesingTheSigning`}
          />
        </div>
      </CheckboxGroup>
      <FormGroupErrors fields={[`videoIssues_${meetingId}`]} />
    </div>
  );
}

function MeetingSection({ meetings }: { meetings: Meeting[] }) {
  const [activeMeeting, setActiveMeeting] = useState<Meeting | undefined>(meetings[0]);
  if (!activeMeeting) {
    return null;
  }

  return (
    <div className={Styles.formColumns}>
      <div className={Styles.info}>
        {meetings.length > 1 && (
          <div className={Styles.signerSectionTitle}>
            {meetings.map((meeting, index) => {
              const active = activeMeeting.id === meeting.id;
              return (
                <ActionButton
                  className={classNames(Styles.signerName, active && Styles.active)}
                  onClick={() => setActiveMeeting(meeting)}
                  key={meeting.id}
                >
                  <FormattedMessage
                    id="8d087fb7-a733-4bb1-9025-1b382addc95e"
                    defaultMessage="Meeting {index}"
                    values={{ index: index + 1 }}
                  />
                </ActionButton>
              );
            })}
          </div>
        )}
        <div className={Styles.sectionTitle}>
          <FormattedMessage id="656e9f1e-15da-405c-9200-a25fc1ae4734" defaultMessage="Meeting" />
        </div>
        <MeetingInfo meeting={activeMeeting} />
      </div>
      <MeetingQuestions meetingId={activeMeeting.id} />
    </div>
  );
}

export default MeetingSection;
