import { defineMessages, useIntl } from "react-intl";

import Icon from "common/core/icon";
import Link from "common/core/link";
import Tooltip from "common/core/tooltip";

import Styles from "./task_link.module.scss";

type Props = {
  id: string;
  label: string;
  description: string;
};

const MESSAGES = defineMessages({
  triggerTooltipLabel: {
    id: "e7e3e63d-8da1-42ae-aad3-b8fc2dd54367",
    defaultMessage: 'Description of "{label}" task',
  },
});

export default function TaskLink({ id, label, description }: Props) {
  const intl = useIntl();

  return (
    <div className={Styles.link}>
      <Link to={`/tasks?taskId=${id}`} underlined={false}>
        {label}
      </Link>
      <Tooltip
        target={<Icon name="question" />}
        placement="rightBottom"
        triggerButtonLabel={intl.formatMessage(MESSAGES.triggerTooltipLabel, { label })}
      >
        {description}
      </Tooltip>
    </div>
  );
}
