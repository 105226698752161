import { injectIntl, defineMessages, FormattedMessage, type IntlShape } from "react-intl";

import Button from "common/core/button";
import { PHONE } from "common/video_conference/audio_video_settings/container";
import { isValid } from "common/form/inputs/phone/number";
import { MASTER_GENERAL_TERMS_URL, PROOF_MASTER_PRIVACY_POLICY_URL } from "constants/globals";

const MESSAGES = defineMessages({
  join: {
    id: "4c51700a-c0ec-44be-9097-7fb168313e58",
    defaultMessage: "Join Meeting",
  },
  termOfService: {
    id: "3ee140dd-bd68-47d1-a026-a263beb6f828",
    defaultMessage: "Term of Service",
  },
  privacyPolicy: {
    id: "733f49b3-6de4-4323-b94e-a35ba1e6c9ce",
    defaultMessage: "Privacy Policy",
  },
});
type Props = {
  audioSource: string;
  intl: IntlShape;
  onClick: (args: { phoneNumber?: string }) => void;
  selectedDevices: { phone?: string };
};
function ButtonItem({ audioSource, intl, onClick, selectedDevices }: Props) {
  const disabled =
    audioSource === PHONE &&
    (!selectedDevices.phone || !isValid({ phoneNumber: selectedDevices.phone }));
  return (
    <>
      <div>
        <FormattedMessage
          id="7ba54791-93cb-42e2-ad81-94460c149a20"
          defaultMessage="As an account holder by clicking {joinMeeting} I consent to Notarize creating a audio-video recording of me and that the {termOfService} and {privacyPolicy} that I have previously agreed to will apply to the recording and all actions I take on the Platform."
          values={{
            joinMeeting: <em>{intl.formatMessage(MESSAGES.join)}</em>,
            termOfService: (
              <a href={MASTER_GENERAL_TERMS_URL} target="_blank" rel="noreferrer noopener">
                {intl.formatMessage(MESSAGES.termOfService)}
              </a>
            ),
            privacyPolicy: (
              <a href={PROOF_MASTER_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer noopener">
                {intl.formatMessage(MESSAGES.privacyPolicy)}
              </a>
            ),
          }}
        />
      </div>
      <Button
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        fullwidth
        disabled={disabled}
        onClick={() => onClick({ phoneNumber: selectedDevices.phone })}
        automationId="join-meeting-button"
      >
        {intl.formatMessage(MESSAGES.join)}
      </Button>
    </>
  );
}

export default injectIntl(ButtonItem);
