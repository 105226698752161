import { useState, type ChangeEvent } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "admin_portal/company/details_query.graphql";
import { Card, CardHeading, CardText } from "common/core/card";
import { usePermissions } from "common/core/current_user_role";
import { SettingsTitle } from "common/settingsv2/common";
import { TextTagSyntax, Feature, ConfigurableFeature } from "graphql_globals";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import Button from "common/core/button";
import Select from "common/form/inputs/select";
import { StyledTextInput } from "common/form/inputs/text";
import { pushNotification } from "common/core/notification_center/actions";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useFeatureFlag } from "common/feature_gating";
import { Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";
import DocVisibilityToggle from "../../details/toggles/doc_visibility";
import { Settings } from "../../settings";
import { FeatureFlags } from "../../feature_flags";
import UpdateOrganizationAutoIdentifyAndTagMutation from "./update_organization_auto_identify_and_tag_mutation.graphql";
import UdpateOrganizationTextTagSyntaxMutation from "./update_organization_text_tag_syntax_mutation.graphql";
import UpdateNotaryWaitTimeMutation from "./update_organization_notary_wait_time_mutation.graphql";
import ToggleOrganizationFeatureMutation from "./toggle_organization_feature_mutation.graphql";
import ToggleOrganizationCustomPanelsFeatureMutation from "./toggle_organization_custom_panels_feature_mutation.graphql";
import ToggleOrganizationNotariesFeatureMutation from "./toggle_organization_notaries_feature_mutation.graphql";
import ToggleOrganizationSuppressSignerEmailsMutation from "./toggle_organization_suppress_signer_emails_mutation.graphql";
import ToggleOrganizationKbaRequirementMutation from "./toggle_organization_kba_requirement_mutation.graphql";
import ExportOrganizationDocumentTemplatesMutation from "./export_organization_document_templates_mutation.graphql";
import UpdateLenderProfileMutation from "../update_lender_profile_mutation.graphql";
import GenerateOrganizationApiKeyMutation from "./generate_organization_api_key_mutation.graphql";
import SetOrganizationAsTestAccountMutation from "./set_organization_as_test_account_mutation.graphql";
import { FeatureToggle } from "./feature_toggle";

type Props = {
  organization: Organization;
};
type StatusState =
  | { status: null }
  | { status: "loading" }
  | { status: "error"; message: string | null };

const TextTagSyntaxTypeList = Object.values(TextTagSyntax).map((value) => ({
  label: value,
  value,
}));

const INIT_STATE: StatusState = { status: null };
const MESSAGES = defineMessages({
  saveSuccess: {
    id: "81793c19-77af-49ab-894f-f005f7ace4f1",
    defaultMessage: "You have successfully updated your text tag syntax",
  },
  templateExport: {
    id: "f4801d21-1ca6-4f3d-a98c-da52ef3e6e0e",
    defaultMessage:
      "We have started exporting all the templates for this organization. This can take up to 10 minutes, you will be emailed once we have finished.",
  },
  apiKeyGenerated: {
    id: "e2834f7b-2085-42b0-9a3e-77b205198503",
    defaultMessage: "Successfully generated API Key",
  },
  apiKeyRevokeAndReGenerated: {
    id: "e8cc3a67-9321-466d-832d-14b31a52f32c",
    defaultMessage: "Successfully revoked and generated a new API Key",
  },
  apiKeyFailed: {
    id: "9cbac127-e6d8-4676-9f34-c57e1b67992c",
    defaultMessage: "Failed to generate API Key",
  },
});

export function OrganizationFeatures({ organization }: Props) {
  const {
    id,
    apiKey,
    defaultTextTagSyntax,
    isTest,
    organizationTransactions,
    featureList,
    lenderAccess,
    defaultVerifiedEsignEnabled,
    wetSignEnabled,
    autoIdentifyAndTag,
    showApi,
  } = organization;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const canUpdateCompany = hasPermissionFor("editCompanyDetails");
  const canUpdateParentOrgNotaryAssignment = hasPermissionFor("editParentOrgNotaryAssignment");
  const canViewSettings = hasPermissionFor("viewSettingsUpdateTool");
  const hideApi = useFeatureFlag("hide-api");
  const disableApiKey = hideApi && !showApi;

  const [status, setStatus] = useState(INIT_STATE);

  const overflowEnabled = featureList.includes(Feature.ORG_NOTARY_OVERFLOW);
  const organizationNotariesEnabled = featureList.includes(Feature.ORGANIZATION_NOTARIES);
  const credibleWitnessEnabled = featureList.includes(Feature.CREDIBLE_WITNESS);
  const childOrganizationsEnabled = featureList.includes(Feature.CHILD_ORGANIZATIONS);
  const hidePersonallyKnown =
    // isLegacyNotary -- this cannot be easily migrated
    featureList.includes(Feature.BYOT_NOTARY) || !organizationNotariesEnabled;
  const personallyKnownEnabled = featureList.includes(Feature.PERSONALLY_KNOWN_SIGNER_ID);
  const customPanelsEnabled = featureList.includes(Feature.CUSTOM_PANELS);
  const parentOrgNotaryAssignmentEnabled = featureList.includes(
    Feature.PARENT_ORG_NOTARY_ASSIGNMENT,
  );

  const [notaryWaitTime, setNotaryWaitTime] = useState(
    organization.notaryWaitTime?.toString() || "",
  );
  const [selectedTextTagSyntax, setSelectedTextTagSyntax] = useState(defaultTextTagSyntax);

  const totalTransactions = organizationTransactions.totalCount;
  const PKN_TOGGLE_EXCEPTION = "org_notaries_off_while_pkn_on";

  const updateOrganizationTextTagSyntaxMutateFn = useMutation(
    UdpateOrganizationTextTagSyntaxMutation,
  );
  const toggleOrganizationFeatureMutateFn = useMutation(ToggleOrganizationFeatureMutation);
  const toggleOrganizationCustomPanelsFeatureMutateFn = useMutation(
    ToggleOrganizationCustomPanelsFeatureMutation,
  );
  const toggleOrganizationNotariesFeatureMutateFn = useMutation(
    ToggleOrganizationNotariesFeatureMutation,
  );
  const updateNotaryWaitTimeMutateFn = useMutation(UpdateNotaryWaitTimeMutation);
  const toggleOrganizationSuppressSignerEmailsMutateFn = useMutation(
    ToggleOrganizationSuppressSignerEmailsMutation,
  );
  const toggleOrganizationKbaRequirementMutationFn = useMutation(
    ToggleOrganizationKbaRequirementMutation,
  );
  const updateLenderProfileMutationFn = useMutation(UpdateLenderProfileMutation);
  const updateOrganizationAutoIdentifyAndTagMutateFn = useMutation(
    UpdateOrganizationAutoIdentifyAndTagMutation,
  );
  const generateOrganizationApiKeyMutateFn = useMutation(GenerateOrganizationApiKeyMutation);
  const setOrganizationAsTestAccountMutateFn = useMutation(SetOrganizationAsTestAccountMutation);

  const exportTemplatesFn = useMutation(ExportOrganizationDocumentTemplatesMutation);

  function handleError(error: Error) {
    if (!isGraphQLError(error)) {
      captureException(error);
    }
    const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
    setStatus({
      status: "error",
      message: (err as unknown as { description?: string }).description || err.message,
    });
  }

  function onApiKeyGenerate() {
    generateOrganizationApiKeyMutateFn({ variables: { input: { id: organization.id } } })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          position: "topCenter",
          message: apiKey
            ? intl.formatMessage(MESSAGES.apiKeyRevokeAndReGenerated)
            : intl.formatMessage(MESSAGES.apiKeyGenerated),
        });
      })
      .catch((err) => {
        captureException(err);
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          position: "topCenter",
          message: intl.formatMessage(MESSAGES.apiKeyFailed),
        });
      });
  }

  function onExportTemplates() {
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      message: intl.formatMessage(MESSAGES.templateExport),
    });
    exportTemplatesFn({
      variables: {
        input: {
          organizationId: organization.id,
        },
      },
    });
  }

  async function updateTextTagSyntax() {
    setStatus({ status: "loading" });
    try {
      await updateOrganizationTextTagSyntaxMutateFn({
        variables: {
          input: {
            id,
            defaultTextTagSyntax: selectedTextTagSyntax,
          },
        },
      });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.saveSuccess),
      });
      setStatus(INIT_STATE);
    } catch (e: unknown) {
      setStatus({ status: "error", message: (e as Error).message });
    }
  }

  async function onTestAccountChange() {
    await setOrganizationAsTestAccountMutateFn({
      variables: { input: { organizationId: organization.id } },
    });
    setStatus(INIT_STATE);
  }

  async function toggleOrgNotariesFeature() {
    try {
      await toggleOrganizationNotariesFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            active: !organization.featureList.includes(Feature.ORGANIZATION_NOTARIES),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      if (!isGraphQLError(error)) {
        captureException(error);
      }
      const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
      const message =
        (err as Error).message === PKN_TOGGLE_EXCEPTION
          ? "Personally known to notary must be turned off before organization notaries can be turned off"
          : (err as Error).message;
      setStatus({
        status: "error",
        message: (err as { description?: string }).description || message,
      });
    }
  }

  async function toggleCustomPanelsFeature() {
    try {
      await toggleOrganizationCustomPanelsFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleOverflowFeature() {
    try {
      await toggleOrganizationFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            feature: ConfigurableFeature.ORG_NOTARY_OVERFLOW,
            active: !organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function updateNotaryWaitTime() {
    setStatus({ status: "loading" });
    try {
      await updateNotaryWaitTimeMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            waitTime: Number(notaryWaitTime),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleCredibleWitnessFeature() {
    try {
      await toggleOrganizationFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            feature: ConfigurableFeature.CREDIBLE_WITNESS,
            active: !organization.featureList.includes(Feature.CREDIBLE_WITNESS),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleChildOrganizationsFeature() {
    try {
      await toggleOrganizationFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            feature: ConfigurableFeature.CHILD_ORGANIZATIONS,
            active: !organization.featureList.includes(Feature.CHILD_ORGANIZATIONS),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleParentOrgNotaryAssignmentFeature() {
    try {
      await toggleOrganizationFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            feature: ConfigurableFeature.PARENT_ORG_NOTARY_ASSIGNMENT,
            active: !parentOrgNotaryAssignmentEnabled,
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleSuppressSignerEmailsFeature() {
    try {
      await toggleOrganizationSuppressSignerEmailsMutateFn({
        variables: { input: { id } },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleOrganizationKbaRequirementFeature() {
    try {
      await toggleOrganizationKbaRequirementMutationFn({
        variables: {
          input: { id, enabled: !defaultVerifiedEsignEnabled },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function toggleWetSign() {
    try {
      await updateLenderProfileMutationFn({
        variables: {
          input: { organizationId: organization.id, wetSignUploadEnabled: !wetSignEnabled },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  async function togglePersonallyKnownIdentification() {
    try {
      await toggleOrganizationFeatureMutateFn({
        variables: {
          input: {
            id: organization.id,
            feature: ConfigurableFeature.PERSONALLY_KNOWN_SIGNER_ID,
            active: !organization.featureList.includes(Feature.PERSONALLY_KNOWN_SIGNER_ID),
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      if (!isGraphQLError(error)) {
        captureException(error);
      }

      const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
      const message =
        (err as Error).message === PKN_TOGGLE_EXCEPTION
          ? "Personally known to notary cannot be turned on without company notaries"
          : (err as Error).message;
      setStatus({
        status: "error",
        message: (err as { description?: string }).description || message,
      });
    }
  }

  async function toggleAutoIdentifyAndTag() {
    try {
      await updateOrganizationAutoIdentifyAndTagMutateFn({
        variables: {
          input: {
            id: organization.id,
            autoIdentifyAndTag: !autoIdentifyAndTag,
          },
        },
      });
      setStatus(INIT_STATE);
    } catch (error) {
      handleError(error as Error);
    }
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="41b0124a-6115-4495-abc1-bc7e1f1066a8" defaultMessage="Features" />
      </SettingsTitle>
      <Card className={Styles.orgDetailsCard}>
        {canUpdateCompany && (
          <>
            <CardHeading>
              <FormattedMessage
                id="3ef56f8b-254f-4d2f-8895-74cd119ad274"
                defaultMessage="API Key"
              />
            </CardHeading>

            {apiKey && (
              <Paragraph data-automation-id="organization-api-key" style={{ marginBottom: "16px" }}>
                {apiKey}
              </Paragraph>
            )}
            <Button
              variant="secondary"
              buttonColor={apiKey ? "danger" : "action"}
              onClick={onApiKeyGenerate}
              automationId={"generate-api-key-button"}
              disabled={disableApiKey}
            >
              {apiKey ? "Revoke And Generate New Key" : "Generate API Key"}
            </Button>
            {disableApiKey && (
              <Paragraph textColor="subtle" className={Styles.helperText}>
                <FormattedMessage
                  id="d1d5cabc-3326-42eb-939f-733f5b12b4ea"
                  defaultMessage="You must first enable Show API organization flag for this organization in order to generate an API key."
                />
              </Paragraph>
            )}
          </>
        )}

        {canUpdateCompany && (
          <>
            <CardHeading>
              <FormattedMessage
                id="c5023d49-a67f-4695-8475-cd63116b0e83"
                defaultMessage="Text Tag Syntax"
              />
            </CardHeading>
            <Select
              items={TextTagSyntaxTypeList}
              onChange={setSelectedTextTagSyntax}
              value={selectedTextTagSyntax}
              autosize={false}
              disabled={status.status === "loading"}
              automationId="organization-text-tag-syntax"
            />
            {defaultTextTagSyntax !== selectedTextTagSyntax && (
              <Button
                className={Styles.saveButton}
                buttonColor="action"
                variant="primary"
                onClick={() => updateTextTagSyntax()}
                disabled={status.status === "loading"}
                automationId="update-text-tag-syntax"
              >
                <FormattedMessage id="00bcfbd5-2be3-437d-9979-26741b098e27" defaultMessage="Save" />
              </Button>
            )}
          </>
        )}

        {
          <>
            <CardHeading>
              <FormattedMessage
                id="f30804d4-1112-403f-bc83-2efcef7b849b"
                defaultMessage="Templates"
              />
            </CardHeading>

            <Button variant="secondary" buttonColor="action" onClick={onExportTemplates}>
              {"Export Templates"}
            </Button>
          </>
        }

        <FeatureToggle
          title={
            <FormattedMessage
              id="7efb46e8-64c7-42db-a4bc-a2bf8d54f95b"
              defaultMessage="Test Account"
            />
          }
          onChange={onTestAccountChange}
          value={isTest}
          disabled={!canUpdateCompany || isTest || totalTransactions > 0}
          automationId="testAccount"
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="fe72e498-c5c9-46a9-850c-00c43270483b"
              defaultMessage="Use In-House Notaries"
            />
          }
          onChange={toggleOrgNotariesFeature}
          value={organizationNotariesEnabled}
          disabled={!canUpdateCompany}
          automationId="organization-notaries"
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="8ca7cc23-2cf3-4501-a2a6-5c51a3e21b52"
              defaultMessage="Use Panel Notaries"
            />
          }
          onChange={toggleCustomPanelsFeature}
          value={customPanelsEnabled}
          disabled={!canUpdateCompany}
          automationId="custom-panels"
        />

        {(organizationNotariesEnabled || customPanelsEnabled) && (
          <FeatureToggle
            title={
              <FormattedMessage
                id="8ca7cc23-2cf3-4501-a2a6-5c51a3e21b52"
                defaultMessage="In-House Notary Overflow"
              />
            }
            onChange={toggleOverflowFeature}
            value={overflowEnabled}
            disabled={!canUpdateCompany}
            automationId="overflow"
          />
        )}

        {overflowEnabled && (
          <>
            <CardHeading>
              <FormattedMessage
                id="e1ad6db1-97ec-4aa8-b36c-d689bb0ac59c"
                defaultMessage="Overflow Threshold: IHN -> Panel or ODN Notaries (minutes)"
              />
            </CardHeading>
            <p className={Styles.thresholdDescription}>
              <FormattedMessage
                id="4e27d6db-d4d9-43a0-911b-ba506ff622cb"
                defaultMessage="Unanswered IHN calls will route to Panel or ODN Notaries"
              />
            </p>
            <StyledTextInput
              onChange={(ev: ChangeEvent<HTMLInputElement>) => setNotaryWaitTime(ev.target.value)}
              value={notaryWaitTime}
              disabled={status.status === "loading"}
            />
            <Button
              className={Styles.saveButton}
              onClick={updateNotaryWaitTime}
              isLoading={status.status === "loading"}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage
                id="68b023ed-c0f6-40af-91a9-236373fefb17"
                defaultMessage="Save threshold settings"
              />
            </Button>
          </>
        )}

        <FeatureToggle
          title={
            <FormattedMessage
              id="e0866818-1326-42e8-9c6b-cf9fcf3cc947"
              defaultMessage="Allow Credible Witness"
            />
          }
          onChange={toggleCredibleWitnessFeature}
          value={credibleWitnessEnabled}
          disabled={!canUpdateCompany}
          automationId="credible-witness"
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="41efc37d-2ab3-4de7-86cc-1130079e8755"
              defaultMessage="Auto Identify and Tag"
            />
          }
          onChange={toggleAutoIdentifyAndTag}
          value={autoIdentifyAndTag}
          disabled={!canUpdateCompany}
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="575679e9-6b39-4367-b9de-c86c73e7b23c"
              defaultMessage="Enable Child Organizations"
            />
          }
          onChange={toggleChildOrganizationsFeature}
          value={childOrganizationsEnabled}
          disabled={!canUpdateCompany}
          automationId="child-organizations"
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="6aa31159-f773-4f97-8905-765775c19565"
              defaultMessage="Suppress Signer Emails"
            />
          }
          onChange={toggleSuppressSignerEmailsFeature}
          value={organization.suppressSignerEmails}
          disabled={!canUpdateCompany}
          automationId="suppress-signer-emails"
        />

        <FeatureToggle
          title={
            <FormattedMessage
              id="a23e0c4d-b29e-444a-a0ac-3c781e0dbf1d"
              defaultMessage="Enable Verified eSign"
            />
          }
          onChange={toggleOrganizationKbaRequirementFeature}
          value={defaultVerifiedEsignEnabled}
          disabled={!canUpdateCompany}
          automationId="default-verified-esign-enabled"
        />

        <DocVisibilityToggle organization={organization} />

        {!hidePersonallyKnown && (
          <FeatureToggle
            title={
              <FormattedMessage
                id="22bb5c56-e0ac-4f57-84d0-cde70233b122"
                defaultMessage="Allow personally known to notary as signer id method"
              />
            }
            onChange={togglePersonallyKnownIdentification}
            value={personallyKnownEnabled}
            disabled={!canUpdateCompany}
            automationId="personally-known"
          />
        )}
        <FeatureToggle
          title={
            <FormattedMessage
              id="de1a06f9-36b4-43ff-bc2a-564d4c5f2634"
              defaultMessage="Enable Parent Org Notary Assignment"
            />
          }
          onChange={toggleParentOrgNotaryAssignmentFeature}
          value={parentOrgNotaryAssignmentEnabled}
          disabled={!canUpdateParentOrgNotaryAssignment}
        />

        {lenderAccess && (
          <FeatureToggle
            title={
              <FormattedMessage
                id="7dac9cc9-f5fc-46a3-bfce-c4f05cf1ddfa"
                defaultMessage="Wet Sign Doc Upload (Hybrids Only)"
              />
            }
            onChange={toggleWetSign}
            value={wetSignEnabled}
            disabled={!canUpdateCompany}
            automationId="wet-sign"
          />
        )}

        <FeatureFlags organization={organization} />

        {canViewSettings && <Settings organization={organization} />}

        <CardHeading>
          <FormattedMessage
            id="9a83c9b7-9d43-4bd6-8df8-20329de51556"
            defaultMessage="Feature List"
          />
        </CardHeading>
        <CardText>{featureList.join(", ")}</CardText>
      </Card>
      {status.status === "error" && (
        <MutationErrorModal message={status.message} onClick={() => setStatus(INIT_STATE)} />
      )}
    </>
  );
}
