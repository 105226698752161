import "./notary_history.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { StandardTable } from "common/table";
import { CustomFormattedDateTime, FormattedDate } from "common/core/format/date";
import { NotaryLogActions } from "graphql_globals";
import TableRow from "common/table/row";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import Link from "common/core/link";

import type { NotaryHistory_changeLogs as ChangeLogs } from "./notary_history_fragment.graphql";

type Entry = {
  field_name?: string;
  old_value?: unknown;
  new_value?: unknown;
  key?: string;
  name?: string;
  document_type?: string;
  version_id?: number;
};

type MetadataHash = { changes: Entry[] };

function encodeValue(value: unknown): ReactNode {
  return value === null || typeof value === "string" ? value : JSON.stringify(value);
}

function getFieldValues(
  action: NotaryLogActions,
  entry: Entry,
): { fieldName: ReactNode; oldValue: ReactNode; newValue: ReactNode } {
  switch (action) {
    case NotaryLogActions.NOTARY_INFO_UPDATED: {
      let oldValue = entry.old_value;
      let newValue = entry.new_value;
      if (entry.field_name === "Address") {
        oldValue =
          oldValue &&
          formattedPropertyAddress(oldValue as Parameters<typeof formattedPropertyAddress>[0]);
        newValue =
          newValue &&
          formattedPropertyAddress(newValue as Parameters<typeof formattedPropertyAddress>[0]);
      } else if (entry.field_name === "Is Attorney") {
        oldValue = oldValue === false ? "No" : "Yes";
        newValue = newValue === false ? "No" : "Yes";
      } else if (entry.field_name === "Identity verification started") {
        return {
          fieldName: entry.field_name,
          oldValue: null,
          newValue: (
            <Link to={`/tools?transactionSearch=${newValue as string}`}>{newValue as string}</Link>
          ),
        };
      }

      return {
        fieldName: entry.field_name,
        oldValue: encodeValue(oldValue),
        newValue: encodeValue(newValue),
      };
    }
    case NotaryLogActions.ASSETS_UPDATED: {
      return {
        fieldName: (
          <FormattedMessage
            id="93cc83b9-14cc-407b-bb9f-3e2b56800fa7"
            defaultMessage="Created New {name}"
            values={{ name: entry.name }}
          />
        ),
        oldValue: null,
        newValue: null,
      };
    }
    case NotaryLogActions.DOCUMENTS_UPLOADED: {
      return {
        fieldName: (
          <FormattedMessage
            id="0d563d0b-127f-4d7a-bd74-10044bf21525"
            defaultMessage="Uploaded {docType} Document"
            values={{ docType: entry.document_type }}
          />
        ),
        oldValue: null,
        newValue: (
          <FormattedMessage
            id="7d65e838-8563-4341-b677-ad68049f5fc0"
            defaultMessage="Version: {version}"
            values={{ version: entry.version_id ? entry.version_id : 1 }}
          />
        ),
      };
    }
    default: {
      return {
        fieldName: null,
        oldValue: null,
        newValue: null,
      };
    }
  }
}

function rowCells(changeLog: ChangeLogs, entry: Entry) {
  const { action, createdAt, actor } = changeLog;
  const { fieldName, oldValue, newValue } = getFieldValues(action, entry);
  return [
    {
      children: (
        <>
          <FormattedDate value={createdAt} />
          <CustomFormattedDateTime value={createdAt} formatStyle="p" />
        </>
      ),
    },
    { children: fieldName },
    { children: oldValue },
    { children: newValue },
    { children: actor },
  ];
}

export default function NotaryHistory({ changeLogs }: { changeLogs: ChangeLogs[] }) {
  return (
    <div className="NotaryDetailsHistory">
      <StandardTable
        className="NotaryDetailsHistory--Table"
        headings={[
          <FormattedMessage
            key="date"
            id="19b7809e-40b4-4fe4-9e78-bb64ebb1879d"
            defaultMessage="Date"
          />,
          <FormattedMessage
            key="valueChangeField"
            id="bcaeb0a8-bcbc-4833-ae75-7344009a2525"
            defaultMessage="Change"
          />,
          <FormattedMessage
            key="valueChangeFrom"
            id="787e34ad-dfa4-44a7-9828-d57f6e1c4eab"
            defaultMessage="From"
          />,
          <FormattedMessage
            key="valueChangeTo"
            id="4c924a1e-1720-49fe-b37e-716624693cc7"
            defaultMessage="To"
          />,
          <FormattedMessage
            key="user"
            id="98987678-d4c4-4ae8-a615-1c869f2cab90"
            defaultMessage="User"
          />,
        ]}
      >
        {changeLogs.map((changeLog, i: number) =>
          (changeLog.actionMetadata as MetadataHash).changes.map((entry, ii) => (
            <TableRow key={`${i}-${ii}`} cells={rowCells(changeLog, entry)} />
          )),
        )}
      </StandardTable>
    </div>
  );
}
