import { FormattedMessage } from "react-intl";

import { format } from "common/core/format/date";
import Table from "common/core/table";

import { useProofReasonCodes, type ProofReasonCode } from "./context";
import CategoriesColumn from "./categories_column";
import EnabledColumn from "./enabled_column";

const formatDate = (value: string) => format({ value, formatStyle: "MM/dd/yyyy H:mmaaa" });

function filterCodes(codes: ProofReasonCode[], searchTerm: string): ProofReasonCode[] {
  searchTerm = searchTerm.toLocaleLowerCase();
  return codes.filter(
    (code) =>
      code.code.toLocaleLowerCase().includes(searchTerm) ||
      code.description.toLocaleLowerCase().includes(searchTerm) ||
      code.socureReasonCodes.some((socureCode) =>
        socureCode.code.toLocaleLowerCase().includes(searchTerm),
      ) ||
      code.categories.some((category) => category.toLocaleLowerCase().includes(searchTerm)),
  );
}

const ProofCodeColumn = {
  Header: (
    <span>
      <FormattedMessage id="a3fa266c-fcfa-4686-9025-030a7c4f42f6" defaultMessage="Proof Code" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.code}</span>;
  },
  collapse: false,
};

const SocureCodesColumn = {
  Header: (
    <span>
      <FormattedMessage id="50a1301e-d829-49a8-829d-42cac9581592" defaultMessage="Socure Codes" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.socureReasonCodes.map((sc) => sc.code).join(", ")}</span>;
  },
  collapse: false,
};

const DescriptionColumn = {
  Header: (
    <span>
      <FormattedMessage id="88313022-bc19-4ae0-9800-2984045a3cd5" defaultMessage="Description" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{reasonCode.description}</span>;
  },
  collapse: false,
};

const CreatedAtColumn = {
  Header: (
    <span>
      <FormattedMessage id="9cd28c29-614f-4211-84bc-7149f1d8407a" defaultMessage="Created At" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{formatDate(reasonCode.createdAt)}</span>;
  },
  collapse: false,
};

const UpdatedAtColumn = {
  Header: (
    <span>
      <FormattedMessage id="d4394fad-65b5-4930-9ad9-94b19c352620" defaultMessage="Updated At" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <span>{formatDate(reasonCode.updatedAt)}</span>;
  },
  collapse: false,
};

export default function ProofReasonCodeTable() {
  const { reasonCodeData, loadingData, searchFilter } = useProofReasonCodes();

  const allCodes = reasonCodeData ?? [];
  const filteredCodes = filterCodes(allCodes, searchFilter);

  return (
    <Table
      columns={[
        EnabledColumn,
        ProofCodeColumn,
        CategoriesColumn,
        DescriptionColumn,
        SocureCodesColumn,
        CreatedAtColumn,
        UpdatedAtColumn,
      ]}
      data={filteredCodes}
      totalItems={allCodes.length}
      loading={loadingData}
      caption="Proof Reason Codes"
    ></Table>
  );
}
