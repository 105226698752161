import { FormattedMessage } from "react-intl";
import { useNavigate, Outlet } from "react-router-dom";

import Dashboard from "common/dashboard";
import { Sidebar, SidebarTabLink, SidebarBackButton } from "common/sidebar";

export function ReasonCodesWrapper() {
  const navigate = useNavigate();

  return (
    <Dashboard>
      <Sidebar
        title={
          <FormattedMessage
            id="3900ebfa-fa81-406b-b761-830d320cf076"
            defaultMessage="Risk Reason Codes"
          />
        }
        backButton={
          <SidebarBackButton
            onClick={() => {
              navigate("/");
            }}
          />
        }
      >
        <SidebarTabLink to={"/reason-codes/"}>
          <FormattedMessage
            id="b56983fe-0b0c-4816-ac3c-d60ccf898ee1"
            defaultMessage="Proof Reason Codes"
          />
        </SidebarTabLink>
      </Sidebar>
      <Outlet />
    </Dashboard>
  );
}
