import { FormattedMessage } from "react-intl";
import { useState } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";
import type { AdminPanelInfo_panel_Panel as Panel } from "../index_query.graphql";

type Props = {
  panel: Panel;
  onCancel: () => void;
  onDelete: () => Promise<unknown>;
};

function DeletePanelModal({ onCancel, onDelete, panel }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <WorkflowModal
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      buttons={[
        <Button key="cancel" variant="tertiary" disabled={isLoading} onClick={onCancel}>
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654c5" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="danger"
          variant="primary"
          key="delete"
          onClick={() => {
            setIsLoading(true);
            onDelete().catch(() => {
              setIsLoading(false);
            });
          }}
        >
          <FormattedMessage id="5e2319c2-430c-44a1-9e4a-09dd8f37a431" defaultMessage="Delete" />
        </Button>,
      ]}
      title={
        <div>
          <Icon className={Styles.warningIcon} name="doc-warning" />
          <FormattedMessage
            id="1f1f15be-ea80-447c-898b-92a3793305c9"
            defaultMessage="Delete panel?"
          />
        </div>
      }
      footerSeparator={false}
    >
      <>
        <FormattedMessage
          id="00506c1a-aa5e-426f-9652-902ad131a72d"
          defaultMessage="Are you sure you want to delete {panelName}? This cannot be undone. Any created transactions will be routed to the general on-demand."
          values={{ panelName: panel.name }}
        />
      </>
    </WorkflowModal>
  );
}

export default DeletePanelModal;
