import { useIntl, defineMessages } from "react-intl";

import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";

import OrgFlagsCard from "./org_flags_card";
import AllOrganizationFlags from "./all_organization_flags_query.graphql";
import { usePermissions } from "../../../common/core/current_user_role";

const messages = defineMessages({
  organizationFlags: {
    id: "24200a2a-b0eb-497a-bb5e-80ba19ca22fe",
    defaultMessage: "Organization Flags",
  },
});

function OrganizationFlags() {
  const intl = useIntl();
  const { data, loading } = useQuery(AllOrganizationFlags);

  const orgFlags = data?.viewer.settingGroups[0].settings;
  const { hasPermissionFor } = usePermissions();

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <SettingsPageWrapper>
      <SettingsHeader title={intl.formatMessage(messages.organizationFlags)} />
      {orgFlags?.map((flag) => {
        return (
          <OrgFlagsCard
            key={flag.name}
            flag={flag}
            valueCounts={flag.valueCounts as Record<string, number>}
            canEdit={hasPermissionFor("editOrgFlagsUpdateForm")}
          />
        );
      })}
    </SettingsPageWrapper>
  );
}

export default OrganizationFlags;
