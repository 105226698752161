import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import { StandardTable } from "common/table";
import { SettingsHeader } from "common/settingsv2/common";
import TableRow from "common/table/row";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import PopoutMenuItem from "common/core/popout_menu/item";
import PopoutMenu from "common/core/popout_menu";
import { EncryptionKeyStatusesEnum } from "graphql_globals";
import { IconButton } from "common/core/button/icon_button";

import type { EncryptionKeysForOrganization_organization_Organization_encryptionKeys as MasterKey } from "./encryption_keys_query.graphql";
import Styles from "./index.module.scss";
import FormattedKeyStatus from "./formated_key_status";
import CreateDataKeyMutation from "./create_data_key_mutation.graphql";
import ActivateDataKeyMutation from "./activate_data_key.graphql";

const MESSAGES = defineMessages({
  pageTitle: {
    id: "24e69420-78a3-4f72-9898-053101977eda",
    defaultMessage: "Data Keys for {arn}",
  },
  id: {
    id: "204b511a-7c71-4a8e-86ce-eb9085d48db7",
    defaultMessage: "ID",
  },
  status: {
    id: "89c5cbb8-0279-483a-9fd2-3dd75ebaa687",
    defaultMessage: "Status",
  },
  createdAt: {
    id: "a1315ec9-f1e9-4300-aaba-7884d2a6d7a4",
    defaultMessage: "Created At",
  },
  updatedAt: {
    id: "aa1159f2-0936-4864-9cd3-3883c628e286",
    defaultMessage: "Updated At",
  },
  activate: {
    id: "847e2883-8a2c-414e-8ad5-892897bf032b",
    defaultMessage: "Activate",
  },
  goBack: {
    id: "1b552dcc-9bc1-464f-a5e8-867916ba74c3",
    defaultMessage: "Go back to data keys table",
  },
});

type DataKeysPageProps = {
  onClose: () => void;
  masterKey: MasterKey;
  organizationId: string;
};

function DataKeysPage({ onClose, masterKey, organizationId }: DataKeysPageProps) {
  const intl = useIntl();
  const dataKeys = masterKey.dataKeys || [];
  const [activateDataKeyStatus, setActivateDataKeyStatus] = useState<null | "error">(null);
  const [createDataKeyStatus, setCreateDataKeyStatus] = useState<null | "loading" | "error">(null);
  const createDataKey = useMutation(CreateDataKeyMutation);
  const onCreateDataKey = () => {
    setCreateDataKeyStatus("loading");
    createDataKey({
      variables: {
        input: {
          masterKeyId: masterKey.id,
          organizationId,
        },
      },
    })
      .then(() => {
        setCreateDataKeyStatus(null);
      })
      .catch(() => {
        setCreateDataKeyStatus("error");
      });
  };
  const activateDataKey = useMutation(ActivateDataKeyMutation);
  const onActivateDataKey = (dataKeyId: number) => {
    activateDataKey({
      variables: {
        input: {
          organizationId,
          masterKeyId: masterKey.id,
          dataKeyId,
        },
      },
    }).catch(() => {
      setActivateDataKeyStatus("error");
    });
  };

  return (
    <>
      <div className={Styles.wrapperJustifyRight}>
        <IconButton
          name="x"
          variant="tertiary"
          buttonColor="dark"
          label={intl.formatMessage(MESSAGES.goBack)}
          onClick={onClose}
        />
      </div>
      <SettingsHeader title={intl.formatMessage(MESSAGES.pageTitle, { arn: masterKey.kmsArn })} />
      <div className={Styles.wrapperJustifyRight}>
        {masterKey.status === EncryptionKeyStatusesEnum.ACTIVE && (
          <Button
            buttonColor="action"
            variant="primary"
            isLoading={createDataKeyStatus === "loading"}
            onClick={() => onCreateDataKey()}
          >
            <FormattedMessage
              id="e44370b4-dc0a-4b0c-87d9-7a2b8dae591e"
              defaultMessage="Add Data Key"
            />
          </Button>
        )}
      </div>
      <StandardTable
        headings={[
          intl.formatMessage(MESSAGES.id),
          intl.formatMessage(MESSAGES.status),
          intl.formatMessage(MESSAGES.updatedAt),
          intl.formatMessage(MESSAGES.createdAt),
          "",
        ]}
      >
        {dataKeys.map((key) => (
          <TableRow
            key={key.id}
            cells={[
              { children: <span>{key.id}</span> },
              { children: <FormattedKeyStatus status={key.status} /> },
              { children: <span>{key.updatedAt}</span> },
              { children: <span>{key.createdAt}</span> },
              {
                children: (
                  <>
                    {key.status === EncryptionKeyStatusesEnum.STAGED && (
                      <PopoutMenu aria-label="Activate data keys" placement="bottomRight">
                        {({ close }) => (
                          <PopoutMenuItem
                            onClick={() => {
                              close();
                              onActivateDataKey(key.id);
                            }}
                          >
                            {intl.formatMessage(MESSAGES.activate)}
                          </PopoutMenuItem>
                        )}
                      </PopoutMenu>
                    )}
                  </>
                ),
              },
            ]}
          />
        ))}
      </StandardTable>
      {(createDataKeyStatus === "error" || activateDataKeyStatus === "error") && (
        <MutationErrorModal
          onClick={() => {
            setCreateDataKeyStatus(null);
            setActivateDataKeyStatus(null);
          }}
          message={
            <FormattedMessage
              id="d0b96302-46f3-4526-9a71-afab69f0a1ae"
              defaultMessage="Unable to perform action. Please try again or reach out to customer support."
            />
          }
        />
      )}
    </>
  );
}

export default DataKeysPage;
