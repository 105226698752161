import { memo } from "react";

import { isNotaryNST } from "common/notary/capacity";

import { SendManageButton } from "../buttons/send_manage_button";
import { SignButton } from "../buttons/sign_button";
import type { AccountHoverMenuUser as User } from "../user_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  user: User;
};

function PortalSwitcher({ user }: Props) {
  return (
    <div className={Styles.portalSwitcher}>
      <SendManageButton user={user} />
      {!isNotaryNST(user.notaryProfile) && <SignButton />}
    </div>
  );
}

export default memo(PortalSwitcher);
