import { type ComponentProps, useState } from "react";
import { FormattedMessage } from "react-intl";

import ActionButton from "common/core/action_button";
import { dateComparator } from "util/date";

import AddNote from "./add_note";
import Styles from "./index.module.scss";
import Note, { type NoteType } from "./note";

type Props = {
  handleSaveNewNote: ComponentProps<typeof AddNote>["onSave"];
  notes: NoteType[];
};

export default function InternalNotes({ handleSaveNewNote, notes }: Props) {
  const [isAdding, setIsAdding] = useState(false);

  return (
    <div className={Styles.main}>
      {isAdding ? (
        <AddNote onClose={() => setIsAdding(false)} onSave={handleSaveNewNote} />
      ) : (
        <ActionButton className={Styles.addNote} onClick={() => setIsAdding(true)}>
          <FormattedMessage id="3e25cb8a-68ce-40f0-88f4-57900d416f2a" defaultMessage="+ Add note" />
        </ActionButton>
      )}
      {Boolean(notes.length) &&
        [...notes]
          .sort((a, b) => dateComparator(b.createdAt, a.createdAt))
          .map((note) => <Note key={note.id} note={note} />)}
      {!isAdding && !notes.length && (
        <FormattedMessage
          id="a25ad853-22fb-4c4f-8761-623bb2877720"
          defaultMessage="No notes (yet)"
          tagName="span"
        />
      )}
    </div>
  );
}
