import "./index.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useLogout } from "common/authentication";
import { userFullName } from "util/user";
import { includesOrgAdminRole } from "util/organization";
import { redirectToSubdomain } from "util/application_redirect";
import { saveUserDevicePreferences } from "util/device_preferences";
import AppSubdomains from "constants/app_subdomains";
import { UserRole, Feature } from "graphql_globals";
import { isNotaryODN } from "common/notary/capacity";
import { openSupportChat } from "common/support/chat";
import { HeaderDropdown } from "common/app_frame/header/dropdown_menus/dropdown";
import { HeaderDropdownItem } from "common/app_frame/header/dropdown_menus/dropdown_item";
import type { AccountMenuUser as User } from "common/account/menu/user_fragment.graphql";
import { SETTINGS_PATH } from "util/routes";

const TEAM_PATH = "/employee";
const TEMPLATE_PATH = "/templates";

type Props = {
  user: User | null;
};

export function AccountMenu({ user }: Props) {
  const navigate = useNavigate();
  const logout = useLogout();
  const [isOpen, setIsOpen] = useState(false);
  const roles = user?.roles || [];

  const showBusinessData = roles.includes(UserRole.ORGANIZATION_MEMBER);
  const fullName = userFullName(user);
  const businessName = showBusinessData ? user?.organization?.name : null;

  const featureList = user?.organization?.featureList || [];
  const isOrgAdmin = includesOrgAdminRole(roles);

  const showTemplates = featureList.includes(Feature.DOCUMENT_TEMPLATES);
  const showTeam = featureList.includes(Feature.EMPLOYEES) && isOrgAdmin;

  const isNotary = roles.includes(UserRole.NOTARY);
  const needsLevels = isNotaryODN(user?.notaryProfile || null);

  const isAdmin = roles.includes(UserRole.ADMIN);

  const showSettings = showBusinessData || isNotary || isAdmin || roles.includes(UserRole.CUSTOMER);

  function goToSettings() {
    navigate(SETTINGS_PATH);
  }

  function openChat() {
    openSupportChat();
  }

  function goToTechCheck() {
    // clear current tech check related data
    saveUserDevicePreferences({
      webcamDeviceId: null,
      microphoneDeviceId: null,
      speakerDeviceId: null,
    });
    navigate("/tech-check/video");
  }

  function goToMyDashboard() {
    navigate("/my-dashboard");
  }

  function goToTeam() {
    redirectToSubdomain(AppSubdomains.business, TEAM_PATH);
  }

  function goToTemplates() {
    redirectToSubdomain(AppSubdomains.business, TEMPLATE_PATH);
  }

  return (
    <HeaderDropdown
      namespace="AccountMenu"
      title={fullName || businessName || user?.email}
      titleSecondary={fullName ? businessName : null}
      automationId="account-menu-header"
      withCaret
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      {showTeam && (
        <HeaderDropdownItem onClick={goToTeam} automationId="team-button">
          <FormattedMessage id="e8247d2d-1095-4ff3-be4a-a6acb1168127" defaultMessage="Team" />
        </HeaderDropdownItem>
      )}

      {showTemplates && (
        <HeaderDropdownItem onClick={goToTemplates} automationId="templates-button">
          <FormattedMessage id="dea4ef92-d93a-4b4c-a176-dd07f946ccd5" defaultMessage="Templates" />
        </HeaderDropdownItem>
      )}

      {showSettings && (
        <HeaderDropdownItem onClick={goToSettings} automationId="settings-button">
          <FormattedMessage
            id="f2d52921-35ba-483b-ae17-1a82bfa644ff"
            defaultMessage="{settingsText}"
            values={{ settingsText: isAdmin ? "User Settings" : "Settings" }}
          />
        </HeaderDropdownItem>
      )}

      {isNotary && (
        <>
          {needsLevels && (
            <HeaderDropdownItem onClick={goToMyDashboard} automationId="my-dashboard-button">
              <FormattedMessage
                id="5ea8d879-c9ef-478b-b845-847c32025599"
                defaultMessage="My Dashboard"
              />
            </HeaderDropdownItem>
          )}
          <HeaderDropdownItem onClick={goToTechCheck} automationId="av-settings-button">
            <FormattedMessage
              id="daa7143a-42c7-40c7-9a63-1246e0c9999e"
              defaultMessage="A/V Settings"
            />
          </HeaderDropdownItem>
          <HeaderDropdownItem isButton automationId="open-support-chat-button" onClick={openChat}>
            <FormattedMessage id="f3df40e9-ef67-447d-a0d1-af78faf88aff" defaultMessage="Support" />
          </HeaderDropdownItem>
        </>
      )}

      <HeaderDropdownItem onClick={logout} automationId="logout-button">
        <FormattedMessage id="9f79a95d-3ec9-44e8-91c8-669239077160" defaultMessage="Log out" />
      </HeaderDropdownItem>
    </HeaderDropdown>
  );
}
