import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useForm } from "common/core/form";
import { TextInput, EmailTextInput } from "common/core/form/text";
import { Label, AutomaticFormRow, SpacedMultipartFormRow } from "common/core/form/layout";
import { emailPatternValidation, defaultRequiredMessage } from "common/core/form/error";
import { Select } from "common/core/form/select";
import { OrganizationTypeEnum } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { newPathWithPreservedSearchParams } from "util/location";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { useId } from "util/html";

import CreateOrganization from "./provision_organization_mutation.graphql";

const MESSAGES = defineMessages({
  selectLabel: {
    id: "1f0cd089-9192-44fa-b8b5-d5d605a7edb7",
    defaultMessage: "Account Type",
  },
  business: {
    id: "e9d9adcb-fe2b-40a0-898d-ea696a5b59d1",
    defaultMessage: "Business",
  },
  title: {
    id: "4ee2ec42-04f3-4a85-bba5-ebf43477d39e",
    defaultMessage: "Title Agency",
  },
});

type Props = {
  onClose: () => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  name: string;
  organizationType: OrganizationTypeEnum;
  email: string;
};

type Status = { state: null } | { state: "loading" } | { state: "error"; message?: string };

export function AccountProvisionForm({ onClose }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const form = useForm<FormValues>();
  const [status, setStatus] = useState<Status>({
    state: null,
  });

  const selectId = useId();

  const createOrganization = useMutation(CreateOrganization);

  const save = async ({ firstName, lastName, name, organizationType, email }: FormValues) => {
    setStatus({ state: "loading" });
    try {
      const resp = await createOrganization({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            name,
            organizationType,
          },
        },
      });
      form.reset();
      setStatus({ state: null });
      onClose();
      navigate(
        newPathWithPreservedSearchParams(
          `/companies/${resp.data?.createOrganization?.organization.id}/details`,
        ),
        { replace: true },
      );
    } catch (error) {
      if (isGraphQLError(error)) {
        setStatus({ state: "error", message: error.graphQLErrors[0].message });
      } else {
        captureException(error);
        setStatus({ state: "error" });
      }
    }
  };

  return (
    <>
      <WorkflowModal
        buttons={[
          <Button
            key="cancel"
            onClick={() => {
              onClose();
              form.reset();
            }}
            buttonColor="dark"
            variant="tertiary"
          >
            <FormattedMessage id="586e63ee-f795-4db1-8a02-1edda8d5d036" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="save"
            type="submit"
            onClick={form.handleSubmit(save)}
            variant="primary"
            buttonColor="action"
            isLoading={status.state === "loading"}
          >
            <FormattedMessage id="68ae178c-5d2d-4a33-a5f7-ce74d3ab9f14" defaultMessage="Create" />
          </Button>,
        ]}
        title={
          <FormattedMessage
            id="d0c514f8-8014-4392-9678-42eb166f6a46"
            defaultMessage="Create a new account"
          />
        }
      >
        <form>
          <AutomaticFormRow
            fullWidth
            label={
              <FormattedMessage
                id="c67cd14c-09b8-488e-b304-2ef01cfd831d"
                defaultMessage="Company Name"
              />
            }
            form={form}
            name="name"
            registerOptions={{ required: defaultRequiredMessage(intl) }}
            as={TextInput}
          />

          <SpacedMultipartFormRow>
            <AutomaticFormRow
              fullWidth
              label={
                <FormattedMessage
                  id="d4944267-bd28-40d3-bc24-9f4aacef3f3e"
                  defaultMessage="Owner First Name"
                />
              }
              form={form}
              name="firstName"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              as={TextInput}
            />
            <AutomaticFormRow
              fullWidth
              label={
                <FormattedMessage
                  id="eaf13776-c332-4350-b75f-6fe8c60af551"
                  defaultMessage="Owner Last Name"
                />
              }
              form={form}
              name="lastName"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              as={TextInput}
            />
          </SpacedMultipartFormRow>
          <AutomaticFormRow
            fullWidth
            label={
              <FormattedMessage
                id="4c2d4f59-b388-4c59-9635-8a5e9a7c50f9"
                defaultMessage="Owner Email"
              />
            }
            form={form}
            name="email"
            registerOptions={{
              required: defaultRequiredMessage(intl),
              pattern: emailPatternValidation(intl),
            }}
            as={EmailTextInput}
          />
          <Label style={{ marginBottom: "16px", display: "block" }} htmlFor={selectId}>
            <FormattedMessage
              id="e1a77b1d-cdd4-4a18-bb4d-582d3abe7dc3"
              defaultMessage="Organization Type"
            />
          </Label>
          <Select
            id={selectId}
            items={[
              {
                value: OrganizationTypeEnum.BUSINESS,
                label: intl.formatMessage(MESSAGES.business),
              },
              {
                value: OrganizationTypeEnum.TITLE_AGENCY,
                label: intl.formatMessage(MESSAGES.title),
              },
            ]}
            aria-invalid="false"
            {...form.register("organizationType", { required: defaultRequiredMessage(intl) })}
          />
        </form>
      </WorkflowModal>

      {status.state === "error" && (
        <MutationErrorModal message={status.message} onClick={() => setStatus({ state: null })} />
      )}
    </>
  );
}
