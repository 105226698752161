import "./lender_eligibility.scss";

import { useState } from "react";

import type { MortgageTransactionType } from "graphql_globals";
import Select from "common/form/inputs/select";
import Button from "common/core/button";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { LENDER_TRANSACTION_TYPES, LENDER_HYBRID_TRANSACTION_TYPES } from "constants/transaction";
import { useMutation } from "util/graphql";

import UpdateLenderEligibilitiesMutation from "./update_lender_eligibilities_mutation.graphql";

type Props = {
  eligibility: {
    transactionTypes: (MortgageTransactionType | null)[];
    usState: { id: string; name: string | null };
  };
  organization: { id: string };
  removeStateCallback: (usStateId: string) => void;
};

const TRANSACTION_TYPES_ITEMS = Object.values({
  ...LENDER_TRANSACTION_TYPES,
  ...LENDER_HYBRID_TRANSACTION_TYPES,
}).map((value) => ({
  label: value,
  value,
}));

function LenderEligibilityRow(props: Props) {
  const { eligibility } = props;
  const [canPersistTransactionTypes, setCanPersistTransactionTypes] = useState(false);
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState(
    eligibility.transactionTypes,
  );
  const updateLenderEligiblitiesMutateFn = useMutation(UpdateLenderEligibilitiesMutation);

  const persistTransactionTypes = async () => {
    const usStateId = eligibility.usState.id;
    await updateLenderEligiblitiesMutateFn({
      variables: {
        input: {
          organizationId: props.organization.id,
          transactionTypes: selectedTransactionTypes as MortgageTransactionType[],
          usStateId,
        },
      },
    });
    setCanPersistTransactionTypes(false);
    // we've removed all TXN types for this state
    // and need to execute a callback to remove the US State from parent component
    if (!selectedTransactionTypes.length) {
      props.removeStateCallback(usStateId);
    }
  };

  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow className="LenderEligibilityRow">
        <div className="multiSelectLabel">
          <span className="multiSelectLabel--text">{eligibility.usState.name}</span>
        </div>

        <Select
          items={TRANSACTION_TYPES_ITEMS}
          placeholder="TXN Types"
          onChange={(newTypes: typeof selectedTransactionTypes) => {
            setSelectedTransactionTypes(newTypes);
            setCanPersistTransactionTypes(true);
          }}
          value={selectedTransactionTypes}
          multi
        />

        {canPersistTransactionTypes && (
          <Button buttonColor="action" variant="primary" onClick={persistTransactionTypes}>
            Save
          </Button>
        )}
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default LenderEligibilityRow;
