function camelizeString(string: string) {
  return string.toLowerCase().replace(/[-_\s]+(.)?/g, (match, chr) => {
    return chr ? chr.toUpperCase() : "";
  });
}

export default function camelizeObject(key: string, value: Record<string, unknown>) {
  if (typeof value === "object" && !Array.isArray(value)) {
    // It's a non-null, non-array object, create a replacement with the keys initially-capped
    const newValue: Record<string, unknown> = {};

    for (const key in value) {
      newValue[camelizeString(key)] = value[key];
    }
    return newValue;
  }
  return value;
}
