import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { DetailsRow, DetailsRowHeader, BinaryStatusWithToggle } from "common/notary/details";
import { NotaryActivationStatus, type NotaryCapacityType } from "graphql_globals";

import type { NotaryCapacities_capacities as NotaryCapacity } from "./index_fragment.graphql";

type OrganizationCapacity = Omit<NotaryCapacity, "type"> & {
  type: NotaryCapacityType.ORGANIZATION_NOTARY;
};

const MESSAGES = defineMessages({
  statusLabel: {
    id: "c5fe821b-c9c3-4dac-b928-0b4a55572c1d",
    defaultMessage: "More details about notary organization capacity status",
  },
});
const noop = () => {};

function OrganizationNotaryCapacityDetails({ capacity }: { capacity: OrganizationCapacity }) {
  const intl = useIntl();
  const byonEnabled = capacity.status === NotaryActivationStatus.ACCEPTED;
  return (
    <>
      <DetailsRowHeader>
        <FormattedMessage id="2d863808-f567-4195-934e-f72f219b1a6e" defaultMessage="IHN" />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <>
            <span id="byon-toggle">
              <FormattedMessage id="cb620d44-22c5-4489-a24f-d2f5bfa60d0e" defaultMessage="IHN" />
            </span>
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(MESSAGES.statusLabel)}
            >
              <FormattedMessage
                id="bcd1ea9f-6bf8-49d3-9201-2586ff4bddfc"
                defaultMessage="IHN capacity approval is managed by organization admins. Note: To take meetings notaries must also have a compliant profile that is not disabled."
              />
            </Tooltip>
          </>
        }
      >
        <BinaryStatusWithToggle
          value={byonEnabled}
          onChange={noop}
          disabled
          aria-labelledby="byon-toggle"
          customLabel={
            <FormattedMessage
              id="57e15475-8921-463a-bf51-f67a3ea3a064"
              defaultMessage="{byonEnabled, select, true{Active} other{Inactive}}"
              values={{ byonEnabled }}
            />
          }
        />
      </DetailsRow>
    </>
  );
}

export default OrganizationNotaryCapacityDetails;
