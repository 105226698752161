import "./index.scss";

import AppFrameInnerContainer from "common/app_frame/inner_container";
import { usePermissions } from "common/core/current_user_role";

import AdminToolSection from "./admin_tool_section";
import UserSearchTool from "./user_search_tool";
import TransactionSearchTool from "./transaction_search_tool";
import RecordingLocationSearchTool from "./recording_location_search_tool";
import RecordingLocationTool from "./recording_location_tool";
import SettingsUpdateTool from "./settings_update_tool";
import GidDecoder from "./gid_decoder";
import TemplateCopier from "./template_copier";
import ExportMetabaseReports from "./export_metabase_reports_tool";

function AdminTools() {
  const { hasPermissionFor } = usePermissions();
  return (
    <AppFrameInnerContainer title="Tools" closePath="/" fullWidth>
      {hasPermissionFor("userSearchTool") && (
        <AdminToolSection title="User Search">
          <UserSearchTool />
        </AdminToolSection>
      )}
      <AdminToolSection title="Transaction Search">
        <TransactionSearchTool />
      </AdminToolSection>
      {hasPermissionFor("eligibilitySearch") && (
        <AdminToolSection title="Mortgage Eligibility Search">
          <RecordingLocationSearchTool />
        </AdminToolSection>
      )}
      {hasPermissionFor("GIDDecoder") && (
        <AdminToolSection title="GID Decoder">
          <GidDecoder />
        </AdminToolSection>
      )}
      {hasPermissionFor("templateCopier") && (
        <AdminToolSection title="Template Copier">
          <TemplateCopier />
        </AdminToolSection>
      )}
      {hasPermissionFor("viewSettingsUpdateTool") && <SettingsUpdateTool />}
      {hasPermissionFor("addRecordingLocations") && <RecordingLocationTool />}
      {hasPermissionFor("exportMetabaseReports") && (
        <AdminToolSection title="Export Metabase Reports">
          <ExportMetabaseReports />
        </AdminToolSection>
      )}
    </AppFrameInnerContainer>
  );
}

export default AdminTools;
