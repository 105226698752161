import type { ReactNode, MouseEvent } from "react";
import classnames from "classnames";
import { useMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useUpcomingPath } from "common/closing_agenda";
import { Feature, NotaryCapacityType, NotaryOnboardingStatuses } from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import { segmentTrack } from "util/segment";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { EVENT } from "constants/analytics";
import { ACCOUNT_TYPES } from "common/account/constants";
import Link from "common/core/link";
import { AccountMenus } from "common/app_frame/header/dropdown_menus";
import {
  isNotaryNST,
  isNotaryProfileNotTerminatedFor,
  isNotaryIHNCallReady,
  isNotaryODNCallReady,
  isNotaryNSTCallReady,
} from "common/notary/capacity";
import { QueueCount } from "common/notary/queue";
import { SCHEDULED_PATH } from "common/closing_agenda/path";
import { ON_DEMAND_QUEUE_PATH } from "common/notary/queue/path";
import { GET_STARTED_PATH } from "common/proof_frame/path";

import PortalSwitcher from "./portal_switcher";
import type { ViewerDefaultAppFrame_user as User } from "../default_fragment.graphql";
import Styles from "./links.module.scss";

type NavLinkProps = {
  path: string;
  onClick?: (evt: MouseEvent) => void;
  className?: string;
  children: ReactNode;
};

const IS_NOT_BUSINESS_PORTAL = CURRENT_PORTAL !== AppSubdomains.business;

function NavLink({ path, onClick, className, children }: NavLinkProps) {
  const activeCx = useMatch({ path, end: false })
    ? Styles.onActiveUnderline
    : Styles.onHoverUnderline;
  const handleClick = (evt: MouseEvent) => {
    onClick?.(evt);
    if (IS_NOT_BUSINESS_PORTAL) {
      evt.preventDefault();
      redirectToSubdomain(AppSubdomains.business, path);
    }
  };
  return (
    <Link
      black
      to={path}
      onClick={handleClick}
      className={classnames(Styles.link, className, activeCx)}
      underlined={false}
    >
      {children}
    </Link>
  );
}

function GetStartedLink() {
  return (
    <NavLink path={GET_STARTED_PATH} onClick={() => segmentTrack(EVENT.CLICKED_GET_STARTED)}>
      <FormattedMessage id="2f7c0d5c-00e3-4ac6-be82-ca09c3e2b123" defaultMessage="Get started" />
    </NavLink>
  );
}

function ScheduledMeetingsLink({ user }: { user: User }) {
  const meetingPath = useUpcomingPath(SCHEDULED_PATH);
  return (
    <NavLink
      path={meetingPath}
      className={Styles.scheduledMeetings}
      data-automation-id="meetings-nav-bar-link"
    >
      <FormattedMessage
        id="a8d0048e-807c-4220-900d-482360c7a707"
        defaultMessage="Scheduled Meetings{isReady, select, true{ {count}} other{}}"
        values={{
          isReady:
            isNotaryIHNCallReady(user.notaryProfile) || isNotaryNSTCallReady(user.notaryProfile),
          count: <QueueCount countKey="waitingClientCalls" />,
        }}
      />
    </NavLink>
  );
}

function MyClosingsLink() {
  return (
    <NavLink path="/realtor_closings">
      <FormattedMessage
        id="b3c2cfa4-0aed-4afc-98cd-759ee5862321"
        defaultMessage="My closings"
        description="My closings tab for realtors or brokers in header"
      />
    </NavLink>
  );
}

function OnDemandQueueLink({ user }: { user: User }) {
  return (
    <NavLink path={ON_DEMAND_QUEUE_PATH}>
      <FormattedMessage
        id="7eeeaeee-d1bd-4013-a7ac-50ffea0ea33d"
        defaultMessage="On-Demand{isReady, select, true{ {count}} other{}}"
        values={{
          isReady: isNotaryODNCallReady(user.notaryProfile),
          count: <QueueCount countKey="waitingNODCalls" />,
        }}
      />
    </NavLink>
  );
}

export default function NavLinks({ user }: { user: User }) {
  const { organization, notaryProfile } = user;
  const accountTypes = user.accountTypes || [];
  const showMyClosingsLink =
    accountTypes.includes(ACCOUNT_TYPES.REALTOR.toUpperCase()) ||
    accountTypes.includes(ACCOUNT_TYPES.BROKER.toUpperCase());
  const showMeetingsLink =
    isNotaryNST(notaryProfile) ||
    organization?.featureList.some((feat) => feat === Feature.ORGANIZATION_NOTARIES);
  const showQueueLink =
    notaryProfile?.onboardingStatus === NotaryOnboardingStatuses.COMPLETED &&
    isNotaryProfileNotTerminatedFor(notaryProfile, NotaryCapacityType.ON_DEMAND);
  const currentPortal = AppSubdomains[CURRENT_PORTAL];
  return (
    <>
      {organization && currentPortal !== AppSubdomains.customer && <GetStartedLink />}
      <PortalSwitcher user={user} />
      {showQueueLink && <OnDemandQueueLink user={user} />}
      {showMeetingsLink && <ScheduledMeetingsLink user={user} />}
      {showMyClosingsLink && <MyClosingsLink />}
      <AccountMenus user={user} portal={currentPortal} />
    </>
  );
}
