import { useParams, Navigate } from "react-router-dom";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import OrgFromTransactionQuery, {
  type OrgFromTransaction_transaction_OrganizationTransaction as OrganizationTransaction,
} from "./redirect_old_slack_link_details.graphql";

export function RedirectSlackLinkDetails() {
  const params = useParams();
  const transactionId = params.transactionId!;
  const { data } = useQuery(OrgFromTransactionQuery, { variables: { transactionId } });

  if (!data) {
    return <LoadingIndicator />;
  }

  const orgId = (data.transaction as OrganizationTransaction).publicOrganization.id;
  const baseTo = `/companies/${orgId}/transactions/${transactionId}`;
  const rest = params["*"] || "summary";
  return (
    <>
      <LoadingIndicator />
      <Navigate to={`${baseTo}/${rest}`} replace />
    </>
  );
}
