import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { FormattedDate } from "common/core/format/date";
import { StandardTable } from "common/table";
import TableRow from "common/table/row";

import type { AdminNotaryTrainings as NotaryProfile } from "./trainings_fragment.graphql";
import Styles from "./trainings.module.scss";

type Props = {
  notaryProfile: NotaryProfile;
};

function NotaryTrainings({ notaryProfile }: Props) {
  return (
    <div className={Styles.main}>
      <FormattedMessage
        id="6babc16c-18d4-476a-a3de-5a9062d2837e"
        defaultMessage="Proof Academy Enrollment Status"
        tagName="h2"
      />
      <FormattedMessage
        id="177fdfc9-31a2-4bd5-96be-dedb10466454"
        defaultMessage="{hasEnrollment, select, true{Enrolled on {enrollmentDate}} other{Not enrolled yet}}"
        tagName="p"
        values={{
          hasEnrollment: Boolean(notaryProfile.proofAcademyStartDate),
          enrollmentDate: <FormattedDate value={notaryProfile.proofAcademyStartDate} />,
        }}
      />

      <FormattedMessage
        id="ed6845c7-6536-489a-8758-1a8b2a8ea93a"
        defaultMessage="Platform Trainings"
        tagName="h2"
      />
      <StandardTable
        fullWidth
        headings={[
          <FormattedMessage
            key="name"
            id="a9964845-8a68-47f5-a81c-9c7837d51503"
            defaultMessage="Training"
          />,
          <FormattedMessage
            key="enrolled"
            id="6dc05752-b239-421c-9ea1-0cc9adf90f6c"
            defaultMessage="Started on"
          />,
          <FormattedMessage
            key="completed"
            id="87e64531-4223-437f-82b9-3bc214e1d545"
            defaultMessage="Completed on"
          />,
        ]}
      >
        {notaryProfile.trainings.map((training) => (
          <TableRow
            key={training.id}
            cells={[
              { children: training.name },
              { children: <FormattedDate value={training.enrolledAt} /> },
              { children: <FormattedDate value={training.completedAt} /> },
            ]}
          />
        ))}
      </StandardTable>
    </div>
  );
}

export default memo(NotaryTrainings);
