import { FormattedMessage } from "react-intl";

import { Column, Row, Container, COLUMN_SIZE_TWO_THIRDS } from "common/core/responsive";

import EmailsTable from "../table";
import CommonStyles from "../index.module.scss";
import type { AdminCompanyDetailsCommunicationSettings_node_Organization as Organization } from "../index.query.graphql";
import { EmailUITypes } from "../types";

type Props = {
  organization: Organization;
};

export default function CommunicationSettingsSignerEmails({ organization }: Props) {
  return (
    <>
      <Container fluid style={{ width: "100%" }}>
        <Row>
          <Column {...COLUMN_SIZE_TWO_THIRDS}>
            <div className={CommonStyles.heading}>
              <FormattedMessage
                id="7e27946e-c55d-4750-93c7-ecadc8cae715"
                defaultMessage="Contact Emails"
              />
            </div>
            <div>
              <span className={CommonStyles.bodyText}>
                <FormattedMessage
                  id="819418b6-7fda-4e4f-b3b6-6d7367c77756"
                  defaultMessage={
                    "Choose any email from the table below to edit the email template. " +
                    "This allows you to update the email type for all closing team members, as well as send a test email."
                  }
                />
              </span>
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <div>
              <EmailsTable
                emails={organization.organizationBrand!.contactEmailTemplates}
                organization={organization}
                uiType={EmailUITypes.CONTACT}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </>
  );
}
