import { FraudStatusEnum } from "graphql_globals";

import type { FraudIssueColumn } from "./columns";

export function fraudDashboardCallout(
  transaction: Parameters<(typeof FraudIssueColumn)["render"]>[0],
) {
  if (transaction.customerSigners.some((cs) => cs.fraudStatus?.status === FraudStatusEnum.FRAUD)) {
    return "warn";
  }
}
