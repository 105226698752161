import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import Env from "config/environment";
import { hardNavigateTo } from "util/navigation";

export default function AdminRedirectToTransaction() {
  const organizationTransactionId = useParams().globalID!;

  useEffect(() => {
    hardNavigateTo(`${Env.apiHost}/redirect_to_transaction/${organizationTransactionId}`);
  }, []);

  return <LoadingIndicator />;
}
