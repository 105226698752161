import type { ReactNode, ReactElement, ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { AuthTypes } from "graphql_globals";
import MaskedField from "common/form/fields/masked";

import { AuthGateForm, AuthGateAlertBanner, useAuthGateForm, type AuthGateAction } from "..";

function renderErrorBanner(error: NonNullable<ReturnType<typeof useAuthGateForm>["error"]>) {
  if (error.type === "invalid") {
    return (
      <AuthGateAlertBanner kind="danger">
        <FormattedMessage
          id="15ff923f-9bc1-4b48-9298-92c3fa4b6d0b"
          defaultMessage="Incorrect code."
        />
      </AuthGateAlertBanner>
    );
  }

  return <AuthGateAlertBanner kind="danger">{error.message}</AuthGateAlertBanner>;
}

type TotpAuthGateProps = {
  subjectId: string;
  onSuccess?: ComponentProps<typeof AuthGateForm>["onSuccess"];
  children: (args: { content: ReactNode; action: AuthGateAction<"submit"> }) => ReactElement;
  password?: string;
};

function TotpAuthGate({ subjectId, onSuccess, children, password }: TotpAuthGateProps) {
  const { error, invalid, submitting, submit } = useAuthGateForm();

  const content = (
    <>
      {error && renderErrorBanner(error)}
      <AuthGateForm
        authType={AuthTypes.TIME_BASED_ONE_TIME_PASSWORD}
        subjectId={subjectId}
        onSuccess={onSuccess}
        password={password}
        renderField={(formProps) => (
          <MaskedField
            {...formProps}
            label={
              <FormattedMessage
                id="b9226a0d-d97e-4f9f-8d3b-b00e99e187c8"
                defaultMessage="Enter code"
              />
            }
            maskType="number"
            maxlength={6}
            placeholder="000000"
            useStyledInput
          />
        )}
      />
    </>
  );

  const action = {
    type: "submit" as const,
    buttonProps: {
      onClick: () => {
        submit();
      },
      disabled: invalid,
      isLoading: submitting,
    },
  };

  return children({ content, action });
}

export { TotpAuthGate };
