import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { userFullName } from "util/user";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import LoadingIndicator from "common/core/loading_indicator";
import Modal from "common/modal";
import NotaryDocuments from "common/notary/documentation";
import InternalNotaryNotes from "common/notary/internal_notes";
import { useQuery } from "util/graphql";

import NotaryInfo from "./notary_info";
import NotaryHistory from "./notary_history";
import NotaryTou from "./notary_tou";
import NotaryCapacities from "./capacities";
import NotaryTrainings from "./trainings";
import NotaryDetailsQuery from "../notary_details_query.graphql";
import type { NotaryList_viewer } from "../notary_list_query.graphql";

type Props = {
  onClose: () => void;
  canUpdateNotary: boolean;
  notaryId: string;
  notaryUsStates: NotaryList_viewer["notaryUsStates"];
};

export default function NotaryDetailsContainer({
  notaryId,
  notaryUsStates,
  onClose,
  canUpdateNotary,
}: Props) {
  const { data, loading } = useQuery(NotaryDetailsQuery, { variables: { notaryId } });

  if (loading) {
    return <LoadingIndicator />;
  }

  const notary = data!.notary;
  if (notary?.__typename !== "User") {
    throw new Error(`Expected User got ${notary?.__typename}`);
  }

  const tabs = [
    <TabButton
      key="details"
      title={
        <FormattedMessage
          id="bc2a80c4-3958-47ee-bb50-214c23b6319a"
          defaultMessage="Notary Details"
        />
      }
      content={
        <NotaryInfo
          notaryUser={notary}
          notaryUsStates={notaryUsStates}
          canUpdateNotary={canUpdateNotary}
        />
      }
    />,
    <TabButton
      key="capacities"
      title={
        <FormattedMessage id="c853ea2d-7c78-4113-8483-c29aa81e4ffb" defaultMessage="Capacities" />
      }
      content={
        <NotaryCapacities notaryProfile={notary.notaryProfile!} canUpdate={canUpdateNotary} />
      }
    />,
    <TabButton
      key="trainings"
      title={
        <FormattedMessage id="fcfd5212-b12b-44a8-8ac3-9505799d3c5f" defaultMessage="Trainings" />
      }
      content={<NotaryTrainings notaryProfile={notary.notaryProfile!} />}
    />,
    <TabButton
      key="docs"
      title={
        <FormattedMessage id="c04da3ea-ff3d-4199-80b7-36f919c1eacb" defaultMessage="Documents" />
      }
      content={<NotaryDocuments notaryProfile={notary.notaryProfile!} />}
    />,
    <TabButton
      key="history"
      title={
        <FormattedMessage id="0ee7ae4b-12e3-45c0-83a2-fdec7d5ff89f" defaultMessage="History" />
      }
      content={<NotaryHistory changeLogs={notary.notaryProfile!.changeLogs} />}
    />,
    <TabButton
      key="notes"
      title={<FormattedMessage id="0b8297e7-0f78-422d-a7c6-5bb3067f099e" defaultMessage="Notes" />}
      content={<InternalNotaryNotes notaryProfile={notary.notaryProfile!} />}
    />,
    <TabButton
      key="tou"
      title={
        <FormattedMessage
          id="c24d6b5b-49bf-4c8c-8fb0-577c3895e65f"
          defaultMessage={"Terms & Conditions"}
        />
      }
      content={<NotaryTou user={notary} />}
    />,
  ].filter(Boolean) as ReactElement[];
  return (
    <Modal className="NotaryDetailsModal" closeRoute={onClose} title={userFullName(notary, "")}>
      <div className="NotaryDetailsModal--content">
        <TabRow>{tabs}</TabRow>
      </div>
    </Modal>
  );
}
