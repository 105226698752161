import { memo, PureComponent, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useSelector } from "redux/util";
import { useViewer } from "util/viewer_wrapper";
import { hardNavigateTo } from "util/navigation";
import { useIsAuthenticated } from "common/authentication";
import { login, loginReset, googleLogin } from "redux/actions/authentication";
import { segmentAddAnonymousIdToUrl, segmentTrack } from "util/segment";
import { pendoInitialize } from "util/pendo";
import { computeDefaultType } from "common/account/util";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import Login, { LOGIN_ATTEMPTS_EXCEEDED } from "./login";

class AccountLogin extends PureComponent {
  componentDidMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.redirectAfterAuthenticated();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;

    if (isAuthenticated !== prevProps.isAuthenticated && isAuthenticated) {
      this.redirectAfterAuthenticated();
    }
  }

  componentWillUnmount() {
    if (this.originalTitle) {
      document.title = this.originalTitle;
    }
    this.props.dispatch(loginReset());
  }

  redirectAfterAuthenticated(redirect) {
    const toUrl = this.getRedirectLink(redirect);
    segmentTrack("Login Authentictated -- Redirecting...", { toUrl });
    hardNavigateTo(toUrl);
  }

  getRedirectLink(redirect) {
    if (!redirect) {
      return "portal-gateway";
    }

    // short term fix ETX-1123
    return redirect.includes("approve-user-redirect")
      ? redirect
      : segmentAddAnonymousIdToUrl(decodeURIComponent(redirect));
  }

  handleGoogleSignIn = (authCode) => {
    const { dispatch, entry, redirect, onSuccess } = this.props;

    const redirectUrl = this.getRedirectLink(redirect);
    segmentTrack("Attempted Google Login");
    dispatch(googleLogin({ authCode, entry: computeDefaultType(entry), redirectUrl, onSuccess }));
  };

  loginWithPassword = ({ email, password }) => {
    const { dispatch, entry, redirect, refetchViewer, onSuccess } = this.props;
    const redirectUrl = this.getRedirectLink(redirect);
    segmentTrack("Attempted Password Login");
    return dispatch(
      login({
        email,
        password,
        redirectUrl,
        errorOnFail: true,
        clearPasswordOnError: true,
        entry: computeDefaultType(entry),
        onSuccess,
      }),
    ).then((mfaData) => {
      refetchViewer();
      return mfaData;
    });
  };

  onClearErrors = () => {
    this.props.dispatch(loginReset());
  };

  render() {
    const { authentication, email } = this.props;

    const loginAttemptsExceeded = authentication.error?.error === LOGIN_ATTEMPTS_EXCEEDED;
    return (
      <Login
        emailFromParams={email}
        onGoogleSignIn={this.handleGoogleSignIn}
        loginWithPassword={this.loginWithPassword}
        loginAttemptsExceeded={loginAttemptsExceeded}
        onClearErrors={this.onClearErrors}
        onSuccess={() => this.redirectAfterAuthenticated(this.props.redirect)}
      />
    );
  }
}

AccountLogin.propTypes = {
  authentication: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  entry: PropTypes.string,
  redirect: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  refetchViewer: PropTypes.func,
};

/** @type React.FC<{ entry: string; }> */
const Wrapper = memo(({ entry }) => {
  const intl = useIntl();
  useA11y().useDocumentEntitler({ title: intl.formatMessage(useDocumentTitles().signIn) });
  const authentication = useSelector((state) => state.authentication);
  const [searchParams] = useSearchParams();
  const { refetch } = useViewer();
  const dispatch = useDispatch();

  useEffect(() => {
    pendoInitialize();
  }, []);

  return (
    <AccountLogin
      entry={entry}
      isAuthenticated={useIsAuthenticated()}
      dispatch={dispatch}
      authentication={authentication}
      email={searchParams.get("email")}
      redirect={searchParams.get("redirect")}
      refetchViewer={refetch}
    />
  );
});

export default Wrapper;
