import "./stateless.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import Pagination from "common/pagination";
import LoadingIndicator from "common/core/loading_indicator";

function StatelessPagination({
  maxPageNumber,
  onChange,
  pageNumber,
  children,
  className,
  pagingContainerClassName,
  loading,
}) {
  const innerCx = classnames("StatelessPagination--inner", {
    "StatelessPagination--inner__loading": loading,
  });
  const containerCx = classnames("StatelessPagination", className);

  return (
    <div className={containerCx}>
      {loading && <LoadingIndicator />}

      <div className={innerCx}>
        {children}

        {maxPageNumber > 1 && (
          <div className={pagingContainerClassName}>
            <Pagination
              maxPageNumber={maxPageNumber}
              onChange={onChange}
              pageNumber={pageNumber}
              disabled={loading}
            />
          </div>
        )}
      </div>
    </div>
  );
}

StatelessPagination.propTypes = {
  maxPageNumber: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  pagingContainerClassName: PropTypes.string,
};

StatelessPagination.defaultProps = {
  loading: false,
};

export default StatelessPagination;
