import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Select from "common/form/inputs/select";
import PromiseButton from "common/core/button/promise_button";
import { useMutation } from "util/graphql";
import { CardHeading } from "common/core/card";

import UpdateTitleAgencyTitleUnderwritersMutation from "./update_title_agency_title_underwriters_mutation.graphql";

type Organization = {
  id: string;
  publicTitleAgency: null | {
    titleUnderwriters: { id: string }[];
  };
};
type Props = {
  viewer: { titleUnderwriters: { id: string; name: string | null }[] };
  organization: Organization;
};

function getTitleUnderwriterOrgIds(organization: Organization) {
  return organization.publicTitleAgency?.titleUnderwriters.map((underwriter) => underwriter.id);
}

function UnderwriterSelect({ organization, viewer }: Props) {
  const orgUnderwriterIds = getTitleUnderwriterOrgIds(organization);

  const [selectedUnderwriterIds, setSelectedUnderwriterIds] = useState(orgUnderwriterIds || []);
  const [canPersist, setCanPersist] = useState(false);

  const updateTitleAgencyTitleUnderwritersMutateFn = useMutation(
    UpdateTitleAgencyTitleUnderwritersMutation,
  );
  const saveUnderwriters = async () => {
    await updateTitleAgencyTitleUnderwritersMutateFn({
      variables: {
        input: { organizationId: organization.id, titleUnderwriterIds: selectedUnderwriterIds },
      },
    });
    setCanPersist(false);
  };

  return (
    <>
      <CardHeading>
        <FormattedMessage
          id="90c55599-49a0-4f1b-b0df-1888b6b2522b"
          defaultMessage="Title Underwriters"
        />
      </CardHeading>
      <Select
        items={viewer.titleUnderwriters.map((u) => ({ label: u.name, value: u.id }))}
        placeholder="Underwriters"
        onChange={(newIds: string[]) => {
          setSelectedUnderwriterIds(newIds);
          setCanPersist(true);
        }}
        value={selectedUnderwriterIds}
        multi
      />
      {canPersist && (
        <PromiseButton buttonColor="action" variant="primary" onClick={saveUnderwriters}>
          Save Underwriters
        </PromiseButton>
      )}
    </>
  );
}

export default UnderwriterSelect;
