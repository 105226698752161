import { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

export default class SuccessModal extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <IllustrationModal
        className="MergeAccountSuccessModal"
        title={
          <FormattedMessage
            id="720a31fe-25c2-4f01-8572-52eab3c29120"
            defaultMessage="Successfully Merged!"
          />
        }
        buttons={[
          <Button
            variant="primary"
            buttonColor="action"
            key="close"
            onClick={onClose}
            automationId="merge-success-confirm-button"
          >
            <FormattedMessage id="c38fc9bb-509e-4261-ad5e-1cf13098fe7a" defaultMessage="Close" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="ecbd1f32-6d75-4aab-81f6-96337847d97c"
          defaultMessage="The account will now appear in the organization's team member list."
        />
      </IllustrationModal>
    );
  }
}

SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
