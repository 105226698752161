import "./settings.scss";

import { useState, useEffect, type ChangeEvent } from "react";

import Content from "admin_portal/experiments/content";
import { StyledTextInput } from "common/form/inputs/text";
import Button from "common/core/button";
import { useMutation } from "util/graphql";

import DeleteExperimentMutation from "./delete_experiment_mutation.graphql";
import type {
  UpdateExperiment,
  UpdateExperimentVariables,
} from "./update_experiment_mutation.graphql";

type AdminExperimentDetailsSettingsProps = {
  name: string;
  onBack: () => void;
  updateExperimentMutate: ReturnType<
    typeof useMutation<UpdateExperiment, UpdateExperimentVariables>
  >;
};

function AdminExperimentDetailsSettings(props: AdminExperimentDetailsSettingsProps) {
  const { name, onBack, updateExperimentMutate } = props;

  const deleteExperimentMutateFn = useMutation(DeleteExperimentMutation);

  const [confirmationText, setConfirmationText] = useState("");
  const [canDeleteExperiment, setCanDeleteExperiment] = useState(false);
  const [description, setDescription] = useState("");

  function deleteExperiment() {
    deleteExperimentMutateFn({
      variables: {
        input: {
          name,
          comment: "",
        },
      },
    }).then(onBack);
  }

  function updateExperiment() {
    updateExperimentMutate({
      variables: {
        input: {
          name,
          description,
          comment: "",
        },
      },
    }).then(() => {
      setDescription("");
    });
  }

  function onChange(ev: ChangeEvent<HTMLInputElement>) {
    setConfirmationText(ev.target.value);
  }

  function onDescriptionChange(ev: ChangeEvent<HTMLInputElement>) {
    setDescription(ev.target.value);
  }

  useEffect(() => {
    setCanDeleteExperiment(confirmationText === name);
  }, [confirmationText]);

  return (
    <Content className="AdminExperimentDetailsSettings">
      <div className="AdminExperimentDetailsSettings--updateDescription">
        <StyledTextInput
          onChange={onDescriptionChange}
          value={description}
          placeholder="Your new description!"
        />
        <Button
          buttonColor="action"
          variant="primary"
          onClick={updateExperiment}
          className="AdminExperimentDetailsSettings--delete--button"
        >
          Update
        </Button>
      </div>
      <div className="AdminExperimentDetailsSettings--delete">
        <StyledTextInput
          onChange={onChange}
          value={confirmationText}
          placeholder={`Type name of experiment in order to delete (${name})`}
        />
        <Button
          buttonColor="danger"
          variant="primary"
          onClick={deleteExperiment}
          disabled={!canDeleteExperiment}
          className="AdminExperimentDetailsSettings--delete--button"
        >
          Delete
        </Button>
      </div>
    </Content>
  );
}

export default AdminExperimentDetailsSettings;
