import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";

import CommunicationSettingsEditEmailGraph, {
  type CommunicationSettingsEditEmail as Data,
  type CommunicationSettingsEditEmailVariables as Variables,
  type CommunicationSettingsEditEmail_node_Organization as Organization,
} from "../index.query.graphql";
import CreateEmailTemplateMutation from "./create_email_template.mutation.graphql";
import UpdateEmailTemplateMutation from "./update_email_template.mutation.graphql";

export function useSaveInteraction({
  organization,
  subject,
  body,
}: {
  organization: Organization;
  subject: string;
  body: string;
}) {
  const emailTemplate = organization.organizationBrand!.emailTemplate;
  const shouldCreate = emailTemplate.__typename === "DefaultEmailTemplate";

  const [submitting, setSubmitting] = useState(false);
  const createEmailTemplateMutateFn = useMutation(CreateEmailTemplateMutation);
  const updateEmailTemplateMutateFn = useMutation(UpdateEmailTemplateMutation);

  const handleSaveChanges = useCallback(() => {
    setSubmitting(true);
    const variables = { organizationId: organization.id, emailName: emailTemplate.name };
    if (shouldCreate) {
      return createEmailTemplateMutateFn({
        variables: {
          input: {
            organizationBrandId: organization.organizationBrand!.id,
            name: emailTemplate.name,
            subject,
            body,
          },
        },
        update(cacheProxy, { data }) {
          const { node, ...other } = cacheProxy.readQuery<Data, Variables>({
            query: CommunicationSettingsEditEmailGraph,
            variables,
          })!;
          if (node?.__typename !== "Organization") {
            throw new Error(`Expected an organization, got ${node?.__typename}`);
          }
          if (!data?.createEmailTemplate?.emailTemplate) {
            throw new Error("Missing created email template");
          }
          cacheProxy.writeQuery({
            query: CommunicationSettingsEditEmailGraph,
            variables,
            data: {
              ...other,
              node: {
                ...node,
                organizationBrand: {
                  ...node.organizationBrand,
                  emailTemplate: data.createEmailTemplate.emailTemplate,
                },
              },
            },
          });
        },
      }).then(({ data }) => {
        const emailTemplate = data!.createEmailTemplate!.emailTemplate;
        const { body, subject, previewPayload } = emailTemplate;
        setSubmitting(false);
        return {
          body: body!,
          subject: subject!,
          payload: previewPayload,
        };
      });
    }

    return updateEmailTemplateMutateFn({
      variables: {
        input: {
          emailTemplateId: emailTemplate.id,
          subject,
          body,
        },
      },
    }).then(({ data }) => {
      const emailTemplate = data!.updateEmailTemplate!.emailTemplate;
      const { body, subject, previewPayload } = emailTemplate;
      setSubmitting(false);
      return {
        body: body!,
        subject: subject!,
        payload: previewPayload,
      };
    });
  }, [organization, subject, body]);

  return {
    submitting,
    saveChanges: handleSaveChanges,
  };
}

export function Modal({
  onCancel,
  onConfirm,
  loading,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="bd86e126-53ea-490f-a457-257713d47b1e"
          defaultMessage="Ready to publish your changes?"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel-publish"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="05318bbe-4d44-4b58-9cdd-939ab8782ae5" defaultMessage="No, Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="confirm-publish"
          onClick={onConfirm}
          isLoading={loading}
        >
          <FormattedMessage
            id="1383b270-ccf4-4625-a347-430a72064aeb"
            defaultMessage="Yes, Publish Changes"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="15fd3d71-cf75-437b-8207-dea29c645d3c"
        defaultMessage="When you publish your changes, the updates to the template will be effective within a few minutes."
      />
    </WorkflowModal>
  );
}
