import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import TierHistoryQuery from "./tier_history_query.graphql";
import UpdateOrganizationTierMutation from "./update_organization_tier_mutation.graphql";

const messages = defineMessages({
  cancelTierSuccess: {
    id: "36bcb314-f7ce-4ce7-b446-96f09580eb1a",
    defaultMessage: "{tierName} has been canceled",
  },
  cancelTierError: {
    id: "8913292e-2f05-4e34-9d9b-3d9bf1a763cc",
    defaultMessage: "Error canceling tier",
  },
});

type Props = {
  organizationId: string;
  tierPlanId: string;
  tierPlanName: string;
  onClose: () => void;
};

export default function CancelTierChange({
  organizationId,
  tierPlanId,
  tierPlanName,
  onClose,
}: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const updateOrganizationTierMutation = useMutation(UpdateOrganizationTierMutation);
  const cancelOrganizationTierChange = async () => {
    setIsLoading(true);
    try {
      await updateOrganizationTierMutation({
        variables: {
          mutationInput: {
            organizationId,
            tierPlanId,
            delete: true,
          },
        },
        refetchQueries: [
          {
            query: TierHistoryQuery,
            variables: { organizationId },
          },
        ],
      });
      setIsLoading(false);
      onClose();
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.cancelTierSuccess, { tierName: tierPlanName }),
      });
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(messages.cancelTierError),
      });
    }
  };
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="e764f1e2-9019-4223-b5d3-c0fc11f84e62"
          defaultMessage="Cancel tier change"
        />
      }
      buttons={[
        <Button
          key="UpdateOrganizationTier"
          buttonColor="danger"
          variant="primary"
          onClick={cancelOrganizationTierChange}
          isLoading={isLoading}
          automationId="cancel-tier-confirmation-button"
        >
          <FormattedMessage
            id="95c69e18-a127-452a-bfc5-b72066eed74b"
            defaultMessage="Cancel tier change"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <div className="CancelTierChangeConfirmation">
        <FormattedMessage
          id="41e642cb-1d4f-477f-aaf4-8181afb42967"
          defaultMessage="Are you sure you would like to cancel this upcoming tier change?"
        />
      </div>
    </WorkflowModal>
  );
}
