import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState, type ReactNode } from "react";

import { useFeatureFlag } from "common/feature_gating";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { useId } from "util/html";
import { TextAreaInput } from "common/core/form/text";
import { isAriaInvalid, defaultRequiredMessage, FormattedFieldError } from "common/core/form/error";
import { Label } from "common/core/form/layout";
import { isGraphQLError } from "util/graphql/query";

import Styles from "./index.module.scss";
import type { AdminPanelInfo_panel_Panel as Panel } from "../index_query.graphql";

type Props = {
  panel: Panel;
  onSave: (ids: string[], useInvitationFlow: boolean) => Promise<unknown>;
  onCancel: () => void;
  notaryProfileIds?: string[];
};

type FormValues = {
  notaryProfileIDs: string;
};

type Error = "graphError" | "serverError" | null;

const MESSAGES = defineMessages({
  addNotariesButton: {
    id: "581f5001-27fe-45a2-ab55-bd4c5fa455a7",
    defaultMessage: "Add notaries",
  },
  addNotariesTitle: {
    id: "dac6d42c-bc84-4994-acea-ea3074cda901",
    defaultMessage: "Add notaries to panel",
  },
  inviteNotariesButton: {
    id: "09861878-6d71-4e8e-8f38-ba0ddde26fe7",
    defaultMessage: "Invite notaries",
  },
  inviteNotariesTitle: {
    id: "4b219b72-e7a2-4a9d-86c5-29dfbd074d6b",
    defaultMessage: "Invite notaries to panel",
  },
});

function AddNotaryModal({ panel, onSave, onCancel, notaryProfileIds }: Props) {
  const form = useForm<FormValues>({
    defaultValues: {
      notaryProfileIDs: notaryProfileIds?.join(", "),
    },
  });
  const { errors } = form.formState;
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const notaryProfileID = useId();
  const panelID = useId();

  const invitationCheckboxID = useId();
  const inputHelpID = useId();
  const [invitationEnabled, setInvitationEnabled] = useState(
    useFeatureFlag("use-panel-invitation-flow-nx-2285"),
  );

  function renderError(): ReactNode | null {
    if (!error) {
      return null;
    }

    return (
      <span className={Styles.inputError}>
        {error === "graphError" ? (
          <FormattedMessage
            id="dac6d42c-bc84-4994-acea-ea3074cda214"
            defaultMessage="Enter a 9-character notary profile ID"
          />
        ) : (
          <FormattedMessage
            id="dac6d42c-bc84-4994-acea-ea3074cda215"
            defaultMessage="Request failed. Please try again."
          />
        )}
      </span>
    );
  }

  const buttonText = intl.formatMessage(
    invitationEnabled ? MESSAGES.inviteNotariesButton : MESSAGES.addNotariesButton,
  );
  const titleText = intl.formatMessage(
    invitationEnabled ? MESSAGES.inviteNotariesTitle : MESSAGES.addNotariesTitle,
  );

  return (
    <WorkflowModal
      buttons={[
        <Button
          key="cancel"
          variant="tertiary"
          buttonColor="dark"
          disabled={isLoading}
          onClick={() => onCancel()}
        >
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654c5" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={isLoading}
          key="add-notary"
          variant="primary"
          buttonColor="action"
          onClick={form.handleSubmit((formValues: FormValues) => {
            const ids = formValues.notaryProfileIDs.split(",").map((id) => id.trim());
            setError(null);
            setIsLoading(true);
            onSave(ids, invitationEnabled).catch((error) => {
              if (isGraphQLError(error)) {
                setError("graphError");
              } else {
                setError("serverError");
              }
              setIsLoading(false);
            });
          })}
        >
          {buttonText}
        </Button>,
      ]}
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      title={titleText}
      footerSeparator={false}
    >
      <div className={Styles.modalContent}>
        <FormattedMessage
          id="dac6d42c-bc84-4994-acea-ea3074cda672"
          defaultMessage="Add a notary to a company panel to send them specialized ODN calls"
        />
        <div>
          <div id={panelID} className={Styles.panelLabel}>
            <FormattedMessage id="dac6d42c-bc84-4994-acea-ea3074cda211" defaultMessage="Panel" />
          </div>
          <div aria-labelledby={panelID}>{panel.name}</div>
        </div>
        <div>
          <Label htmlFor={notaryProfileID}>
            <FormattedMessage
              id="9bc7b469-9573-4221-aa3b-c96442afe4de"
              defaultMessage="Notary profile ID"
            />
          </Label>
          <div id={inputHelpID} className={Styles.textAreaInstruction}>
            <FormattedMessage
              id="dac6d42c-bc84-4994-acea-ea3074cda212"
              defaultMessage="Separate IDs with a comma"
            />
          </div>
          <TextAreaInput
            id={notaryProfileID}
            aria-invalid={isAriaInvalid(errors.notaryProfileIDs)}
            aria-describedby={inputHelpID}
            {...form.register("notaryProfileIDs", {
              required: defaultRequiredMessage(intl),
            })}
          />
          {renderError()}
          <FormattedFieldError inputName="notaryProfileIDs" error={errors.notaryProfileIDs} />
        </div>
        <div className={Styles.invitationOption}>
          <CheckboxLabel
            checkbox={
              <div className={Styles.invitationCheckbox}>
                <Checkbox
                  aria-invalid="false"
                  aria-describedby={invitationCheckboxID}
                  checked={invitationEnabled}
                  onChange={() => setInvitationEnabled(!invitationEnabled)}
                />
              </div>
            }
            label={
              <span className={Styles.checkboxLabel} id={invitationCheckboxID}>
                <FormattedMessage
                  id="3506e681-308d-4b93-b12b-6905b0f260d2"
                  defaultMessage="Accept Invitation"
                />
              </span>
            }
            subLabel={
              <FormattedMessage
                id="ecaa7674-d5ee-47ae-b194-60c8efadf6bd"
                defaultMessage="Require a notary to accept a panel invite"
              />
            }
          />
        </div>
      </div>
    </WorkflowModal>
  );
}

export default AddNotaryModal;
