import { useParams } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { SettingsHeader, SettingsPageWrapper, SettingsSubtitle } from "common/settingsv2/common";
import { CompanyName, CompanyOrgStructureContainer } from "common/organization/org_structure";

const MESSAGES = defineMessages({
  title: {
    id: "80aae908-1525-46aa-afd7-5081289237e7",
    defaultMessage: "Organization Structure",
  },
});

export function OrgStructurePage() {
  const { globalID } = useParams();
  const intl = useIntl();

  return (
    <SettingsPageWrapper>
      <SettingsHeader title={intl.formatMessage(MESSAGES.title)} />
      <SettingsSubtitle>
        <CompanyName organizationId={globalID!} />
        <br />
        <FormattedMessage
          id="819eb22d-f16c-47f1-a403-c9434dda389d"
          defaultMessage="This shows the entire organization under the top-level account. You are currently viewing the organization that is highlighted in blue."
        />
      </SettingsSubtitle>
      <CompanyOrgStructureContainer orgId={globalID!} />
    </SettingsPageWrapper>
  );
}
