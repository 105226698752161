import { FormattedMessage } from "react-intl";
import { useState } from "react";

import {
  NotaryActivationStatus,
  NotaryCapacityType,
  type NotarySearchCapacityInput,
} from "graphql_globals";
import { FilterDropdown, FilterGroup, FilterMultiOption } from "common/dashboard/filter_dropdown";
import { SelectedFiltersLabel } from "common/dashboard/filter_dropdown/common";

import {
  CAPACITY_STATUS_LABELS,
  CAPACITY_CONDENSED_STATUS_LABELS,
  CAPACITY_TYPE_LABELS,
  isCondensedStatusActive,
} from "../util";
import Styles from "./capacities.module.scss";

type CapacitiesSearch = NotarySearchCapacityInput[] | null | undefined;
type Dispatch = (action: { type: "capacities"; value: null | CapacitiesSearch }) => void;
type Props = {
  capacities: CapacitiesSearch;
  dispatchFilterState: Dispatch;
};

const FLAT_ACTIVATION_STATUS = Object.values(NotaryActivationStatus);
const ACTIVATION_CONDENSED_STATUS = Object.keys(
  CAPACITY_CONDENSED_STATUS_LABELS,
) as (keyof typeof CAPACITY_CONDENSED_STATUS_LABELS)[];

function isCapacityMatchingCondensedActivation(
  activation: "active" | "inactive",
  activationStatus: NotaryActivationStatus,
): boolean {
  const matchesActive = isCondensedStatusActive(activationStatus);
  return activation === "active" ? matchesActive : !matchesActive;
}

function isCapacityStatusSelected(
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
  status: NotaryActivationStatus,
): boolean {
  return Boolean(
    capacities?.some((cap) => cap.type === capacityType && cap.statuses.some((s) => s === status)),
  );
}

function areAllCapcitiesOfTypeSelected(
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
): boolean {
  return Boolean(
    capacities?.some(
      (cap) => cap.type === capacityType && cap.statuses.length === FLAT_ACTIVATION_STATUS.length,
    ),
  );
}

function isCondensedActivationSelected(
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
  activation: "active" | "inactive",
): boolean {
  return Boolean(
    capacities?.some(
      (cap) =>
        cap.type === capacityType &&
        cap.statuses.some((s) => isCapacityMatchingCondensedActivation(activation, s)),
    ),
  );
}

function toggleCondensedActivation(
  dispatchFilterState: Dispatch,
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
  activation: "active" | "inactive",
) {
  return () => {
    const lookup: (status: NotaryActivationStatus) => boolean = (status) =>
      isCapacityMatchingCondensedActivation(activation, status);
    const value = capacities?.some((cap) => cap.type === capacityType && cap.statuses.some(lookup))
      ? capacities.map((cap) => {
          return cap.type === capacityType
            ? { ...cap, statuses: cap.statuses.filter((s) => !lookup(s)) }
            : cap;
        })
      : capacities?.some((cap) => cap.type === capacityType)
        ? capacities.map((cap) => {
            return cap.type === capacityType
              ? { ...cap, statuses: cap.statuses.concat(FLAT_ACTIVATION_STATUS.filter(lookup)) }
              : cap;
          })
        : (capacities || []).concat({
            type: capacityType,
            statuses: FLAT_ACTIVATION_STATUS.filter(lookup),
          });
    dispatchFilterState({ type: "capacities", value });
  };
}

function toggleAllCapacityChange(
  dispatchFilterState: Dispatch,
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
) {
  return () => {
    const removedCap = capacities?.filter((cap) => cap.type !== capacityType) || [];
    const value = areAllCapcitiesOfTypeSelected(capacities, capacityType)
      ? removedCap
      : removedCap.concat({ type: capacityType, statuses: FLAT_ACTIVATION_STATUS });
    dispatchFilterState({ type: "capacities", value });
  };
}

function toggleCapacityChange(
  dispatchFilterState: Dispatch,
  capacities: CapacitiesSearch,
  capacityType: NotaryCapacityType,
  status: NotaryActivationStatus,
) {
  return () => {
    const value = isCapacityStatusSelected(capacities, capacityType, status)
      ? capacities!.map((cap) => {
          return cap.type === capacityType
            ? { ...cap, statuses: cap.statuses.filter((s) => s !== status) }
            : cap;
        })
      : capacities?.some((cap) => cap.type === capacityType)
        ? capacities.map((cap) => {
            return cap.type === capacityType
              ? { ...cap, statuses: cap.statuses.concat(status) }
              : cap;
          })
        : (capacities || []).concat({ type: capacityType, statuses: [status] });
    dispatchFilterState({ type: "capacities", value });
  };
}

function selectedCapacitiesLabel(capacities: CapacitiesSearch) {
  return capacities
    ?.filter((cap) => cap.statuses.length)
    .flatMap((cap) => [CAPACITY_TYPE_LABELS[cap.type], " + "])
    .slice(0, -1);
}

export function CapacityFilter({ capacities, dispatchFilterState }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FilterDropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      key="capacity-filter"
      hasSelection={Boolean(capacities?.length)}
      unselectedLabel={
        <FormattedMessage
          id="93593376-dd75-4e52-9d13-b55ffc47b12b"
          defaultMessage="All Capacities"
        />
      }
      label={<SelectedFiltersLabel label={selectedCapacitiesLabel(capacities)} />}
      onSelectAll={() => {
        dispatchFilterState({ type: "capacities", value: null });
      }}
      className={Styles.filterDropdown}
    >
      <FilterGroup
        label={
          <FormattedMessage id="0c0ce117-e8f6-408f-a965-9ca3d607ad84" defaultMessage="In-House" />
        }
        selected={areAllCapcitiesOfTypeSelected(capacities, NotaryCapacityType.ORGANIZATION_NOTARY)}
        onChange={toggleAllCapacityChange(
          dispatchFilterState,
          capacities,
          NotaryCapacityType.ORGANIZATION_NOTARY,
        )}
      >
        {ACTIVATION_CONDENSED_STATUS.map((activation) => (
          <FilterMultiOption
            key={activation}
            selected={isCondensedActivationSelected(
              capacities,
              NotaryCapacityType.ORGANIZATION_NOTARY,
              activation,
            )}
            onChange={toggleCondensedActivation(
              dispatchFilterState,
              capacities,
              NotaryCapacityType.ORGANIZATION_NOTARY,
              activation,
            )}
          >
            {CAPACITY_CONDENSED_STATUS_LABELS[activation]}
          </FilterMultiOption>
        ))}
      </FilterGroup>
      <FilterGroup
        label={
          <FormattedMessage id="10db0599-57cb-4bbc-b9cc-67b8487ef850" defaultMessage="On-Demand" />
        }
        selected={areAllCapcitiesOfTypeSelected(capacities, NotaryCapacityType.ON_DEMAND)}
        onChange={toggleAllCapacityChange(
          dispatchFilterState,
          capacities,
          NotaryCapacityType.ON_DEMAND,
        )}
      >
        {FLAT_ACTIVATION_STATUS.map((status) => {
          const selected = isCapacityStatusSelected(
            capacities,
            NotaryCapacityType.ON_DEMAND,
            status,
          );
          return (
            <FilterMultiOption
              key={status}
              selected={selected}
              onChange={toggleCapacityChange(
                dispatchFilterState,
                capacities,
                NotaryCapacityType.ON_DEMAND,
                status,
              )}
            >
              {CAPACITY_STATUS_LABELS[status]}
            </FilterMultiOption>
          );
        })}
      </FilterGroup>
      <FilterGroup
        label={
          <FormattedMessage
            id="521abd1c-ee76-42df-af0d-95e2afa98c52"
            defaultMessage="Notary Source"
          />
        }
        selected={areAllCapcitiesOfTypeSelected(
          capacities,
          NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
        )}
        onChange={toggleAllCapacityChange(
          dispatchFilterState,
          capacities,
          NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
        )}
      >
        {ACTIVATION_CONDENSED_STATUS.map((activation) => (
          <FilterMultiOption
            key={activation}
            selected={isCondensedActivationSelected(
              capacities,
              NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
              activation,
            )}
            onChange={toggleCondensedActivation(
              dispatchFilterState,
              capacities,
              NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
              activation,
            )}
          >
            {CAPACITY_CONDENSED_STATUS_LABELS[activation]}
          </FilterMultiOption>
        ))}
      </FilterGroup>
    </FilterDropdown>
  );
}
