import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Column, Row, Container, COLUMN_SIZE_TWO_THIRDS } from "common/core/responsive";
import BinaryToggle from "common/form/inputs/binary_toggle";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";

import EmailsTable from "../table";
import type { AdminCompanyDetailsCommunicationSettings_node_Organization as Organization } from "../index.query.graphql";
import ToggleOrganizationSuppressSignerEmailsMutation from "./toggle_suppress_signer_emails.mutation.graphql";
import CommonStyles from "../index.module.scss";
import { EmailUITypes } from "../types";

type Action = "enable" | "disable";

type Props = {
  organization: Organization;
};

function ConfirmActionModal({
  action,
  loading,
  onCancel,
  onConfirm,
}: {
  action: Action;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (action: Action) => void;
}) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="bd86e126-53ea-490f-a457-257713d47b1e"
          defaultMessage="Are you sure you want to {enabling, select, true {enable} other {suppress}} signer emails?"
          values={{ enabling: action === "enable" }}
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="suppress-cancel"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="2de5d779-e15b-46f6-a151-bfae359ca5db" defaultMessage="No, Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="suppress-confirm"
          onClick={() => {
            onConfirm(action);
          }}
          isLoading={loading}
        >
          <FormattedMessage
            id="9067f25c-f2ac-4416-9e20-a535f2f4fbc8"
            defaultMessage="Yes, Confirm"
          />
        </Button>,
      ]}
    >
      {action === "enable" ? (
        <FormattedMessage
          id="a7d8a118-f8f5-4d2a-a225-f827d6c82160"
          defaultMessage="By enabling signer emails, standard transaction-related email communication from Notarize will be turned on for signers on all transaction types. This change will happen immediately."
        />
      ) : (
        <FormattedMessage
          id="373c6c60-28a3-4a1a-ab63-9d3ffd1aa3a2"
          defaultMessage="By suppressing signer emails, email communication from Notarize will be turned off for signers on all transaction types. Signers will not get any email communication from Notarize except for verifying their email address, if applicable."
        />
      )}
    </WorkflowModal>
  );
}

export default function CommunicationSettingsSignerEmails({ organization }: Props) {
  const { suppressSignerEmails } = organization;
  const [loading, setLoading] = useState(false);
  const [confirmingAction, setConfirmingAction] = useState<Action>();

  const toggleOrganizationSuppressSignerEmails = useMutation(
    ToggleOrganizationSuppressSignerEmailsMutation,
  );

  const handleToggleClick = useCallback((value: boolean) => {
    setConfirmingAction(value ? "enable" : "disable");
  }, []);

  const handleCancel = () => {
    setConfirmingAction(undefined);
  };

  const handleConfirm = useCallback(() => {
    setLoading(true);
    toggleOrganizationSuppressSignerEmails({
      variables: {
        input: {
          id: organization.id,
        },
      },
    }).finally(() => {
      setLoading(false);
      setConfirmingAction(undefined);
    });
  }, [organization]);

  return (
    <>
      {confirmingAction && (
        <ConfirmActionModal
          loading={loading}
          action={confirmingAction}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
      <Container fluid style={{ width: "100%" }}>
        <Row>
          <Column {...COLUMN_SIZE_TWO_THIRDS}>
            <div className={CommonStyles.heading}>
              <FormattedMessage
                id="7e27946e-c55d-4750-93c7-ecadc8cae715"
                defaultMessage="Signer Emails"
              />
            </div>
            <div className={CommonStyles.toggle}>
              <BinaryToggle
                aria-labelledby="signer-emails-toggle"
                onChange={handleToggleClick}
                value={!suppressSignerEmails}
              />
              <span id="signer-emails-toggle" className={CommonStyles.bodyText}>
                <FormattedMessage
                  id="a3a3316d-f7e4-46ac-9454-52eaf4ccd811"
                  defaultMessage="Signer emails are {enabled, select, true {enabled} other {disabled}} for {companyName}."
                  values={{
                    enabled: !suppressSignerEmails,
                    companyName: organization.name,
                  }}
                />
              </span>
            </div>

            <div className={suppressSignerEmails ? CommonStyles.disabled : ""}>
              <span className={CommonStyles.bodyText}>
                <FormattedMessage
                  id="819418b6-7fda-4e4f-b3b6-6d7367c77756"
                  defaultMessage="Choose any email from the table below to edit the email template. This allows you to update that email type for all signers, as well as send a test email."
                />
              </span>
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <div className={suppressSignerEmails ? CommonStyles.disabled : ""}>
              <EmailsTable
                emails={organization.organizationBrand!.signerEmailTemplates}
                organization={organization}
                disabled={suppressSignerEmails}
                uiType={EmailUITypes.SIGNER}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </>
  );
}
