import { useState, memo } from "react";

import { userFullName } from "util/user";
import { CustomFormattedDateTime } from "common/core/format/date";
import NotaryDocumentViewModal from "common/notary/document_view_modal";
import ActionButton from "common/core/action_button";

import Styles from "./index.module.scss";

type Props = {
  note: NoteType;
};
type Attachment = {
  asset: { key: string | null; url: string | null };
  id: string;
  name: string;
};

export type NoteType = {
  attachments: Attachment[];
  author: {
    firstName: string | null;
    id: string;
    lastName: string | null;
    middleName: string | null;
  } | null;
  createdAt: NotarizeScalarDate;
  id: string;
  text: string | null;
};

function Note({ note }: Props) {
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
  return (
    <div className={Styles.note}>
      <div>
        <span>{userFullName(note.author)}</span>
        <CustomFormattedDateTime value={note.createdAt} formatStyle="LL/dd/yy h:mma" />
      </div>
      <span>{note.text}</span>
      {note.attachments.map((attachment) => (
        <ActionButton
          key={attachment.id}
          onClick={() => setSelectedAttachment(attachment)}
          disabled={!attachment.asset.url}
        >
          {attachment.name}
        </ActionButton>
      ))}
      {selectedAttachment && (
        <NotaryDocumentViewModal
          onClose={() => setSelectedAttachment(null)}
          url={selectedAttachment.asset.url!}
          fileName={selectedAttachment.asset.key!}
        />
      )}
    </div>
  );
}

export default memo(Note);
