import "./targeting.scss";

import { useState, useMemo, useCallback, useEffect, type ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

import Content from "admin_portal/experiments/content";
import { StyledTextInput } from "common/form/inputs/text";
import Button from "common/core/button";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { captureException } from "util/exception";
import type { ExperimentAssignmentInput } from "graphql_globals";
import { useCopy } from "util/clipboard";
import { useMutation } from "util/graphql";
import { b } from "util/html";
import { IconButton } from "common/core/button/icon_button";

import AssignGroupMutation from "./assign_group_mutation.graphql";
import type {
  Details_viewer_experiments_groups as ExperimentGroup,
  Details_viewer_experiments as Experiment,
} from "./details_query.graphql";
import type {
  UpdateExperiment,
  UpdateExperimentVariables,
} from "./update_experiment_mutation.graphql";

type IdProps = {
  id: string;
  onDelete: () => void;
};

function Id(props: IdProps) {
  const { id, onDelete } = props;

  const { copy, recentlyCopied } = useCopy();

  return (
    <div className="AdminExperimentDetailsTargeting--id">
      {recentlyCopied ? (
        <p>copied</p>
      ) : (
        <p className="AdminExperimentDetailsTargeting--id--text" onClick={() => copy(id)}>
          {id}
        </p>
      )}
      <IconButton
        label={
          <FormattedMessage id="d9ca3d8a-83f0-4504-b82b-28bc5d9cbc45" defaultMessage="Remove id" />
        }
        variant="tertiary"
        buttonColor="danger"
        buttonSize="condensed"
        onClick={onDelete}
        className="AdminExperimentDetailsTargeting--id--icon"
        name="x"
      />
    </div>
  );
}

type TargetingProps = {
  active: Experiment["active"];
  groups: Experiment["groups"];
  name: Experiment["name"];
  updateExperimentMutate: ReturnType<
    typeof useMutation<UpdateExperiment, UpdateExperimentVariables>
  >;
};

type IdsToAdd = Record<string, string>;

function getAssignments(groups: ExperimentGroup[]): ExperimentAssignmentInput[] {
  return groups.reduce<ExperimentAssignmentInput[]>(
    (array, group) => [...array, ...group.assignedIds.map((id) => ({ value: group.value, id }))],
    [],
  );
}

function getNewAssignments(
  oldGroups: ExperimentGroup[],
  newGroups: ExperimentGroup[],
): ExperimentAssignmentInput[] {
  const oldAssignments = getAssignments(oldGroups);
  const newAssignments = getAssignments(newGroups);

  const removedIds = oldAssignments
    .filter(
      (oldAssignment) =>
        !newAssignments.some((newAssignment) => newAssignment.id === oldAssignment.id),
    )
    .map((assignment) => assignment.id);

  const updatedAssignments = newAssignments.filter((newAssignment) => {
    const oldAssignment = oldAssignments.find(
      (oldAssignment) => oldAssignment.id === newAssignment.id,
    );
    return !oldAssignment || oldAssignment.value !== newAssignment.value;
  });

  return [...removedIds.map((id) => ({ id, value: null })), ...updatedAssignments];
}

function removeAssignment(groups: ExperimentGroup[], groupValue: string, idToRemove: string) {
  return groups.map((group) => {
    if (group.value !== groupValue) {
      return group;
    }

    return {
      ...group,
      assignedIds: group.assignedIds.filter((id) => id !== idToRemove),
    };
  });
}

function addAssignment(groups: ExperimentGroup[], groupValue: string, idToAdd: string) {
  let updatedGroups = [...groups];
  groups.forEach((group) => {
    updatedGroups = removeAssignment(updatedGroups, group.value, idToAdd);
  });

  return updatedGroups.map((group) => {
    if (group.value !== groupValue) {
      return group;
    }

    return {
      ...group,
      assignedIds: [...group.assignedIds, idToAdd],
    };
  });
}

function initialIdsToAdd(groups: ExperimentGroup[]) {
  return groups.reduce<IdsToAdd>((object, group) => ({ ...object, [group.value]: "" }), {});
}

function AdminExperimentDetailsTargeting(props: TargetingProps) {
  const { active, groups, name, updateExperimentMutate } = props;

  const [newActive, setNewActive] = useState(active);
  const [newGroups, setNewGroups] = useState(groups);
  const [idsToAdd, setIdsToAdd] = useState(initialIdsToAdd(groups));
  const [isSaving, setIsSaving] = useState(false);
  const assignExperimentGroupMutate = useMutation(AssignGroupMutation);

  useEffect(() => {
    setNewGroups(groups);
  }, [groups]);

  const newAssignments = useMemo(() => getNewAssignments(groups, newGroups), [newGroups]);

  const save = useCallback(async () => {
    setIsSaving(true);

    if (newActive !== active) {
      try {
        await updateExperimentMutate({
          variables: {
            input: {
              name,
              active: newActive,
              comment: "",
            },
          },
        });
      } catch (error) {
        captureException(error);
      }
    }

    if (newAssignments.length > 0 && newActive === true) {
      try {
        const { data } = await assignExperimentGroupMutate({
          variables: {
            input: {
              name,
              assignments: newAssignments,
              comment: "",
            },
          },
        });

        setNewGroups(data!.assignExperimentGroup!.experiment.groups);
      } catch (error) {
        captureException(error);
      }
    }

    setIsSaving(false);
  }, [newAssignments, newActive]);

  return (
    <Content className="AdminExperimentDetailsTargeting">
      <div className="AdminExperimentDetailsTargeting--header">
        <p
          id="experiment-targeting-toggle"
          className="AdminExperimentDetailsTargeting--header--active-label"
        >
          <FormattedMessage id="9c557d57-e874-47ec-8aac-2a2786b540c6" defaultMessage="Active" />
        </p>
        <BinaryToggle
          aria-labelledby="experiement-targeting-toggle"
          onChange={setNewActive}
          value={newActive}
        />
        {!newActive && (
          <p className="AdminExperimentDetailsTargeting--header--active-default">
            group will default to <strong>control</strong>
            <FormattedMessage
              id="39f046bf-7be3-4112-9223-6fe4d9de8364"
              defaultMessage="group will default to <b>control</b>"
              values={{ b }}
            />
          </p>
        )}

        <Button
          onClick={save}
          className="AdminExperimentDetailsTargeting--header--save-button"
          disabled={newAssignments.length === 0 && newActive === active}
          isLoading={isSaving}
          buttonColor="action"
          variant="primary"
        >
          <FormattedMessage id="725a2499-9fb7-43bd-bd71-d6b27e0e08bb" defaultMessage="Save" />
        </Button>
      </div>

      {newActive &&
        newGroups.map((group) => (
          <div className="AdminExperimentDetailsTargeting--group" key={group.value}>
            <p className="AdminExperimentDetailsTargeting--value">{group.value}</p>
            <div className="AdminExperimentDetailsTargeting--ids">
              {group.assignedIds.map((id) => (
                <Id
                  key={id}
                  id={id}
                  onDelete={() => setNewGroups(removeAssignment(newGroups, group.value, id))}
                />
              ))}
            </div>
            <div className="AdminExperimentDetailsTargeting--add">
              <StyledTextInput
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setIdsToAdd({ ...idsToAdd, [group.value]: e.target.value })
                }
                value={idsToAdd[group.value]}
                placeholder="User / Org GID"
                placeholderAsLabel
              />
              <Button
                onClick={() => {
                  setNewGroups(addAssignment(newGroups, group.value, idsToAdd[group.value]));
                  setIdsToAdd({ ...idsToAdd, [group.value]: "" });
                }}
                className="AdminExperimentDetailsTargeting--add--button"
                disabled={!idsToAdd[group.value]}
                buttonColor="action"
                variant="primary"
              >
                <FormattedMessage id="b78de2b8-65ef-475b-ba64-6a5a2ec7d99d" defaultMessage="Add" />
              </Button>
            </div>
          </div>
        ))}
    </Content>
  );
}

export default AdminExperimentDetailsTargeting;
