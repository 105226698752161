import "./index.scss";

import { useState, useCallback } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import FormGroup from "common/form/group";
import TextField from "common/form/fields/text";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import compose from "util/compose";
import { b } from "util/html";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import TagOrganizationMutation from "util/apollo_graphql/mutations/tag_organization/mutation.graphql";
import { getFormValues } from "util/form";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { IconButton } from "common/core/button/icon_button";

import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "../details_query.graphql";

const messages = defineMessages({
  companyTagTitle: {
    id: "057b5cd8-e385-4a43-8771-aababe08f829",
    defaultMessage: "Add Tags",
  },
  enterTag: {
    id: "89c40828-edd3-468e-a9d7-27b74af99b74",
    defaultMessage: "Enter Tag",
  },
  addTag: {
    id: "8a9793d4-1561-4de8-86f9-92e009c303a9",
    defaultMessage: "+ Add Tag",
  },
  removeTag: {
    id: "b0702f4a-0121-46f7-b6ce-81f92e6291cb",
    defaultMessage: "Remove tag",
  },
  addSuccess: {
    id: "79c59b44-1244-4ef4-9285-dce5a0ab1a4b",
    defaultMessage: "Tag successfully added.",
  },
  addFailure: {
    id: "71b59832-0ed9-4dd6-83bf-ad7a8e08a7e0",
    defaultMessage: "Tag adding failed.",
  },
  removeSuccess: {
    id: "4ce0351c-2b09-4782-a008-2419f7c8d37d",
    defaultMessage: "Tag successfully removed.",
  },
  removeFailure: {
    id: "75747d40-e67e-4695-a9a7-1a82cf1d4053",
    defaultMessage: "Tag removal failed.",
  },
});

type Props = {
  organization: Organization;
};

type FormValues = {
  tag: undefined | string;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type GetFormValueProps = {
  formValues: FormValues;
};

type InnerProps = Props & FormProps & GetFormValueProps;

function OrganizationTags(props: InnerProps) {
  const { handleSubmit, formValues, organization, reset } = props;

  const intl = useIntl();
  const tagOrganizationMutation = useMutation(TagOrganizationMutation);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tagToRemove, setTagToRemove] = useState<string | null>(null);

  const tagOrganization = useCallback(async (tag: string, untag: boolean = false) => {
    setIsUpdating(true);
    try {
      await tagOrganizationMutation({
        variables: {
          mutationInput: {
            organizationId: organization.id,
            tag,
            untag,
          },
        },
      });

      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: untag
          ? intl.formatMessage(messages.removeSuccess)
          : intl.formatMessage(messages.addSuccess),
      });
    } catch (error) {
      captureException(error);

      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: untag
          ? intl.formatMessage(messages.removeFailure)
          : intl.formatMessage(messages.addFailure),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    } finally {
      setIsUpdating(false);
      setTagToRemove(null);

      if (!untag) {
        reset();
      }
    }
  }, []);

  const onSubmit = useCallback(
    ({ tag }: FormValues) => {
      tagOrganization(tag!);
    },
    [tagOrganization],
  );

  const untag = useCallback(() => {
    tagOrganization(tagToRemove!, true);
  }, [tagToRemove, tagOrganization]);

  const cancelUntag = useCallback(() => {
    setTagToRemove(null);
  }, []);

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="3f424546-366c-48a6-b6e7-c9631e333a67" defaultMessage="Add Tags" />
      </SettingsTitle>
      <form
        name="organizationTagForm"
        className="OrganizationTag"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <CardHeading>
            <FormattedMessage id="6a4cca97-7ced-4373-bf48-adbb7d3cff54" defaultMessage="Tags" />
          </CardHeading>

          <div className="OrganizationTag--tags">
            {organization.tags.map((t) => (
              <div
                data-automation-id={`organization-tag-${t.tag}`}
                className="OrganizationTag--tag"
                key={t.tag}
              >
                {t.tag}
                <IconButton
                  automationId={`remove-organization-tag-${t.tag}`}
                  variant="tertiary"
                  buttonColor="danger"
                  buttonSize="condensed"
                  name="x"
                  className="OrganizationTag--tag--x"
                  onClick={() => setTagToRemove(t.tag)}
                  label={intl.formatMessage(messages.removeTag)}
                />
              </div>
            ))}
          </div>

          <CardHeading>
            <FormattedMessage
              id="76c290f1-4058-4c38-9156-44a67d5be2ed"
              defaultMessage="Enter Tag"
            />
          </CardHeading>
          <div className="OrganizationTag--fields">
            <FormGroup
              className="OrganizationTag--fields--input"
              fields={["tag"]}
              disableFormRowStyle
            >
              <TextField
                name="tag"
                placeholder={intl.formatMessage(messages.enterTag)}
                data-automation-id="new-organization-tag"
                useStyledInput
                placeholderAsLabel
              />
            </FormGroup>
          </div>

          <Button
            type="submit"
            isLoading={!tagToRemove && isUpdating}
            disabled={!formValues.tag?.trim()}
            automationId="add-organization-tag"
            variant="tertiary"
            buttonColor="action"
            className="OrganizationTag--add"
          >
            {intl.formatMessage(messages.addTag)}
          </Button>

          {tagToRemove && (
            <WorkflowModal
              closeBehavior={{ tag: "with-button", onClose: cancelUntag }}
              title={
                <FormattedMessage
                  id="a6de52a3-db0b-4e9d-b809-f16a69655540"
                  defaultMessage="Confirm tag removal"
                />
              }
              buttons={[
                <Button
                  automationId="confirm-remove-organization-tag"
                  key="confirm"
                  onClick={untag}
                  isLoading={isUpdating}
                  buttonColor="action"
                  variant="primary"
                >
                  <FormattedMessage
                    id="5a827592-78b1-473c-b5bf-2da977c3ceaa"
                    defaultMessage="Remove Tag"
                  />
                </Button>,
              ]}
              footerSeparator={false}
            >
              <FormattedMessage
                id="1aed1505-d8eb-4d41-baa2-122f9042852e"
                defaultMessage={'"<b>{tag}</b>" will be removed'}
                values={{ tag: tagToRemove, b }}
              />
            </WorkflowModal>
          )}
        </Card>
      </form>
    </>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "organizationTagForm" }),
  getFormValues<InnerProps>("organizationTagForm"),
)(OrganizationTags);
