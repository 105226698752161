import { useEffect, useState, useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import compose from "util/compose";
import Button from "common/core/button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import RadioButtonField from "common/form/fields/radio";
import SelectInput from "common/form/fields/select";
import { OrganizationAllowedCertificateProviders } from "graphql_globals";
import { getFormValues, composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { useMutation } from "util/graphql";
import { usePermissions } from "common/core/current_user_role";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";

import UpdateOrganizationDefaultsMutation from "./update_organization_defaults_mutation.graphql";
import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "./details_query.graphql";
import Styles from "./organization_defaults_admin_form.module.scss";

const MESSAGES = defineMessages({
  success: {
    id: "4fde4470-1e15-4737-923a-a1f3aaca33d9",
    defaultMessage: "Successfully updated defaults",
  },
});

type Props = {
  organization: Organization;
};
type FormValues = {
  secondaryIdRequiredDefault: boolean;
  allowedCertificateProviders: undefined | OrganizationAllowedCertificateProviders[];
};
type FormProps = InjectedFormProps<FormValues, Props>;
type GetFormValueProps = {
  formValues: FormValues;
};
type InnerProps = Props & FormProps & GetFormValueProps;

const ALLOWED_CERTIFICATE_PROVIDERS_ITEMS = Object.values(
  OrganizationAllowedCertificateProviders,
).map((type) => ({
  value: type,
  label: type,
}));

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "allowedCertificateProviders", label: "At least one provider" }),
    validatePresence({ field: "secondaryIdRequiredDefault", label: "Option" }),
  )(values);
}

function OrganizationDefaultsAdminForm({
  handleSubmit,
  initialize,
  organization,
  formValues,
}: InnerProps) {
  const { id, secondaryIdRequiredDefault, allowedCertificateProviders } = organization;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const updateOrganizationDefaults = useMutation(UpdateOrganizationDefaultsMutation);
  const intl = useIntl();
  const onSubmit = ({ secondaryIdRequiredDefault, allowedCertificateProviders }: FormValues) => {
    setIsSubmitting(true);
    updateOrganizationDefaults({
      variables: {
        input: {
          id,
          secondaryIdRequiredDefault,
          allowedCertificateProviders,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitDisabled = useMemo(() => {
    return (
      secondaryIdRequiredDefault === formValues.secondaryIdRequiredDefault &&
      formValues.allowedCertificateProviders !== undefined &&
      allowedCertificateProviders.length === formValues.allowedCertificateProviders.length &&
      allowedCertificateProviders.every(
        (acp, idx) => acp === (formValues.allowedCertificateProviders || [])[idx],
      )
    );
  }, [formValues, secondaryIdRequiredDefault, allowedCertificateProviders]);

  useEffect(() => {
    initialize({ secondaryIdRequiredDefault, allowedCertificateProviders });
  }, []);

  const editPermission = hasPermissionFor("editCompanyDetails");
  const submitButton = () => {
    return editPermission ? (
      <div className={Styles.saveButton}>
        <Button
          buttonColor="action"
          variant="primary"
          type="submit"
          isLoading={isSubmitting}
          disabled={submitDisabled}
        >
          <FormattedMessage id="fd29f1e2-3dc6-48ff-a544-3e64dfbdd8b2" defaultMessage="Save" />
        </Button>
      </div>
    ) : null;
  };

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="50f972f9-adb8-4d05-b8a4-56e8305e0600"
          defaultMessage="Organization Defaults"
        />
      </SettingsTitle>
      <form name="OrganizationDefaultsAdminForm" onSubmit={handleSubmit(onSubmit)}>
        <Card footer={submitButton()}>
          <CardHeading>
            <FormattedMessage
              id="cb55368a-4cc4-42b1-a481-933f226ae74f"
              defaultMessage="Allowed Certificate Providers"
            />
          </CardHeading>
          <FormGroup disableFormRowStyle fields={["allowedCertificateProviders"]}>
            <SelectInput
              automationId="organization-defaults-allowed-certificate-providers"
              name="allowedCertificateProviders"
              disabled={!editPermission}
              items={ALLOWED_CERTIFICATE_PROVIDERS_ITEMS}
              multi
              clearable={false}
              searchable={false}
            />
            <FormGroupErrors fields={["allowedCertificateProviders"]} />
          </FormGroup>

          <CardHeading>
            <FormattedMessage
              id="f2ac0b04-0a88-4053-99db-381cc6586464"
              defaultMessage="Secondary Id Required Default"
            />
          </CardHeading>
          <FormGroup disableFormRowStyle fields={["secondaryIdRequiredDefault"]}>
            <RadioButtonField
              name="secondaryIdRequiredDefault"
              labelText="Off"
              radioValue={false}
              size="small"
              disabled={!editPermission}
            />
            <RadioButtonField
              name="secondaryIdRequiredDefault"
              labelText="On"
              radioValue
              size="small"
              disabled={!editPermission}
            />
            <FormGroupErrors fields={["secondaryIdRequiredDefault"]} />
          </FormGroup>
        </Card>
      </form>
    </>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "OrganizationDefaultsAdminForm", validate }),
  getFormValues<InnerProps>("OrganizationDefaultsAdminForm"),
)(OrganizationDefaultsAdminForm);
