import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";
import SelectedDevicesController from "common/selected_devices_controller";
import { Mutation } from "util/graphql/mutation";
import { QueryWithLoading } from "util/graphql/query";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import { FullLogo } from "common/core/logo";

import JoinMeetingMutation from "./join_meeting_mutation.graphql";
import ViewerQuery from "./viewer_query.graphql";
import AudioVideoSettings from "./audio_video_settings";
import UnsupportedBrowserModal, { meetingIsUnsupported } from "./unsupported_browser";

const MESSAGES = defineMessages({
  meetingOver: {
    id: "91c21035-8b0b-45a7-bc66-59728c614515",
    defaultMessage: "Cannot join meeting because it has already completed.",
  },
  queryError: {
    id: "0bc859dd-531c-408d-9025-6f7da56e1b9f",
    defaultMessage: "Unable to load meeting.",
  },
  joinError: {
    id: "4d065a83-a670-4e70-b14f-afe41bf6d168",
    defaultMessage: "Unable to join meeting.",
  },
  maxedCapacity: {
    id: "8be57b7f-326e-403e-bb9c-74b083e99c8f",
    defaultMessage: "Cannot join meeting because it contains too many participants.",
  },
});

class AudioVideoGate extends Component {
  constructor(props) {
    super(props);

    this.state = { audioVideoChecked: false, errorMessage: null };
    this.meetingIsUnsupported = meetingIsUnsupported();
  }

  joinMeeting = ({ phoneNumber }) => {
    const {
      intl,
      joinMeetingMutateFn,
      meetingId,
      user: { id },
    } = this.props;
    const input = {
      meetingId,
      userId: id,
      recordingConsent: true,
      phoneNumber,
    };

    joinMeetingMutateFn({ variables: { input } })
      .then(() => {
        this.setState({ audioVideoChecked: true });
      })
      .catch((err) => {
        if (err?.message.includes("meeting_already_completed")) {
          this.setState({ errorMessage: intl.formatMessage(MESSAGES.meetingOver) });
        } else if (err?.message.includes("maximum_participant_capacity_reached")) {
          this.setState({ errorMessage: intl.formatMessage(MESSAGES.maxedCapacity) });
        } else {
          captureException(err);
          this.setState({ errorMessage: intl.formatMessage(MESSAGES.joinError) });
        }
      });
  };

  renderMessaging = () => {
    return <AlertMessage>{this.state.errorMessage}</AlertMessage>;
  };

  render() {
    const { children } = this.props;
    const { audioVideoChecked, errorMessage } = this.state;

    if (this.meetingIsUnsupported) {
      const redirect = () => this.props.navigate("/");
      return <UnsupportedBrowserModal onClose={redirect} />;
    }

    if (!audioVideoChecked) {
      return (
        <div className="AudioVideoGate">
          <FullLogo whiteLogo className="AudioVideoGate--image" />
          <SelectedDevicesController>
            {({ onChangeDevices, selectedDevices }) => (
              <AudioVideoSettings
                renderMessaging={errorMessage ? this.renderMessaging : undefined}
                selectedDevices={selectedDevices}
                onChangeDevices={onChangeDevices}
                onSubmit={this.joinMeeting}
              />
            )}
          </SelectedDevicesController>
        </div>
      );
    }

    return children;
  }
}

AudioVideoGate.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  joinMeetingMutateFn: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function WrappedAudioVideoGate({ children }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { meetingId } = useParams();
  return (
    <Mutation mutation={JoinMeetingMutation}>
      {(joinMeetingMutateFn) => (
        <QueryWithLoading query={ViewerQuery}>
          {({ data, error, refetch }) => {
            if (error) {
              return (
                <RegularErrorModal
                  clearErrors={refetch}
                  errorString={intl.formatMessage(MESSAGES.queryError)}
                />
              );
            }

            return (
              <AudioVideoGate
                intl={intl}
                joinMeetingMutateFn={joinMeetingMutateFn}
                user={data.viewer.user}
                meetingId={meetingId}
                navigate={navigate}
              >
                {children}
              </AudioVideoGate>
            );
          }}
        </QueryWithLoading>
      )}
    </Mutation>
  );
}

/** @type any */
export default WrappedAudioVideoGate;
