import { useCallback } from "react";

import { useMutation } from "util/graphql";
import type { FullAdminOrganizationDetails_organization_Organization_closingStates as ClosingState } from "admin_portal/company/details_query.graphql";

import UpdateMortgageSpecificClosingStateMutation from "./update_mortgage_specific_closing_state_mutation.graphql";

type Options = {
  label: string | null;
  value: string;
};

export const optionsToValues = (options: Options[]) => {
  return options.map((option) => option.value);
};

export const useMortgageClosingStatePreferences = (closingStates: ClosingState[]) => {
  const updateMortgageSpecificClosingState = useMutation(
    UpdateMortgageSpecificClosingStateMutation,
  );

  const addClosingStatePreference = useCallback(
    (closingStateId: string, notaryStateIDs: string[]) => {
      return updateMortgageSpecificClosingState({
        variables: {
          input: {
            closingStateId,
            specificUsStateIds: notaryStateIDs,
          },
        },
      });
    },
    [],
  );

  const updateClosingStatePreference = useCallback(
    (closingStateId: string, notaryStateIds: string[]) => {
      return updateMortgageSpecificClosingState({
        variables: {
          input: {
            closingStateId,
            specificUsStateIds: notaryStateIds,
          },
        },
      });
    },
    [],
  );

  const deleteClosingStatePreference = useCallback((closingStateId: string) => {
    return updateMortgageSpecificClosingState({
      variables: {
        input: {
          closingStateId,
          specificUsStateIds: [],
        },
      },
    });
  }, []);

  // This callback heavily relies on assumptions about input data:
  // 1. previousStateIds and nextStateIds always differ
  // 2. the amount in which they differ is always by 1
  // Currently, these assumptions work but could break due to developer error. If we want to fully validate,
  // we'll need to write-up some Set arithmetic(specifically set difference).
  const intraStatePreferenceChange = useCallback(
    (previousClosingStateIds: string[], nextClosingStateIds: string[]) => {
      if (nextClosingStateIds.length > previousClosingStateIds.length) {
        const newIntraClosingStateId = nextClosingStateIds.find(
          (nextClosingStateId) =>
            !previousClosingStateIds.find(
              (previousClosingStateId) => nextClosingStateId === previousClosingStateId,
            ),
        )!;
        const newIntraClosingState = closingStates.find((closingState) => {
          return closingState.id === newIntraClosingStateId;
        })!;
        return updateMortgageSpecificClosingState({
          variables: {
            input: {
              closingStateId: newIntraClosingStateId,
              specificUsStateIds: [newIntraClosingState.usState.id],
            },
          },
        });
      } else if (nextClosingStateIds.length < previousClosingStateIds.length) {
        const oldIntraClosingStateId = previousClosingStateIds.find(
          (previousClosingStateId) =>
            !nextClosingStateIds.find(
              (nextClosingStateId) => previousClosingStateId === nextClosingStateId,
            ),
        )!;
        return updateMortgageSpecificClosingState({
          variables: {
            input: {
              closingStateId: oldIntraClosingStateId,
              specificUsStateIds: [],
            },
          },
        });
      }
    },
    [closingStates],
  );

  return {
    intraStatePreferenceChange,
    updateClosingStatePreference,
    deleteClosingStatePreference,
    addClosingStatePreference,
  };
};

type MortgageClosingStatePreferenceHook = ReturnType<typeof useMortgageClosingStatePreferences>;

export type { ClosingState, Options, MortgageClosingStatePreferenceHook };
