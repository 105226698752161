import { useParams } from "react-router-dom";

import AdobeInstance from "common/adobe_instance";
import { useQuery } from "util/graphql";
import { SettingsPageWrapper } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";

import AdobeQuery, {
  type OrganizationAdobe_organization_Organization as Organization,
} from "./adobe_query.graphql";

export default function AdobeIntegration() {
  const organizationId = useParams().globalID!;
  const { data, loading } = useQuery(AdobeQuery, {
    variables: { organizationId },
  });
  const organization = data?.organization as Organization;

  return loading ? (
    <LoadingIndicator />
  ) : (
    <SettingsPageWrapper>
      <AdobeInstance
        organizationId={organization.id}
        accountManager={organization.accountManager}
        adobeEnabled={organization.adobeEnabled}
      />
    </SettingsPageWrapper>
  );
}
