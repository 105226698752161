import { FormattedMessage } from "react-intl";

import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "admin_portal/company/details_query.graphql";

import CredentialsForm from "./credentials_form";

type Props = {
  organization: Organization;
};

export function DocmagicProfile({ organization }: Props) {
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="54dfe671-7d4b-461a-9faa-fce9bc93ff49"
          defaultMessage="DocMagic Profile"
        />
      </SettingsTitle>

      <Card>
        <CardHeading>
          <FormattedMessage
            id="9761ebb0-1053-4331-abb8-8f12003dee58"
            defaultMessage="DocMagic Profile Settings"
          />
        </CardHeading>

        <CredentialsForm
          organizationId={organization.id}
          existingCredentials={organization.docmagicProfile}
        />
      </Card>
    </>
  );
}
