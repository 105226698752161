import "./eoriginal_testing_form_error_modal.scss";

import PropTypes from "prop-types";

import BlockingModal from "common/blocking_modal";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import uploadFailedImage from "assets/images/upload-doc-fail.svg";

function EOriginalTestingFormErrorModal({ errors, isOpen, onClose }) {
  return (
    <BlockingModal className="EOriginalTestingFormErrorModal" isOpen={isOpen}>
      <ModalGraphic>
        <img alt="bundle failure" src={uploadFailedImage} />
      </ModalGraphic>
      <ModalHeader>An error occurred and the eNote was not completed.</ModalHeader>

      <ModalSubheader>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </ModalSubheader>

      <ModalActions>
        <Button buttonColor="action" buttonSize="large" variant="primary" onClick={onClose}>
          Ok
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

EOriginalTestingFormErrorModal.propTypes = {
  errors: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EOriginalTestingFormErrorModal;
