import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import { useProofReasonCodes } from "./context";
import DiscardConfirmModal from "./discard_confirm_modal";
import ReviewModal from "./review_modal";
import Styles from "./proof_reason_codes.module.scss";

export default function ActionButtons() {
  const { edits, discardAllEdits } = useProofReasonCodes();
  const hasEdits = Boolean(Object.keys(edits).length);

  const [discardConfirmOpen, setDiscardConfirmOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);

  const handleDiscard = useCallback(() => {
    setDiscardConfirmOpen(true);
  }, []);

  const handleConfirmDiscard = useCallback(() => {
    setDiscardConfirmOpen(false);
    discardAllEdits();
  }, []);

  const handleCancelDiscard = useCallback(() => {
    setDiscardConfirmOpen(false);
  }, []);

  const handleReview = useCallback(() => {
    setReviewOpen(true);
  }, []);

  const handleReviewCancel = useCallback(() => {
    setReviewOpen(false);
  }, []);

  const handleReviewCommit = useCallback(() => {}, []);

  return (
    <>
      <DiscardConfirmModal
        isOpen={discardConfirmOpen}
        onConfirm={handleConfirmDiscard}
        onCancel={handleCancelDiscard}
      />

      <ReviewModal
        isOpen={reviewOpen}
        onCommit={handleReviewCommit}
        onCancel={handleReviewCancel}
      />

      <div className={Styles.buttonBar}>
        <Button
          variant="secondary"
          buttonColor="danger"
          onClick={handleDiscard}
          disabled={!hasEdits}
        >
          <FormattedMessage
            id="2155b0c4-353c-45c7-8d3a-e2c243de73b6"
            defaultMessage="Discard changes"
          />
        </Button>
        <Button variant="primary" buttonColor="action" onClick={handleReview} disabled={!hasEdits}>
          <FormattedMessage
            id="456cb25f-cfec-47d0-930b-2eb08c427d9b"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </>
  );
}
