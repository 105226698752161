import { useParams } from "react-router-dom";

import { useActiveOrganization } from "common/account/active_organization";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

/** Used in components shared between portals having an org context and keystone where the org id is in the url params **/
export function useContextOrParamsOrgId(): string {
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const { globalID: paramsOrgId } = useParams();
  const [contextOrgId] = useActiveOrganization();
  return isAdminPortal ? paramsOrgId! : contextOrgId!;
}
