import { memo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import Modal from "common/modal";
import LoadingIndicator from "common/core/loading_indicator";
import { LongFormattedDateTime } from "common/core/format/date";
import { useQuery } from "util/graphql";

import SearchForMeetingRequestsQuery, {
  type SearchForMeetingRequestsVariables,
} from "./search_modal_query.graphql";
import MeetingRequestDetailsModal from "./details_modal";
import Styles from "./search_modal.module.scss";

type Props = {
  id: string;
  onClose: () => void;
};

function SearchModal({ id, onClose }: Props) {
  const searchKey: keyof SearchForMeetingRequestsVariables = id.startsWith("ot")
    ? "transactionId"
    : id.startsWith("us")
      ? "userId"
      : id.startsWith("or")
        ? "orgId"
        : "docBundleId";
  const { data, loading } = useQuery(SearchForMeetingRequestsQuery, {
    variables: { [searchKey]: id },
    fetchPolicy: "no-cache",
  });
  const [openMeetingRequestId, setOpenMeetingRequestId] = useState<null | string>(null);
  const handleCloseDetails = useCallback(() => setOpenMeetingRequestId(null), []);
  if (loading || !data) {
    return <LoadingIndicator />;
  }
  const { queriedMeetingRequests } = data.viewer;
  return (
    <Modal
      closeRoute={onClose}
      title={
        <FormattedMessage
          id="f4ab222a-1a01-4754-af70-16ed57dea113"
          defaultMessage="Search Results"
        />
      }
    >
      {queriedMeetingRequests.totalCount ? (
        <ul className={Styles.resultList}>
          {queriedMeetingRequests.edges.map(({ node: meetingRequest }) => (
            <li key={meetingRequest.id} onClick={() => setOpenMeetingRequestId(meetingRequest.id)}>
              <FormattedMessage
                id="0a689f53-ae66-46de-b92a-e1711e5a242d"
                defaultMessage="{requestedBy} on {date}"
                values={{
                  requestedBy: meetingRequest.requestedBy,
                  date: <LongFormattedDateTime value={meetingRequest.createdAt} />,
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <FormattedMessage
          id="d3988c65-31ad-4305-bba1-fd192da797d1"
          defaultMessage="No meeting requests found for {id}"
          values={{ id }}
        />
      )}
      {openMeetingRequestId && (
        <MeetingRequestDetailsModal id={openMeetingRequestId} onClose={handleCloseDetails} />
      )}
    </Modal>
  );
}

export default memo(SearchModal);
