import "common/styles/data_table.scss";

import { Link, Outlet, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { newPathWithPreservedSearchParams } from "util/location";
import Pagination from "common/pagination";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import { maxPageNumber, paginationVariables } from "util/pagination";
import { usePermissions } from "common/core/current_user_role";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { SearchField } from "common/core/search_field";

import AdminTable from "./table";
import Styles from "./index.module.scss";
import AdminListDashboardQuery from "./index_query.graphql";

const MESSAGES = defineMessages({
  searchLabel: {
    id: "c4c86161-a4ed-4b3d-b6e9-239bee9bfc7e",
    defaultMessage: "Search by admin name or email",
  },
});

function AdminListContainer() {
  const { hasPermissionFor } = usePermissions();
  const intl = useIntl();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const term = searchParams.get("term");
  const { first, offset } = paginationVariables(page);

  const { loading, data } = useQuery(AdminListDashboardQuery, {
    variables: { first, offset, term },
  });
  if (loading) {
    return <LoadingIndicator />;
  }

  const { admins } = data!.viewer;
  return (
    <AppFrameInnerContainer className={Styles.adminList} backPath="/" title="Admins">
      {hasPermissionFor("createAdmin") && (
        <Link
          to={newPathWithPreservedSearchParams("/admin/records/new")}
          className={Styles.adminButton}
          data-automation-id="add-an-admin-button"
        >
          <FormattedMessage
            id="3c9db46a-091e-4d69-bb99-38c00b0c53de"
            defaultMessage="+ Add An Admin"
          />
        </Link>
      )}

      <SearchField
        onSearch={(term) => {
          setSearchParams(new URLSearchParams({ term }));
        }}
        value={term || ""}
        className={Styles.searchField}
        placeholder={intl.formatMessage(MESSAGES.searchLabel)}
        searchOnBlur
        searchOnClear
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
      />

      <AdminTable connection={admins} />

      <Pagination
        maxPageNumber={maxPageNumber(admins.totalCount)}
        onChange={(page) => {
          setSearchParams((cur) => {
            const newParams = new URLSearchParams(cur);
            newParams.set("page", page.toString());
            return newParams;
          });
        }}
        pageNumber={Number(page)}
      />

      <Outlet />
    </AppFrameInnerContainer>
  );
}

export default AdminListContainer;
