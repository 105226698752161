import { type ReactNode, useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Container, Row, Column, useMatchScreenClass } from "common/core/responsive";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Icon from "common/core/icon";
import LoadingIndicator from "common/core/loading_indicator";
import { useForm } from "common/core/form";
import { TextInput, TextAreaInput } from "common/core/form/text";
import { isAriaInvalid, FieldErrorMessage } from "common/core/form/error";
import { FormattedDate } from "common/core/format/date";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { TransactionEmailTypesEnum, type EmailTemplateNamesEnum } from "graphql_globals";
import { useQuery } from "util/graphql";
import { EMAIL_LABELS } from "admin_portal/company/details/communication_settings/table";

import { useRenderInteraction } from "./interactions/render";
import { useSaveInteraction, Modal as ConfirmSaveModal } from "./interactions/save";
import { useRevertInteraction, Modal as ConfirmRevertModal } from "./interactions/revert";
import {
  useTestEmailInteraction,
  Modal as ConfirmSendTestEmailModal,
} from "./interactions/test_email";
import { uiTypeLabelAndSlug } from "../helpers";
import CommunicationSettingsEditEmailGraph, {
  type CommunicationSettingsEditEmail_node_Organization as Organization,
} from "./index.query.graphql";
import type { EmailUITypes } from "../types";
import CommonStyles from "../index.module.scss";
import Styles from "./index.module.scss";

type FormValues = {
  subject: string;
  body: string;
  payload: string;
};

type Modal = "confirm-publish" | "confirm-revert" | "confirm-discard" | "send-test-email";

const DefaultError = (
  <FormattedMessage
    id="7b562197-77a7-491b-a4ba-2d08823a04cf"
    defaultMessage="Please enter a valid value."
  />
);

function renderHtml(html: string, isSmall: boolean) {
  return (
    <div className={classnames(Styles.emailPreview, isSmall && Styles.emailPreviewSmall)}>
      <iframe title="email preview" sandbox="" srcDoc={html} />
    </div>
  );
}

function ConfirmDiscardModal({
  onCancel,
  onConfirm,
  children,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  children: ReactNode;
}) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="2fb77c80-42f1-4272-9cb8-7ab7110c6e3f"
          defaultMessage="Are you sure you want to discard your changes?"
        />
      }
      buttons={[
        <Button buttonColor="action" variant="secondary" key="cancel-publish" onClick={onCancel}>
          <FormattedMessage id="7e0ebe7f-5505-452f-ae32-8295753b6390" defaultMessage="No, Cancel" />
        </Button>,
        <Button buttonColor="action" variant="primary" key="confirm-publish" onClick={onConfirm}>
          <FormattedMessage
            id="8f7e6783-685f-4cbc-9457-eb9389fb84ad"
            defaultMessage="Yes, Discard Changes"
          />
        </Button>,
      ]}
    >
      {children}
    </WorkflowModal>
  );
}

function getTypeLabel(emailType: TransactionEmailTypesEnum | null) {
  if (emailType === TransactionEmailTypesEnum.ECLOSE) {
    return <FormattedMessage id="4f95c76d-15d5-4998-b6f1-4fdfdde2678a" defaultMessage="eClose" />;
  } else if (emailType === TransactionEmailTypesEnum.HYBRID) {
    return <FormattedMessage id="4a3c93f2-317b-4941-a363-7762478b5af6" defaultMessage="Hybrid" />;
  } else if (emailType === TransactionEmailTypesEnum.NOTARIZATION) {
    return (
      <FormattedMessage id="039582b0-096b-456a-a60d-74104af91a22" defaultMessage="Notarization" />
    );
  } else if (emailType === TransactionEmailTypesEnum.ESIGN) {
    return (
      <FormattedMessage id="a6bb716f-bec1-4ac0-8c72-fa9c354e76a9" defaultMessage="eSign Only" />
    );
  }

  return <FormattedMessage id="ed234157-7507-486a-8e28-8f8d30262c70" defaultMessage="General" />;
}

function CommunicationSettingsEditEmail({
  organization,
  uiTypeLabel,
  uiTypeSlug,
}: {
  organization: Organization;
  uiTypeLabel: string;
  uiTypeSlug: string;
}) {
  const emailTemplate = organization.organizationBrand!.emailTemplate;
  const {
    name: emailName,
    type: emailType,
    body: initialBody,
    subject: initialSubject,
    previewPayload: initialPayload,
    updatedAt,
  } = emailTemplate;

  const navigate = useNavigate();
  const isMedium = useMatchScreenClass("xs", "sm", "md");

  const [openModal, setOpenModal] = useState<Modal>();
  const { formState, reset, watch, register } = useForm<FormValues>({
    defaultValues: { subject: initialSubject!, body: initialBody!, payload: initialPayload },
    mode: "onChange",
  });
  const { isDirty, isValid, errors } = formState;
  const [subject, body, payload] = watch(["subject", "body", "payload"]);
  const watchedFormValues = { subject, body, payload };
  const modified = emailTemplate.__typename === "EmailTemplate";

  const {
    rendering,
    renderData,
    errors: renderErrors,
    previewEmail,
  } = useRenderInteraction({
    organization,
    initialValues: watchedFormValues,
  });
  const { submitting, saveChanges } = useSaveInteraction({
    organization,
    body,
    subject,
  });
  const { reverting, revert } = useRevertInteraction({
    organization,
  });
  const { sending, sendTestEmail } = useTestEmailInteraction({
    organization,
    data: watchedFormValues,
  });

  const handleDiscard = () => {
    reset({
      subject: initialSubject!,
      body: initialBody!,
      payload: initialPayload,
    });
    setOpenModal(undefined);
  };

  const handlePublish = () => {
    if (!isValid) {
      return;
    }
    saveChanges().then(({ body, subject, payload }) => {
      reset({
        body,
        subject,
        payload,
      });
      setOpenModal(undefined);
    });
  };

  const handleRevert = () => {
    revert().then(({ data }) => {
      const defaultEmailTemplate = data!.deleteEmailTemplate!.defaultEmailTemplate;
      const { body, subject, previewPayload } = defaultEmailTemplate;
      reset({
        body: body!,
        subject: subject!,
        payload: previewPayload,
      });
      setOpenModal(undefined);
    });
  };

  const handleSendTestEmail = (recipientInputs: { email: string }[]) => {
    const recipients = recipientInputs.map((recip) => recip.email);
    sendTestEmail(recipients).then(() => {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        title: (
          <FormattedMessage
            id="8201c330-73ca-4966-b72b-762b0540c2c2"
            defaultMessage="Test {emailCount, plural, one {email} other {emails}} sent!"
            values={{ emailCount: recipients.length }}
          />
        ),
        message: (
          <FormattedMessage
            id="9387a475-e2b8-43c5-a99d-b07079c1eb0d"
            defaultMessage="Emails may take up to a minute to arrive."
          />
        ),
      });
      setOpenModal(undefined);
    });
  };

  useEffect(() => {
    if (isValid) {
      previewEmail(watchedFormValues);
    }
  }, [isValid, previewEmail, subject, body, payload]);

  return (
    <>
      {openModal === "confirm-publish" && (
        <ConfirmSaveModal
          onConfirm={handlePublish}
          onCancel={() => {
            setOpenModal(undefined);
          }}
          loading={submitting}
        />
      )}
      {openModal === "confirm-revert" && (
        <ConfirmRevertModal
          onConfirm={handleRevert}
          onCancel={() => {
            setOpenModal(undefined);
          }}
          loading={reverting}
        />
      )}
      {openModal === "confirm-discard" && (
        <ConfirmDiscardModal
          onConfirm={handleDiscard}
          onCancel={() => {
            setOpenModal(undefined);
          }}
        >
          <FormattedMessage
            id="3a275336-82c4-4c89-86db-40889974e0fb"
            defaultMessage="Changes will be erased back to your last publish on {modifiedDate} made by {author}."
            values={{
              modifiedDate: <FormattedDate value={updatedAt} />,
              author: emailTemplate.updatedBy,
            }}
          />
        </ConfirmDiscardModal>
      )}
      {openModal === "send-test-email" && (
        <ConfirmSendTestEmailModal
          onConfirm={handleSendTestEmail}
          onCancel={() => {
            setOpenModal(undefined);
          }}
          loading={sending}
        />
      )}
      <Container className={Styles.container} fluid>
        <Row>
          <Column className={Styles.navbar}>
            <Button
              variant="tertiary"
              buttonColor="dark"
              onClick={() => {
                navigate(
                  `/companies/${organization.id}/communication-settings/${uiTypeSlug}-emails`,
                );
              }}
            >
              <div className={Styles.backButton}>
                <Icon name="arrow-left" />
                <span>
                  <FormattedMessage
                    id="958c60f7-4ae8-49fa-aa99-15b24a694e07"
                    defaultMessage={`Back to {uiTypeLabel} Emails`}
                    values={{
                      uiTypeLabel,
                    }}
                  />
                </span>
              </div>
            </Button>
            <Button
              buttonColor="action"
              variant="secondary"
              onClick={() => {
                setOpenModal("send-test-email");
              }}
              isLoading={sending}
              disabled={!isValid}
            >
              <FormattedMessage
                id="30368354-e883-4ef3-a9a5-b634bb7753e6"
                defaultMessage="Send A Test Email"
              />
            </Button>
          </Column>
        </Row>
        <Row>
          <Column className={Styles.infobar}>
            <div className={Styles.breadcrumbs}>
              <span>
                <FormattedMessage
                  id="a7546037-6764-49b9-a99f-2c29e0c4530b"
                  defaultMessage="Editing: {uiTypeLabel} Emails / {typeLabel} / {name}"
                  values={{
                    typeLabel: getTypeLabel(emailType),
                    name: EMAIL_LABELS[emailName],
                    uiTypeLabel,
                  }}
                />
              </span>
            </div>
            <div className={Styles.actionButtons}>
              {modified && (
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  onClick={() => {
                    setOpenModal("confirm-revert");
                  }}
                  isLoading={reverting}
                >
                  <FormattedMessage
                    id="2bea140b-cbb9-4ff6-92ed-74395f9d818f"
                    defaultMessage="Revert to Notarize Default"
                  />
                </Button>
              )}
              {isDirty && (
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  onClick={() => {
                    if (!modified) {
                      handleDiscard();
                    } else {
                      setOpenModal("confirm-discard");
                    }
                  }}
                >
                  <FormattedMessage
                    id="500f8fe3-9b5c-462d-b946-201b4a804b7e"
                    defaultMessage="Discard Changes"
                  />
                </Button>
              )}
              <Button
                buttonColor="action"
                variant="primary"
                onClick={() => {
                  setOpenModal("confirm-publish");
                }}
                isLoading={submitting}
                disabled={!isValid || !isDirty}
              >
                <FormattedMessage
                  id="1b580770-c5de-4634-8c35-8c3a07a14b02"
                  defaultMessage="Publish Changes"
                />
              </Button>
            </div>
          </Column>
        </Row>
        <Row className={Styles.body}>
          <Column className={Styles.previewContainer} xs={12} lg={7}>
            {rendering && (
              <div className={Styles.loadingIndicator}>
                <FormattedMessage
                  id="da7e8180-bd76-44d5-90f9-cb91843fdda4"
                  defaultMessage="Loading..."
                />
              </div>
            )}
            {(renderErrors || !renderData?.emailBody) && (
              <div className={Styles.renderError}>
                <div className={Styles.renderErrorIcon}>
                  <Icon name="doc-warning" />
                </div>
                <span>
                  <FormattedMessage
                    id="4d45e98b-955a-4081-9444-7e933d31997c"
                    defaultMessage="We cannot display a preview due to an error in the email body or test data. If you are having trouble fixing the issue, you can discard changes or revert to default."
                  />
                </span>
              </div>
            )}
            {renderData && (
              <>
                <div className={Styles.subject}>
                  <FormattedMessage
                    id="6335ae83-9c48-4dce-8f9e-ef2a24ac0e34"
                    defaultMessage="Subject: {subject}"
                    values={{ subject: renderData.emailSubject }}
                  />
                </div>
                {renderHtml(renderData.emailBody, isMedium)}
              </>
            )}
          </Column>
          <Column
            className={classnames(Styles.editor, isMedium && Styles.editorSmall)}
            xs={12}
            lg={5}
          >
            <form className={Styles.form} spellCheck="false">
              <div className={Styles.textInputContainer}>
                <div className={CommonStyles.subheading}>
                  <FormattedMessage
                    id="2a327928-03ea-46e9-9e4a-371621f8af2c"
                    defaultMessage="Email Subject Line"
                  />
                </div>
                <TextInput
                  aria-invalid={isAriaInvalid(errors.subject)}
                  {...register("subject", {
                    required: true,
                  })}
                />
                {errors.subject && (
                  <span className={Styles.errorMessage}>
                    <FieldErrorMessage inputName="subject" message={DefaultError} />
                  </span>
                )}
              </div>
              <div className={Styles.bodyInputContainer}>
                <div className={CommonStyles.subheading}>
                  <FormattedMessage
                    id="7ba8505a-e9ed-4a0d-8f02-70b71e815470"
                    defaultMessage="Email Body"
                  />
                </div>
                <TextAreaInput
                  aria-invalid={isAriaInvalid(errors.body)}
                  {...register("body", {
                    required: true,
                  })}
                />
              </div>
              {(errors.body || Boolean(renderErrors?.bodyError)) && (
                <span className={Styles.errorMessage}>
                  <FieldErrorMessage
                    inputName="body"
                    message={renderErrors?.bodyError ? renderErrors.bodyError : DefaultError}
                  />
                </span>
              )}
              <div className={Styles.payloadInputContainer}>
                <div className={CommonStyles.subheading}>
                  <FormattedMessage
                    id="c0f990e6-4e0b-456c-b255-2e1f368d32e4"
                    defaultMessage="Test Data"
                  />
                </div>
                <TextAreaInput
                  aria-invalid={isAriaInvalid(errors.payload)}
                  {...register("payload", {
                    required: true,
                  })}
                />
              </div>
              {(errors.payload || Boolean(renderErrors?.payloadError)) && (
                <span className={Styles.errorMessage}>
                  <FieldErrorMessage
                    inputName="body"
                    message={renderErrors?.payloadError ? renderErrors.payloadError : DefaultError}
                  />
                </span>
              )}
            </form>
          </Column>
        </Row>
      </Container>
    </>
  );
}

export default function CommunicationSettingsEditEmailWrapper({
  uiType,
}: {
  uiType: EmailUITypes;
}) {
  const { globalID, emailName } = useParams();
  const { uiTypeSlug, uiTypeLabel } = useMemo(() => uiTypeLabelAndSlug(uiType), [uiType]);
  const { data, loading } = useQuery(CommunicationSettingsEditEmailGraph, {
    variables: {
      organizationId: globalID!,
      emailName: emailName!.toUpperCase() as EmailTemplateNamesEnum,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.node! : null;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return (
    <CommunicationSettingsEditEmail
      uiTypeSlug={uiTypeSlug}
      uiTypeLabel={uiTypeLabel}
      organization={organization}
    />
  );
}
