import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import { LongFormattedDateTime } from "common/core/format/date";
import { Card } from "common/core/card";
import LoadingIndicator from "common/core/loading_indicator";
import Pagination from "common/pagination";
import { useQuery } from "util/graphql";
import { maxPageNumber } from "util/pagination";
import { useId } from "util/html";

import Styles from "./index.module.scss";
import OrganizationAuditLogs, {
  type OrganizationAuditLogs_organization_Organization as Organization,
  type OrganizationAuditLogs_organization_Organization_auditLogs_edges as LogEntries,
  type OrganizationAuditLogs_organization_Organization_auditLogs_edges_node as LogEntry,
} from "./organization_audit_logs.query.graphql";

type Change = {
  field_name: string;
  old_value: string;
  new_value: string;
  reason: string;
};

type Metadata = {
  changes: Change[];
};

const messages = defineMessages({
  title: {
    id: "df6b3a5e-3330-447d-8e86-f9e71f9a778b",
    defaultMessage: "Organization Settings Audit Log",
  },
  none: {
    id: "b9262086-8ad9-4f7c-be39-06ea71c9732c",
    defaultMessage: "none",
  },
});

const ITEMS_PER_PAGE = 5;

export default function AdminCompanyDetailsAuditLogs() {
  const { globalID } = useParams();
  const [searchParams] = useSearchParams();
  const intl = useIntl();
  const page = Number(searchParams.get("page") || 1);
  const { data, loading } = useQuery(OrganizationAuditLogs, {
    variables: {
      organizationId: globalID!,
      numPerPage: ITEMS_PER_PAGE,
      pageOffset: (page - 1) * ITEMS_PER_PAGE,
    },
  });

  const navigate = useNavigate();

  const dateFieldId = useId();
  const actingUserFieldId = useId();
  const fieldFieldId = useId();
  const oldValueFieldId = useId();
  const newValueFieldId = useId();
  const reasonFieldId = useId();

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.organization as Organization | null;
  const logEdges = organization?.auditLogs.edges as LogEntries[] | null;
  const totalCount = organization?.auditLogs.totalCount;

  const getChanges = (logEntry: LogEntry): Change[] => {
    const metadata = logEntry.actionMetadata as Metadata;
    return metadata.changes;
  };

  return (
    <SettingsPageWrapper>
      <SettingsHeader title={intl.formatMessage(messages.title)} />
      {!logEdges?.length && (
        <FormattedMessage
          id="91ae7d44-0ae1-4b20-8b94-3694a145da9d"
          defaultMessage="There are no audit log entries for this organization yet."
        />
      )}
      <div className={Styles.container}>
        {logEdges?.map(({ node: log }: { node: LogEntry }) => (
          <Card key={log.createdAt}>
            <dl className={Styles.header}>
              <div className={Styles.headerContainer}>
                <dt className={Styles.headerLabel} id={dateFieldId}>
                  <FormattedMessage
                    id="2edebf56-48c2-4c28-bf4f-8833778856bc"
                    defaultMessage="Date"
                  />
                </dt>
                <dd aria-labelledby={dateFieldId}>
                  <LongFormattedDateTime value={log.createdAt} />
                </dd>
              </div>
              <div className={Styles.headerContainer}>
                <dt className={Styles.headerLabel} id={actingUserFieldId}>
                  <FormattedMessage
                    id="444257b4-8dd6-468b-8423-26329bac3371"
                    defaultMessage="Acting User"
                  />
                </dt>
                <dd aria-labelledby={actingUserFieldId}>{log.actor}</dd>
              </div>
            </dl>
            <div>
              {getChanges(log).map((change: Change) => (
                <dl className={Styles.entry} key={change.field_name}>
                  <div className={Styles.entryContainer}>
                    <dt className={Styles.entryLabel} id={fieldFieldId}>
                      <FormattedMessage
                        id="e8a726d1-19e6-4063-ba7a-2715baacaac9"
                        defaultMessage="Field"
                      />
                    </dt>
                    <dd aria-labelledby={fieldFieldId}>{change.field_name}</dd>
                  </div>
                  <div className={Styles.entryContainer}>
                    <dt className={Styles.entryLabel} id={oldValueFieldId}>
                      <FormattedMessage
                        id="a6d51c60-9210-40b9-8eb1-97ef7b3a4faa"
                        defaultMessage="Old Value"
                      />
                    </dt>
                    <dd aria-labelledby={oldValueFieldId}>
                      {change.old_value || intl.formatMessage(messages.none)}
                    </dd>
                  </div>
                  <div className={Styles.entryContainer}>
                    <dt className={Styles.entryLabel} id={newValueFieldId}>
                      <FormattedMessage
                        id="0c7c2568-2251-4d3f-b159-301944e85f55"
                        defaultMessage="New Value"
                      />
                    </dt>
                    <dd aria-labelledby={newValueFieldId}>
                      {change.new_value || intl.formatMessage(messages.none)}
                    </dd>
                  </div>
                  <div className={Styles.entryContainer}>
                    <dt className={Styles.entryLabel} id={reasonFieldId}>
                      <FormattedMessage
                        id="575871af-a23f-436a-9efa-ad6831680d8e"
                        defaultMessage="Reason"
                      />
                    </dt>
                    <dd aria-labelledby={reasonFieldId}>
                      {change.reason || intl.formatMessage(messages.none)}
                    </dd>
                  </div>
                </dl>
              ))}
            </div>
          </Card>
        ))}
        <Pagination
          maxPageNumber={maxPageNumber(totalCount || 0, ITEMS_PER_PAGE)}
          onChange={(page) => {
            navigate(`${location.pathname}?page=${page}`);
          }}
          pageNumber={page}
        />
      </div>
    </SettingsPageWrapper>
  );
}
