import type { ComponentProps } from "react";
import { useParams } from "react-router-dom";

import type AddNote from "common/internal_notes/add_note";
import { useQuery, useMutation } from "util/graphql";
import InternalNotesContainer from "common/internal_notes";
import LoadingIndicator from "common/core/loading_indicator";

import CreateOrganizationTransactionNoteMutation from "./create_organization_transaction_note_mutation.graphql";
import InternalNotesQuery, { type InternalNotes } from "./internal_notes_query.graphql";

export default function InternalTransactionNotes() {
  const transactionID = useParams().transactionID!;

  const { data, loading } = useQuery(InternalNotesQuery, {
    variables: { transactionId: transactionID },
  });

  const createNoteMutateFn = useMutation(CreateOrganizationTransactionNoteMutation);
  const handleSaveNewNote: ComponentProps<typeof AddNote>["onSave"] = (noteText, attachments) =>
    createNoteMutateFn({
      variables: {
        input: {
          organizationTransactionId: transactionID,
          text: noteText,
          attachments: attachments
            .filter((attachment) => attachment.state === "done")
            .map((attachment) => ({ name: attachment.file.name, s3Key: attachment.key })),
        },
      },
      update(cacheProxy, { data }) {
        const organizationTransactionEntry = cacheProxy.readQuery<InternalNotes>({
          query: InternalNotesQuery,
          variables: {
            transactionId: transactionID,
          },
        })!.transaction;
        if (organizationTransactionEntry?.__typename !== "OrganizationTransaction") {
          throw new Error("Figure out errror");
        }

        cacheProxy.writeQuery({
          query: InternalNotesQuery,
          variables: {
            transactionId: transactionID,
          },
          data: {
            transaction: {
              ...organizationTransactionEntry,
              notes: [
                ...organizationTransactionEntry.notes,
                data!.createOrganizationTransactionNote!.note,
              ],
            },
          },
        });
      },
    });

  if (loading || data?.transaction?.__typename !== "OrganizationTransaction") {
    return <LoadingIndicator />;
  }

  return (
    <InternalNotesContainer handleSaveNewNote={handleSaveNewNote} notes={data.transaction.notes} />
  );
}
