import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import WorkflowModal from "common/modals/workflow_modal";
import { FormattedDate } from "common/core/format/date";
import { CardHeading } from "common/core/card";

import Styles from "./index.module.scss";
import type {
  FullAdminOrganizationDetails_organization_Organization as Organization,
  FullAdminOrganizationDetails_organization_Organization_settings as OrganizationSetting,
} from "../details_query.graphql";

type Props = {
  organization: Organization;
};

export function Settings(props: Props) {
  const { organization } = props;
  const settings = organization.settings;
  const [showSettings, setShowSettings] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<OrganizationSetting | null>();

  return (
    <>
      <CardHeading>
        <FormattedMessage id="a09c555b-0eaa-42fb-a0e6-7b096c5918da" defaultMessage="Settings" />
      </CardHeading>
      <Link onClick={() => setShowSettings(!showSettings)}>
        {showSettings ? (
          <FormattedMessage
            id="84003238-d243-4b2d-aca4-a8807c9269a4"
            defaultMessage="Hide settings"
          />
        ) : (
          <FormattedMessage
            id="53f150cf-7c2c-47a1-96db-23bdc2c1a5fc"
            defaultMessage="Show settings"
          />
        )}
      </Link>
      {showSettings && (
        <>
          <div className={Styles.container}>
            {settings.map((setting) => (
              <div
                key={setting.name}
                className={Styles.setting}
                onClick={() => setSelectedSetting(setting)}
              >
                <p className={Styles.name}>
                  {setting.name}
                  <span className={Styles.value}>{setting.value}</span>
                </p>
              </div>
            ))}
          </div>
          {!settings.length && (
            <FormattedMessage
              id="9718bc8d-1bc9-4d1a-97b0-2daf580ecb4d"
              defaultMessage="There aren't any settings specific to this organization"
            />
          )}
        </>
      )}

      {selectedSetting && (
        <WorkflowModal
          className={Styles.details}
          closeBehavior={{ tag: "with-button", onClose: () => setSelectedSetting(null) }}
          title={selectedSetting.name}
        >
          <p className={Styles.detailsHeader}>
            <FormattedMessage id="2c39590b-4efc-4a5f-9198-d1bdb58ce696" defaultMessage="Value" />
          </p>
          {selectedSetting.value}

          <p className={Styles.detailsHeader}>
            <FormattedMessage id="94afddca-6444-461e-8f47-32a07750a05b" defaultMessage="Reason" />
          </p>
          {selectedSetting.reason || (
            <FormattedMessage
              id="724684fb-da23-47b5-8c01-7f0b2e30531a"
              defaultMessage="Not provided"
            />
          )}

          <p className={Styles.detailsHeader}>
            <FormattedMessage id="8df35a15-a12a-4e76-a365-8cd2374e398e" defaultMessage="Created" />
          </p>
          <FormattedDate value={selectedSetting.createdAt} />

          <p className={Styles.detailsHeader}>
            <FormattedMessage id="48c1b89e-14d3-4281-ba5f-8afded489b9b" defaultMessage="Updated" />
          </p>
          <FormattedDate value={selectedSetting.updatedAt} />
        </WorkflowModal>
      )}
    </>
  );
}
