import { type ReactNode, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import sanitizeHtml from "sanitize-html";
import { defer, concat, of, startWith, switchMap, debounceTime, skip } from "rxjs";

import { useMutation } from "util/graphql";
import { useSubject } from "util/rxjs/hooks";

import PreviewEmailMutation, {
  type PreviewEmail,
  type PreviewEmailVariables,
} from "./preview_email.mutation.graphql";
import type { CommunicationSettingsEditEmail_node_Organization as Organization } from "../index.query.graphql";

type PreviewData = {
  body: string;
  subject: string;
  payload: string;
};

type ErrorData = {
  bodyError?: string;
  payloadError?: ReactNode;
};

type RenderEvent = {
  loading: boolean;
  emailBody?: string;
  emailSubject?: string;
} & ErrorData;

type RenderData = {
  emailBody: string;
  emailSubject: string;
};

function cleanHtml(html: string) {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: false,
  });
}

function getStateFromMutation(
  previewData: PreviewData,
  mutateFn: ReturnType<typeof useMutation<PreviewEmail, PreviewEmailVariables>>,
  organizationId: string,
): Promise<RenderEvent> {
  let validatedJsonPayload;
  try {
    validatedJsonPayload = JSON.parse(previewData.payload) as NotarizeScalarHash | null | undefined;
  } catch {
    return Promise.resolve({
      loading: false,
      payloadError: (
        <FormattedMessage
          id="a451c85f-4f7f-4717-a2e2-8926ee308a6e"
          defaultMessage="This appears to be invalid JSON"
        />
      ),
    });
  }

  return mutateFn({
    variables: {
      input: {
        organizationId,
        emailSubject: previewData.subject,
        emailBody: previewData.body,
        payload: validatedJsonPayload,
      },
    },
  }).then(({ data }) => {
    if (!data?.previewEmail) {
      return { loading: false };
    }

    const { emailBody, emailBodyError, emailSubject, emailSubjectError } = data.previewEmail;

    if (emailBodyError || emailSubjectError) {
      return { loading: false, emailBodyError, emailSubjectError };
    }
    return {
      loading: false,
      emailBody: cleanHtml(emailBody),
      emailSubject,
    };
  });
}

export function useRenderInteraction({
  initialValues,
  organization,
}: {
  initialValues: PreviewData;
  organization: Organization;
}) {
  const [rendering, setRendering] = useState(true);
  const [errors, setErrors] = useState<ErrorData>();
  const [renderData, setRenderData] = useState<RenderData>();
  const previewEmailMutateFn = useMutation(PreviewEmailMutation);
  const organizationId = organization.id;

  const previewData$ = useSubject<PreviewData>();

  useEffect(() => {
    const sub = previewData$
      .pipe(
        skip(1),
        debounceTime(1500),
        startWith(initialValues),
        switchMap((previewData) => {
          return concat(
            of({ loading: true }),
            defer(() => getStateFromMutation(previewData, previewEmailMutateFn, organizationId)),
          );
        }),
      )
      .subscribe({
        next: (event: RenderEvent) => {
          if (event.loading) {
            setRendering(true);
            setErrors(undefined);
          } else if (event.bodyError || event.payloadError) {
            setRendering(false);
            setErrors({ bodyError: event.bodyError, payloadError: event.payloadError });
          } else {
            setRendering(false);
            setErrors(undefined);
            setRenderData({
              emailBody: event.emailBody!,
              emailSubject: event.emailSubject!,
            });
          }
        },
      });
    return () => sub.unsubscribe();
  }, [organizationId, previewData$]);

  const previewEmail = useCallback(
    (previewData: PreviewData) => {
      previewData$.next(previewData);
    },
    [previewData$],
  );

  return {
    rendering,
    errors,
    renderData,
    previewEmail,
  };
}
