import "common/form/form.scss";

import { useEffect, useState, useMemo } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import FormGroup from "common/form/group";
import MultiSelectField from "common/form/fields/multi_select";
import TextField from "common/form/fields/text";
import SelectField from "common/form/fields/select";
import FormGroupErrors from "common/form/group_errors";
import { composeValidators } from "util/form";
import { validatePresence, validateIf } from "validators/form";
import { validateEmailFormat } from "validators/account";
import { type CreateNotaryInput, Language } from "graphql_globals";
import { useLanguageOptions } from "common/language";
import { NOTARY_TIER_LABELS } from "constants/notary";
import Button from "common/core/button";
import Modal from "common/modal";

import CreateNotaryMutation from "./create_notary_mutation.graphql";

type Props = {
  organizationDropdownItems: { value: string; label: string }[];
  notaryUsStates: ({ id: string; name: string | null } | null)[];
  onClose: () => void;
  onCreate: () => void;
};
type FormValues = Pick<
  CreateNotaryInput,
  "firstName" | "lastName" | "usStateId" | "email" | "organizationId" | "tier"
> & {
  languages: { label: string; value: Language }[];
  automationId: NonNullable<CreateNotaryInput["skills"]>["automationId"];
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const messages = defineMessages({
  firstName: {
    id: "3f740f68-4bae-44ad-885f-65b0e657d8f0",
    defaultMessage: "First name",
  },
  lastName: {
    id: "807c18b5-56be-47d8-a1e6-5963ff5e5a04",
    defaultMessage: "Last name",
  },
  email: {
    id: "5dca23f2-f181-4e1f-b8dc-a435f8fd7330",
    defaultMessage: "Email address",
  },
  tier: {
    id: "b0467b52-3c12-407e-ae1f-480e1206a0a7",
    defaultMessage: "Tier",
  },
  state: {
    id: "b6d349de-093b-4d64-a910-e20c74f0ddf3",
    defaultMessage: "US state",
  },
  company: {
    id: "91315c20-36bf-46f1-a430-90f0a6e6396b",
    defaultMessage: "Company",
  },
  defaultErrorMessage: {
    id: "5c3c940d-a9d1-4a8b-a5a2-6c7657f73f1d",
    defaultMessage: "Something went wrong",
  },
});

const validate = composeValidators(
  validatePresence({ field: "firstName", label: "First Name" }),
  validatePresence({ field: "lastName", label: "Last Name" }),
  validatePresence({ field: "usStateId", label: "State" }),
  validateEmailFormat({ field: "email", label: "Email" }),
  validateIf({
    field: "email",
    condition: (email: string | null) => {
      if (!email) {
        return false;
      }
      const isInternal = email.endsWith("@notarize.com") || email.endsWith("@proof.com");
      return !isInternal;
    },
    validation: validatePresence({
      field: "organizationId",
      label: "Company (for non-Notarize notary)",
    }),
  }),
);

function AdminNewNotary({
  onClose,
  onCreate,
  handleSubmit,
  initialize,
  notaryUsStates,
  organizationDropdownItems,
}: InnerProps) {
  const createNotaryMutateFn = useMutation(CreateNotaryMutation);
  const intl = useIntl();
  const TIER_DROPDOWN_ITEMS = useMemo(
    () =>
      Object.freeze(
        NOTARY_TIER_LABELS.map(({ value, label }) => ({
          label: intl.formatMessage(label),
          value,
        })),
      ),
    [],
  );
  const languageOptions = useLanguageOptions();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, updateErrorMessage] = useState<null | string>(null);

  const handleCreateNotaryCb = ({
    firstName,
    lastName,
    usStateId,
    languages,
    email,
    organizationId,
    tier,
    automationId,
  }: FormValues) => {
    setIsSubmitting(true);
    updateErrorMessage(null);
    return createNotaryMutateFn({
      variables: {
        input: {
          firstName,
          lastName,
          usStateId,
          languages: languages.map((pair) => pair.value),
          email,
          organizationId,
          tier,
          skills: { automationId },
        },
      },
    })
      .then(() => {
        setIsSubmitting(false);
        onCreate();
      })
      .catch((error) => {
        if (isGraphQLError(error)) {
          updateErrorMessage(
            error.graphQLErrors[0]?.message ?? intl.formatMessage(messages.defaultErrorMessage),
          );
        } else {
          updateErrorMessage(intl.formatMessage(messages.defaultErrorMessage));
          captureException(error);
        }
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    initialize({
      languages: [
        {
          label: languageOptions.find((pair) => pair.value === Language.EN)!.label,
          value: Language.EN,
        },
      ],
    });
  }, []);

  return (
    <Modal
      className={"NewNotaryModal"}
      closeRoute={onClose}
      title={
        <FormattedMessage
          id="3c10de5d-842e-406f-81bf-a8778fc2fea1"
          defaultMessage="Add a New Notary"
        />
      }
    >
      <form className="NewNotaryModal-form" onSubmit={handleSubmit(handleCreateNotaryCb)}>
        <FormGroup fields={["firstName"]}>
          <TextField
            data-automation-id="new-notary-first-name"
            placeholder={intl.formatMessage(messages.firstName)}
            name="firstName"
          />
          <FormGroupErrors fields={["firstName"]} />
        </FormGroup>

        <FormGroup fields={["lastName"]}>
          <TextField
            data-automation-id="new-notary-last-name"
            placeholder={intl.formatMessage(messages.lastName)}
            name="lastName"
          />
          <FormGroupErrors fields={["lastName"]} />
        </FormGroup>

        <FormGroup fields={["email"]}>
          <TextField
            data-automation-id="new-notary-email"
            placeholder={intl.formatMessage(messages.email)}
            name="email"
          />
          <FormGroupErrors fields={["email"]} />
        </FormGroup>

        <FormGroup fields={["usStateId"]}>
          <SelectField
            data-automation-id="new-notary-us-state-id"
            placeholder={intl.formatMessage(messages.state)}
            name="usStateId"
            searchable
            items={notaryUsStates.map((state) => ({ value: state!.id, label: state!.name }))}
          />
          <FormGroupErrors fields={["usStateId"]} />
        </FormGroup>

        <FormGroup fields={["languages"]}>
          <MultiSelectField name="languages" items={languageOptions} />
        </FormGroup>

        <FormGroup fields={["organizationId"]}>
          <SelectField
            data-automation-id="notary-organization-input-field"
            placeholder={intl.formatMessage(messages.company)}
            name="organizationId"
            searchable
            items={organizationDropdownItems}
          />
          <FormGroupErrors fields={["organizationId"]} />
        </FormGroup>

        <FormGroup fields={["tier"]}>
          <SelectField
            data-automation-id="notary-tier-input-field"
            placeholder={intl.formatMessage(messages.tier)}
            name="tier"
            items={TIER_DROPDOWN_ITEMS}
          />
        </FormGroup>

        <FormGroup fields={["automationId"]}>
          <TextField
            type="hidden"
            data-automation-id="automation-requirements"
            placeholder="Test id (for development only)"
            name="automationId"
          />
        </FormGroup>
        {errorMessage && <div className="NewNotaryModal-form--Error">{errorMessage}</div>}
        <Button
          automationId="add-notary"
          type="submit"
          buttonColor="action"
          buttonSize="large"
          variant="primary"
          fullwidth
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="f8c953d8-8c59-464d-b1b3-b1ba4214b58a" defaultMessage="Add Notary" />
        </Button>
      </form>
    </Modal>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({
  form: "newNotary",
  validate,
});

export default formEnhancer(AdminNewNotary);
