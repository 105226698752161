import { memo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import { TextInput } from "common/core/form/text";
import { AutomaticFormRow } from "common/core/form/layout";
import type { FullAdminOrganizationDetails_organization_Organization_owner } from "admin_portal/company/details_query.graphql";

import Styles from "./index.module.scss";

type Props = {
  owner: FullAdminOrganizationDetails_organization_Organization_owner | null;
  active: boolean;
  onCancel: () => void;
  onSave: (formValues: ToggleActivationFormValues, active: boolean) => Promise<unknown>;
};

export type ToggleActivationFormValues = {
  suppressEmail: "yes" | "no";
  reason: string;
};

function ToggleActivationOrganizationModal({ owner, active, onCancel, onSave }: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<ToggleActivationFormValues>({
    defaultValues: {
      suppressEmail: "no",
      reason: "",
    },
  });
  const watchReasonField = form.watch("reason");

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="1b1827e1-aba1-40e2-bd4e-02e8f23634f0"
          defaultMessage="{active, select, true{Deactivate} other{Activate}} account"
          values={{ active: Boolean(active) }}
        />
      }
      buttons={[
        <Button
          key="cancel"
          variant="tertiary"
          buttonColor="dark"
          disabled={isLoading}
          onClick={onCancel}
        >
          <FormattedMessage id="eedfe13e-1513-451f-8c97-b64fed1cd443" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId={`toggle-activation-state-button-${
            active ? "deactivate" : "activate"
          }-confirm`}
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          disabled={watchReasonField === ""}
          onClick={form.handleSubmit((formValues: ToggleActivationFormValues) => {
            setIsLoading(true);
            onSave(formValues, !active).catch(() => {
              setIsLoading(false);
            });
          })}
        >
          <FormattedMessage
            id="d197fed9-b71f-4e54-80e5-acc9ab0bf018"
            defaultMessage="{active, select, true{Deactivate} other{Activate}}"
            values={{ active: Boolean(active) }}
          />
        </Button>,
      ]}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      footerSeparator={false}
    >
      <RadioGroup
        label={
          <FormattedMessage
            id="110bcda4-7bb4-4512-a282-65b1ae3fe295"
            defaultMessage="To {active, select, true{deactivate} other{activate}} the account, choose an email option."
            values={{ active: Boolean(active) }}
          />
        }
      >
        <RadioLabel
          label="Do not send email"
          radio={
            <RadioInput
              data-automation-id="suppress-email-true"
              value="yes"
              {...form.register("suppressEmail")}
            />
          }
        />
        {owner?.email && (
          <RadioLabel
            label={
              <FormattedMessage
                id="110bcda4-7bb4-4512-a282-65b1ae3fe295"
                defaultMessage='{active, select, true {"Your account has been suspended"} other {"Your plan is activated!"}} to {email}'
                values={{ active: Boolean(active), email: owner.email }}
              />
            }
            radio={
              <RadioInput
                data-automation-id="suppress-email-false"
                value="no"
                {...form.register("suppressEmail")}
              />
            }
          ></RadioLabel>
        )}
      </RadioGroup>

      <AutomaticFormRow<ToggleActivationFormValues>
        className={Styles.reasonInput}
        form={form}
        name="reason"
        registerOptions={{ required: defaultRequiredMessage(intl) }}
        as={TextInput}
        placeholder="Reason For Change"
        fullWidth
        aria-label="reason"
      />
    </WorkflowModal>
  );
}

export default memo(ToggleActivationOrganizationModal);
