import type { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

function AdminToolSection({ children, title }: Props) {
  return (
    <div className="AdminToolSection">
      <p className="title">{title}</p>
      {children}
    </div>
  );
}

export default AdminToolSection;
