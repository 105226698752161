import { Container } from "common/core/responsive";
import BillingSetup from "admin_portal/company/billing_setup";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";

import AdminBillingViewerQuery from "./viewer_query.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../organization_query.graphql";

function AdminCompanyDetailsPricingAndBillingBilling(props: { organization: Organization }) {
  const { hasPermissionFor } = usePermissions();
  const { data, loading } = useQuery(AdminBillingViewerQuery);
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <Container fluid style={{ width: "100%" }}>
      <BillingSetup
        organization={props.organization}
        viewer={data!.viewer}
        formDisabled={!hasPermissionFor("editCompanyDetailsBilling")}
      />
    </Container>
  );
}

export default AdminCompanyDetailsPricingAndBillingBilling;
