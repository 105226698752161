import { memo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Portal } from "util/html";
import Overlay from "common/modals/overlay";
import { PDFViewerWithControls } from "common/pdf/pspdfkit/viewer";
import ClickOutside from "common/core/click_outside";
import backArrow from "assets/images/back-arrow.png";

import Styles from "./document_view_modal.module.scss";

type Props = {
  fileName: string;
  url: string;
  onClose: () => void;
};

const MESSAGES = defineMessages({
  modalLabel: { id: "cee1fc2f-e9e0-4f23-9bfa-6764f3dfd926", defaultMessage: "Notary Documents" },
});

function NotaryDocumentViewModal({ fileName, url, onClose }: Props) {
  const intl = useIntl();
  return (
    <Portal>
      <Overlay>
        <ClickOutside onClickOutside={onClose}>
          <div
            role="dialog"
            aria-label={intl.formatMessage(MESSAGES.modalLabel)}
            className={Styles.documentModal}
          >
            <div className={Styles.backButton} onClick={onClose}>
              <img alt="back-arrow" src={backArrow} />
              <FormattedMessage
                id="c7adece2-7bd5-4a7b-ac63-298b52a208b7"
                defaultMessage="Exit Document View"
              />
            </div>
            {fileName.endsWith(".pdf") ? (
              <PDFViewerWithControls url={url} />
            ) : (
              <img src={url} className={Styles.imgDocument} alt="document" />
            )}
          </div>
        </ClickOutside>
      </Overlay>
    </Portal>
  );
}

export default memo(NotaryDocumentViewModal);
