import Button, { type ButtonPropsOmit } from "common/core/button";
import { useAwaitPromise } from "common/core/await_promise";

type Props = ButtonPropsOmit<"onClick"> & { onClick: () => Promise<unknown> };

/**
 * PromiseButton is an asynchronous button that takes in a function onClick that MUST return a promise. It then waits
 * until the promise is either fulfilled or rejected. While that is happening, the underlying Button implementation will
 * be disabled and loading. Once the promise is fulfilled or rejected, it is propagated back up the promise chain
 */
function PromiseButton(props: Props) {
  const { waiting, startWaiting } = useAwaitPromise();
  return (
    <Button
      {...props}
      onClick={() => startWaiting(props.onClick())}
      isLoading={props.isLoading || waiting}
    />
  );
}

export default PromiseButton;
