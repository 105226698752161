import { useEffect, useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import RadioButtonField from "common/form/fields/radio";
import Button from "common/core/button";
import { AuthTypes, OrganizationTypeEnum } from "graphql_globals";
import { Card, CardText } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";

import UpdateDefaultTransactionAuthenticationRequirementMutation from "./update_default_transaction_authentication_requirement_mutation.graphql";
import DefaultAuthenticationRequirementQuery, {
  type DefaultAuthenticationRequirement_organization_Organization as Organization,
} from "./default_authentication_requirement_query.graphql";
import Styles from "./index.module.scss";

type Props = {
  organizationId: string;
};
type FormValues = {
  defaultAuthRequirement: AuthTypes;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps;

const MESSAGES = defineMessages({
  none: { id: "65f95d39-0379-4a24-9390-046500bbcbb8", defaultMessage: "None" },
  noneDescription: {
    id: "ada8a1d3-2514-4732-9dbf-def2f3da37e7",
    defaultMessage: "The signer can create their account with only email and password",
  },
  sms: { id: "efc856ce-198e-45d8-bdff-d731c7071812", defaultMessage: "Require SMS authentication" },
  smsDescription: {
    id: "b13cc5cf-fc17-463b-bc48-5960e5060dae",
    defaultMessage:
      "After creating their account, the signer will receive a code by SMS to verify their identity",
  },
  zip: {
    id: "124e9424-f5d3-4f7e-8036-5b1f8a982522",
    defaultMessage: "Require ZIP Code authentication",
  },
  zipDescription: {
    id: "31005177-5698-417f-9612-eb4916cc4e15",
    defaultMessage:
      "The signer will need to verify their identity by inputting the property's ZIP Code",
  },
  save: { id: "fd29f1e2-3dc6-48ff-a544-3e64dfbdd8b2", defaultMessage: "Save" },
  success: {
    id: "e55104c1-a88d-4986-9708-0620ee460e8f",
    defaultMessage: "Successfully updated authentication requirements",
  },
});

function DefaultTransactionAuthenticationRequirementForm({
  handleSubmit,
  initialize,
  organizationId,
}: InnerProps) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, loading } = useQuery(DefaultAuthenticationRequirementQuery, {
    variables: { organizationId },
  });

  const updateDefaultTransactionAuthenticationRequirement = useMutation(
    UpdateDefaultTransactionAuthenticationRequirementMutation,
  );
  const onSubmit = ({ defaultAuthRequirement }: FormValues) => {
    setIsSubmitting(true);
    updateDefaultTransactionAuthenticationRequirement({
      variables: {
        input: {
          organizationId,
          defaultAuthenticationRequirement: defaultAuthRequirement,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const organization = data?.organization as Organization | undefined;
  useEffect(() => {
    if (organization) {
      initialize({
        defaultAuthRequirement: organization.defaultAuthenticationRequirement,
      });
    }
  }, [Boolean(organization)]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="669bca27-ec6f-4367-bac6-a7569dc557fc"
          defaultMessage="Signer Authentication Requirement"
        />
      </SettingsTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          footer={
            <Button
              automationId="save-default-auth-requirements"
              buttonColor="action"
              variant="primary"
              type="submit"
              disabled={loading}
              isLoading={isSubmitting}
            >
              {intl.formatMessage(MESSAGES.save)}
            </Button>
          }
        >
          <CardText>
            <FormattedMessage
              id="fdf5a00a-9ad6-4986-958e-674324a63550"
              description="defaultTransactionAuthRequirementDescription"
              defaultMessage="For an extra level of security, you can require multi-factor authentication for all transactions"
            />
          </CardText>
          <div>
            <RadioButtonField
              name="defaultAuthRequirement"
              className={Styles.defaultAuthRadioButton}
              automationId="default-auth-type-none"
              radioValue={AuthTypes.NONE}
              size="small"
              labelText={intl.formatMessage(MESSAGES.none)}
              infoText={intl.formatMessage(MESSAGES.noneDescription)}
            />
            <RadioButtonField
              name="defaultAuthRequirement"
              className={Styles.defaultAuthRadioButton}
              automationId="default-auth-type-sms"
              radioValue={AuthTypes.SMS}
              size="small"
              labelText={intl.formatMessage(MESSAGES.sms)}
              infoText={intl.formatMessage(MESSAGES.smsDescription)}
            />
            {organization?.organizationType !== OrganizationTypeEnum.BUSINESS && (
              <RadioButtonField
                name="defaultAuthRequirement"
                className={Styles.defaultAuthRadioButton}
                automationId="default-auth-type-zip"
                radioValue={AuthTypes.ZIP}
                size="small"
                labelText={intl.formatMessage(MESSAGES.zip)}
                infoText={intl.formatMessage(MESSAGES.zipDescription)}
              />
            )}
          </div>
        </Card>
      </form>
    </>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({ form: "DefaultAuthSettings" });
export default formEnhancer(DefaultTransactionAuthenticationRequirementForm);
