import { type ReactNode, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import { NetworkStatus, useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import TransactionResendModal from "common/transactions/actions/resend";
import { ResendTransactionButton } from "common/transactions/details/resend_transaction_button";
import { getParentPathByOffset, newPathWithPreservedSearchParams } from "util/location";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import {
  MortgageTransactionType,
  OrganizationTransactionVariant,
  OrgTransactionStates,
} from "graphql_globals";
import { TransactionDetailsTabs } from "common/transactions/details/tabs";
import { TransactionDetailsWrapper } from "common/transactions/details/wrapper";
import { useTxnDetailsRedesign } from "util/feature_detection";
import Button from "common/core/button";

import AdminTransactionDetailsQuery, {
  type AdminTransactionDetails_node_OrganizationTransaction as OrganizationTransaction,
  type AdminTransactionDetails_node_OrganizationTransaction_bundle as DocumentBundle,
  type AdminTransactionDetails_viewer as Viewer,
} from "./index_query.graphql";
import Styles from "./index.module.scss";

type TransactionDetailsOrganization = {
  id: string;
  proofDefend: boolean;
};

type ChildRenderProps = {
  transaction: OrganizationTransaction;
  bundle: DocumentBundle;
  viewer: Viewer;
  organization: TransactionDetailsOrganization;
  viewable: boolean;
  finalizedDocumentOnly: boolean;
  showNotarialActs: boolean;
  refetch: () => Promise<unknown>;
};
type Props = {
  children: (renderProps: ChildRenderProps) => ReactNode;
};
type LoadedProps = Props & {
  node: ChildRenderProps["transaction"];
  viewer: ChildRenderProps["viewer"];
  organization: ChildRenderProps["organization"];
  refetch: ChildRenderProps["refetch"];
};

const MESSAGES = defineMessages({
  tooltipTriggerLabel: {
    id: "77c69238-64b4-4bf3-966e-baefc90ce2ee",
    defaultMessage: "More details about document visibility",
  },
});
const INCLUDE_RESEND_TRANSACTION_STATES = Object.freeze(
  new Set<null | OrgTransactionStates>([
    OrgTransactionStates.SENT,
    OrgTransactionStates.RECEIVED,
    OrgTransactionStates.ATTEMPTED,
  ]),
);

function transactionIncludesResendTransaction(transaction: OrganizationTransaction): boolean {
  return (
    transaction.transactionType !== MortgageTransactionType.wet_sign &&
    transaction.transactionVariant !== OrganizationTransactionVariant.VERIFY &&
    !transaction.publicOrganization.suppressSignerEmails &&
    INCLUDE_RESEND_TRANSACTION_STATES.has(transaction.state)
  );
}

function AdminTransactionDetailsModalLoaded(props: LoadedProps) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign("keystone");
  const { node: transaction, organization, refetch } = props;
  const bundle = transaction.bundle!;
  const [isResendOpen, setIsResendOpen] = useState(false);
  const intl = useIntl();
  const { pathname } = useLocation();
  const includeResendTransaction = transactionIncludesResendTransaction(transaction);
  const createdAsWetSign = transaction.transactionType === MortgageTransactionType.wet_sign;
  const isVerifyTransaction =
    transaction.transactionVariant === OrganizationTransactionVariant.VERIFY;
  const closeRoute = newPathWithPreservedSearchParams(getParentPathByOffset(pathname, 2));

  return (
    <TransactionDetailsWrapper
      closeRoute={closeRoute}
      className={isTxnDetailsRedesign ? undefined : Styles.deprecatedContainer}
      transaction={transaction}
      viewerOrganization={null}
      refetch={refetch}
      transactionDetailsTabs={
        <TransactionDetailsTabs
          bundle={bundle}
          transaction={transaction}
          showFullRecord
          withNotes={!onlyRequiresEsign(bundle.completionRequirements)}
          withActivityLog
          withDocuments
        />
      }
      transactionDetailsActions={
        <Button
          variant="secondary"
          buttonColor="action"
          onClick={() => setIsResendOpen(true)}
          disabled={!includeResendTransaction}
          disabledHint={
            !includeResendTransaction && createdAsWetSign ? (
              <FormattedMessage
                id="86956d51-f1e8-4306-b04c-fcab698719ff"
                defaultMessage="This is a wet sign transaction and no communications are sent to the signer."
              />
            ) : isVerifyTransaction ? (
              <FormattedMessage
                id="95f8a951-ea8f-432e-9836-43ca508b459a"
                defaultMessage="This is a verify transaction and no communications are sent to the signer."
              />
            ) : (
              <FormattedMessage
                id="8a7fe8df-6139-4f2e-80ed-688ef73df535"
                defaultMessage="The organization either has suppressed signer emails, or the transaction has not yet been sent to signer."
              />
            )
          }
          disabledHintPlacement="bottom"
        >
          <FormattedMessage
            id="1e0f78b5-60d6-4486-9d70-55a72b005c3e"
            defaultMessage="Resend transaction"
          />
        </Button>
      }
    >
      {!isTxnDetailsRedesign && (
        <ResendTransactionButton
          disabled={!includeResendTransaction}
          onClick={() => setIsResendOpen(true)}
          tooltip={
            !includeResendTransaction && (
              <Tooltip
                target={<Icon className={Styles.sendTransactionTooltip} name="question" />}
                placement="bottom"
                triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
              >
                {createdAsWetSign ? (
                  <FormattedMessage
                    id="86956d51-f1e8-4306-b04c-fcab698719ff"
                    defaultMessage="This is a wet sign transaction and no communications are sent to the signer."
                  />
                ) : isVerifyTransaction ? (
                  <FormattedMessage
                    id="95f8a951-ea8f-432e-9836-43ca508b459a"
                    defaultMessage="This is a verify transaction and no communications are sent to the signer."
                  />
                ) : (
                  <FormattedMessage
                    id="8a7fe8df-6139-4f2e-80ed-688ef73df535"
                    defaultMessage="The organization either has suppressed signer emails, or the transaction has not yet been sent to signer."
                  />
                )}
              </Tooltip>
            )
          }
        />
      )}
      {props.children({
        viewer: props.viewer,
        bundle,
        organization,
        transaction,
        viewable: true,
        finalizedDocumentOnly: false,
        showNotarialActs: true,
        refetch,
      })}
      {includeResendTransaction && (
        <TransactionResendModal
          transaction={transaction}
          isOpen={isResendOpen}
          onClose={() => setIsResendOpen(false)}
          ignoreOrganizationFeatures
          refetch={refetch}
        />
      )}
    </TransactionDetailsWrapper>
  );
}

function AdminTransactionDetailsModal(props: Props) {
  const { transactionID } = useParams();
  const { networkStatus, data, refetch } = useQuery(AdminTransactionDetailsQuery, {
    variables: { transactionId: transactionID! },
  });

  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }

  const { node, viewer } = data!;
  if (node?.__typename !== "OrganizationTransaction") {
    throw new Error(`Expected OrganizationTransaction, got ${node?.__typename}`);
  }

  // For now we're going to pretend that the org has proofDefend. Ideally we'd
  // fetch this, but we don't have an activeOrganization here, and we can't
  // fetch proofDefend from the transaction's publicOrganization yet. Since this
  // is keystone, it should be ok to just assume admins can see proofDefend
  // stuff.
  const fakeOrganization: TransactionDetailsOrganization = {
    id: "fake-org",
    proofDefend: true,
  };

  return (
    <AdminTransactionDetailsModalLoaded
      node={node}
      viewer={viewer}
      organization={fakeOrganization}
      refetch={refetch}
    >
      {props.children}
    </AdminTransactionDetailsModalLoaded>
  );
}

export default AdminTransactionDetailsModal;
