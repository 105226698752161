import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useLazyQuery } from "util/graphql/query";
import Button from "common/core/button";
import { SearchField } from "common/core/search_field";

import DecodeGidQuery from "./index_query.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  placeholder: {
    id: "d3f77b70-d3f0-4f8f-b184-00d5ec70be14",
    defaultMessage: "Enter a GID",
  },
  searchLabel: {
    id: "cb275797-3efc-4c10-8862-aafb8a259658",
    defaultMessage: "Search for a GID",
  },
});

function GidDecoderContainer() {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState("");
  const [decodeGid, { data, loading }] = useLazyQuery(DecodeGidQuery);
  const doSearch = () => decodeGid({ variables: { gid: searchQuery } });
  return (
    <div className={Styles.decoder}>
      <SearchField
        className={Styles.search}
        onSearch={doSearch}
        onChange={(q) => setSearchQuery(q.value)}
        value={searchQuery}
        placeholder={intl.formatMessage(MESSAGES.placeholder)}
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
        searchOnClear={false}
        noIcon
      />
      <Button
        buttonColor="action"
        variant="primary"
        onClick={doSearch}
        disabled={!searchQuery}
        isLoading={loading}
        automationId="GidDecoder--submit"
      >
        <FormattedMessage id="bc7d53a8-b379-4312-8dbc-e582ee945400" defaultMessage="Decode" />
      </Button>
      <div className={Styles.output}>{!loading && data?.viewer.decodedGid}</div>
    </div>
  );
}

export default GidDecoderContainer;
