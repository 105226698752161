import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { type ComponentProps, useCallback, useState } from "react";

import { OrganizationAccountProvisionStatuses } from "graphql_globals";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { Card, CardHeading } from "common/core/card";
import { usePermissions } from "common/core/current_user_role";
import { SettingsTitle } from "common/settingsv2/common";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { FormattedDate } from "common/core/format/date";
import { LegalTeamEmailEdit } from "admin_portal/common/legal_team_email_edit";
import { EmailVerificationBanner } from "common/banners/email_verification_banner";
import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "admin_portal/company/details_query.graphql";
import { Badge } from "common/core/badge";
import Tooltip from "common/core/tooltip";

import Address from "./address";
import { OwnerEmailAddress } from "./owner_email_address";
import PushOrganizationToSalesforceMutation from "./push_organization_to_salesforce_mutation.graphql";
import ToggleActivationOrganizationMutation from "./toggle_activation_organization_mutation.graphql";
import ToggleActivationOrganizationModal from "./toggle_activation_organization_modal";
import Styles from "./index.module.scss";

type Props = {
  refetch: () => Promise<unknown>;
  organization: Organization;
};
type StatusState =
  | { state: null }
  | { state: "loading" }
  | { state: "error"; message: string | null };

const INIT_STATE: StatusState = { state: null };
const MESSAGES = defineMessages({
  success: {
    id: "69396e3f-7141-4d82-bfde-43e88c1ba901",
    defaultMessage: "Successfully pushed to Salesforce",
  },
});

export function OrganizationOverview(props: Props) {
  const { refetch } = props;
  const {
    id,
    rootOrganizationId,
    createdAt,
    name,
    active,
    address,
    provisionStatus,
    owner,
    accountCreation,
  } = props.organization;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const canProvisionOrg = hasPermissionFor("provisionOrganization");
  const canUpdateCompany = hasPermissionFor("editCompanyDetails");
  const hasLegalEditPermission = hasPermissionFor("privacyEmailEdit");
  const pushOrganizationToSalesforceMutateFn = useMutation(PushOrganizationToSalesforceMutation);
  const toggleActivationOrganizationMutateFn = useMutation(ToggleActivationOrganizationMutation);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [status, setStatus] = useState(INIT_STATE);

  const onActiveChange = useCallback<
    ComponentProps<typeof ToggleActivationOrganizationModal>["onSave"]
  >(
    (formValues, orgState) => {
      setStatus({ state: "loading" });
      return toggleActivationOrganizationMutateFn({
        variables: {
          input: {
            id,
            active: orgState,
            suppressEmail: formValues.suppressEmail === "yes",
            reason: formValues.reason,
          },
        },
      })
        .then(() => {
          setStatus(INIT_STATE);
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
            message: (
              <FormattedMessage
                id="3b32cec0-c128-4890-b50f-5a094b9617eb"
                defaultMessage="Account is {active, select, true{deactivated} other{activated}}."
                values={{ active: Boolean(active) }}
              />
            ),
            position: "topCenter",
          });
          setShowEmailModal(false);
        })
        .catch(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: (
              <FormattedMessage
                id="988dea69-e3c1-42aa-ab05-fcc3b6a9ae2f"
                defaultMessage="We're sorry, something went wrong. Please try again."
              />
            ),
            position: "topCenter",
          });
          setStatus(INIT_STATE);
          setShowEmailModal(false);
        });
    },
    [props.organization],
  );

  function pushToSalesforce() {
    setStatus({ state: "loading" });
    pushOrganizationToSalesforceMutateFn({
      variables: {
        input: { organizationId: id },
      },
    })
      .then(() => {
        setStatus(INIT_STATE);
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
      })
      .catch((error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
        setStatus({ state: "error", message: err.description || err.message });
      });
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="19deeb9b-18f2-4f56-bce9-f717da026c3f" defaultMessage="Overview" />
      </SettingsTitle>
      <Card className={Styles.orgDetailsCard}>
        {provisionStatus && provisionStatus !== OrganizationAccountProvisionStatuses.COMPLETE && (
          <EmailVerificationBanner
            canProvisionOrg={canProvisionOrg}
            provisionStatus={provisionStatus}
            organizationId={id}
            refetch={refetch}
          />
        )}

        <CardHeading>
          <FormattedMessage
            id="594fc2da-b652-4387-b5a6-bfb231dbbada"
            defaultMessage="Organization Name"
          />
          {id === rootOrganizationId && (
            <Tooltip
              target={
                <Badge className={Styles.rootOrgBadge} kind="infoSubtle">
                  <FormattedMessage
                    id="a8beebd6-26b8-4b42-84dd-2c4c9401b397"
                    defaultMessage="Root organization"
                  />
                </Badge>
              }
              placement="bottomLeft"
            >
              <FormattedMessage
                id="d3593006-8ba4-417c-a14e-6d4c714e11c8"
                defaultMessage="This org is a Root Org, company-level details can be found here"
              />
            </Tooltip>
          )}
        </CardHeading>

        <span>
          {name || (
            <FormattedMessage
              id="e553d553-3156-41e5-b604-99fc19e7be5d"
              defaultMessage="No name on file"
            />
          )}
        </span>

        <CardHeading>
          <FormattedMessage id="30a23a6e-c1ae-4bb4-9979-0fc4be5d9ebd" defaultMessage="Address" />
        </CardHeading>
        <Address address={address} />

        <CardHeading>
          <FormattedMessage id="07542d6b-66cd-482a-8b5d-2522de6da5f8" defaultMessage="Account ID" />
        </CardHeading>
        <span data-automation-id="Account ID">{id}</span>

        <CardHeading>
          <FormattedMessage
            id="0a251377-4e04-4ccc-9e99-03e5e94be4cc"
            defaultMessage="Email Address"
          />
        </CardHeading>
        {owner &&
          (hasLegalEditPermission ? (
            <LegalTeamEmailEdit
              userEmail={owner.pendingEmail!}
              userId={owner.id}
              refetch={refetch}
            />
          ) : (
            <OwnerEmailAddress
              ownerEmail={owner.email!}
              orgId={id}
              refetch={refetch}
              provisionStatus={provisionStatus}
              canProvisionOrg={canProvisionOrg}
            />
          ))}

        <CardHeading>
          <FormattedMessage id="fcc00f40-bb50-4b52-b683-77aeba110d05" defaultMessage="Status" />
        </CardHeading>
        <span>{active ? "Active" : "Inactive"}</span>

        <CardHeading>
          <FormattedMessage
            id="d4136df9-ab50-47d6-9efa-89152a7737b4"
            defaultMessage="Account Created"
          />
        </CardHeading>
        <span>
          <FormattedDate value={createdAt} />
        </span>

        <CardHeading>
          <FormattedMessage
            id="6e955e0c-18e6-4ed5-b198-755851fe2a80"
            defaultMessage="Account Creation Method"
          />
        </CardHeading>
        <span>{accountCreation || "UNKNOWN"}</span>

        <CardHeading>
          <FormattedMessage id="a31fcbf1-45c9-4acf-bb23-679d147a3676" defaultMessage="Salesforce" />
        </CardHeading>
        <Button
          onClick={pushToSalesforce}
          isLoading={status.state === "loading"}
          buttonColor="action"
          variant="secondary"
          disabled={!canUpdateCompany}
        >
          <FormattedMessage
            id="1b07f0c9-7c2a-4a02-abdd-564cc32b221b"
            defaultMessage="Sync to Salesforce"
          />
        </Button>

        <CardHeading>
          <FormattedMessage
            id="a91e0006-f2e0-49c6-811c-2da1c1b6788f"
            defaultMessage="Activate / Deactivate"
          />
        </CardHeading>
        {canUpdateCompany ? (
          <Button
            buttonColor="action"
            variant="primary"
            isLoading={status.state === "loading"}
            onClick={() => {
              setShowEmailModal(true);
            }}
            automationId={`toggle-activation-state-button-${active ? "deactivate" : "activate"}`}
          >
            <FormattedMessage
              id="746d59eb-6c28-4fe5-be84-4b2d4df2b1f8"
              defaultMessage="{active, select, true{Deactivate} other{Activate}}"
              values={{ active: Boolean(active) }}
            />
          </Button>
        ) : null}
        {showEmailModal && (
          <ToggleActivationOrganizationModal
            owner={owner}
            active={active}
            onCancel={() => {
              setShowEmailModal(false);
            }}
            onSave={onActiveChange}
          />
        )}
        {status.state === "error" && (
          <MutationErrorModal message={status.message} onClick={() => setStatus(INIT_STATE)} />
        )}
      </Card>
    </>
  );
}
