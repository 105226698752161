import TableRow from "common/table/row";
import Icon from "common/core/icon";
import Link from "common/core/link";
import { userFullName } from "util/user";
import { newPathWithPreservedSearchParams } from "util/location";

type User = { id: string; email: null | string } & Parameters<typeof userFullName>[0];
type Props = {
  admin: User;
  /** If falsy, don't show action */
  hasDetailsLink: boolean;
};

function AdminTableRow({ admin, hasDetailsLink }: Props) {
  return (
    <TableRow
      cells={[
        {
          header: true,
          className: "ellipsis",
          children: userFullName(admin, "[No name given]"),
        },
        {
          className: "ellipsis",
          children: admin.email,
        },
        {
          className: "link-append",
          children: hasDetailsLink && (
            <Link
              to={newPathWithPreservedSearchParams(`/admin/records/admin/${admin.id}`)}
              automationId="admin-admins-details"
            >
              Details <Icon className="icon" name="caret-right" />
            </Link>
          ),
        },
      ]}
    />
  );
}

export default AdminTableRow;
