import { defineMessages, useIntl } from "react-intl";

import { COLOR } from "constants/color";
import { EncryptionKeyStatusesEnum } from "graphql_globals";

const Formated_key_status = defineMessages({
  [EncryptionKeyStatusesEnum.STAGED]: {
    id: "6b161e89-787d-4d87-8a8f-5958e556baf0",
    defaultMessage: "Staged",
  },
  [EncryptionKeyStatusesEnum.ACTIVE]: {
    id: "023a3327-5e36-4a15-94c0-f702454f2056",
    defaultMessage: "Active",
  },
  [EncryptionKeyStatusesEnum.ARCHIVED]: {
    id: "be4f0de9-1ba9-46f6-a593-c00e1ff99457",
    defaultMessage: "Archived",
  },
  [EncryptionKeyStatusesEnum.PENDING_DELETION]: {
    id: "f8f00ca2-41f4-4833-bfd8-c208e3f0a4a7",
    defaultMessage: "Pending Deletion",
  },
});

const STATUS_COLORS = Object.freeze({
  [EncryptionKeyStatusesEnum.STAGED]: "grey",
  [EncryptionKeyStatusesEnum.ACTIVE]: COLOR.SUCCESS,
  [EncryptionKeyStatusesEnum.ARCHIVED]: COLOR.ORANGE,
  [EncryptionKeyStatusesEnum.PENDING_DELETION]: COLOR.DANGER,
});

function FormattedKeyStatus({ status }: { status: EncryptionKeyStatusesEnum }) {
  const intl = useIntl();

  return (
    <span style={{ color: STATUS_COLORS[status] }}>
      {intl.formatMessage(Formated_key_status[status])}
    </span>
  );
}

export default FormattedKeyStatus;
