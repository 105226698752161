import {
  useCallback,
  useState,
  type ComponentProps,
  type ReactNode,
  type ReactElement,
} from "react";
import { FormattedMessage } from "react-intl";

import { AuthTypes, Channel } from "graphql_globals";
import MaskedField from "common/form/fields/masked";
import ActionButton from "common/core/action_button";
import { useMutation } from "util/graphql";
import { withMinDelay } from "util/rxjs";
import { Paragraph } from "common/core/typography";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import InitiateChannelAuthenticationMutation from "./initiate_channel_authentication_mutation.graphql";
import { AuthGateForm, AuthGateAlertBanner, useAuthGateForm, type AuthGateAction } from "..";
import Styles from "./index.module.scss";

type VerifyBannerProps = {
  error: ReturnType<typeof useAuthGateForm>["error"];
  hint: string;
  sendText: () => Promise<unknown>;
};

function VerifyBanner({ error, hint, sendText }: VerifyBannerProps) {
  const [loading, setLoading] = useState(false);

  const resend = loading ? (
    <FormattedMessage
      tagName="p"
      id="5bd5b398-d5d3-4676-a77a-a9f08d5aaf47"
      defaultMessage="Sending..."
    />
  ) : (
    <ActionButton
      className={Styles.resendButton}
      onClick={() => {
        setLoading(true);
        withMinDelay(sendText())
          .subscribe()
          .add(() => setLoading(false));
      }}
    >
      <FormattedMessage
        tagName="p"
        id="92a0302b-ad4a-474c-98ca-a6d7044d219a"
        defaultMessage="Send a new code"
      />
    </ActionButton>
  );

  if (!error) {
    return (
      <AuthGateAlertBanner kind="success">
        <Paragraph>
          <FormattedMessage
            id="34784d6b-b4af-476b-999d-7f1c5755655d"
            defaultMessage="We’ve sent an email to <sensitive>{hint}</sensitive>."
            values={{ hint, sensitive: (text) => <b className={SENSITIVE_CLASS}>{text}</b> }}
          />
          {resend}
        </Paragraph>
      </AuthGateAlertBanner>
    );
  }

  if (error.type === "invalid") {
    return (
      <AuthGateAlertBanner kind="danger">
        <Paragraph>
          <FormattedMessage
            id="3cc26b9e-51af-4c93-81e3-1dd8575bf41f"
            defaultMessage="Incorrect code."
          />
          {resend}
        </Paragraph>
      </AuthGateAlertBanner>
    );
  }

  return (
    <AuthGateAlertBanner kind="danger">
      <p>
        {error.message}
        {resend}
      </p>
    </AuthGateAlertBanner>
  );
}

type EmailAuthGateProps = {
  subjectId: string;
  hint: string;
  onSuccess?: ComponentProps<typeof AuthGateForm>["onSuccess"];
  children: (args: {
    content: ReactNode;
    action: AuthGateAction<"initiate" | "submit">;
  }) => ReactElement;
  password?: string;
};

function EmailAuthGate({ subjectId, hint, onSuccess, children, password }: EmailAuthGateProps) {
  const [step, setStep] = useState<"info" | "verify">("info");
  const { error, invalid, submitting, submit } = useAuthGateForm();
  const initiateChannelAuthenticationMutateFn = useMutation(InitiateChannelAuthenticationMutation);

  const sendText = useCallback(
    () =>
      initiateChannelAuthenticationMutateFn({
        variables: {
          input: {
            channel: Channel.EMAIL,
            subjectId,
          },
        },
      }),
    [subjectId],
  );

  const content = (
    <>
      {step === "info" && (
        <p>
          <FormattedMessage
            id="88900673-2b6f-4891-9832-bdbc6cad4495"
            defaultMessage="Please first verify your email <sensitive>({hint})</sensitive>"
            values={{ hint, sensitive: (text) => <b className={SENSITIVE_CLASS}>{text}</b> }}
          />
        </p>
      )}
      {step === "verify" && (
        <>
          <VerifyBanner error={error} hint={hint} sendText={sendText} />
          <AuthGateForm
            authType={AuthTypes.EMAIL}
            subjectId={subjectId}
            onSuccess={onSuccess}
            password={password}
            renderField={(formProps) => (
              <MaskedField
                {...formProps}
                label={
                  <FormattedMessage
                    id="4917932e-da83-4893-9429-a9f3fda589a2"
                    defaultMessage="Enter 6-digit verification code"
                  />
                }
                maskType="number"
                maxlength={6}
                placeholder="000000"
                useStyledInput
              />
            )}
          />
        </>
      )}
    </>
  );

  const action =
    step === "info"
      ? {
          type: "initiate" as const,
          buttonProps: {
            onClick: () => {
              sendText();
              setStep("verify");
            },
            disabled: false,
            isLoading: false,
          },
        }
      : {
          type: "submit" as const,
          buttonProps: {
            onClick: () => {
              submit();
            },
            disabled: invalid,
            isLoading: submitting,
          },
        };

  return children({ content, action });
}

export { EmailAuthGate };
