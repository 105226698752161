import { StandardTable } from "common/table";
import { usePermissions } from "common/core/current_user_role";

import AdminTableRow from "./row";
import type { AdminListDashboard_viewer_admins as AdminUserConnection } from "../index_query.graphql";

type Props = {
  connection: AdminUserConnection;
};

function AdminTable({ connection }: Props) {
  const { hasPermissionFor } = usePermissions();
  const hasDetailsLink = hasPermissionFor("adminDetails");
  return (
    <StandardTable className="AdminTable" headings={["Name", "Email Address", ""]}>
      {connection.edges.map(({ node: admin }) => (
        <AdminTableRow key={admin.id} admin={admin} hasDetailsLink={hasDetailsLink} />
      ))}
    </StandardTable>
  );
}

export default AdminTable;
