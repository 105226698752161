import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { AddressInput, type Address, validateAddressIsUS } from "common/core/form/address";
import { useForm } from "common/core/form";
import { TextInput } from "common/core/form/text";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { useLazyQuery } from "util/graphql/query";
import FormRow from "common/form/elements/row";
import { isAriaInvalid, FormattedFieldError, defaultRequiredMessage } from "common/core/form/error";

import RecordingLocationEligibilityQuery, {
  type RecordingLocationEligibility,
} from "./index_query.graphql";
import RecordingLocationQuery from "./recording_location_query.graphql";
import Styles from "./index.module.scss";

type AddressFormValues = {
  address: Address;
  recordingLocationId: string;
  lenderId: string;
  titleAgencyId: string;
  underwriterId: string;
};

type FormValues = {
  recordingLocationId: string;
  lenderId: string;
  titleAgencyId: string;
  underwriterId: string;
};

type EligibilityResultsProps = {
  eligibilityData: RecordingLocationEligibility;
  includeLenderCheck: boolean;
};

const MESSAGES = defineMessages({
  recordingLocationPlaceholder: {
    id: "21799c14-c8fc-446e-bf8c-33cd772bc9e2",
    defaultMessage: "recording location id",
  },
  lenderPlaceholder: { id: "83229c94-1c23-4312-b058-e7d0bd3b11d2", defaultMessage: "lender id" },
  titleAgencyPlaceholder: {
    id: "e5660cc6-29b3-489e-be80-8cc6102e305e",
    defaultMessage: "title agency id",
  },
  titleUnderwriterPlaceholder: {
    id: "f6857cd8-a464-4944-8e20-3cf47cb677f2",
    defaultMessage: "title underwriter id",
  },
});

function RecordingLocationSearchTool() {
  const intl = useIntl();
  const addressForm = useForm<AddressFormValues>();
  const form = useForm<FormValues>();
  const { formState } = form;
  const lenderId = form.watch("lenderId");
  const [getEligibilityData, { data: eligibilityData, loading: eligibilityLoading }] = useLazyQuery(
    RecordingLocationEligibilityQuery,
  );
  const [
    getRecordingLocationData,
    { data: recordingLocationData, loading: recordingLocationLoading },
  ] = useLazyQuery(RecordingLocationQuery);

  const submit = ({ recordingLocationId, titleAgencyId, underwriterId }: FormValues) => {
    getEligibilityData({
      variables: {
        lenderId,
        recordingLocationId,
        titleAgencyId,
        underwriterId,
        includeLender: Boolean(lenderId),
      },
    });
  };

  return (
    <div className={Styles.recordingLocationSearchTool}>
      <h2 className={Styles.header}>
        <FormattedMessage
          id="3454accc-cae5-4b77-8fb9-09f0b13b46d3"
          defaultMessage="Recording location lookup"
        />
      </h2>
      <p>
        <FormattedMessage
          id="65b9269a-50f6-40be-a0c4-93a53b165fba"
          defaultMessage="Provides recording information for a given address"
        />
      </p>
      <div className={Styles.recordingLocationSection}>
        <div>
          <AddressInput<"address", AddressFormValues>
            form={addressForm}
            name="address"
            showAddressLookup
            countryValidation={validateAddressIsUS}
            required
          />
          <Button
            onClick={() => {
              const { address } = addressForm.getValues();
              getRecordingLocationData({ variables: { address } });
            }}
            isLoading={recordingLocationLoading}
            buttonColor="action"
            variant="secondary"
            automationId="recording-location-lookup"
          >
            <FormattedMessage
              id="6967ee86-beeb-4efa-ae12-16477d15be4a"
              defaultMessage="Find recording location(s)"
            />
          </Button>
        </div>
        <div className={Styles.resultsSection}>
          {recordingLocationData?.viewer.recordingLocationEligibilities && (
            <>
              <h3>
                <FormattedMessage
                  id="60b2f8d4-49b2-4135-acce-0e8d03a521eb"
                  defaultMessage="Recording location results ({count}):"
                  values={{
                    count: recordingLocationData.viewer.recordingLocationEligibilities.length,
                  }}
                />
              </h3>
              {recordingLocationData.viewer.recordingLocationEligibilities.length > 0 && (
                <div className={Styles.results}>
                  {recordingLocationData.viewer.recordingLocationEligibilities.map(
                    (recordingLocation) => {
                      return (
                        <div
                          key={recordingLocation.id}
                          className={Styles.result}
                          data-automation-id={`recording-result-${recordingLocation.name}`}
                        >
                          <div>
                            <FormattedMessage
                              id="33ec077b-5276-4d82-9db1-24c43d998731"
                              defaultMessage="Name: {recordingLocationName} ({recordingLocationId})"
                              values={{
                                recordingLocationName: recordingLocation.name,
                                recordingLocationId: recordingLocation.id,
                              }}
                            />
                          </div>
                          <div>
                            <FormattedMessage
                              id="9fc484fa-d4fb-4126-8335-f293d3603837"
                              defaultMessage="eRecording supported: {erecordingSupported, select, true{Yes} other{No}}"
                              values={{
                                erecordingSupported: recordingLocation.erecordingSupported,
                              }}
                            />
                          </div>
                          <div>
                            <FormattedMessage
                              id="fa536bad-6cb5-4720-970a-27495f707742"
                              defaultMessage="Recording type: {recordingType}"
                              values={{
                                recordingType: recordingLocation.recordingType,
                              }}
                            />
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <h2 className={Styles.header}>
        <FormattedMessage id="dae41b08-7b42-4bc0-8a00-4690a2183f80" defaultMessage="Eligibility" />
      </h2>
      <p>
        <FormattedMessage
          id="f3d61889-3312-4ecd-880f-9066f5f0df9d"
          defaultMessage="Determines if a transaction can be created based on the given IDs"
        />
      </p>
      <div className={Styles.eligibilitySection}>
        <form onSubmit={form.handleSubmit(submit)} className={Styles.form}>
          <FormRow>
            <label>
              <FormattedMessage
                id="d86b29f3-270f-498d-bcfb-5d23164bd192"
                defaultMessage="Enter recording location ID"
              />
            </label>
            <TextInput
              className={Styles.input}
              placeholder={intl.formatMessage(MESSAGES.recordingLocationPlaceholder)}
              aria-invalid={isAriaInvalid(formState.errors.recordingLocationId)}
              {...form.register("recordingLocationId", {
                required: defaultRequiredMessage(intl),
              })}
            />
            <FormattedFieldError
              inputName="recordingLocationId"
              error={formState.errors.recordingLocationId}
            />
          </FormRow>
          <FormRow>
            <label>
              <FormattedMessage
                id="acacbd74-e556-4716-a05f-159a34544b65"
                defaultMessage="Enter lender ID (if applicable)"
              />
            </label>
            <TextInput
              className={Styles.input}
              placeholder={intl.formatMessage(MESSAGES.lenderPlaceholder)}
              aria-invalid="false"
              {...form.register("lenderId")}
            />
          </FormRow>
          <FormRow>
            <label>
              <FormattedMessage
                id="b02fecf1-08d0-4204-b8ba-5be2a8610fb1"
                defaultMessage="Enter title agency ID"
              />
            </label>
            <TextInput
              className={Styles.input}
              placeholder={intl.formatMessage(MESSAGES.titleAgencyPlaceholder)}
              aria-invalid={isAriaInvalid(formState.errors.titleAgencyId)}
              {...form.register("titleAgencyId", {
                required: defaultRequiredMessage(intl),
              })}
            />
            <FormattedFieldError inputName="titleAgencyId" error={formState.errors.titleAgencyId} />
          </FormRow>
          <FormRow>
            <label>
              <FormattedMessage
                id="81760967-578b-48b3-8ba7-746a4b065116"
                defaultMessage="Enter underwriter ID"
              />
            </label>
            <TextInput
              className={Styles.input}
              placeholder={intl.formatMessage(MESSAGES.titleUnderwriterPlaceholder)}
              aria-invalid={isAriaInvalid(formState.errors.underwriterId)}
              {...form.register("underwriterId", {
                required: defaultRequiredMessage(intl),
              })}
            />
            <FormattedFieldError inputName="underwriterId" error={formState.errors.underwriterId} />
          </FormRow>
          <Button
            type="submit"
            isLoading={eligibilityLoading}
            variant="secondary"
            buttonColor="action"
          >
            <FormattedMessage
              id="81760967-578b-48b3-8ba7-746a4b065116"
              defaultMessage="Get eligibilities"
            />
          </Button>
        </form>
        {eligibilityData && (
          <EligibilityResults
            eligibilityData={eligibilityData}
            includeLenderCheck={Boolean(lenderId)}
          />
        )}
      </div>
    </div>
  );
}

function EligibilityResults({ eligibilityData, includeLenderCheck }: EligibilityResultsProps) {
  const { recordingLocation, lender, titleAgency, titleUnderwriter } = eligibilityData;

  if (!recordingLocation || !titleAgency || !titleUnderwriter || (!lender && includeLenderCheck)) {
    return (
      <div className={Styles.eligibilityResults}>
        <h3>
          <FormattedMessage
            id="247b6857-e165-46e2-9313-5c9c744bc6e0"
            defaultMessage="There was an issue with the inputted information"
          />
        </h3>
        {!recordingLocation && (
          <div className={Styles.eligibilityResultsSection}>
            <FormattedMessage
              id="389e0101-7f8f-414f-9958-06da7fc9c7f5"
              defaultMessage="No recording location matched the given ID"
            />
          </div>
        )}
        {!lender && includeLenderCheck && (
          <div className={Styles.eligibilityResultsSection}>
            <FormattedMessage
              id="d52602bd-41df-4992-ad76-bb20cd3742ab"
              defaultMessage="No lender matched the given ID"
            />
          </div>
        )}
        {!titleAgency && (
          <div className={Styles.eligibilityResultsSection}>
            <FormattedMessage
              id="f649ebca-5dd1-48de-b3e4-3c0fa20bb16e"
              defaultMessage="No title agency matched the given ID"
            />
          </div>
        )}
        {!titleUnderwriter && (
          <div className={Styles.eligibilityResultsSection}>
            <FormattedMessage
              id="5287d25f-e663-4d73-b5d2-a08a42d2aaf7"
              defaultMessage="No underwriter matched the given ID"
            />
          </div>
        )}
      </div>
    );
  }

  if (recordingLocation.__typename !== "RecordingLocation") {
    throw new Error(`Expected RecordingLocation, got ${recordingLocation.__typename}.`);
  }
  // Not using optional chaining since user might not have passed in a lenderId and lender might be null
  if (lender && lender.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${lender.__typename}.`);
  }
  if (titleAgency.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${titleAgency.__typename}.`);
  }
  if (titleUnderwriter.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${titleUnderwriter.__typename}.`);
  }

  const recordingLocationStateId = recordingLocation.usState.id;
  const recordingLocationStateName = recordingLocation.usState.name;

  const lenderSupportsState = lender?.lenderEligibilities.some(
    (lenderEligibility) => lenderEligibility.usState.id === recordingLocationStateId,
  );

  const titleAgencySupports = titleAgency.usStates?.some(
    (state) => state!.id === recordingLocationStateId,
  );

  const titleUnderwriterSupports = titleUnderwriter.closingStates?.some(
    (closingState) => closingState!.usState.id === recordingLocationStateId,
  );

  return (
    <div className={Styles.eligibilityResults}>
      <h3>
        <FormattedMessage
          id="f41f08d5-bbaf-4848-a985-a36e7489627e"
          defaultMessage="Eligibility Summary"
        />
      </h3>
      <div>
        <div className={Styles.eligibilityResultsSection}>
          <h5>
            {lender?.id ? (
              <FormattedMessage
                id="448c3cd1-e9d6-4c56-a155-46330f9fd7a1"
                defaultMessage="Lender ({lenderName}) {lenderSupportsState, select, true{supports} other{does not support}} {recordingLocationStateName}"
                values={{
                  lenderSupportsState,
                  recordingLocationStateName,
                  lenderName: lender.name,
                }}
              />
            ) : (
              <FormattedMessage
                id="217693cc-a712-48a5-84f1-dd8fcc0e3ec0"
                defaultMessage="No lender provided/found"
              />
            )}
          </h5>
        </div>
        <div className={Styles.eligibilityResultsSection}>
          <h5>
            {titleAgency.id ? (
              <FormattedMessage
                id="b1d3c769-0df1-468c-8491-2991efe846dc"
                defaultMessage="Title Agency ({titleAgencyName}) {titleAgencySupports, select, true{supports} other{does not support}} {recordingLocationStateName}"
                values={{
                  titleAgencySupports,
                  recordingLocationStateName,
                  titleAgencyName: titleAgency.name,
                }}
              />
            ) : (
              <FormattedMessage
                id="233ec731-3479-48f1-a885-3cfada503c12"
                defaultMessage="No title agency found"
              />
            )}
          </h5>
        </div>
        <div className={Styles.eligibilityResultsSection}>
          <h5>
            {titleUnderwriter.id ? (
              <FormattedMessage
                id="c2937190-f1ff-43ae-89d1-429c4e44aa7e"
                defaultMessage="Title Underwriter ({underwriterName}) {titleUnderwriterSupports, select, true{supports} other{does not support}} {recordingLocationStateName}"
                values={{
                  titleUnderwriterSupports,
                  recordingLocationStateName,
                  underwriterName: titleUnderwriter.name,
                }}
              />
            ) : (
              <FormattedMessage
                id="233ec731-3479-48f1-a885-3cfada503c12"
                defaultMessage="No title underwriter found"
              />
            )}
          </h5>
        </div>
        <div className={Styles.eligibilityResultsSection}>
          <FormattedMessage
            id="b8165915-658e-4c56-a44f-e66363153927"
            defaultMessage="Can this transaction be created?"
          />{" "}
          {titleUnderwriterSupports &&
          titleAgencySupports &&
          (lenderSupportsState || !includeLenderCheck) ? (
            <Icon name="tick-small" />
          ) : (
            <Icon name="x-small" />
          )}
        </div>
      </div>
    </div>
  );
}

export default RecordingLocationSearchTool;
