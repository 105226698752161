import "./history.scss";

import classnames from "classnames";
import { isBefore } from "date-fns";

import Content from "admin_portal/experiments/content";
import { format } from "common/core/format/date";

import type { Details_viewer_experiments_auditTrail as ExperimentAuditTrail } from "./details_query.graphql";

type AdminExperimentDetailsHistoryProps = {
  auditTrail: ExperimentAuditTrail[];
};

function AdminExperimentDetailsHistory(props: AdminExperimentDetailsHistoryProps) {
  const { auditTrail } = props;

  const sortedAuditTrail = [...auditTrail].sort((crumb1, crumb2) => {
    if (isBefore(new Date(crumb1.createdAt), new Date(crumb2.createdAt))) {
      return 1;
    }
    return -1;
  });
  return (
    <Content className="AdminExperimentDetailsHistory">
      <table className="AdminExperimentDetailsHistory--table">
        <thead>
          <tr className="AdminExperimentDetailsHistory--table--header--row">
            <th className="AdminExperimentDetailsHistory--table--header--cell">Event</th>
            <th className="AdminExperimentDetailsHistory--table--header--cell">When</th>
          </tr>
        </thead>
        <tbody>
          {sortedAuditTrail.map((crumb, index) => {
            const rowCx = classnames("AdminExperimentDetailsHistory--table--row", {
              "AdminExperimentDetailsHistory--table--row--border": index !== auditTrail.length - 1,
            });
            const formattedCreatedAt = format({
              value: crumb.createdAt,
              formatStyle: "MM/dd/yyyy h:mm a",
            });
            return (
              <tr key={`crumb-${crumb.createdAt}-${index}`} className={rowCx}>
                <td className="AdminExperimentDetailsHistory--table--cell">
                  {crumb.updatedBy} {crumb.description}
                </td>
                <td className="AdminExperimentDetailsHistory--table--cell">{formattedCreatedAt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Content>
  );
}

export default AdminExperimentDetailsHistory;
