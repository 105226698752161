const DEFAULT_BATCH_SIZE = 20;

export function batcher<T>(arr: T[], batchSize: number = DEFAULT_BATCH_SIZE) {
  let i = 0;
  const batchedArray = [];
  while (i * batchSize < arr.length) {
    const rightBoundary = batchSize * (i + 1);
    const max = rightBoundary > arr.length ? arr.length : rightBoundary;
    batchedArray.push(arr.slice(i * batchSize, max));
    i += 1;
  }

  return batchedArray;
}
