import { Field } from "redux-form";
import { defineMessages, useIntl } from "react-intl";

import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";
import EmailField from "common/form/fields/email";
import EmailTypoWarningMessage from "common/core/warnings/email_typo";
import TimezoneField from "common/form/fields/timezone";
import FormRowHelpTooltip from "common/form/form_row_help_tooltip";
import FullNameSubForm, {
  validationRules as fullNameValidationRules,
} from "common/form/sub_forms/full_name";
import ProfileSectionHeader from "common/settings/profile_section_header";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import PhoneNumberWarningMessage from "common/core/warnings/phone_number_warning_message";
import PhoneNumberInput from "common/form/fields/phone/number";
import PhoneCountryCodeInput from "common/form/fields/phone/country_code";
import { isInternational } from "common/form/inputs/phone/country_code";
import { validatePresence, validateIf, validatePhoneNumberLength } from "validators/form";
import { validateEmailFormat } from "validators/account";
import { composeValidators } from "util/form";

type FormValues = {
  email: string;
  phoneNumber: string;
  phoneCountryCode: string;
};
type Props = {
  includeTimezoneField?: boolean;
  needsVerification: boolean | null;
  formName: string;
  user: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  };
  formValues: FormValues;
  formErrors: { email: boolean };
};

export const validationRules = (values: FormValues, props: { includeTimezoneField?: boolean }) =>
  composeValidators(
    validateEmailFormat({ field: "email", label: "Email" }),
    validateIf({
      condition: () => Boolean(props.includeTimezoneField),
      validation: validatePresence({ field: "timezone", label: "Timezone" }),
    }),
    validateIf({
      field: "phoneNumber",
      condition: (phone: string | null) => Boolean(phone?.length),
      validation: validatePhoneNumberLength({
        field: "phoneNumber",
        isInternational: isInternational({ countryCode: values.phoneCountryCode }),
      }),
    }),
    fullNameValidationRules(),
  );

const messages = defineMessages({
  account: {
    id: "f4e07f6f-6d23-431d-beb5-c3e0db66eb98",
    defaultMessage: "Account",
  },
  accountId: {
    id: "73a36102-3dc8-4316-b1e0-5170fabb3b18",
    defaultMessage: "Account ID",
  },
  email: {
    id: "5210faca-9af3-4bd8-bc1e-86c305f3dc68",
    defaultMessage: "Email",
  },
  phone: {
    id: "737da0f2-01f7-4a13-a45b-95deb439a0c7",
    defaultMessage: "Phone #",
  },
  emailTooltip: {
    id: "709b6703-d605-4af3-ba5b-8ec29fdf7397",
    defaultMessage: "You must verify your current email address before you can update it.",
  },
  timezone: {
    id: "b5e2bcdd-d675-4108-b562-be09da114cb9",
    defaultMessage: "Timezone",
  },
  exampleEmail: {
    id: "79da6c92-d809-40a0-86d5-490d8d0dd4b1",
    defaultMessage: "example@email.com",
  },
  selectYourTimezone: {
    id: "417c2b71-ee76-4783-825c-78156d98f710",
    defaultMessage: "Select your timezone",
  },
});

function UpdateNameEmailTimezoneFields(props: Props) {
  const { needsVerification, formValues } = props;
  const intl = useIntl();
  return (
    <div>
      <ProfileSectionHeader>{intl.formatMessage(messages.account)}</ProfileSectionHeader>

      <div className="Form-row">
        <label htmlFor="user-account-id">{intl.formatMessage(messages.accountId)}</label>
        <Field
          id="user-account-id"
          name="id"
          component="input"
          type="text"
          className="Form-input--readonly"
          disabled
        />
      </div>

      <FullNameSubForm formName={props.formName} user={props.user} />

      <FormGroup fields={["email"]}>
        <label htmlFor="email">{intl.formatMessage(messages.email)}</label>
        <EmailField
          id="email"
          name="email"
          placeholder={intl.formatMessage(messages.exampleEmail)}
          disabled={needsVerification}
        />
        {needsVerification && (
          <FormRowHelpTooltip>{intl.formatMessage(messages.emailTooltip)}</FormRowHelpTooltip>
        )}
        <FormGroupErrors fields={["email"]} />
        <EmailTypoWarningMessage
          email={formValues.email}
          disabled={Boolean(props.formErrors.email)}
        />
      </FormGroup>

      <FormGroup fields={["phoneCountryCode", "phoneNumber"]}>
        <label htmlFor="">{intl.formatMessage(messages.phone)}</label>
        <MultipartRow>
          <MultipartColumn width={3}>
            <PhoneCountryCodeInput
              id="phoneCountryCode"
              name="phoneCountryCode"
              data-automation-id="phone-country-code-field"
            />
          </MultipartColumn>
          <MultipartColumn width={9}>
            <PhoneNumberInput
              id="phoneNumber"
              name="phoneNumber"
              isInternational={isInternational({ countryCode: formValues.phoneCountryCode })}
              data-automation-id="phone-number-field"
            />
          </MultipartColumn>
        </MultipartRow>
        <FormGroupErrors fields={["phoneCountryCode", "phoneNumber"]} />
        <PhoneNumberWarningMessage phoneNumber={formValues.phoneNumber} />
      </FormGroup>

      {props.includeTimezoneField && (
        <FormGroup fields={["timezone"]}>
          <label htmlFor="timezone">{intl.formatMessage(messages.timezone)}</label>
          <TimezoneField
            id="timezone"
            name="timezone"
            placeholder={intl.formatMessage(messages.selectYourTimezone)}
            data-automation-id="timezone-field"
          />
          <FormGroupErrors fields={["timezone"]} />
        </FormGroup>
      )}
    </div>
  );
}

export default UpdateNameEmailTimezoneFields;
