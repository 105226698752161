import type { ComponentProps } from "react";

import type AddNote from "common/internal_notes/add_note";
import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import InternalNotes from "common/internal_notes";

import CreateNotaryProfileNoteMutation from "./create_notary_profile_note_mutation.graphql";
import NotaryProfileFragment, { type NotaryNotes as NotaryProfile } from "./index_fragment.graphql";

type Props = {
  notaryProfile: NotaryProfile;
};

export default function InternalNotaryNotes({ notaryProfile }: Props) {
  const createNoteMutateFn = useMutation(CreateNotaryProfileNoteMutation);
  const handleSaveNewNote: ComponentProps<typeof AddNote>["onSave"] = (noteText, attachments) =>
    createNoteMutateFn({
      variables: {
        input: {
          notaryProfileId: notaryProfile.id,
          text: noteText,
          attachments: attachments
            .filter((attachment) => attachment.state === "done")
            .map((attachment) => ({ name: attachment.file.name, s3Key: attachment.key })),
        },
      },
      update(cacheProxy, { data }) {
        const id = cacheProxy.identify(notaryProfile);
        const notaryProfileEntry = cacheProxy.readFragment<NotaryProfile>({
          id,
          fragment: NotaryProfileFragment,
          fragmentName: "NotaryNotes",
        });
        if (!notaryProfileEntry) {
          captureException(
            new Error("Failed to find notary profile entry in cache when adding notary note"),
            { notaryProfile },
          );
          return;
        }
        cacheProxy.writeFragment({
          id,
          fragment: NotaryProfileFragment,
          fragmentName: "NotaryNotes",
          data: {
            ...notaryProfileEntry,
            notes: [...notaryProfileEntry.notes, data!.createNotaryProfileNote!.note],
          },
        });
      },
    });

  return <InternalNotes handleSaveNewNote={handleSaveNewNote} notes={notaryProfile.notes} />;
}
