import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { OrganizationAccountManager } from "graphql_globals";
import { useMutation } from "util/graphql";
import AdobeLogo from "assets/images/adobe-logo.svg";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import Select from "common/form/inputs/select";
import Button from "common/core/button";
import { Card, CardHeading } from "common/core/card";

import Styles from "./index.module.scss";
import UpdateOrganizationAccountManagerMutation from "./update_organization_account_manager_mutation.graphql";

type State = "error" | "loading";

type Props = {
  organizationId: string;
  accountManager: OrganizationAccountManager;
  adobeEnabled: boolean;
};

const KNOWN_ACCOUNT_MANAGERS = Object.freeze([
  {
    value: OrganizationAccountManager.adobe,
    label: <FormattedMessage id="68fb554e-1277-41d8-92a7-dbf8866ab5af" defaultMessage="Adobe" />,
  },
  {
    value: OrganizationAccountManager.notarize,
    label: <FormattedMessage id="6ffa3e58-06cd-4f0c-adea-bc714a72c204" defaultMessage="Notarize" />,
  },
]);

const MESSAGES = defineMessages({
  accountManager: {
    id: "02ef4103-cdc0-486a-a86c-924172628125",
    defaultMessage: "Who is their contract with?",
  },
  adobeEnabled: {
    id: "26878f3a-d20b-44fe-9218-b4f96e8e3b9c",
    defaultMessage: "Integration is enabled",
  },
  yes: {
    id: "e9200fc2-e0ff-432b-a761-7dba8cda2f54",
    defaultMessage: "Yes",
  },
  no: {
    id: "d27f666a-9c25-4682-b8d2-b16647f757b3",
    defaultMessage: "No",
  },
  saveAccountManager: {
    id: "157fdf75-3643-4bf6-80d4-5363ecc62389",
    defaultMessage: "Save Account Manager",
  },
});

function AdobeInstance({ organizationId, accountManager, adobeEnabled }: Props) {
  const [mutationStatus, setMutationStatus] = useState<State | null>(null);
  const [selectedAccountManager, setSelectedAccountManager] =
    useState<OrganizationAccountManager>(accountManager);

  const updateOrganizationAccountManager = useMutation(UpdateOrganizationAccountManagerMutation);
  const intl = useIntl();

  const updateAccountManager = () => {
    setMutationStatus("loading");

    updateOrganizationAccountManager({
      variables: {
        input: {
          id: organizationId,
          accountManager: selectedAccountManager,
        },
      },
    })
      .catch(() => {
        setMutationStatus("error");
      })
      .finally(() => {
        setMutationStatus(null);
      });
  };

  const adobeInstanceForm = (
    <>
      <Card>
        <CardHeading>{intl.formatMessage(MESSAGES.accountManager)}</CardHeading>

        <Select
          name="accountManager"
          automationId="adobe-instance-select-account-manager"
          items={KNOWN_ACCOUNT_MANAGERS}
          value={selectedAccountManager}
          onChange={setSelectedAccountManager}
          disabled={mutationStatus !== null}
        />
        {accountManager !== selectedAccountManager && (
          <Button
            buttonColor="action"
            variant="primary"
            onClick={updateAccountManager}
            disabled={mutationStatus !== null}
            isLoading={mutationStatus === "loading"}
            automationId="update-adobe-account-manager-button"
          >
            {intl.formatMessage(MESSAGES.saveAccountManager)}
          </Button>
        )}

        <CardHeading>{intl.formatMessage(MESSAGES.adobeEnabled)}</CardHeading>

        <span className="AdobeIntegrationEnabled">
          {intl.formatMessage(adobeEnabled ? MESSAGES.yes : MESSAGES.no)}
        </span>
      </Card>
    </>
  );

  return (
    <div className="AdobeInstanceContainer" data-automation-id="adobe-instance-admin-portal">
      <div className={Styles.header}>
        <img alt="adobe icon" src={AdobeLogo} />
      </div>
      {adobeInstanceForm}

      {mutationStatus === "error" && <MutationErrorModal onClick={() => setMutationStatus(null)} />}
    </div>
  );
}

export default AdobeInstance;
