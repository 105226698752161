import { FormattedMessage } from "react-intl";

import { AcceptedTouList } from "util/tou";

import type { NotaryTou as User } from "./notary_tou_fragment.graphql";
import Styles from "./notary_tou.module.scss";

export default function NotaryTou({ user }: { user: User }) {
  return (
    <div className={Styles.termsOfUseSection}>
      <FormattedMessage
        id="33dacd95-76e4-4dd1-979c-f9462c8fcb54"
        defaultMessage="Accepted Terms"
        tagName="h3"
      />
      <AcceptedTouList applicableTou={user.applicableTou} />
    </div>
  );
}
