import { type ChangeEvent, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./csv_uploader.module.scss";
import type { FormRow } from ".";

const JURISDICTION_KEY = "Recording Jurisdiction";
const COUNTY_KEY = "County";
const STATE_KEY = "State";
const RJID_KEY = "RJID Number";
const RECORDING_SERVICES_KEY = "eRecording Submission Services";
const ADDRESS_COMPONENT = "Address Component";
const REQUIRED_KEYS = [JURISDICTION_KEY, COUNTY_KEY, STATE_KEY, RJID_KEY];
const OPTIONAL_KEYS = [RECORDING_SERVICES_KEY, ADDRESS_COMPONENT];

type CSVUploaderProps = {
  onError: (error: string) => void;
  onDataUploaded: (data: FormRow[]) => void;
};

export default function CSVUploader({ onError, onDataUploaded }: CSVUploaderProps) {
  const [file, setFile] = useState<File | null>(null);

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return;
    }
    const files = Array.from(e.target.files);
    if (files.length === 1) {
      setFile(files[0]);
    } else {
      setFile(null);
    }
  };

  const upload = async () => {
    if (file === null) {
      return;
    }
    const Papa = await import("papaparse");
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      transform: (value: string | null) => value?.trim(),
      complete: ({ data, meta: { fields } }) => {
        try {
          const parsedData: FormRow[] = [];
          const missingKeys = REQUIRED_KEYS.filter((key) => !fields!.includes(key));
          if (missingKeys.length > 0) {
            onError(`Missing the following columns: ${missingKeys.join(", ")}`);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.forEach((row: any) => {
            parsedData.push({
              jurisdiction: row[JURISDICTION_KEY],
              county: row[COUNTY_KEY],
              state: row[STATE_KEY],
              rjid: row[RJID_KEY],
              erecordingSupported: Boolean(
                !row[RECORDING_SERVICES_KEY] ||
                  row[RECORDING_SERVICES_KEY].toLowerCase().trim() === "true",
              ),
              addressComponent: row[ADDRESS_COMPONENT]?.trim() || null,
            });
          });
          onDataUploaded(parsedData);
        } catch (error) {
          onError((error as Error).message);
        }
      },
      error: (error: { message: string }) => {
        onError(error.message);
      },
    });
  };

  return (
    <div className={Styles.uploader}>
      <p className={Styles.info}>
        <FormattedMessage
          id="9cf40937-45fa-44e4-be66-3a6024649f3d"
          defaultMessage={
            "To add new recording locations, manually input data in the form below or upload data from a csv file. The csv file must contain the following columns: {requiredKeys}." +
            " Additionally, the csv file may optionally include columns: {optionalKeys}."
          }
          values={{
            requiredKeys: REQUIRED_KEYS.join(", "),
            optionalKeys: OPTIONAL_KEYS.join(", "),
          }}
        />
      </p>
      <div>
        <input type="file" id="pria-file-csv" accept=".csv" onChange={handleFileSelected} />
        <Button
          buttonColor="action"
          variant="primary"
          buttonSize="condensed"
          onClick={upload}
          disabled={file === null}
        >
          <FormattedMessage id="7fdf8d2a-7e24-4893-8833-7be4ad3fa31b" defaultMessage="Upload" />
        </Button>
      </div>
    </div>
  );
}
