import "common/details/deprecated_index.scss";
import "./index.scss";

import type { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import { OrganizationTransactionVariant, UserRole } from "graphql_globals";
import { NetworkStatus, useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Modal from "common/modal";
import Tabs from "common/core/tabs";
import Tab from "common/core/tabs/tab";
import DocumentTabName from "common/details/bundle/document_tab_name";
import { useFeatureFlag } from "common/feature_gating";
import { newPathWithPreservedSearchParams } from "util/location";
import { usePermissions } from "common/core/current_user_role";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";

import AdminAnalyticsDetailsQuery, {
  type AdminAnalyticsDetails_node_Meeting as Meeting,
  type AdminAnalyticsDetails_node_Meeting_bundle as DocumentBundle,
  type AdminAnalyticsDetails_viewer as Viewer,
} from "./index_query.graphql";

type ChildRenderProps = {
  meeting: Meeting;
  viewer: Viewer;
  refetch: () => Promise<unknown>;
  bundle: DocumentBundle;
  notary: Meeting["notary"];
  transaction: DocumentBundle["transaction"];
  finalizedDocumentOnly: boolean;
  meetingIds: string[];
  userRole: UserRole;
  useWrapperDiv: boolean;
  showNotarialActs: boolean;
};
type Props = {
  children: (renderProps: ChildRenderProps) => ReactNode;
};
type LoadedProps = Props & {
  node: ChildRenderProps["meeting"];
  viewer: ChildRenderProps["viewer"];
  refetch: ChildRenderProps["refetch"];
};

function AdminAnalyticsDetailsModalLoaded({
  node: meeting,
  viewer,
  children,
  refetch,
}: LoadedProps) {
  const bundle = meeting.bundle!;
  const intl = useIntl();
  const { globalID, page } = useParams();
  const { search } = useLocation();
  const ftechTabEanbled = useFeatureFlag("enable-ftech-transaction-tab");
  const basePath = `/analytics/page/${page || "1"}`;
  const { hasPermissionFor } = usePermissions();
  const transactionVariant = meeting.organizationTransaction.transactionVariant;

  return (
    <Modal
      title={
        <FormattedMessage
          id="df3e2f87-7a01-4ac0-a81d-16cfd6221d9b"
          defaultMessage="{transactionVariant} Details"
          values={{
            transactionVariant: formattedTransactionVariant(transactionVariant, intl),
          }}
        />
      }
      closeRoute={newPathWithPreservedSearchParams(basePath)}
      className="AdminAnalyticsDetails"
    >
      <Tabs modalView>
        <Tab to={`${basePath}/${globalID}/summary`} search={search}>
          <FormattedMessage id="52f0e805-dc20-40f8-8a2e-d6d14cb67983" defaultMessage="Summary" />
        </Tab>
        <Tab to={`${basePath}/${globalID}/user`} search={search}>
          <FormattedMessage id="368dc7fb-2e42-4a62-836b-5007cb355fee" defaultMessage="Recipient" />
        </Tab>
        {ftechTabEanbled && (
          <Tab to={`${basePath}/${globalID}/deepfake`} search={search}>
            <FormattedMessage id="fd308e3f-7df7-4387-b4c3-c663da5038b4" defaultMessage="Deepfake" />
          </Tab>
        )}
        <Tab to={`${basePath}/${globalID}/notary`} search={search}>
          {transactionVariant === OrganizationTransactionVariant.PROOF ? (
            <FormattedMessage id="99df0043-c2cb-4603-b850-c1c4c54965a3" defaultMessage="Agent" />
          ) : (
            <FormattedMessage id="23569ee5-ec32-4676-a808-2f6e860451fe" defaultMessage="Notary" />
          )}
        </Tab>
        {meeting.meetingParticipants.some((p) => p.__typename === "WitnessParticipant") && (
          <Tab to={`${basePath}/${globalID}/witness`} search={search}>
            <FormattedMessage id="b50746e1-c279-442e-b423-185efb22410d" defaultMessage="Witness" />
          </Tab>
        )}
        {hasPermissionFor("meetingDetailsVideo") && (
          <Tab to={`${basePath}/${globalID}/video`} search={search}>
            <FormattedMessage id="1586d3b3-d94e-4ff3-a983-2512888c0144" defaultMessage="Video" />
          </Tab>
        )}
        <Tab to={`${basePath}/${globalID}/document`} search={search}>
          <DocumentTabName bundle={bundle} />
        </Tab>
      </Tabs>
      {children({
        meeting,
        notary: meeting.notary,
        viewer,
        bundle,
        transaction: bundle.transaction,
        finalizedDocumentOnly: false,
        // applies to signer
        meetingIds: [meeting.id],
        // applies to both notary and video common
        userRole: UserRole.ADMIN,
        useWrapperDiv: true,
        showNotarialActs:
          bundle.transaction.transactionVariant !== OrganizationTransactionVariant.PROOF,
        refetch,
      })}
    </Modal>
  );
}

function AdminAnalyticsDetailsModal(props: Props) {
  const { globalID } = useParams();
  const { networkStatus, data, refetch } = useQuery(AdminAnalyticsDetailsQuery, {
    variables: { meetingId: globalID! },
  });

  if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
    return <LoadingIndicator />;
  }

  const { node, viewer } = data!;
  if (node?.__typename !== "Meeting") {
    throw new Error(`Expected Meeting, got ${node?.__typename}`);
  }

  return (
    <AdminAnalyticsDetailsModalLoaded node={node} viewer={viewer} refetch={refetch}>
      {props.children}
    </AdminAnalyticsDetailsModalLoaded>
  );
}

export default AdminAnalyticsDetailsModal;
