import { FormattedMessage } from "react-intl";
import { useNavigate, Outlet } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import Dashboard from "common/dashboard";
import { Sidebar, SidebarTabLink, SidebarBackButton } from "common/sidebar";
import { useQuery } from "util/graphql";

import GroupDisplayNames from "./group_display_names_query.graphql";

function AdminSettings() {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GroupDisplayNames);

  if (loading) {
    return <LoadingIndicator />;
  }

  const groups = data?.viewer.settingGroups.filter((group) => group.displayName !== "Organization"); // organization is handled by org flags page

  return (
    <Dashboard>
      <Sidebar
        title={
          <FormattedMessage
            id="bd747f0f-2f14-46ae-a86d-0eedbcdb61af"
            defaultMessage="System Settings"
          />
        }
        backButton={
          <SidebarBackButton
            onClick={() => {
              navigate("/");
            }}
          />
        }
      >
        {groups?.map((group) => {
          return (
            <SidebarTabLink
              key={group.groupName}
              to={`/system-settings/${group.groupName}`}
              automationId={`system-settings-tab-${group.groupName}`}
            >
              {group.displayName}
            </SidebarTabLink>
          );
        })}
      </Sidebar>
      <Outlet />
    </Dashboard>
  );
}

export default AdminSettings;
