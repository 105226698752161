import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { ComboboxDropdown, ComboboxDropdownMenuOption } from "common/core/combobox_dropdown";
import { ProofReasonCategory } from "graphql_globals";

import { type ProofReasonCode } from "./context";
import Styles from "./proof_reason_codes.module.scss";

const CategoriesColumn = {
  Header: (
    <span>
      <FormattedMessage id="6a2e36b8-ae03-4b69-aacb-c4259ff4430b" defaultMessage="Categories" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return (
      <ComboboxDropdown
        className={classnames(Styles.categoriesWidth)}
        label="No categories selected"
        selectedLabel={reasonCode.categories.join(", ")}
        hasSelection={false} // don't want to show the clear button
        allowMultipleSelection
        noBorder
        noBackground
      >
        {() => (
          <>
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.AGE)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.AGE}
              disabled
            />
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.EMAIL)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.EMAIL}
              disabled
            />
            <ComboboxDropdownMenuOption
              selected={reasonCode.categories.includes(ProofReasonCategory.IDENTITY)}
              onChange={() => {}}
              optionLabel={ProofReasonCategory.IDENTITY}
              disabled
            />
          </>
        )}
      </ComboboxDropdown>
    );
  },
  collapse: false,
};

export default CategoriesColumn;
