import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useMutation } from "util/graphql";
import { VerificationStatesEnum } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import Button from "common/core/button";

import UpdateVerificationMutation from "./update_verification.mutation.graphql";

const MESSAGES = defineMessages({
  verifiedDomainSuccess: {
    id: "b03765a8-f210-4b7c-8733-6f89e86ceb55",
    defaultMessage: "Successfully marked domain as verified",
  },
  failedToVerifyDomain: {
    id: "b03765a8-f210-4b7c-8733-6f89e86ceb55",
    defaultMessage: "Failed to verify domain",
  },
  cheatVerifyDomain: {
    id: "cb82a7f8-6578-4a0b-904b-7399cac7f68c",
    defaultMessage: "(Cheat) Verify Domain",
  },
});

export function CheatUpdateVerificationButton({ domainItemId }: { domainItemId: string }) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const verifyDomainMutation = useMutation(UpdateVerificationMutation);
  const updateVerification = useCallback(() => {
    setIsLoading(true);
    return verifyDomainMutation({
      variables: {
        input: {
          domainVerificationId: domainItemId,
          verificationState: VerificationStatesEnum.VERIFIED,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(MESSAGES.verifiedDomainSuccess),
          position: "topCenter",
        });
      })
      .catch(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.failedToVerifyDomain),
          position: "topCenter",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [domainItemId]);

  return (
    <Button
      variant="secondary"
      buttonColor="danger"
      isLoading={isLoading}
      onClick={updateVerification}
    >
      {intl.formatMessage(MESSAGES.cheatVerifyDomain)}
    </Button>
  );
}
