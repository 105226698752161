import { FormattedMessage } from "react-intl";
import { useState } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";
import type { AdminPanelInfo_panel_Panel_members_edges_node_notaryProfile as Notary } from "../index_query.graphql";

type Props = {
  notary: Notary;
  onCancel: () => void;
  onDelete: () => Promise<unknown>;
};

function DeleteNotaryModal({ onCancel, onDelete, notary }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <WorkflowModal
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      buttons={[
        <Button key="cancel" variant="tertiary" disabled={isLoading} onClick={onCancel}>
          <FormattedMessage id="f0ba582f-b50b-4421-8954-99fca9ea21bf" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="danger"
          variant="primary"
          key="delete"
          onClick={() => {
            setIsLoading(true);
            onDelete().catch(() => {
              setIsLoading(false);
            });
          }}
        >
          <FormattedMessage id="a4d0959b-a7ed-4d7d-a7c2-1e1b561f07e7" defaultMessage="Delete" />
        </Button>,
      ]}
      title={
        <div>
          <Icon className={Styles.warningIcon} name="doc-warning" />
          <FormattedMessage
            id="a4d0959b-a7ed-4d7d-a7c2-1e1b561f07e0"
            defaultMessage="Remove notary?"
          />
        </div>
      }
      footerSeparator={false}
    >
      <FormattedMessage
        id="60ae48a1-3ce1-49e5-bbe0-ffce6bb22bc4"
        defaultMessage="Are you sure you want to remove {notaryId} from this panel? They will stop receiving meeting calls from this panel."
        values={{ notaryId: notary.id }}
      />
    </WorkflowModal>
  );
}

export default DeleteNotaryModal;
