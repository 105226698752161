import "./groups.scss";

import { useState, useEffect, type ChangeEvent } from "react";

import Content from "admin_portal/experiments/content";
import Button from "common/core/button";
import AdminExperimentGroups from "admin_portal/experiments/groups";
import type { useMutation } from "util/graphql/mutation";

import DetailsQuery, {
  type Details_viewer_experiments_groups as ExperimentGroup,
} from "./details_query.graphql";
import type {
  UpdateExperiment,
  UpdateExperimentVariables,
} from "./update_experiment_mutation.graphql";

type AdminExperimentDetailsGroupsProps = {
  name: string;
  groups: ExperimentGroup[];
  updateExperimentMutate: ReturnType<
    typeof useMutation<UpdateExperiment, UpdateExperimentVariables>
  >;
};

type SlimGroup = {
  value: string;
  percent: number;
};

function findIfGroupsChanged(oldGroups: SlimGroup[], newGroups: SlimGroup[]) {
  if (oldGroups.length !== newGroups.length) {
    return true;
  }

  let changed = false;
  oldGroups.forEach((oldGroup: SlimGroup, index: number) => {
    if (oldGroup.percent !== newGroups[index].percent) {
      changed = true;
    }
    if (oldGroup.value !== newGroups[index].value) {
      changed = true;
    }
  });

  return changed;
}

function AdminExperimentDetailsGroups(props: AdminExperimentDetailsGroupsProps) {
  const { name, groups, updateExperimentMutate } = props;

  const [changedGroups, setChangedGroups] = useState(
    groups.map((group) => ({
      value: group.value,
      percent: group.percent,
    })),
  );
  const [showPercentageWarningMessage, setShowPercentageWarningMessage] = useState(false);
  const [areGroupsDifferent, setAreGroupsDifferent] = useState(false);
  const [showGroupNamingWarningMessage, setShowGroupNamingWarningMessage] = useState<number | null>(
    null,
  );

  const totalPercentage = changedGroups.reduce((result, group) => result + group.percent, 0);

  function onChangeGroupValue(ev: ChangeEvent<HTMLInputElement>, groupNumber: number) {
    const newGroups = changedGroups;
    newGroups[groupNumber].value = ev.target.value;
    setChangedGroups([...newGroups]);
  }

  function onChangeGroupPercentage(ev: ChangeEvent<HTMLInputElement>, groupNumber: number) {
    const parsedInput = parseInt(ev.target.value, 10);
    const newGroups = changedGroups;
    if (!isNaN(parsedInput)) {
      newGroups[groupNumber].percent = parsedInput;
    } else if (ev.target.value === "") {
      newGroups[groupNumber].percent = 0;
    }
    setChangedGroups([...newGroups]);
  }

  function addGroup() {
    setChangedGroups([
      ...changedGroups,
      {
        value: "",
        percent: 0,
      },
    ]);
  }

  function deleteGroup(index: number) {
    const newGroups = changedGroups;
    newGroups.splice(index, 1);
    setChangedGroups([...newGroups]);
  }

  function submitGroups() {
    let hasErrors = false;
    if (totalPercentage > 100) {
      setShowPercentageWarningMessage(true);
      hasErrors = true;
    }
    changedGroups.forEach((group, index) => {
      if (group.value === "") {
        setShowGroupNamingWarningMessage(index);
        hasErrors = true;
      }
    });
    if (!hasErrors) {
      updateExperimentMutate({
        variables: {
          input: {
            name,
            groups: changedGroups,
            comment: "",
          },
        },
        refetchQueries: [{ query: DetailsQuery }],
      });
    }
  }

  useEffect(() => {
    if (totalPercentage <= 100) {
      setShowPercentageWarningMessage(false);
    }
    setAreGroupsDifferent(findIfGroupsChanged(groups, changedGroups));
  }, [changedGroups]);

  return (
    <Content className="AdminExperimentDetailsGroups">
      <div className="AdminExperimentDetailsGroups--header">
        Groups
        <Button
          buttonColor="action"
          variant="primary"
          disabled={!areGroupsDifferent}
          onClick={submitGroups}
        >
          Save
        </Button>
      </div>
      <AdminExperimentGroups
        showGroupNamingWarningMessage={showGroupNamingWarningMessage}
        showPercentageWarningMessage={showPercentageWarningMessage}
        groups={changedGroups}
        onChangeGroupValue={onChangeGroupValue}
        onChangeGroupPercentage={onChangeGroupPercentage}
        deleteGroup={deleteGroup}
        addGroup={addGroup}
      />
    </Content>
  );
}

export default AdminExperimentDetailsGroups;
