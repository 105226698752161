import type { ReactNode } from "react";

import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";

type Props = {
  confirm: () => void;
  cancel: () => void;
  subheader: ReactNode;
  confirmText: ReactNode;
};

function AdminConfirmModal(props: Props) {
  return (
    <BlockingModal isOpen>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalSubheader>{props.subheader}</ModalSubheader>
      <ModalActions>
        <Button
          buttonColor="action"
          variant="primary"
          automationId="admin-confirm"
          onClick={props.confirm}
        >
          {props.confirmText}
        </Button>
        <Button
          onClick={props.cancel}
          variant="tertiary"
          buttonColor="dark"
          automationId="admin-nevermind"
        >
          Cancel
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

export default AdminConfirmModal;
