import "./index.scss";

import { getUnixTime, subWeeks, subMonths } from "date-fns";
import { useLocation, useParams, useSearchParams, useNavigate, Outlet } from "react-router-dom";

import Pagination from "common/pagination/stateful";
import Icon from "common/core/icon";
import { maxPageNumber } from "util/pagination";
import { useQuery } from "util/graphql";
import { DEFAULT_ITEMS_PER_PAGE } from "constants/pagination";
import LoadingIndicator from "common/core/loading_indicator";

import AnalyticsFilter from "./analytics_filter";
import AnalyticsPage from "./analytics_page";
import AnalyticsHeader from "./analytics_header";
import AdminAnalyticsRootQuery, { type AdminAnalyticsRootVariables } from "./index_query.graphql";

const NOW = new Date();
const ONE_WEEK_AGO = getUnixTime(subWeeks(NOW, 1));
const ONE_MONTH_AGO = getUnixTime(subMonths(NOW, 1));

function useVars(pageNumber: number): AdminAnalyticsRootVariables {
  const [searchParams] = useSearchParams();
  const after = searchParams.get("after");
  const before = searchParams.get("before");
  const startAfter = after ? Number(after) : null;
  const startBefore = before ? Number(before) : null;
  return {
    offset: (pageNumber - 1) * DEFAULT_ITEMS_PER_PAGE,
    notaryName: searchParams.get("notary") || null,
    notaryEmail: searchParams.get("notaryEmail") || null,
    businessName: searchParams.get("business") || null,
    customerName: searchParams.get("name") || null,
    customerEmail: searchParams.get("email") || null,
    after: startAfter,
    before: startBefore,
    afterWeek: ONE_WEEK_AGO,
    afterMonth: ONE_MONTH_AGO,
  };
}

function Analytics() {
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useParams();
  const pageNumber = Number(page || 1);

  const { loading, data } = useQuery(AdminAnalyticsRootQuery, { variables: useVars(pageNumber) });
  if (loading) {
    return <LoadingIndicator />;
  }

  const { viewer } = data!;
  const { meetings } = viewer;
  const meetingsCount = meetings.totalCount;
  const maxPageNumberValue = maxPageNumber(meetingsCount);
  const pagination = meetingsCount ? (
    <Pagination
      className="AdminDashboardAnalytics-pagination"
      maxPageNumber={maxPageNumberValue}
      pageNumber={pageNumber}
      onChange={(newPageNumber: number) => {
        navigate(`/analytics/page/${newPageNumber}${location.search}`);
      }}
    />
  ) : null;
  return (
    <div className="AdminDashboardAnalytics">
      <Outlet />

      <nav className="AdminDashboardAnalytics-subheader">
        <button
          type="submit"
          className="Dashboard-subheader-back"
          data-automation-id="admin-analytics-back"
          onClick={() => navigate("/")}
        >
          <Icon name="arrow-left" />
        </button>
        <h2>Analytics</h2>
      </nav>

      <AnalyticsHeader viewer={viewer} />

      <AnalyticsFilter />

      <AnalyticsPage pageNumber={pageNumber} meetings={meetings} />

      {pagination}
    </div>
  );
}

export default Analytics;
