import { useParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import { useQuery } from "util/graphql";
import type { SettingGroupName } from "graphql_globals";

import SettingsCard from "../settings_card";
import SettingGroups from "./setting_groups_query.graphql";

function AdminSystemSettingsGroup() {
  const { groupName } = useParams();
  const { hasPermissionFor } = usePermissions();
  const { data, loading } = useQuery(SettingGroups, {
    variables: {
      groupName: groupName as SettingGroupName,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const groupDisplayName = data?.viewer.settingGroups[0].displayName;
  const settings = data?.viewer.settingGroups[0].settings;

  return (
    <SettingsPageWrapper>
      <SettingsHeader title={groupDisplayName} />
      {settings?.map((setting) => {
        return (
          <SettingsCard
            groupName={groupName! as SettingGroupName}
            setting={setting}
            canEdit={hasPermissionFor("editSettingsUpdateForm")}
            key={setting.name}
          />
        );
      })}
    </SettingsPageWrapper>
  );
}

export default AdminSystemSettingsGroup;
