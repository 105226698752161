import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import EOriginalTestingForm from "admin_portal/company/mortgage/eoriginal_testing_form";
import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import Button from "common/core/button";
import Select from "common/form/inputs/select";
import { MORTGAGE_TYPES } from "constants/mortgage_types";
import { usePermissions } from "common/core/current_user_role";
import { useMutation } from "util/graphql";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import type {
  FullAdminOrganizationDetails_viewer as Viewer,
  FullAdminOrganizationDetails_organization_Organization as Organization,
  FullAdminOrganizationDetails_organization_Organization_closingStates as ClosingState,
} from "admin_portal/company/details_query.graphql";
import TitleAgencyUsStatesSelection from "admin_portal/company/mortgage/title_agency_us_states";

import UnderwriterSelect from "./underwriter_eligibilities";
import EligibilitiesSetup from "./eligibilities_setup";
import ClosingPreferences from "./closing_preferences";
import TitleUnderwriterStateEligibilities from "./title_underwriter_state_eligibilities";
import UpdateMortgageOrganizationTypeMutation from "./update_mortgage_organization_type_mutation.graphql";

type Props = {
  organization: Organization;
  organizationMortgageType: string | null;
  viewer: Viewer;
  setMortgageType: (arg: string | null) => void;
  refetch: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  success: {
    id: "3e24b95e-e2f9-4730-85b4-96dc71ba0ee1",
    defaultMessage: "Successfully updated organization type ",
  },
});

export function OrganizationType({
  organization,
  organizationMortgageType,
  viewer,
  setMortgageType,
  refetch,
}: Props) {
  const { id: organizationId, isTest } = organization;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();

  const [selectedMortgageType, setSelectedMortgageType] = useState<string | null>(null);
  const [status, setStatus] = useState<{ status: string | null; message?: string | null }>({
    status: null,
    message: null,
  });

  const updateMortgageOrganizationTypeMutateFn = useMutation(
    UpdateMortgageOrganizationTypeMutation,
  );

  const MortgageTypeList = Object.values(MORTGAGE_TYPES).map((value) => ({
    label: value,
    value,
  }));

  function persistMortgageType() {
    setStatus({ status: "loading" });
    updateMortgageOrganizationTypeMutateFn({
      variables: {
        input: {
          organizationId,
          mortgageType: selectedMortgageType!,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
        setStatus({ status: null });
        setMortgageType(selectedMortgageType);
      })
      .catch((e) => {
        setStatus({ status: "error", message: e.message });
      });
  }

  function saveButton() {
    return (
      !organizationMortgageType && (
        <Button
          automationId="org-type-save-button"
          buttonColor="action"
          variant="primary"
          onClick={() => persistMortgageType()}
          disabled={status.status === "loading"}
        >
          Save
        </Button>
      )
    );
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="62af569d-b2d5-4f2d-82d5-190e52d3bdca" defaultMessage="Org Type" />
      </SettingsTitle>
      <Card footer={saveButton()}>
        <CardHeading>
          <FormattedMessage id="f873e563-81a6-40e5-a41b-392a67781934" defaultMessage="Type" />
        </CardHeading>
        <Select
          items={MortgageTypeList}
          placeholder="Business"
          onChange={setSelectedMortgageType}
          value={selectedMortgageType || organizationMortgageType}
          autosize={false}
          disabled={Boolean(organizationMortgageType) || status.status === "loading"}
        />

        {organizationMortgageType === MORTGAGE_TYPES.UNDERWRITER && (
          <>
            <TitleUnderwriterStateEligibilities
              viewer={viewer}
              closingStates={(organization.closingStates ?? []) as ClosingState[]}
              organizationId={organizationId}
            />

            <ClosingPreferences viewer={viewer} organization={organization} />
          </>
        )}

        {organizationMortgageType === MORTGAGE_TYPES.LENDER && (
          <>
            <EligibilitiesSetup
              header={
                <FormattedMessage
                  id="a930138f-9df4-4c05-a8ec-49ebd7856c70"
                  defaultMessage="Lender Eligibilities"
                />
              }
              link={`/company/${organizationId}/mortgage_details`}
              text="Setup Lender Eligibilities"
            />
            {isTest && (
              <>
                <CardHeading>
                  <FormattedMessage
                    id="eea6f293-555f-4953-8fa9-b83207b44b2d"
                    defaultMessage="MERS Testing"
                  />
                </CardHeading>
                <EOriginalTestingForm organization={organization} refetch={refetch} />
              </>
            )}
          </>
        )}

        {organizationMortgageType === MORTGAGE_TYPES.TITLE && (
          <>
            <TitleAgencyUsStatesSelection organization={organization} viewer={viewer} />
            {hasPermissionFor("editCompanyDetailsUnderwriters") && (
              <UnderwriterSelect viewer={viewer} organization={organization} />
            )}
          </>
        )}
      </Card>
      {status.status === "error" && (
        <MutationErrorModal message={status.message} onClick={() => setStatus({ status: null })} />
      )}
    </>
  );
}
