import { useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import LenderEligibilities from "admin_portal/company/mortgage/lender_eligibilities";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import LoadingIndicator from "common/core/loading_indicator";

import AdminMortgageCompanyDetailsQuery, {
  type AdminMortgageCompanyDetails_viewer as Viewer,
  type AdminMortgageCompanyDetails_organization_Organization as Organization,
} from "./mortgage_company_details_query.graphql";

type LoadedProps = {
  viewer: Viewer;
  organization: Organization;
};

function MortgageCompanyDetailsLoaded({ viewer, organization }: LoadedProps) {
  return (
    <AppFrameInnerContainer
      title={organization.name}
      subtitle="Manage Lender Eligibilities"
      className="MortageCompanyDetails"
      backPath="goBack"
    >
      <DeprecatedDetailGridSection>
        <LenderEligibilities viewer={viewer} organization={organization} />
      </DeprecatedDetailGridSection>
    </AppFrameInnerContainer>
  );
}

function MortgageCompanyDetails() {
  const { globalID } = useParams();
  const { loading, data } = useQuery(AdminMortgageCompanyDetailsQuery, {
    variables: { organizationId: globalID! },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  const { organization, viewer } = data!;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  return <MortgageCompanyDetailsLoaded viewer={viewer} organization={organization} />;
}

export default MortgageCompanyDetails;
