import "./index.scss";

import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

type Props = {
  mainFilters: ReactNode[];
  additionalFilters: ReactNode[];
  onClear: () => void;
  totalSelected: number;
};

function NotaryFilter({ mainFilters, additionalFilters, totalSelected = 0, onClear }: Props) {
  const [moreFilters, setMoreFilters] = useState(false);
  const toggleMoreFilters = () => setMoreFilters((v) => !v);
  return (
    <div className="NotaryFilterContainer">
      <div className="NotaryFilterBar">
        {mainFilters.map((filter, index) => (
          <div key={index} className="NotaryFilterBar--filter">
            {filter}
          </div>
        ))}
        <Button variant="tertiary" buttonColor="action" onClick={toggleMoreFilters}>
          <FormattedMessage
            id="02869c9a-af92-4d67-8c37-a1f716e291bd"
            defaultMessage="{moreFilters, select, true{Show Less} other{More Filters}}"
            values={{ moreFilters }}
          />
        </Button>
        <Button variant="tertiary" buttonColor="action" disabled={!totalSelected} onClick={onClear}>
          <FormattedMessage
            id="7c18c30a-4b6b-4ea0-bec4-50b55c12b9c7"
            defaultMessage="Clear ({totalSelected})"
            values={{ totalSelected }}
          />
        </Button>
      </div>
      {moreFilters && (
        <div className="NotaryFilterBar">
          {additionalFilters.map((filter, index) => (
            <div key={index} className="NotaryFilterBar--filter">
              {filter}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NotaryFilter;
