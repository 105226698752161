import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

import { useProofReasonCodes, type ProofReasonCode } from "./context";
import Styles from "./proof_reason_codes.module.scss";

export default function ReviewDiffs() {
  const { edits, originals } = useProofReasonCodes();

  // Shouldn't be displaying this in these cases
  if (!originals || Object.keys(edits).length === 0) {
    return (
      <FormattedMessage
        id="4febce3e-86ef-40a8-8f19-a49ce58b6571"
        defaultMessage="There are no edits"
      />
    );
  }

  return (
    <ul className={Styles.diffList}>
      {Object.entries(edits).map(([proofReasonCodeId, edit]) => {
        const original = originals[proofReasonCodeId];
        return (
          <li key={proofReasonCodeId}>
            {originals[proofReasonCodeId].code}
            <ul className={Styles.diffListDiffs}>
              {edit &&
                Object.entries(edit).map(([fieldName, newValue]) => {
                  return (
                    <li key={fieldName}>
                      {fieldName}: {original[fieldName as keyof ProofReasonCode].toString()}{" "}
                      <Icon name="arrow-right" /> {newValue.toString()}
                    </li>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
