import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import { useState, type ReactNode } from "react";

import LoadingIndicator from "common/core/loading_indicator";
import { NetworkStatus, useQuery, useMutation } from "util/graphql";
import { SettingsHeader, SettingsSubtitle, SettingsPageWrapper } from "common/settingsv2/common";
import Button from "common/core/button";
import Table from "common/core/table";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { pushNotification } from "common/core/notification_center/actions";

import { AddPanelModal } from "./add_or_edit_org_panel_modal";
import Styles from "./index.module.scss";
import AdminCompanyPanelsDashQuery, {
  type AdminCompanyPanelsDash_organization_Organization as Organization,
  type AdminCompanyPanelsDash_organization_Organization_panels_edges as PanelEdge,
} from "./index_query.graphql";
import CreatePanelMutation from "./create_panel_mutation.graphql";

const MESSAGES = defineMessages({
  orgPanels: {
    id: "e01fa5b5-69e1-44d6-8a16-276b660332ef",
    defaultMessage: "Panels",
  },
  addedPanel: {
    id: "7afb622b-ce6f-48da-a3b0-48f17d30ccde",
    defaultMessage: "Successfully created panel",
  },
});

function renderColumns(organizationId: string): {
  Header: ReactNode;
  render: (panel: PanelEdge) => ReactNode;
}[] {
  return [
    {
      Header: <FormattedMessage id="fdef0812-ff61-40fe-99f7-63ba395cc4f2" defaultMessage="Name" />,
      render: (panel) => panel.node.name,
    },
    {
      Header: (
        <FormattedMessage id="fdef0812-ff61-40fe-99f7-63ba395cc4f5" defaultMessage="Company" />
      ),
      render: (panel) => panel.node.ownerOrganization?.name,
    },
    {
      Header: (
        <FormattedMessage
          id="fdef0812-ff61-40fe-99f7-63ba395cc4f3"
          defaultMessage="Total Notaries"
        />
      ),
      render: (panel) => panel.node.members.totalCount,
    },
    {
      Header: (
        <FormattedMessage
          id="fdef0812-ff61-40fe-99f7-63ba395cc4f9"
          defaultMessage="Total Meetings"
        />
      ),
      render: (panel) => panel.node.meetingCount,
    },
    {
      Header: (
        <FormattedMessage id="fdef0812-ff61-40fe-99f7-63ba395cc4f4" defaultMessage="Details" />
      ),
      render: (panel) => (
        <Link to={`/companies/${organizationId}/panels/${panel.node.id}`}>
          <FormattedMessage id="f112e681-2ae8-4fd8-a799-b3f8440bc958" defaultMessage="View" />
          <Icon className={Styles.icon} name="caret-right-small" />
        </Link>
      ),
    },
  ];
}

function CompanyPanels(props: { organization: Organization }) {
  const { organization } = props;
  const panelsEdges = organization.panels.edges;
  const intl = useIntl();
  // Limit the organization to one panel, not counting parent org panels
  const creationDisabled = panelsEdges.some(
    (panelEdge) => panelEdge.node.ownerOrganization?.id === organization.id,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const createPanelMutateFn = useMutation(CreatePanelMutation);
  const navigate = useNavigate();

  return (
    <SettingsPageWrapper>
      <div className={Styles.content}>
        <div className={Styles.heading}>
          <div>
            <SettingsHeader title={intl.formatMessage(MESSAGES.orgPanels)} />
            <SettingsSubtitle>
              <FormattedMessage
                id="cb21e913-8110-4ae6-a5bf-569de9427835"
                defaultMessage="Create a list of notaries based on customer criteria to take calls"
              />
            </SettingsSubtitle>
          </div>
          <Button
            onClick={() => setIsModalOpen(true)}
            buttonColor="action"
            variant="primary"
            disabled={creationDisabled}
            disabledHint={
              <FormattedMessage
                id="da1211bf-a458-40f0-bfa7-f4e2000d539b"
                defaultMessage="You can only create one panel per org."
              />
            }
            withIcon={{ name: "add-1", placement: "left" }}
          >
            <FormattedMessage
              id="82a7b8fd-e4db-42b9-b79d-a30d73b71059"
              defaultMessage="Create new"
            />
          </Button>
        </div>
        <Table
          data={panelsEdges}
          columns={renderColumns(organization.id)}
          totalItems={panelsEdges.length}
          rowInteraction={{
            onClick: (panel: PanelEdge) =>
              navigate(`/companies/${organization.id}/panels/${panel.node.id}`),
          }}
        />
      </div>
      {isModalOpen && (
        <AddPanelModal
          organization={organization}
          onCancel={() => setIsModalOpen(false)}
          onSave={async ({ panelName, description, routingExpiry, allowsAssignment }) => {
            await createPanelMutateFn({
              variables: {
                input: {
                  organizationId: organization.id,
                  name: panelName,
                  routingExpiry: routingExpiry ? Number(routingExpiry) : null,
                  description,
                  allowsAssignment,
                },
              },
            });
            pushNotification({ message: intl.formatMessage(MESSAGES.addedPanel) });
            setIsModalOpen(false);
          }}
        />
      )}
    </SettingsPageWrapper>
  );
}

function CompanyPanelsContainer() {
  const { globalID } = useParams();
  const { data, networkStatus } = useQuery(AdminCompanyPanelsDashQuery, {
    variables: { organizationId: globalID! },
  });

  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }
  const { organization } = data!;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  return <CompanyPanels organization={organization} />;
}

export default CompanyPanelsContainer;
