import { type ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { isValid, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

import Table from "common/core/table";
import Link from "common/core/link";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { EmailTemplateNamesEnum, type TransactionEmailTypesEnum } from "graphql_globals";
import { format } from "common/core/format/date";

import type { AdminCompanyDetailsCommunicationSettings_node_Organization as Organization } from "../index.query.graphql";
import type {
  EmailTemplates_EmailTemplate as EmailTemplate,
  EmailTemplates_$$other as DefaultEmailTemplate,
} from "../email_templates.fragment.graphql";
import Styles from "./index.module.scss";
import type { EmailUITypes } from "../types";
import { uiTypeLabelAndSlug } from "../helpers";

type Email = EmailTemplate | DefaultEmailTemplate;

type Props = {
  emails: Email[];
  organization: Organization;
  disabled?: boolean;
  uiType: EmailUITypes;
};

export const EMAIL_LABELS: Record<EmailTemplateNamesEnum, ReactNode> = Object.freeze({
  MORTGAGE_TRANSACTION_INVITATION: (
    <FormattedMessage id="74c37279-f229-4f05-8e25-b7c45f0ec713" defaultMessage="Invitation Email" />
  ),
  MORTGAGE_HYBRID_TRANSACTION_INVITATION: (
    <FormattedMessage id="f97a90cc-b90f-45c2-a213-e9eb6c36e6a0" defaultMessage="Invitation Email" />
  ),
  MORTGAGE_TRANSACTION_RETRIEVAL: (
    <FormattedMessage id="bbc445a5-b01d-4d7a-8c19-2bac9ecd155c" defaultMessage="Completion Email" />
  ),
  MORTGAGE_HYBRID_TRANSACTION_RETRIEVAL: (
    <FormattedMessage id="5c4b3ea0-ff55-4794-a986-fc4fdef3a5bc" defaultMessage="Completion Email" />
  ),
  ORGANIZATION_TRANSACTION_RECALLED: (
    <FormattedMessage id="a4c6e250-38c3-4c95-b904-7008355e630f" defaultMessage="Recalled Email" />
  ),
  ORGANIZATION_HYBRID_TRANSACTION_RECALLED: (
    <FormattedMessage id="91f7975b-b658-4124-b289-81077f9483cc" defaultMessage="Recalled Email" />
  ),
  MORTGAGE_TRANSACTION_REINVITATION: (
    <FormattedMessage
      id="3772f5c6-728b-4f5a-9ad8-78a043247836"
      defaultMessage="Reinvitation Email"
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_REINVITATION: (
    <FormattedMessage
      id="3c296b40-e935-4cbb-9680-e874730dd2ce"
      defaultMessage="Reinvitation Email"
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_REMINDER: (
    <FormattedMessage id="e9d4a28f-51dd-42c0-a73a-530acc2936da" defaultMessage="Reminder Email" />
  ),
  ORGANIZATION_TRANSACTION_INVITATION: (
    <FormattedMessage id="3f1edd9f-a12b-4d2b-8f15-9cd5a8f8f9d6" defaultMessage="Invitation Email" />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_REGENERATED_ACCESS_LINK: (
    <FormattedMessage
      id="f07ec636-2093-49f6-b206-6730c3fb2c59"
      defaultMessage="Regenerated Access Link Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_MEETING_STARTED_NOTIFICATION: (
    <FormattedMessage
      id="1900bbf8-80f6-4a5a-a89c-f6e6aedbb769"
      defaultMessage="Meeting Started Notification Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_COMPLETED_NOTIFICATION: (
    <FormattedMessage
      id="78307b12-62ea-40d7-ace4-0dd246b2ebb9"
      defaultMessage="Transaction Completed Email"
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_COMPLETED_NOTIFICATION: (
    <FormattedMessage
      id="9b827538-c673-442f-a34d-427f856847ce"
      defaultMessage="Transaction Completed Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_REMINDER: (
    <FormattedMessage
      id="edfbd74b-0ebc-4ef7-8fb4-68ca554967ea"
      defaultMessage="Transaction Reminder Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_RESENT_NOTIFICATION: (
    <FormattedMessage
      id="d5e6fdbb-f9ac-4363-854a-4e0f4457c44d"
      defaultMessage="Resent Notification Email"
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_RESENT_NOTIFICATION: (
    <FormattedMessage
      id="1b92de57-6457-43a5-8877-6f46877ff964"
      defaultMessage="Resent Notification Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_SENT_NOTIFICATION: (
    <FormattedMessage
      id="dee63a55-3a5d-48e8-b8ea-c2942e946b2b"
      defaultMessage="Sent Notification Email"
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_SENT_NOTIFICATION: (
    <FormattedMessage
      id="b040c108-e8d1-429e-bb98-a7d8cac12016"
      defaultMessage="Sent Notification Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_WET_SIGN_REMINDER: (
    <FormattedMessage
      id="440f4e74-45f2-45dc-bc36-9b2a951b59be"
      defaultMessage="Wet Sign Reminder Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_WET_SIGN_DOCUMENTS_UPDATED: (
    <FormattedMessage
      id="a110fa2d-c407-4acc-8af4-3f8f3383479a"
      defaultMessage="Wet Sign Documents Updated Email"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_WET_SIGN_COMPLETED: (
    <FormattedMessage
      id="c567eaa4-d82e-4b0b-9af2-d24bc2ec1e7d"
      defaultMessage="Wet Sign Documents Completed"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_SEND_TO_TITLE_AGENCY: (
    <FormattedMessage
      id="2fa810ee-4cb3-4b58-9abe-f5f3e3d47ec9"
      defaultMessage="Collab: Send To Title Agency Email"
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_DOCUMENTS_AVAILABLE: (
    <FormattedMessage
      id="4fa37360-15b1-4d99-bfb2-90efbe4499da"
      defaultMessage="Collab: Documents Available Email"
    />
  ),
  MORTGAGE_TRANSACTION_ALREADY_VERIFIED_EMAIL: (
    <FormattedMessage
      id="c070859e-b53b-4b14-a46b-1bb83cbe4d39"
      defaultMessage="Collab: Already Verified Email"
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_UPDATED: (
    <FormattedMessage
      id="faabe91e-9012-47fe-bea9-a0b9fc9fac77"
      defaultMessage="Collab: Documents Update"
    />
  ),
  ORGANIZATION_TRANSACTION_RETRIEVAL: (
    <FormattedMessage id="350e9864-e22a-43b4-84b1-b558be943d0d" defaultMessage="Retrieval Email" />
  ),
  ORGANIZATION_ESIGN_ONLY_TRANSACTION_RETRIEVAL: (
    <FormattedMessage id="c3011e5b-42ee-40f8-985e-acf1dd3625d5" defaultMessage="Retrieval Email" />
  ),
  SIGNER_RECEIPT: (
    <FormattedMessage id="ad13a243-6049-4911-8f0b-598d862d15cc" defaultMessage="Receipt" />
  ),
  [EmailTemplateNamesEnum.SIGNER_PAYMENT_REMINDER]: (
    <FormattedMessage
      id="238feb00-8eda-47ec-b1ab-bc0c90485a93"
      defaultMessage="Signer Payment Reminder"
    />
  ),
  TITLE_AGENCY_SENT_TO_SIGNER_CONFIRMATION: (
    <FormattedMessage
      id="72b0de82-0fbf-4138-b644-dc124ab2e4a3"
      defaultMessage="Sent To Signer Confirmation Email (collab)"
    />
  ),
  TITLE_AGENCY_PLACE_AN_ORDER_CONFIRMATION: (
    <FormattedMessage
      id="7a8c77a6-afe1-436c-ad6e-bbf56179b571"
      defaultMessage="Place An Order Confirmation Email (collab)"
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_SENT: (
    <FormattedMessage
      id="c6364974-7382-4a73-b701-977a0ea8e945"
      defaultMessage="Sent To Signer Email (collab)"
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_RESENT: (
    <FormattedMessage
      id="ec2ccb4d-9e03-46eb-8212-be585f663a91"
      defaultMessage="Resent To Signer Email (collab)"
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_RECALLED: (
    <FormattedMessage
      id="3a2134d7-d9ad-4bc7-a9ec-62f9a913f982"
      defaultMessage="Recalled Email (collab)"
    />
  ),
  ORGANIZATION_TRANSACTION_SIGNING_RESCHEDULED_NOTIFICATION: (
    <FormattedMessage
      id="8b5a8c2c-c768-480c-b8aa-95559f884fc4"
      defaultMessage="Signing Rescheduled Email"
    />
  ),
  INVITE_CREDIBLE_WITNESS: (
    <FormattedMessage
      id="28e8c4db-094c-4fcc-8e95-46ffe8df8b4c"
      defaultMessage="Credible Witness Invitation"
    />
  ),
  CREDIBLE_WITNESS_NOTIFICATION: (
    <FormattedMessage
      id="9b4836d8-27c2-4872-b8bc-e7b5d4d18609"
      defaultMessage="Credible Witness Notification"
    />
  ),
  [EmailTemplateNamesEnum.SHARED_RETRIEVAL]: (
    <FormattedMessage id="c1e01818-a98f-4ed1-a6ff-76c76130bb06" defaultMessage="Shared Retrieval" />
  ),
  [EmailTemplateNamesEnum.RETRIEVAL]: (
    <FormattedMessage id="f45e504a-43c5-4799-8470-cfa544ca62df" defaultMessage="Retrieval" />
  ),
  [EmailTemplateNamesEnum.SIGNER_NOTIFY_OTHER_SIGNERS]: (
    <FormattedMessage
      id="13615c6e-b05b-48a5-82a7-0615cb748b8f"
      defaultMessage="Notify Other Signers Email"
    />
  ),
  [EmailTemplateNamesEnum.RETAIL_REFERRAL_INVITATION]: (
    <FormattedMessage
      id="ff103f5f-919a-4b7d-b861-cc23b6a4482c"
      defaultMessage="Retail Referral Email"
    />
  ),
  [EmailTemplateNamesEnum.IDENTIFY_TRANSACTION_RETRIEVAL]: (
    <FormattedMessage
      id="38529b27-0524-4a86-b540-28c76fd134cd"
      defaultMessage="Identify Retrieval Email"
    />
  ),
  [EmailTemplateNamesEnum.IDENTIFY_TRANSACTION_INVITATION]: (
    <FormattedMessage
      id="38529b27-0524-4a86-b540-28c76fd134cd"
      defaultMessage="Identify Invitation Email"
    />
  ),
});

const EMAIL_DESCRIPTIONS: Record<EmailTemplateNamesEnum, ReactNode> = Object.freeze({
  MORTGAGE_TRANSACTION_INVITATION: (
    <FormattedMessage
      id="727ce3d4-885e-439f-bf00-e5bbe5ee7094"
      defaultMessage="The initial email sent to signers for full online transactions."
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_INVITATION: (
    <FormattedMessage
      id="f0b34dc8-441b-44a9-81ee-f3dc4fe141f5"
      defaultMessage="The initial email sent to signers for hybrid transactions."
    />
  ),
  MORTGAGE_TRANSACTION_RETRIEVAL: (
    <FormattedMessage
      id="40bc6024-11d7-474a-bc25-27a42f9a1736"
      defaultMessage="Sent to signers when all signing is completed for a full online transaction."
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_RETRIEVAL: (
    <FormattedMessage
      id="4868760a-8571-4bac-b6f5-69eab257b359"
      defaultMessage="Sent to signers when all signing is completed for a hybrid transaction."
    />
  ),
  ORGANIZATION_TRANSACTION_RECALLED: (
    <FormattedMessage
      id="f7845a96-3045-44c0-ab88-2c95db2d00eb"
      defaultMessage="Sent to signers when a full online transaction is recalled."
    />
  ),
  ORGANIZATION_HYBRID_TRANSACTION_RECALLED: (
    <FormattedMessage
      id="67ce8f9c-59be-4911-b879-062e0dc984e0"
      defaultMessage="Sent to signers when a hybrid transaction is recalled."
    />
  ),
  MORTGAGE_TRANSACTION_REINVITATION: (
    <FormattedMessage
      id="cb789621-d306-4840-a398-46e12d6a62c0"
      defaultMessage="The new email sent to signers upon resend of a recalled full online transaction."
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_REINVITATION: (
    <FormattedMessage
      id="0e52dc2c-ebb9-43a1-af5a-f3f23b73c022"
      defaultMessage="The new email sent to signers upon resend of a recalled hybrid transaction."
    />
  ),
  MORTGAGE_HYBRID_TRANSACTION_REMINDER: (
    <FormattedMessage
      id="20cfd0db-408e-461c-8f86-19a3700da768"
      defaultMessage="Reminder email sent to signers an hour before closing for hybrid transactions."
    />
  ),
  ORGANIZATION_TRANSACTION_INVITATION: (
    <FormattedMessage
      id="cb28108d-fe23-4cbc-ba40-e382cdc8ed05"
      defaultMessage="The initial email sent to signers for a transaction."
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_REGENERATED_ACCESS_LINK: (
    <FormattedMessage
      id="97df10bb-c67c-4fb2-a7a8-fb1fc65778d9"
      defaultMessage="Regenerated access link email for hybrid access portal."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_MEETING_STARTED_NOTIFICATION: (
    <FormattedMessage
      id="c123421e-fc33-4db3-9374-3667d4cda310"
      defaultMessage="Email sent to contacts when notary meeting started."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_COMPLETED_NOTIFICATION: (
    <FormattedMessage
      id="279dc6ce-e30a-4f3b-99af-8f1f459b3b7f"
      defaultMessage="Sent to contacts when all signing is completed for a full online transaction."
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_COMPLETED_NOTIFICATION: (
    <FormattedMessage
      id="b079e014-2f07-4351-b097-1ef5eae25d2d"
      defaultMessage="Sent to contacts when all signing is completed for a hybrid transaction."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_REMINDER: (
    <FormattedMessage
      id="513e0496-4093-475f-acfd-23091e5414ab"
      defaultMessage="Reminder sent to contacts when signing is incomplete for a hybrid transaction."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_RESENT_NOTIFICATION: (
    <FormattedMessage
      id="195561f1-6c5f-496b-8e85-d9ef73fc4434"
      defaultMessage="Sent to contacts when a full online transaction is resent after recall."
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_RESENT_NOTIFICATION: (
    <FormattedMessage
      id="23e0b971-bc24-46a3-8cc7-889dcfabd159"
      defaultMessage="Sent to contacts when a hybrid transaction is resent after recall."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_SENT_NOTIFICATION: (
    <FormattedMessage
      id="8361d94a-da5f-49c5-9836-c6e630baeccf"
      defaultMessage="Sent to contacts when a full online transaction is sent."
    />
  ),
  MORTGAGE_ORG_HYBRID_TRANSACTION_CONTACT_TRANSACTION_SENT_NOTIFICATION: (
    <FormattedMessage
      id="55da72cd-e931-4b59-9766-0042dfad846a"
      defaultMessage="Sent to contacts when a full online transaction is sent."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_CONTACT_TRANSACTION_WET_SIGN_REMINDER: (
    <FormattedMessage
      id="efc5e5f0-56af-4a59-83cf-125dd58bbcbe"
      defaultMessage="Sent to contacts as a reminder for hybrid transactions (w/ eSign Complete) that have wet-sign upload enabled on the lender."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_WET_SIGN_DOCUMENTS_UPDATED: (
    <FormattedMessage
      id="7854081a-5327-4627-884f-2c6ba991e5cc"
      defaultMessage="Sent to contacts for hybrid transactions that have had wet-sign documents updated"
    />
  ),
  MORTGAGE_ORG_TRANSACTION_WET_SIGN_COMPLETED: (
    <FormattedMessage
      id="f5c6b320-3978-4ba0-baa1-91980b1b8bc0"
      defaultMessage="Sent to contacts for hybrid transactions after all eSign documents have been signed but have wet-sign documents left."
    />
  ),
  MORTGAGE_ORG_TRANSACTION_SEND_TO_TITLE_AGENCY: (
    <FormattedMessage
      id="87e4ec1e-779f-4377-adb1-dc498241d667"
      defaultMessage="Sent to title collaborator requesting the addition of title documents."
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_DOCUMENTS_AVAILABLE: (
    <FormattedMessage
      id="9a2594d2-df15-4270-80b9-32da949b8e9f"
      defaultMessage="Sent to title collaborator after being added to a collab transaction."
    />
  ),
  TITLE_AGENCY_SENT_TO_SIGNER_CONFIRMATION: (
    <FormattedMessage
      id="28613d06-7cf7-47a4-a3c6-b90017f54b7c"
      defaultMessage="Confirmation email sent to title contact after Title agency sends collab transaction to signer."
    />
  ),
  TITLE_AGENCY_PLACE_AN_ORDER_CONFIRMATION: (
    <FormattedMessage
      id="3472fa4c-9cd9-4900-b1a1-85573f9ffc85"
      defaultMessage="Confirmation email sent to title contact after Title agency places an order on collab transaction."
    />
  ),
  MORTGAGE_TRANSACTION_ALREADY_VERIFIED_EMAIL: (
    <FormattedMessage
      id="f3d86d6f-e52e-46c4-909e-ebcecc6c6b46"
      defaultMessage="In the nononboarded title flow, sent to title contact if the contact is already verified."
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_UPDATED: (
    <FormattedMessage
      id="d0453737-81e5-48fa-a612-3c63b432ec4e"
      defaultMessage="Sent to title contact after collab transaction has been resent upon recall."
    />
  ),
  ORGANIZATION_TRANSACTION_RETRIEVAL: (
    <FormattedMessage
      id="03e69a4c-aae0-4953-bdea-a31a6c13ee65"
      defaultMessage="The initial email sent to signers for full online transactions."
    />
  ),
  ORGANIZATION_ESIGN_ONLY_TRANSACTION_RETRIEVAL: (
    <FormattedMessage
      id="665e0514-151d-4959-9827-9b4e656878f6"
      defaultMessage="Sent to signers when all signing is completed for esign-only transaction."
    />
  ),
  SIGNER_RECEIPT: (
    <FormattedMessage
      id="5059bbe4-3806-4ba0-95fb-6b5eee68ada3"
      defaultMessage="Receipt sent to signers after payment."
    />
  ),
  [EmailTemplateNamesEnum.SIGNER_PAYMENT_REMINDER]: (
    <FormattedMessage
      id="17682fdc-5b7b-4bf6-8368-2f9a32ebfde6"
      defaultMessage="Payment reminder sent to signers after transaction completion."
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_SENT: (
    <FormattedMessage
      id="4c22e441-762b-4ab7-b9f1-ca2b6a3aca4b"
      defaultMessage="Sent to title contact after hybrid transaction sent."
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_RESENT: (
    <FormattedMessage
      id="5722e912-20df-4190-a2ce-0ab7a4e3e47f"
      defaultMessage="Sent to title contact after transaction resent after recall."
    />
  ),
  MORTGAGE_TRANSACTION_COLLABORATION_RECALLED: (
    <FormattedMessage
      id="f17f6414-6f14-4f9d-a53a-455aef2362e1"
      defaultMessage="Sent to title contact after full online transaction recalled."
    />
  ),
  ORGANIZATION_TRANSACTION_SIGNING_RESCHEDULED_NOTIFICATION: (
    <FormattedMessage
      id="f5077dae-8033-46f8-b67e-7c362e0f6104"
      defaultMessage="Sent to signers when the signing has been rescheduled for an active transaction"
    />
  ),
  INVITE_CREDIBLE_WITNESS: (
    <FormattedMessage
      id="c641467f-a05c-443b-8725-2c4382e63c6f"
      defaultMessage="Sent to credible witness to join meeting when signer has started their meeting with a notary"
    />
  ),
  CREDIBLE_WITNESS_NOTIFICATION: (
    <FormattedMessage
      id="399bb2af-6ffe-4949-a00c-54033ca7d578"
      defaultMessage="Notifies credible witness that they were added as part of a transaction"
    />
  ),
  [EmailTemplateNamesEnum.SHARED_RETRIEVAL]: (
    <FormattedMessage
      id="f89dde13-bddf-450a-8875-cbbcf23b7dea"
      defaultMessage="Sent when signer shares completed documents (to access in verify portal)"
    />
  ),
  [EmailTemplateNamesEnum.RETRIEVAL]: (
    <FormattedMessage
      id="721a4c6c-c7d6-4fde-88fe-17d3c54844f3"
      defaultMessage="Sent to signers after completing retail transaction"
    />
  ),
  [EmailTemplateNamesEnum.SIGNER_NOTIFY_OTHER_SIGNERS]: (
    <FormattedMessage
      id="96f4f6eb-f704-4c12-83bc-7889cd89283a"
      defaultMessage="Notifies signers with incomplete documents that other signers have completed their portion of the documents"
    />
  ),
  [EmailTemplateNamesEnum.RETAIL_REFERRAL_INVITATION]: (
    <FormattedMessage
      id="c4d4f834-05c8-4218-b9cb-9be3bb8a9de2"
      defaultMessage="The referral email containing the referral link sent to the signer to start a transaction."
    />
  ),
  [EmailTemplateNamesEnum.IDENTIFY_TRANSACTION_RETRIEVAL]: (
    <FormattedMessage
      id="c4d4f834-05c8-4218-b9cb-9be3bb8a9de2"
      defaultMessage="Sent to signers after completing a retail Identify transaction."
    />
  ),
  [EmailTemplateNamesEnum.IDENTIFY_TRANSACTION_INVITATION]: (
    <FormattedMessage
      id="c4d4f834-05c8-4218-b9cb-9be3bb8a9de2"
      defaultMessage="The initial email sent to signers for an Identify transaction."
    />
  ),
});

const EMAIL_TYPE_COPY: Record<TransactionEmailTypesEnum, ReactNode> = Object.freeze({
  HYBRID: <FormattedMessage id="770c411c-cd30-49a3-81cf-890c12b4dd53" defaultMessage="Hybrid" />,
  ECLOSE: <FormattedMessage id="b31efa01-1788-4b19-af32-99877cbf8f36" defaultMessage="eClose" />,
  ESIGN: <FormattedMessage id="ca801626-f805-47f9-9ff8-43466c0457a4" defaultMessage="eSign" />,
  NOTARIZATION: (
    <FormattedMessage id="74069027-3f76-45da-abf9-6841fafeae4e" defaultMessage="Notarization" />
  ),
});

const NameColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="705b719c-70a7-4464-8fcf-22f6b3e0b426" defaultMessage="Email Name" />
    </span>
  ),
  render: (email: Email) => {
    const { name } = email;
    return (
      <span>
        {EMAIL_LABELS[name]}
        {email.__typename === "EmailTemplate" && (
          <div className={Styles.modifiedPill}>
            <FormattedMessage id="87beeb63-b176-46b7-9675-ac59d0cf813a" defaultMessage="Modified" />
          </div>
        )}
      </span>
    );
  },
  collapse: false,
});

const TypeColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="843b503c-050c-4b98-b42c-a01933f6743b" defaultMessage="Type" />
    </span>
  ),
  render: ({ type }: Email) => type && EMAIL_TYPE_COPY[type],
  collapse: true,
});

const DescriptionColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="1fe789f3-0b30-47f1-a117-e16833e4d3d2" defaultMessage="Description" />
    </span>
  ),
  render: ({ name }: Email) => (
    <Tooltip
      target={
        <span className={Styles.descriptionTooltipIcon}>
          <Icon name="question" />
        </span>
      }
      placement="top"
      className={Styles.descriptionTooltip}
    >
      <span>{EMAIL_DESCRIPTIONS[name]}</span>
    </Tooltip>
  ),
  collapse: true,
});

const LastModifiedColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="e3c4c76f-5a44-4a99-af77-d091b2335314" defaultMessage="Last Modified" />
    </span>
  ),
  render: ({ updatedAt }: Email) =>
    updatedAt &&
    isValid(parseISO(updatedAt)) &&
    format({ value: parseISO(updatedAt), formatStyle: "LL/dd/yyyy h:mm aa" }),
  collapse: true,
});

const ModifiedByColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="234332f4-280d-4e1b-a9d6-d1adcc2bbb84" defaultMessage="Modified By" />
    </span>
  ),
  render: ({ updatedBy }: Email) => updatedBy,
  collapse: true,
});

const EditColumn = ({
  disabled,
  onEditClick,
}: {
  disabled: boolean;
  onEditClick: (email: Email) => void;
}) =>
  Object.freeze({
    Header: (
      <span>
        <FormattedMessage id="efe275f3-1ddc-4895-8653-afa09f7a6459" defaultMessage="Edit" />
      </span>
    ),
    render: (email: Email) => (
      <Link
        onClick={
          disabled
            ? () => {}
            : () => {
                onEditClick(email);
              }
        }
      >
        <FormattedMessage id="7b6da24f-65fd-4cd0-8737-82579aab66c9" defaultMessage="Edit" />
      </Link>
    ),
    collapse: true,
  });

export default function CommunicationSettingsEmailTable({
  organization,
  emails,
  disabled = false,
  uiType,
}: Props) {
  const { uiTypeSlug } = useMemo(() => uiTypeLabelAndSlug(uiType), [uiType]);
  const navigate = useNavigate();
  const handleEditClick = useCallback(
    (email: Email) => {
      navigate(
        `/companies/${organization.id}/communication-settings/${uiTypeSlug}-emails/${email.name}/edit`,
      );
    },
    [organization],
  );
  const columns = useMemo(
    () => [
      NameColumn,
      TypeColumn,
      DescriptionColumn,
      LastModifiedColumn,
      ModifiedByColumn,
      EditColumn({ disabled, onEditClick: handleEditClick }),
    ],
    [],
  );
  return (
    <div className={Styles.table}>
      <Table data={emails} columns={columns} totalItems={emails.length} />
    </div>
  );
}
