import "./index.scss";

import { useState } from "react";

import Button from "common/core/button";

import Header from "./header";
import Dashboard from "./dashboard";
import NewModal from "./new_modal";

export default function Experiments() {
  const [isNewModalOpen, setNewModalOpen] = useState(false);

  const actionButton = (
    <Button
      buttonColor="action"
      variant="primary"
      onClick={() => {
        setNewModalOpen(true);
      }}
    >
      New
    </Button>
  );
  return (
    <div className="AdminExperiments">
      <Header title="Experiments" actionButton={actionButton} />
      <Dashboard />
      {isNewModalOpen && (
        <NewModal
          onClose={() => {
            setNewModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
