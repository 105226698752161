import { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

export default class MergeModal extends Component {
  render() {
    const { onMerge, onClose } = this.props;

    return (
      <IllustrationModal
        className="MergeAccountMergeModal"
        title={
          <FormattedMessage
            id="d652b12d-363f-40eb-a58b-e82eed63111b"
            defaultMessage="Merge this account?"
          />
        }
        buttons={[
          <Button
            buttonColor="danger"
            variant="primary"
            key="merge"
            onClick={onMerge}
            automationId="merge-account-confirm-button"
          >
            <FormattedMessage id="5e5dd497-053f-46c2-972d-d5e1104c4e23" defaultMessage="Merge" />
          </Button>,
          <Button
            buttonColor="dark"
            variant="tertiary"
            key="cancel"
            onClick={onClose}
            automationId="merge-account-cancel-button"
          >
            <FormattedMessage id="af129d7f-dd38-459b-be8e-47915fb4c8f9" defaultMessage="Cancel" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="38cfbcbb-4e47-47b2-a709-c00aa98ec3c9"
          defaultMessage="This cannot be undone in the Admin Portal. An incident alert will have to be filed."
        />
      </IllustrationModal>
    );
  }
}

MergeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
};
