import { useParams, Navigate } from "react-router-dom";
import { useState } from "react";

import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { SettingsPageWrapper } from "common/settingsv2/common";

import EncryptionKeysForOrganization, {
  type EncryptionKeysForOrganization_organization_Organization as Organization,
} from "./encryption_keys_query.graphql";
import MasterKeys from "./master_keys_page";
import DataKeys from "./data_keys_page";

function AdminCompanyDetailsDataSecurity() {
  const { globalID } = useParams();
  const [selectedMasterKeyArn, setSelectedMasterKeyArn] = useState<null | string>(null);
  const { data, loading } = useQuery(EncryptionKeysForOrganization, {
    variables: {
      organizationId: globalID!,
    },
  });
  const organization = data?.organization as Organization | null;

  if (organization?.id !== organization?.rootOrganizationId) {
    return <Navigate to={`/companies/${globalID}/details`} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const encryptionKeys = organization?.encryptionKeys;
  const selectedMasterKey =
    selectedMasterKeyArn && encryptionKeys?.find((key) => key.kmsArn === selectedMasterKeyArn);

  return (
    <SettingsPageWrapper>
      {selectedMasterKey ? (
        <DataKeys
          onClose={() => setSelectedMasterKeyArn(null)}
          masterKey={selectedMasterKey}
          organizationId={globalID as string}
        />
      ) : (
        <MasterKeys
          masterKeys={encryptionKeys || []}
          onDataKeysSelect={setSelectedMasterKeyArn}
          organizationId={globalID as string}
        />
      )}
    </SettingsPageWrapper>
  );
}

export default AdminCompanyDetailsDataSecurity;
