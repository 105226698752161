import "./index.scss";

import { type ChangeEvent, type KeyboardEvent, PureComponent } from "react";
import { FormattedMessage, defineMessages, useIntl, type IntlShape } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { addHours, isPast } from "date-fns";

import { UserRole } from "graphql_globals";
import { captureException } from "util/exception";
import { withMinDelay } from "util/rxjs";
import Card from "common/card";
import Link from "common/core/link";
import { StyledTextInput } from "common/form/inputs/text";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { StandardTable } from "common/table";
import TableRow from "common/table/row";
import { MailtoLink } from "common/core/mailto_link";
import { addError } from "redux/actions/errors";
import store from "redux/store";
import { useMutation, type ApolloCache } from "util/graphql";
import { QueryWithLoading, isGraphQLError } from "util/graphql/query";
import BanUserMutation, {
  type BanUser,
  type BanUserVariables,
} from "util/apollo_graphql/mutations/ban_user/mutation.graphql";
import UnbanUserMutation, {
  type UnbanUser,
  type UnbanUserVariables,
} from "util/apollo_graphql/mutations/unban_user/mutation.graphql";
import { useTagUserMutation } from "common/user/tag";
import { useRetireSignerIdentities, type Options } from "common/signer/identity/retire";
import { userFullName } from "util/user";
import { ERROR_TYPES } from "constants/errors";
import {
  type UserPermissions,
  type AdminPermissions,
  type OrganizationPermissions,
  usePermissions,
} from "common/core/current_user_role";
import AlertMessage from "common/core/alert_message";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import WorkflowModal from "common/modals/workflow_modal";
import { CAPACITY_TYPE_LABELS } from "admin_portal/notaries/util";
import { LegalTeamEmailEdit } from "admin_portal/common/legal_team_email_edit";
import { IconButton } from "common/core/button/icon_button";
import RevokeUserCertMutation, {
  type RevokeCertificate,
  type RevokeCertificateVariables,
} from "common/settingsv2/sidebar_settings/profile/proof_certificate/certificate/revoke_certificate_mutation.graphql";
import type { TagUser, TagUserVariables } from "common/user/tag/tag_user_mutation.graphql";

import BanUserModal from "./ban_user_modal";
import RevokeUserCertModal from "./revoke_user_cert_modal";
import UserQuery, {
  type User as UserType,
  type User_user_User as User,
  type User_user_User_documentBundlesAsCustomer_meetings_edges as Meeting,
} from "./user_query.graphql";
import ForceLogoutUserMutation, {
  type ForceLogoutUser,
  type ForceLogoutUserVariables,
} from "./force_logout_user.graphql";
import MfaResetMutation, { type MfaReset, type MfaResetVariables } from "./mfa_reset.graphql";
import DisableUserMutation, {
  type DisableUser,
  type DisableUserVariables,
} from "./disable_user.graphql";
import EnableUserMutation, {
  type EnableUser,
  type EnableUserVariables,
} from "./enable_user.graphql";
import DeleteBundleMutation, {
  type DeleteDocumentBundle,
  type DeleteDocumentBundleVariables,
} from "./delete_document_bundle.graphql";

const TTL_TAG_HOURS = 72;

const CX = "AdminUserDetails";

const defaultTitle = (
  <FormattedMessage
    id="3f28547d-9181-41f4-8e63-de7a212a1c32"
    defaultMessage="User Details (no name provided)"
  />
);
const idTitle = <FormattedMessage id="5331f3cf-e9f9-4457-8fb1-5fddfaa8cb25" defaultMessage="ID" />;
const gidTitle = (
  <FormattedMessage id="9fdab0db-0158-4936-8a3c-3ecf3c9c77f6" defaultMessage="GID" />
);
const emailTitle = (
  <FormattedMessage id="b0d9a96a-f578-4e11-b368-89b8045c97e3" defaultMessage="Email" />
);
const organizationIdTitle = (
  <FormattedMessage id="ab0e987d-6f4d-414c-9411-4f47418dd551" defaultMessage="Organization ID" />
);
const organizationNameTitle = (
  <FormattedMessage id="57242360-1ef2-46f7-8ad1-a971c6941a92" defaultMessage="Organization Name" />
);
const rolesTitle = (
  <FormattedMessage id="fc0d4cca-476a-4c65-9b29-d13bec3bfeaa" defaultMessage="Roles" />
);
const historyTitle = (
  <FormattedMessage id="dbc1ebb5-527a-475e-8d65-2a4a782f007d" defaultMessage="History" />
);
const transactionsTitle = (
  <FormattedMessage id="6fc450ea-8271-4687-b709-303e16dc5ad6" defaultMessage="Transactions" />
);
const profileIdTitle = (
  <FormattedMessage id="beff97f3-bb5c-4bc8-ab69-16f9df4a05a1" defaultMessage="Profile ID" />
);
const activeTierTitle = (
  <FormattedMessage id="c01c7952-57cd-44c1-ba03-9d32b20e828d" defaultMessage="Active Tier" />
);
const tagsTitle = (
  <FormattedMessage id="4e904111-1fae-4076-b5c1-c70139cab0a1" defaultMessage="Tags" />
);
const tagsNone = (
  <FormattedMessage id="7a363da9-c59b-4606-9e24-d984bd68a67b" defaultMessage="user has no tags" />
);
const tagsInfo = (
  <FormattedMessage
    id="fae5ed66-2545-4c7e-ac9f-d5077eb02e03"
    defaultMessage="Tags you add will last for {tagLife} hours"
    values={{ tagLife: TTL_TAG_HOURS }}
  />
);
const tagsLink = (
  <FormattedMessage
    id="27f64915-3005-49e3-99ea-371161259662"
    defaultMessage="List of useful tags"
  />
);
const addTagButton = (
  <FormattedMessage id="5a69dada-c60d-4f91-b7e6-80e41eeff89c" defaultMessage="Add" />
);
const signerIdentitiesTitle = (
  <FormattedMessage id="7800a8e8-6ff8-4bb1-a0f2-02a745e611bb" defaultMessage="Signer Identities" />
);
const signerIdentitiesInfo = (
  <div className={`${CX}--button--description`}>
    <FormattedMessage
      id="0e9d4b7a-ed4c-4a3a-a2aa-d0994fd06d98"
      defaultMessage="Expire this user's signer identities."
    />
  </div>
);
const signerIdentitiesButton = (
  <FormattedMessage id="01b445cd-9a64-4736-9dfe-f8a90bf40d6f" defaultMessage="Expire" />
);
const backButton = (
  <FormattedMessage id="632de3ee-cc8e-4100-ad40-18cd086474e2" defaultMessage="Back" />
);

const messages = defineMessages({
  addTagPlaceholder: {
    id: "18a07547-cae8-4fcb-8e54-d23ba1dd1cda",
    defaultMessage: "Enter tag",
  },
  genericError: {
    id: "0f3c3378-567d-48dc-9629-41eb9513d977",
    defaultMessage: "Something went wrong",
  },
  accountEnabled: {
    id: "42705b48-e8a6-4e0a-92ec-4f74ea4cd845",
    defaultMessage: "Account enabled!",
  },
  accountDisabled: {
    id: "6a59cc73-74c4-4501-96c1-06e475003920",
    defaultMessage: "Account disabled!",
  },
  accountClosed: {
    id: "4a295eaa-2581-4d1a-8914-6ffbcb681f17",
    defaultMessage: "Account closed!",
  },
  documentsDeleted: {
    id: "aa81db59-42da-48d4-9aac-ca47269e23b7",
    defaultMessage: "Documents deleted!",
  },
  mfaReset: {
    id: "a0d7d4d0-0c9a-4f6b-9d2f-1a9e6b0e8b1e",
    defaultMessage: "MFA Reset successful!",
  },
  mfaResetError: {
    id: "92887da8-a9b4-4374-b43d-47b6f3c36e71",
    defaultMessage: "MFA Reset failed!",
  },
  emailMfaResetError: {
    id: "11c6e69c-9b67-4e00-9626-e838f0a9df05",
    defaultMessage:
      "Cannot reset email authentication. Update email directly once completely vetted.",
  },
  tryAgain: {
    id: "e09b5e15-f000-4711-97a0-f955e8d46e25",
    defaultMessage: "Try again",
  },
  signerIdentityNotFound: {
    id: "b1995ed2-5cb7-43a9-ad26-5a4f18e9ce75",
    defaultMessage: "Unable to ban, user has not yet gone through KBA.",
  },
  deleteDocuments: {
    id: "40017e49-6981-4bb8-bb81-3b8d53cdaa30",
    defaultMessage: "Delete documents from transaction",
  },
  certificateRevoked: {
    id: "2cb9c930-9d67-4a2c-95e8-80f499bb0056",
    defaultMessage: "Certificate revoked!",
  },
});

type Props = {
  user: User;
  permissions: {
    hasPermissionFor: (
      permission: UserPermissions | AdminPermissions | OrganizationPermissions,
    ) => boolean;
  };

  // GraphQL
  banUserMutateFn: ReturnType<typeof useMutation<BanUser, BanUserVariables>>;
  unbanUserMutateFn: ReturnType<typeof useMutation<UnbanUser, UnbanUserVariables>>;
  tagUserMutateFn: ReturnType<typeof useMutation<TagUser, TagUserVariables>>;
  retireSignerIdentitiesMutateFn: (options?: Options) => Promise<unknown>;
  forceLogoutUserMutateFn: ReturnType<
    typeof useMutation<ForceLogoutUser, ForceLogoutUserVariables>
  >;
  mfaResetMutateFn: ReturnType<typeof useMutation<MfaReset, MfaResetVariables>>;
  revokeUserCertMutateFn: ReturnType<
    typeof useMutation<RevokeCertificate, RevokeCertificateVariables>
  >;
  disableUserMutateFn: ReturnType<typeof useMutation<DisableUser, DisableUserVariables>>;
  enableUserMutateFn: ReturnType<typeof useMutation<EnableUser, EnableUserVariables>>;
  deleteBundleMutateFn: ReturnType<
    typeof useMutation<DeleteDocumentBundle, DeleteDocumentBundleVariables>
  >;

  // injectIntl
  intl: IntlShape;
  navigate: ReturnType<typeof useNavigate>;
};

type State = {
  tagToAdd: string;
  addingTag: boolean;
  showExperimentGroups: boolean;
  retiringSignerIdentities: boolean;
  retiredSignerIdentities: boolean;
  banningUser: boolean;
  togglingUserEnabledStatus: boolean;
  deletingUser: boolean;
  deletingUserPending: boolean;
  deletingBundle: boolean;
  deletingBundlePending: boolean;
  bundleIdToDelete: string;
  forceLoggingOut: boolean;
  mfaReset: boolean;
  revokingUserCert: boolean;
};

class AdminUserDetails extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tagToAdd: "",
      addingTag: false,
      showExperimentGroups: false,
      retiringSignerIdentities: false,
      retiredSignerIdentities: false,
      banningUser: false,
      togglingUserEnabledStatus: false,
      deletingUser: false,
      deletingUserPending: false,
      deletingBundle: false,
      deletingBundlePending: false,
      bundleIdToDelete: "",
      forceLoggingOut: false,
      mfaReset: false,
      revokingUserCert: false,
    };
  }

  handleTagChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ tagToAdd: ev.target.value });
  };

  handleTagKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      this.handleAddTag();
      (ev.target as HTMLElement).blur();
    }
  };

  handleAddTag = () => {
    const { user, tagUserMutateFn, intl } = this.props;
    const { tagToAdd } = this.state;

    if (!tagToAdd.trim()) {
      return;
    }

    this.setState({ addingTag: true });
    return tagUserMutateFn({
      variables: {
        input: {
          userId: user.id,
          tag: tagToAdd,
          expiresAt: addHours(new Date(), TTL_TAG_HOURS).toISOString(),
        },
        userTagList: null,
      },
    })
      .then(() => {
        this.setState({ tagToAdd: "" });
      })
      .catch((error: string) => {
        captureException(error);
        const errorString = intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      })
      .finally(() => {
        this.setState({ addingTag: false });
      });
  };

  handleRetireSignerIdentities = async () => {
    const { user, retireSignerIdentitiesMutateFn, intl } = this.props;

    this.setState({ retiringSignerIdentities: true });

    try {
      await retireSignerIdentitiesMutateFn({ userId: user.id });
      this.setState({ retiredSignerIdentities: true });
    } catch (error) {
      let errorString = intl.formatMessage(messages.genericError);

      if (isGraphQLError(error)) {
        errorString = intl.formatMessage(this.errorToMessage(error));
      } else {
        captureException(error);
      }

      store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
    } finally {
      this.setState({ retiringSignerIdentities: false });
    }
  };

  errorToMessage = (error: Error) => {
    switch (error.message) {
      case "add_tag_placeholder":
        return messages.addTagPlaceholder;
      case "generic_error":
        return messages.genericError;
      case "account_enabled":
        return messages.accountEnabled;
      case "account_disabled":
        return messages.accountDisabled;
      case "account_closed":
        return messages.accountClosed;
      case "documents_deleted":
        return messages.documentsDeleted;
      case "mfa_reset":
        return messages.mfaReset;
      case "mfa_reset_error":
        return messages.mfaResetError;
      case "email_mfa_reset_error":
        return messages.emailMfaResetError;
      case "try_again":
        return messages.tryAgain;
      case "signer_identity_not_found":
        return messages.signerIdentityNotFound;
      case "delete_documents":
        return messages.deleteDocuments;
      case "certificate_revoked":
        return messages.certificateRevoked;
      default:
        return messages.genericError;
    }
  };

  handleUserBanning = async () => {
    const { user, banUserMutateFn, unbanUserMutateFn, intl } = this.props;
    const mutateFn = user.banned ? unbanUserMutateFn : banUserMutateFn;

    try {
      await mutateFn({
        variables: {
          input: { userId: user.id },
        },
      });
    } catch (error) {
      let errorString = intl.formatMessage(messages.genericError);

      if (isGraphQLError(error)) {
        errorString = intl.formatMessage(this.errorToMessage(error));
      } else {
        captureException(error);
      }

      store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
    } finally {
      this.setState({ banningUser: false });
    }
  };

  handleOpenBanUserModal = () => {
    this.setState({ banningUser: true });
  };

  handleCloseBanUserModal = () => {
    this.setState({ banningUser: false });
  };

  handleOpenRevokeUserCertModal = () => {
    this.setState({ revokingUserCert: true });
  };

  handleCloseRevokeUserCertModal = () => {
    this.setState({ revokingUserCert: false });
  };

  handleOpenDeleteUserModal = () => {
    this.setState({ deletingUser: true });
  };

  handleCloseDeleteUserModal = () => {
    this.setState({ deletingUser: false });
  };

  handleOpenDeleteBundleModal = (bundleId: string) => {
    this.setState({ deletingBundle: true });
    this.setState({ bundleIdToDelete: bundleId });
  };

  handleCloseDeleteBundleModal = () => {
    this.setState({ deletingBundle: false });
    this.setState({ bundleIdToDelete: "" });
  };

  handleForceLogoutUser = () => {
    const { forceLogoutUserMutateFn, user, intl } = this.props;

    this.setState({ forceLoggingOut: true });

    withMinDelay(forceLogoutUserMutateFn({ variables: { input: { userId: user.id } } }))
      .subscribe({
        error: () => {
          store.dispatch(addError(intl.formatMessage(messages.genericError), ERROR_TYPES.REGULAR));
        },
      })
      .add(() => this.setState({ forceLoggingOut: false }));
  };

  handleMfaReset = async () => {
    const { mfaResetMutateFn, user, intl } = this.props;

    this.setState({ mfaReset: true });

    try {
      await mfaResetMutateFn({ variables: { input: { userId: user.id } } });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.mfaReset),
      });
    } catch (error) {
      if (isGraphQLError(error) && error.graphQLErrors[0].message === "email_mfa_reset") {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          title: intl.formatMessage(messages.mfaResetError),
          message: intl.formatMessage(messages.emailMfaResetError),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      } else {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          title: intl.formatMessage(messages.genericError),
          message: intl.formatMessage(messages.tryAgain),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      }
    } finally {
      this.setState({ mfaReset: false });
    }
  };

  handleUserEnabledStatusChange = async () => {
    const { user, disableUserMutateFn, enableUserMutateFn, intl } = this.props;

    this.setState({ togglingUserEnabledStatus: true });

    try {
      user.isActive
        ? await disableUserMutateFn({
            variables: {
              input: { userId: user.id },
            },
            update(cacheProxy: ApolloCache<unknown>) {
              const data = cacheProxy.readQuery<UserType>({
                query: UserQuery,
                variables: { userId: user.id, userTagList: null },
              });
              const newData = {
                ...data,
                user: {
                  ...data?.user,
                  isActive: false,
                },
              };
              cacheProxy.writeQuery({ query: UserQuery, data: newData });
            },
          })
        : await enableUserMutateFn({
            variables: {
              input: { userId: user.id },
            },
            update(cacheProxy: ApolloCache<unknown>) {
              const data = cacheProxy.readQuery<UserType>({
                query: UserQuery,
                variables: { userId: user.id, userTagList: null },
              });
              const newData = {
                ...data,
                user: {
                  ...data?.user,
                  isActive: true,
                },
              };
              cacheProxy.writeQuery({ query: UserQuery, data: newData });
            },
          });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(
          user.isActive ? messages.accountDisabled : messages.accountEnabled,
        ),
      });
    } catch (error) {
      if (isGraphQLError(error)) {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          title: (
            <FormattedMessage
              id="7ffb179b-584a-47b6-9182-ea1158bd0a50"
              defaultMessage="Failed to renable account"
            />
          ),
          message: error.graphQLErrors[0].message,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      } else {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          title: intl.formatMessage(messages.genericError),
          message: intl.formatMessage(messages.tryAgain),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      }
    } finally {
      this.setState({ togglingUserEnabledStatus: false });
    }
  };

  handleDeleteUser = async () => {
    const { user, disableUserMutateFn, intl, navigate } = this.props;

    this.setState({ deletingUserPending: true });

    try {
      await disableUserMutateFn({
        variables: {
          input: { userId: user.id, soft: false },
        },
      });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.accountClosed),
      });
      navigate("/tools");
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        title: intl.formatMessage(messages.genericError),
        message: intl.formatMessage(messages.tryAgain),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    } finally {
      this.setState({ deletingUser: false, deletingUserPending: false });
    }
  };

  handleDeleteBundle = async () => {
    const { user, deleteBundleMutateFn, intl } = this.props;
    const { bundleIdToDelete } = this.state;

    this.setState({ deletingBundlePending: true });

    try {
      await deleteBundleMutateFn({
        variables: {
          input: { id: bundleIdToDelete, expireDocuments: true },
        },
        refetchQueries: [
          {
            query: UserQuery,
            variables: { userId: user.id },
          },
        ],
      });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.documentsDeleted),
      });
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        title: intl.formatMessage(messages.genericError),
        message: intl.formatMessage(messages.tryAgain),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    }

    this.setState({ deletingBundle: false, deletingBundlePending: false });
  };

  handleRevokeUserCert = async () => {
    const { user, revokeUserCertMutateFn, intl } = this.props;

    try {
      await revokeUserCertMutateFn({
        variables: {
          input: { userId: user.id },
        },
      });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.certificateRevoked),
      });
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        title: intl.formatMessage(messages.genericError),
        message: intl.formatMessage(messages.tryAgain),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    } finally {
      this.setState({ revokingUserCert: false });
    }
  };

  getCertMessage = () => {
    const { user } = this.props;
    const userCert = user.certificate;
    const isCertExpired = userCert?.validTo && isPast(userCert.validTo);
    const isCertRevoked = userCert && Boolean(userCert.revokedAt);

    if (!userCert) {
      return (
        <FormattedMessage
          id="34f70808-2822-43b5-87d9-692b279e7f51"
          defaultMessage="This user does not have a certificate."
        />
      );
    } else if (isCertRevoked) {
      return (
        <FormattedMessage
          id="0b445653-260b-4d6c-9e6a-60f965e358bc"
          defaultMessage="This user's certificate was revoked on {revokedAt}."
          values={{ revokedAt: userCert.revokedAt }}
        />
      );
    } else if (isCertExpired) {
      return (
        <FormattedMessage
          id="0a930fef-255d-49b5-8334-7724fb5039f1"
          defaultMessage="This user's certificate expired on {expiration}."
          values={{ expiration: userCert.validTo }}
        />
      );
    }
    return (
      <FormattedMessage
        id="e2f655be-7706-4629-9490-1e76071f96e0"
        defaultMessage="This user has a valid certificate which expires on {expiration}."
        values={{ expiration: userCert.validTo }}
      />
    );
  };

  render() {
    const {
      user,
      intl,
      navigate,
      permissions: { hasPermissionFor },
    } = this.props;
    const {
      tagToAdd,
      addingTag,
      retiringSignerIdentities,
      retiredSignerIdentities,
      banningUser,
      togglingUserEnabledStatus,
      deletingUser,
      deletingUserPending,
      deletingBundle,
      deletingBundlePending,
      forceLoggingOut,
      mfaReset,
      showExperimentGroups,
      revokingUserCert,
    } = this.state;

    const userIsBanned = user.banned;

    const userCert = user.certificate;
    const isCertExpired = userCert && isPast(userCert.validTo);
    const isCertRevoked = userCert && Boolean(userCert.revokedAt);

    const buttons = [
      <Button buttonColor="action" key="back" onClick={() => navigate(-1)}>
        {backButton}
      </Button>,
    ];

    let numMeetingsForUser = 0;
    user.documentBundlesAsCustomer?.forEach((documentBundle) => {
      documentBundle!.meetings.edges.forEach((meeting: Meeting) => {
        if (
          meeting.node.meetingParticipants.some((participant) => participant.userId === user.id)
        ) {
          numMeetingsForUser += 1;
        }
      });
    });

    const revokeCertButton = (
      <Button
        className={`${CX}--inline--button`}
        aria-describedby="revoke-cert-instructions"
        onClick={this.handleOpenRevokeUserCertModal}
        isLoading={revokingUserCert}
        variant="secondary"
        buttonColor="action"
        automationId="revoke-user-cert"
        buttonSize="condensed"
        disabled={
          !userCert || isCertExpired || isCertRevoked || !hasPermissionFor("revokeUserCert")
        }
      >
        <FormattedMessage
          id="e2f655be-7706-4629-9490-1e76071f96e0"
          defaultMessage="Revoke Certificate"
        />
      </Button>
    );

    return (
      <Card
        className={CX}
        title={userFullName(user) || defaultTitle}
        buttons={buttons}
        sticky={false}
      >
        {!user.isActive && (
          <AlertMessage kind="warning" data-automation-id="account-disabled-banner">
            <FormattedMessage
              id="f72149c3-1bc9-48bf-a575-0a252f2980b9"
              defaultMessage="This user's account has been disabled. Scroll down to enable the account."
            />
          </AlertMessage>
        )}
        <div className={`${CX}--ids`}>
          <div>
            <p className={`${CX}--header`}>{idTitle}</p>
            {user.systemId}
          </div>
          <div>
            <p className={`${CX}--header`}>{gidTitle}</p>
            {user.id}
          </div>
          <div>
            <p className={`${CX}--header`}>{profileIdTitle}</p>
            {user.customerProfile ? user.customerProfile.id : null}
          </div>
          <div>
            <p className={`${CX}--header`}>{activeTierTitle}</p>
            {user.activeTier.name}
          </div>
          <div>
            <p className={`${CX}--header`}>{rolesTitle}</p>
            {user.roles!.join(", ")}
          </div>
          {hasPermissionFor("banUser") && (
            <div className={`${CX}--ban-user--button`}>
              <Button
                onClick={this.handleOpenBanUserModal}
                isLoading={banningUser}
                buttonColor="danger"
                variant="primary"
                automationId="admin-ban-user"
              >
                <FormattedMessage
                  id="16327c75-b7f5-4b2d-a752-8c3ee19a3b73"
                  defaultMessage="{userIsBanned, select, true {Unban} other {Ban}} User"
                  values={{ userIsBanned }}
                />
              </Button>
            </div>
          )}
        </div>

        <p className={`${CX}--header`}>{emailTitle}</p>
        {hasPermissionFor("privacyEmailEdit") ? (
          <LegalTeamEmailEdit userEmail={user.pendingEmail!} userId={user.id} />
        ) : (
          <MailtoLink emailAddress={user.email!} />
        )}

        {user.organization && (
          <div className={`${CX}--ids`}>
            <div>
              <p className={`${CX}--header`}>{organizationIdTitle}</p>
              <Link to={`/companies/${user.organization.id}/transactions`}>
                {user.organization.id}
              </Link>
            </div>
            <div>
              <p className={`${CX}--header`}>{organizationNameTitle}</p>
              {user.organization.name}
            </div>
          </div>
        )}

        <p className={`${CX}--header`}>{historyTitle}</p>
        <div className={`${CX}--content`}>
          <Link
            href={`https://mixpanel.com/project/1108896/view/108035/app/profile#distinct_id=${user.id}`}
          >
            <FormattedMessage id="06c0fe7f-a160-412b-8cc2-4549263861d4" defaultMessage="Mixpanel" />
          </Link>
        </div>
        <div>
          <Link to={`/analytics/page/1?email=${encodeURIComponent(user.email!)}`}>
            <FormattedMessage
              id="985652e4-f52f-4a4c-8510-dbaf3421ef00"
              defaultMessage="Meetings ({numMeetingsForUser})"
              values={{ numMeetingsForUser }}
            />
          </Link>
        </div>

        <p className={`${CX}--header`}>{rolesTitle}</p>
        {user
          .roles!.flatMap((role) => {
            if (role === UserRole.NOTARY) {
              const capLabels = user
                .notaryProfile!.capacities.flatMap((cap) => [CAPACITY_TYPE_LABELS[cap.type], " + "])
                .slice(0, -1);
              return [role, " (", ...capLabels, ")", ", "];
            }
            return [role, ", "];
          })
          .slice(0, -1)}

        <div className={`${CX}--section`}>
          <p className={`${CX}--header`}>{transactionsTitle}</p>
          <div>
            <StandardTable
              headings={[
                "Tx GID",
                "Status",
                "Organization",
                "Type",
                "Meetings",
                "Created",
                "Doc Bundle Id",
              ]}
              automationId="admin-user-transactions-table"
            >
              {user.documentBundlesAsCustomer?.map((bundle) => {
                const tx = bundle!.organizationTransaction;
                const txIdElement = (
                  <Link to={`/companies/${tx.organization.id}/transactions/${tx.id}/summary`}>
                    {tx.id}
                  </Link>
                );

                const orgNameElement = (
                  <Link to={`/companies/${tx.organization.id}/transactions`}>
                    {tx.organization.name}
                  </Link>
                );

                bundle = bundle!;

                const docIdElement = (
                  <>
                    {bundle.id}
                    {hasPermissionFor("deleteUser") &&
                      bundle.eligibleForDelete &&
                      !bundle.expired && (
                        <IconButton
                          className="bundle-delete-button"
                          name="delete"
                          automationId={`bundle-delete-button-${bundle.id}`}
                          onClick={() => this.handleOpenDeleteBundleModal(bundle.id)}
                          buttonColor="danger"
                          variant="tertiary"
                          buttonSize="condensed"
                          label={intl.formatMessage(messages.deleteDocuments)}
                        />
                      )}
                    {hasPermissionFor("deleteUser") && bundle.expired && (
                      <Tooltip
                        target={<Icon className="bundle-expired-notice" name="expiry" />}
                        placement="right"
                      >
                        <FormattedMessage
                          id="871e6c5e-d174-4dbc-941f-af0b80a142ff"
                          defaultMessage="Expired"
                        />
                      </Tooltip>
                    )}
                  </>
                );

                return (
                  <TableRow
                    key={bundle.id}
                    className={bundle.deleted ? "deleted" : ""}
                    cells={[
                      { children: txIdElement },
                      { children: bundle.processingState },
                      { className: "ellipsis", children: orgNameElement },
                      { className: "ellipsis", children: tx.transactionType },
                      { children: bundle.meetings.edges.length },
                      { className: "ellipsis", children: bundle.createdAt },
                      { className: "ellipsis", children: docIdElement },
                    ]}
                  />
                );
              })}
            </StandardTable>
          </div>
        </div>

        <div className={`${CX}--section`}>
          <p className={`${CX}--header`}>
            <FormattedMessage
              id="64c41a8a-dbdf-42f9-b9cd-6d35ab9b8295"
              defaultMessage="Experiments"
            />
            <span
              onClick={() => {
                this.setState((prevState) => ({
                  showExperimentGroups: !prevState.showExperimentGroups,
                }));
              }}
              className={`${CX}--header--button`}
            >
              {showExperimentGroups ? (
                <FormattedMessage
                  id="beb95538-21da-40c8-a00d-964f804b02d4"
                  defaultMessage="Hide Groups"
                />
              ) : (
                <FormattedMessage
                  id="f7cca6e2-7e1e-4f74-803b-8ab74c1f4418"
                  defaultMessage="Show Groups"
                />
              )}
            </span>
          </p>
          {showExperimentGroups && (
            <div className={`${CX}--experimentList`}>
              {user.experiments.length === 0 && (
                <FormattedMessage
                  id="b7a36b6f-d720-46af-ae2c-97ec34cd5469"
                  defaultMessage="No active experiments"
                />
              )}
              {user.experiments.map((experiment, index) => {
                return (
                  <div
                    key={`${experiment.name}-${index}`}
                    className={`${CX}--experimentList--experiment`}
                  >
                    <div className={`${CX}--experimentList--experiment--name`}>
                      <Link to={`/experiments/${experiment.name}`}>{experiment.name}</Link>:
                    </div>
                    <div className={`${CX}--experimentList--experiment--group`}>
                      {experiment.group}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {hasPermissionFor("userTags") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>{tagsTitle}</p>
            <p data-automation-id="list-of-tags">
              {user.tags?.map((tag) => (tag ? `"${tag.tag}"` : "")).join(", ") || tagsNone}
            </p>
            {hasPermissionFor("createUserTags") && (
              <>
                <div className={`${CX}--add-tag`}>
                  <StyledTextInput
                    value={tagToAdd}
                    onChange={this.handleTagChange}
                    onKeyDown={this.handleTagKeyDown}
                    placeholder={intl.formatMessage(messages.addTagPlaceholder)}
                    automationId="change-user-tag"
                  />
                  <Button
                    className={`${CX}--add-tag--button`}
                    onClick={this.handleAddTag}
                    isLoading={addingTag}
                    disabled={!tagToAdd.trim()}
                    automationId="add-user-tag"
                    buttonColor="action"
                    variant="primary"
                  >
                    {addTagButton}
                  </Button>
                </div>
                <p>{tagsInfo}</p>
              </>
            )}
            <Link
              className={`${CX}--add-tag--link`}
              href="https://notarize.atlassian.net/wiki/spaces/EN/pages/269811894/User+Tags"
            >
              {tagsLink}
            </Link>
          </div>
        )}
        {hasPermissionFor("mfaReset") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>
              <FormattedMessage
                id="a0d7d4d0-0c9a-4f6b-9d2f-1a9e6b0e8b1e"
                defaultMessage="Reset MFA"
              />
            </p>

            <div className={`${CX}--button--row`}>
              <div id="reset-mfa-instructions">
                <FormattedMessage
                  id="b03a74a9-8a59-4324-8490-96f937ff4009"
                  defaultMessage="Reset MFA for this user. This will remove all MFA configurations (except email) and require the user to re-enroll."
                />
                <div className={`${CX}--mfa--options`}>
                  <FormattedMessage
                    id="aac79208-7151-43cf-a856-9dbc6f05ac8f"
                    defaultMessage="Current MFA option(s) Required:"
                  />
                  {user.authenticationRequirements.map((requirement, index) => (
                    <FormattedMessage
                      key={index}
                      tagName="p"
                      id="8b256d0c-ca6e-43c7-bbe7-3bd7ccb281a7"
                      defaultMessage="{authType, select, EMAIL {Email} SMS {Text Message} TOTP {Authenticator App} other {...}} {setup}"
                      values={{
                        authType: requirement.authType,
                        setup: (
                          <FormattedMessage
                            id="7a256d0c-ca6e-43c7-bbe7-3bd7ccb281a5"
                            tagName={
                              user.setupAuthentication.includes(requirement.authType)
                                ? "strong"
                                : undefined
                            }
                            defaultMessage="{isSetup, select, true {(Set Up)} other {(Not Set Up)}}"
                            values={{
                              isSetup: user.setupAuthentication.includes(requirement.authType),
                            }}
                          />
                        ),
                      }}
                    />
                  ))}
                </div>
              </div>

              <Button
                className={`${CX}--reset--mfa--button`}
                aria-describedby="reset-mfa-instructions"
                onClick={this.handleMfaReset}
                isLoading={mfaReset}
                variant="secondary"
                buttonColor="action"
                buttonSize="condensed"
              >
                <FormattedMessage
                  id="e2f655be-7706-4629-9490-1e76071f96e0"
                  defaultMessage="Reset MFA"
                />
              </Button>
            </div>
          </div>
        )}
        {hasPermissionFor("expireSignerIdentity") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>{signerIdentitiesTitle}</p>
            <div className={`${CX}--button--row`}>
              {signerIdentitiesInfo}
              <Button
                className={`${CX}--inline--button`}
                onClick={this.handleRetireSignerIdentities}
                isLoading={retiringSignerIdentities}
                disabled={retiredSignerIdentities}
                variant="secondary"
                buttonColor="action"
                automationId="admin-retire-signer-identities"
                buttonSize="condensed"
              >
                {signerIdentitiesButton}
              </Button>
            </div>
          </div>
        )}
        {hasPermissionFor("forceUserLogout") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>
              <FormattedMessage
                id="0cfee4ac-f2a3-442d-a108-f2e7427ce3c6"
                defaultMessage="Force Logout"
              />
            </p>

            <div className={`${CX}--button--row`}>
              <div className={`${CX}--button--description`} id="force-logout-instructions">
                <FormattedMessage
                  id="b03a74a9-8a59-4324-8490-96f937ff4009"
                  defaultMessage="Force logout user from all devices."
                />
              </div>
              <Button
                className={`${CX}--inline--button`}
                aria-describedby="force-logout-instructions"
                onClick={this.handleForceLogoutUser}
                isLoading={forceLoggingOut}
                variant="secondary"
                buttonColor="action"
                buttonSize="condensed"
              >
                <FormattedMessage
                  id="e2f655be-7706-4629-9490-1e76071f96e0"
                  defaultMessage="Log out"
                />
              </Button>
            </div>
          </div>
        )}
        <div className={`${CX}--section`}>
          <p className={`${CX}--header`}>
            <FormattedMessage
              id="e2f655be-7706-4629-9490-1e76071f96e0"
              defaultMessage="User Certificate"
            />
          </p>
          <div className={`${CX}--button--row`}>
            <div className={`${CX}--button--description`} id="revoke-cert-instructions">
              {this.getCertMessage()}
            </div>
            {hasPermissionFor("revokeUserCert") && revokeCertButton}
            {!hasPermissionFor("revokeUserCert") && (
              <Tooltip target={revokeCertButton} placement="top" triggerButtonLabel={"TEST"}>
                <FormattedMessage
                  id="971dca62-bd88-45b4-bb1e-a54132392f0d"
                  defaultMessage="You do not have permission to revoke this user's certificate."
                />
              </Tooltip>
            )}
          </div>
        </div>
        {hasPermissionFor("deleteUser") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>
              <FormattedMessage
                id="27009dfc-9267-4c39-bffd-6cf99fb08db9"
                defaultMessage="{userDisabled, select, true{Re-enable} other{Disable}} Account"
                values={{ userDisabled: !user.isActive }}
              />
            </p>
            <div className={`${CX}--button--row`}>
              <div className={`${CX}--button--description`} id="toggle-enable-instructions">
                <FormattedMessage
                  id="7ca9109e-4ee1-4e86-8754-a6d6e8662b08"
                  defaultMessage="{userDisabled, select, true{Re-enable} other{Disable}} this user's account."
                  values={{ userDisabled: !user.isActive }}
                />
              </div>
              <Button
                className={`${CX}--inline--button`}
                aria-describedby="toggle-enable-instructions"
                disabled={user.isActive && !user.eligibleForDisable}
                onClick={this.handleUserEnabledStatusChange}
                isLoading={togglingUserEnabledStatus}
                automationId="toggle-account-enabled-button"
                variant="secondary"
                buttonColor="action"
                buttonSize="condensed"
              >
                <FormattedMessage
                  id="99f59715-297c-45a5-9cdf-7fa294bc0c0c"
                  defaultMessage="{userDisabled, select, true{Re-enable} other{Disable}} Account"
                  values={{ userDisabled: !user.isActive }}
                />
              </Button>
            </div>
          </div>
        )}
        {hasPermissionFor("deleteUser") && (
          <div className={`${CX}--section`}>
            <p className={`${CX}--header`}>
              <FormattedMessage
                id="f792ce1c-8744-4b6e-b9e8-adf5953a7040"
                defaultMessage="Delete Account"
              />
            </p>

            <div className={`${CX}--button--row`}>
              <div className={`${CX}--button--description`} id="delete-user-instructions">
                <FormattedMessage
                  id="1c00cdfa-503c-4353-8f39-0f3e402523cf"
                  defaultMessage="Delete this user's account."
                />
              </div>
              <Button
                className={`${CX}--inline--button`}
                aria-describedby="delete-user-instructions"
                disabled={!user.eligibleForDelete}
                onClick={this.handleOpenDeleteUserModal}
                isLoading={deletingUser}
                automationId="delete-account-button"
                buttonColor="danger"
                variant="primary"
                buttonSize="condensed"
              >
                <FormattedMessage
                  id="a9199422-ccb0-4198-9a5d-c9561380b655"
                  defaultMessage="Delete Account"
                />
              </Button>
            </div>
          </div>
        )}
        {banningUser && (
          <BanUserModal
            isBanned={userIsBanned}
            onClose={this.handleCloseBanUserModal}
            onAccept={this.handleUserBanning}
          />
        )}
        {revokingUserCert && (
          <RevokeUserCertModal
            onClose={this.handleCloseRevokeUserCertModal}
            onAccept={this.handleRevokeUserCert}
          />
        )}
        {deletingUser && (
          <WorkflowModal
            title={
              <FormattedMessage
                id="75049188-89ad-43a5-b1f3-38241c1cc7ea"
                defaultMessage="Are you sure you want to delete this user's account?"
              />
            }
            closeBehavior={{ tag: "with-button", onClose: this.handleCloseDeleteUserModal }}
            buttons={[
              <Button
                key="delete-account-cancel"
                buttonColor="dark"
                variant="tertiary"
                onClick={this.handleCloseDeleteUserModal}
                automationId="delete-account-cancel-button"
              >
                <FormattedMessage
                  id="8fa2ac0a-bba5-42f3-9e79-3da21cbb9d9a"
                  defaultMessage="Cancel"
                />
              </Button>,
              <Button
                key="delete-account-confirm-confirm"
                buttonColor="danger"
                variant="primary"
                onClick={this.handleDeleteUser}
                isLoading={deletingUserPending}
                automationId="delete-account-confirmation-button"
                aria-describedby="delete-confirm-instructions"
              >
                <FormattedMessage
                  id="d4614fce-5e5c-4d93-b98f-64a449f72e41"
                  defaultMessage="Delete Account"
                />
              </Button>,
            ]}
            footerSeparator={false}
            large
          >
            <FormattedMessage
              id="25eff4d0-762e-4578-a542-d1bf410ca8e6"
              defaultMessage="This action will delete the user's account and remove all PII. You won't be able to undo this action."
            />
          </WorkflowModal>
        )}
        {deletingBundle && (
          <WorkflowModal
            title={
              <FormattedMessage
                id="a9c682b5-4b59-44d3-8ec0-b17f17ff6f37"
                defaultMessage="Are you sure you want to delete these documents?"
              />
            }
            closeBehavior={{ tag: "with-button", onClose: this.handleCloseDeleteBundleModal }}
            buttons={[
              <Button
                key="delete-bundle-cancel"
                buttonColor="dark"
                variant="tertiary"
                onClick={this.handleCloseDeleteBundleModal}
                automationId="delete-bundle-cancel-button"
              >
                <FormattedMessage
                  id="52c19f89-c5af-4006-9e85-81a689071718"
                  defaultMessage="Cancel"
                />
              </Button>,
              <Button
                key="delete-bundle-confirm-confirm"
                buttonColor="danger"
                variant="primary"
                onClick={this.handleDeleteBundle}
                isLoading={deletingBundlePending}
                automationId="delete-bundle-confirmation-button"
              >
                <FormattedMessage
                  id="b546be34-3b8e-4ddc-bfce-94540094acc6"
                  defaultMessage="Delete Documents"
                />
              </Button>,
            ]}
            footerSeparator={false}
            large
          >
            <FormattedMessage
              id="54f786a7-6046-43d5-8ba1-cdab541dc738"
              defaultMessage="This action will delete all documents from this transaction. You won't be able to undo this action."
            />
          </WorkflowModal>
        )}
      </Card>
    );
  }
}

function AdminUserDetailsContainer() {
  const { userId } = useParams();
  const intl = useIntl();
  const permissions = usePermissions();
  const navigate = useNavigate();

  const banUserMutateFn = useMutation(BanUserMutation);
  const unbanUserMutateFn = useMutation(UnbanUserMutation);
  const forceLogoutUserMutateFn = useMutation(ForceLogoutUserMutation);
  const mfaResetMutateFn = useMutation(MfaResetMutation);
  const disableUserMutateFn = useMutation(DisableUserMutation);
  const enableUserMutateFn = useMutation(EnableUserMutation);
  const deleteBundleMutateFn = useMutation(DeleteBundleMutation);
  const revokeUserCertMutateFn = useMutation(RevokeUserCertMutation);
  const tagUserMutateFn = useTagUserMutation();
  const retireSignerIdentities = useRetireSignerIdentities();

  return (
    <QueryWithLoading query={UserQuery} variables={{ userId: userId!, userTagList: null }}>
      {(query) => (
        <AdminUserDetails
          user={query.data!.user! as User}
          banUserMutateFn={banUserMutateFn}
          unbanUserMutateFn={unbanUserMutateFn}
          tagUserMutateFn={tagUserMutateFn}
          forceLogoutUserMutateFn={forceLogoutUserMutateFn}
          mfaResetMutateFn={mfaResetMutateFn}
          disableUserMutateFn={disableUserMutateFn}
          enableUserMutateFn={enableUserMutateFn}
          deleteBundleMutateFn={deleteBundleMutateFn}
          retireSignerIdentitiesMutateFn={retireSignerIdentities}
          revokeUserCertMutateFn={revokeUserCertMutateFn}
          intl={intl}
          permissions={permissions}
          navigate={navigate}
        />
      )}
    </QueryWithLoading>
  );
}

export default AdminUserDetailsContainer;
