import { defineMessages, useIntl } from "react-intl";
import { useEffect } from "react";

import { useDispatch, useSelector } from "redux/util";
import { forgotPassword } from "redux/actions/authentication";
import Button from "common/core/button";
import {
  FormattedFieldError,
  isAriaInvalid,
  FieldErrorMessage,
  defaultRequiredMessage,
  emailPatternValidation,
} from "common/core/form/error";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";

import { BackButtonOnlyFooter } from "./common";
import ProofCard from "../card";
import { ForgotPasswordHeader } from "../../components";
import Styles from "./index.module.scss";
import type { EmailScreen } from "./email";

type FormValues = {
  email: string;
};

const MESSAGES = defineMessages({
  emailPlaceholder: {
    id: "29f0dc4b-b1f2-4004-8499-98702be12fa6",
    defaultMessage: "Email address",
  },
  backButtonAriaLabel: {
    id: "2df4db6e-c4b5-4e5a-813f-269b63326647",
    defaultMessage: "Back to email input",
  },
  instructions: {
    id: "dde2d64d-c7c3-4139-9069-b26fe233e634",
    defaultMessage:
      "Enter your email address to receive instructions on how to reset your password",
  },
  submitting: {
    id: "6fa0e1e2-dbc4-4522-a1ec-eb394a4c1d80",
    defaultMessage: "Sending",
  },
  successMessage: {
    id: "3728bca0-c303-4389-919f-4166201bfaa6",
    defaultMessage: "Reset link sent",
  },
  cta: {
    id: "df1e5bac-96d6-4b31-93c3-cbcb79d7597d",
    defaultMessage: "Send password reset link",
  },
  errorMessage: {
    id: "6f6b787a-1788-427e-8432-1d01dbfc61c0",
    defaultMessage: "* Invalid email",
  },
});

type BodyProps = {
  email: string;
  onNextScreen: (screen: EmailScreen) => void;
};

export type ForgotPasswordScreen = {
  type: "forgot_password";
  email: string;
};

export default function ForgotPasswordScreenCard({ email, onNextScreen }: BodyProps) {
  const authentication = useSelector((state) => state.authentication);
  const error = !Object.keys(authentication.error).length ? null : authentication.error;
  const intl = useIntl();

  const screenReaderId = useId();

  const dispatch = useDispatch();

  const onSendPasswordReset = (formValues: FormValues) => {
    dispatch(forgotPassword(formValues.email));
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful, isSubmitted },
    setFocus,
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      email,
    },
  });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);
  return (
    <ProofCard
      body={
        <>
          <div className={Styles.mainHeading}>
            <ForgotPasswordHeader />
          </div>
          <SROnly>
            <p id={screenReaderId}>{intl.formatMessage(MESSAGES.instructions)}</p>
          </SROnly>
          <form
            className={Styles.formContainer}
            onSubmit={handleSubmit(onSendPasswordReset)}
            noValidate
          >
            <div className={Styles.formContainer}>
              <StyledTextInput
                aria-invalid={isSubmitted && isAriaInvalid(errors.email)}
                aria-describedby={screenReaderId}
                label={intl.formatMessage(MESSAGES.emailPlaceholder)}
                data-automation-id="email-field"
                type="email"
                {...register("email", {
                  required: defaultRequiredMessage(intl),
                  pattern: emailPatternValidation(intl),
                })}
              />
              {isSubmitted && errors.email && (
                <FormattedFieldError
                  className={Styles.formError}
                  inputName="email"
                  error={errors.email}
                />
              )}
            </div>
            <Button
              type="submit"
              disabled={isSubmitSuccessful}
              buttonColor="action"
              buttonSize="large"
              variant="primary"
              fullwidth
            >
              {isSubmitting && intl.formatMessage(MESSAGES.submitting)}
              {isSubmitSuccessful && <p>{intl.formatMessage(MESSAGES.successMessage)}</p>}
              {!isSubmitting && !isSubmitSuccessful && intl.formatMessage(MESSAGES.cta)}
            </Button>
            {error && (
              <FieldErrorMessage
                className={Styles.formError}
                inputName="email"
                message={intl.formatMessage(MESSAGES.errorMessage)}
              />
            )}
          </form>
        </>
      }
      footer={
        <BackButtonOnlyFooter
          onClick={() => onNextScreen({ type: "email", email })}
          aria-label={intl.formatMessage(MESSAGES.backButtonAriaLabel)}
        />
      }
    />
  );
}
