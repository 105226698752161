import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

type Props = {
  isBanned: boolean;
  onClose: () => void;
  onAccept: () => Promise<unknown>;
};

// Also handles verification of unbanning.
function BanUserModal(props: Props) {
  const { isBanned, onClose, onAccept } = props;
  const [isLoading, setLoading] = useState(false);

  const handleAccept = () => {
    setLoading(true);
    onAccept().finally(() => setLoading(false));
  };

  return (
    <IllustrationModal
      className="AdminBanUserModal"
      title={
        <FormattedMessage
          id="4a71d30a-6bbd-474b-89fa-93f2b160c82f"
          defaultMessage="Are you sure you want to {isBanned, select, true{unban} other{ban}} this user?"
          values={{ isBanned }}
        />
      }
      buttons={[
        <Button
          key="0"
          buttonColor="danger"
          variant="primary"
          automationId="ban-user-button"
          onClick={handleAccept}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="ddabf06f-dcac-4559-a08f-0ae276663e8b"
            defaultMessage="{isBanned, select, true{Unban} other{Ban}}"
            values={{ isBanned }}
          />
        </Button>,
        <Button
          key="1"
          buttonSize="large"
          buttonColor="dark"
          variant="tertiary"
          automationId="cancel-ban-status-management"
          onClick={onClose}
        >
          <FormattedMessage id="bc40bdf2-53df-4aeb-8d98-62c6f24d9afa" defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="cb41821c-126b-4aad-b4d0-33254eb9952b"
        defaultMessage="{isBanned, select, true{Unbanning this user will allow them to join meetings as a signer on the platform.} other{Banning this user will prevent them from joining meetings as a signer on the platform.}}"
        values={{ isBanned }}
      />
    </IllustrationModal>
  );
}

export default memo(BanUserModal);
