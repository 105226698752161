import { memo } from "react";
import PropTypes from "prop-types";

import Button from "common/core/button";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalActions from "common/blocking_modal/actions";
import BlockingModal from "common/blocking_modal";
import errorImg from "assets/images/destructive-action.svg";

function AdminResponseModal({ isOpen, onClose, responseModalMsg }) {
  return (
    <BlockingModal isOpen={isOpen}>
      <ModalGraphic>
        <img alt="error" src={errorImg} />
      </ModalGraphic>

      <ModalHeader>The admin was not deleted</ModalHeader>

      <ModalSubheader>{responseModalMsg || "An unknown error occured"}</ModalSubheader>

      <ModalActions>
        <Button onClick={onClose} buttonColor="action" variant="secondary" buttonSize="large">
          Return To Admin Details
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

AdminResponseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  responseModalMsg: PropTypes.string,
};

export default memo(AdminResponseModal);
