import { memo, useState, useEffect, useRef, type ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { DeepfakeRiskSignalRiskScore } from "graphql_globals";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { Heading, Paragraph } from "common/core/typography";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { Checkbox } from "common/core/form/option";

import Styles from "./index.module.scss";
import TransactionRiskQuery, {
  type TransactionRisk_transaction_OrganizationTransaction as OrganizationTransaction,
  type TransactionRisk_transaction_OrganizationTransaction_deepfakeRiskSignals as Signal,
  type TransactionRisk_transaction_OrganizationTransaction_deepfakeRiskSignals_frames as DeepfakeRiskFrame,
} from "./index_query.graphql";

type SelectedDetection =
  | null
  | "face-similarity"
  | "face-reenactment"
  | "liveness"
  | "frame-analysis";

const SCORE_LABELS = Object.freeze({
  [DeepfakeRiskSignalRiskScore.HIGH]: (
    <FormattedMessage id="6e95657d-7fdf-4e8d-9c37-7286b32a1334" defaultMessage="High risk" />
  ),
  [DeepfakeRiskSignalRiskScore.MEDIUM]: (
    <FormattedMessage id="33d24598-0f2f-4a5e-909b-7346923f3372" defaultMessage="Medium risk" />
  ),
  [DeepfakeRiskSignalRiskScore.LOW]: (
    <FormattedMessage id="de92ea04-4b4e-45c3-90e0-4a3762136a41" defaultMessage="Low risk" />
  ),
});
const SCORE_ICONS = Object.freeze({
  [DeepfakeRiskSignalRiskScore.HIGH]: <Icon name="failure" />,
  [DeepfakeRiskSignalRiskScore.MEDIUM]: <Icon name="warning" />,
  [DeepfakeRiskSignalRiskScore.LOW]: <Icon name="success" />,
});

function IDComparison(props: { signal: Signal }) {
  const { signals } = props.signal;
  return (
    <div className={Styles.idCompare}>
      <img src={signals.photoIdSimilarity.croppedPhotoId} alt="" />
      <img src={signals.photoIdSimilarity.croppedFrameFace} alt="" />
    </div>
  );
}

function PoseComparison(props: { signal: Signal }) {
  const { frames, signals } = props.signal;
  const [version, setVersion] = useState<"heatmap" | "sidebyside" | "blended">("sidebyside");
  const [advancedEnabled, setAdvancedEnabled] = useState(false);
  const minFrame = frames[signals.pose.minFrame];
  const maxFrame = frames[signals.pose.maxFrame];
  return (
    <div className={Styles.compareContainer}>
      <div className={Styles.poseActions}>
        <button
          type="button"
          aria-pressed={version === "sidebyside"}
          onClick={() => setVersion("sidebyside")}
        >
          <FormattedMessage
            id="0652cfea-27c3-4b7e-8494-f909ce110d50"
            defaultMessage="Side-by-side"
          />
        </button>
        <button
          type="button"
          aria-pressed={version === "blended"}
          onClick={() => setVersion("blended")}
        >
          <FormattedMessage id="7e229280-ac7f-454d-afc0-d58f4db1ee4a" defaultMessage="Merged" />
        </button>
        <button
          type="button"
          aria-pressed={version === "heatmap"}
          onClick={() => setVersion("heatmap")}
        >
          <FormattedMessage id="e293cf56-8664-49c5-86bf-99371199bc74" defaultMessage="Heatmap" />
        </button>
        <button
          type="button"
          className={classnames(
            Styles.toggleAdvanced,
            version === "heatmap" && Styles.advancedWithHeatmap,
          )}
          onClick={() => setAdvancedEnabled((o) => !o)}
        >
          <Checkbox aria-invalid="false" checked={advancedEnabled} />
          <FormattedMessage
            id="199519b5-08cb-47da-a06a-1845baa873be"
            defaultMessage="See pose bounding"
          />
        </button>
      </div>
      <div
        className={classnames(
          Styles.abCompare,
          version === "sidebyside"
            ? Styles.sideBySide
            : version === "heatmap"
              ? Styles.heatmap
              : Styles.blended,
        )}
      >
        <div>
          {/* This first image is hidden from sight. See the CSS */}
          <img
            src={
              version === "heatmap"
                ? signals.jitter.heatmapFrame
                : minFrame[advancedEnabled ? "boundingBox" : "frame"]
            }
            alt=""
          />
          <img
            src={
              version === "heatmap"
                ? signals.jitter.heatmapFrame
                : minFrame[advancedEnabled ? "boundingBox" : "frame"]
            }
            alt=""
          />
          <img src={maxFrame[advancedEnabled ? "boundingBox" : "frame"]} alt="" />
        </div>
      </div>
    </div>
  );
}

function SignalItem(props: {
  riskScore: DeepfakeRiskSignalRiskScore;
  selected: boolean;
  outerContent?: ReactNode;
  content: NonNullable<ReactNode>;
  header: NonNullable<ReactNode>;
  onSelect?: () => void;
}) {
  const { outerContent, riskScore } = props;
  return (
    <li>
      <button
        type="button"
        onClick={props.onSelect}
        data-risk-score={riskScore}
        className={
          props.selected ? Styles.selectedSignal : !props.onSelect ? Styles.unselectable : undefined
        }
      >
        <span className={Styles.riskIcon}>
          {SCORE_ICONS[riskScore]}
          <span>{SCORE_LABELS[riskScore]}</span>
        </span>
        <span>
          <Heading level="h3" textStyle="headingFive">
            {props.header}
          </Heading>
          {props.content}
        </span>
        <Icon className={Styles.expand} name="caret-right" />
      </button>
      {props.selected && outerContent}
    </li>
  );
}

type Timeline =
  | { t: "paused"; progress: number; hovered: false | number }
  | { t: "playing"; progress: number }
  | { t: "scrubbing"; progress: number; startMousePosition: number; startProgress: number };

const TIMELINE_HEIGHT = 110;
const SCRUBBER_WIDTH = 47;
const MIN_PROGRESS = SCRUBBER_WIDTH / -2;

function FaceHeatmapTimeline(props: { frames: DeepfakeRiskFrame[] }) {
  const [state, setState] = useState<Timeline>({
    t: "paused",
    hovered: false,
    progress: MIN_PROGRESS,
  });
  const { frames } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const current = { timeline: state, width };

  useEffect(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    switch (current.timeline.t) {
      case "playing": {
        const intervalId = setInterval(
          () =>
            setState((o) => {
              const max_progress = current.width + MIN_PROGRESS;
              const newProgress = o.progress + 3;
              return newProgress >= max_progress
                ? { t: "paused", progress: max_progress, hovered: false }
                : { t: "playing", progress: newProgress };
            }),
          16,
        );
        return () => clearInterval(intervalId);
      }
      case "scrubbing": {
        const { startMousePosition, startProgress } = current.timeline;
        const move = (event: MouseEvent) => {
          const max_progress = current.width + MIN_PROGRESS;
          const newProgress = Math.min(
            max_progress,
            Math.max(MIN_PROGRESS, startProgress + event.clientX - startMousePosition),
          );
          setState({ t: "scrubbing", progress: newProgress, startMousePosition, startProgress });
        };
        const stop = () => setState((o) => ({ t: "paused", hovered: false, progress: o.progress }));
        document.addEventListener("mousemove", move);
        document.addEventListener("mouseup", stop);
        return () => {
          document.removeEventListener("mousemove", move);
          document.removeEventListener("mouseup", stop);
        };
      }
    }
  }, [current]);
  const max_progress = Math.max(width + MIN_PROGRESS, 0);
  const percent =
    (state.progress / (max_progress - MIN_PROGRESS) +
      Math.abs(MIN_PROGRESS) / (max_progress - MIN_PROGRESS)) *
    100;
  const frameChunkPercentSize = 100 / (frames.length - 1);
  const frameIndex = Math.floor(percent / frameChunkPercentSize);
  return (
    <div ref={elementRef}>
      <div className={Styles.projection}>
        <img src={frames[frameIndex].boundingBox} alt="" />
        {
          <img
            src={
              state.t === "paused" && state.hovered !== false
                ? frames[state.hovered].boundingBox
                : frames[frameIndex].boundingBox
            }
            alt=""
            style={{
              opacity:
                state.t === "paused" && state.hovered !== false
                  ? 1
                  : (percent - frameChunkPercentSize * frameIndex) / frameChunkPercentSize,
            }}
          />
        }
      </div>
      <div
        className={classnames(Styles.timeline, state.t === "scrubbing" && Styles.scrubbing)}
        style={{ height: TIMELINE_HEIGHT, width: "100%" }}
      >
        <div
          className={Styles.timelineBg}
          style={{ clipPath: `polygon(0px 0px, ${percent}% 0px, ${percent}% 100%, 0px 100%)` }}
        >
          {frames.slice(0, frames.length - 1).map((frame, index) => (
            <div
              key={index}
              onMouseEnter={() => {
                setState((o) => (o.t === "paused" ? { ...o, hovered: index } : o));
              }}
              onMouseLeave={() => {
                setState((o) => (o.t === "paused" ? { ...o, hovered: false } : o));
              }}
            />
          ))}
        </div>
        <button
          type="button"
          className={Styles.scrubber}
          onMouseDown={(event) =>
            setState((o) => ({
              t: "scrubbing",
              startMousePosition: event.clientX,
              progress: o.progress,
              startProgress: o.progress,
            }))
          }
          style={{ transform: `translateX(${state.progress}px)` }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SCRUBBER_WIDTH}
            height={TIMELINE_HEIGHT}
            fill="none"
            viewBox="0 0 94 217"
          >
            <path
              className={Styles.scrubberBar}
              strokeLinecap="round"
              strokeWidth="6"
              d="M33.3371 210H60.9559C65.6601 210 68.5345 204.833 66.0537 200.836L55.4544 183.759C53.2922 180.276 52.1465 176.257 52.1465 172.157V19C52.1465 16.2386 49.9079 14 47.1465 14C44.3851 14 42.1465 16.2386 42.1465 19V172.157C42.1465 176.257 41.0008 180.276 38.8386 183.759L28.2393 200.836C25.7584 204.833 28.6329 210 33.3371 210Z"
            />
          </svg>
        </button>
      </div>
      <div className={Styles.ticks}>
        {frames.slice(0, frames.length - 1).map((frame, index) => (
          <div key={index}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        ))}
      </div>
      <div className={Styles.actions}>
        <Button
          onClick={() =>
            setState((o) => {
              const isPaused = o.t === "paused";
              return isPaused
                ? { t: "playing", progress: o.progress }
                : {
                    t: "paused",
                    progress: o.progress >= max_progress ? MIN_PROGRESS : o.progress,
                    hovered: false,
                  };
            })
          }
          variant="primary"
          buttonColor="action"
        >
          <FormattedMessage
            id="d323db60-705c-4257-9507-f81cafec0c6e"
            defaultMessage="{isPlaying, select, true{Pause} other{Play}}"
            values={{ isPlaying: state.t === "playing" }}
          />
        </Button>
        <Button variant="secondary" buttonColor="action">
          <FormattedMessage
            id="1fb34b12-05e7-470a-9791-58e895f2a549"
            defaultMessage="View meeting recording {icon}"
            values={{ icon: <Icon name="new-window" /> }}
          />
        </Button>
      </div>
    </div>
  );
}

function LoadedDetails({ transaction }: { transaction: OrganizationTransaction }) {
  const [selected, setSelected] = useState<SelectedDetection>(null);
  const firstMeetingSignal = transaction.deepfakeRiskSignals?.at(0);
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div>
          <Heading level="h1" textStyle="headingTwo">
            <FormattedMessage
              id="29be27ab-3aad-43c9-9acf-be8f22bd0ef3"
              defaultMessage="Risk score"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="4f5de0da-890a-48ac-ba5a-883c1008f635"
              defaultMessage="Risk score summarizes the Proof platform's evaluation of this transaction's risk of digital fraud using generative AI techniques. See below for a comprehensive look at the signals leading to this score."
            />
          </Paragraph>
          <div>
            <Button
              variant="secondary"
              buttonColor="action"
              withIcon={{ placement: "right", name: "new-window" }}
              onClick={() => navigate("../video")}
            >
              <FormattedMessage
                id="1fb34b12-05e7-470a-9791-58e895f2a549"
                defaultMessage="View meeting recording"
              />
            </Button>
          </div>
        </div>
        <div>
          <div className={Styles.gauge}>
            <div className={Styles.slices}>
              <div />
              <div />
              <div />
            </div>
            <div className={Styles.needle} data-risk-score={firstMeetingSignal?.riskScore} />
            <div className={Styles.gaugeCenter}>
              {firstMeetingSignal ? (
                SCORE_LABELS[firstMeetingSignal.riskScore]
              ) : (
                <FormattedMessage
                  id="d576624b-ce2b-4d27-9ffe-966d69d45da1"
                  defaultMessage="Unknown risk"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {firstMeetingSignal && (
        <div className={Styles.signals}>
          <Heading level="h2" textStyle="headingFour">
            <FormattedMessage
              id="6eb4e258-0b68-4902-961d-970e8d546ecf"
              defaultMessage="Detection"
            />
          </Heading>
          <ul className={Styles.signalsList}>
            <SignalItem
              riskScore={firstMeetingSignal.riskScore}
              header={
                <FormattedMessage
                  id="3faa9892-9f73-4514-9959-058b8e1260b3"
                  defaultMessage="Frame Analysis"
                />
              }
              content={
                <FormattedMessage
                  id="b7a7e0b7-2e2b-4d7c-8d1b-7d8b9b7c7b4d"
                  defaultMessage="View frame by frame analysis of the meeting recording."
                />
              }
              selected={selected === "frame-analysis"}
              onSelect={() =>
                setSelected((o) => (o === "frame-analysis" ? null : "frame-analysis"))
              }
              outerContent={<FaceHeatmapTimeline frames={firstMeetingSignal.frames} />}
            />
            <SignalItem
              riskScore={firstMeetingSignal.signals.pose.riskScore}
              header={
                <FormattedMessage
                  id="3faa9892-9f73-4514-9959-058b8e1260b3"
                  defaultMessage="Face reenactment"
                />
              }
              content={
                <>
                  {firstMeetingSignal.signals.pose.riskScore ===
                  DeepfakeRiskSignalRiskScore.HIGH ? (
                    <FormattedMessage
                      id="1b296f80-3a01-4a11-98d8-d7ab68571b7c"
                      defaultMessage="Analysis strongly suggests that the signer's pose does not change during the meeting, which can be an signal that they are using face reenactment for impersonation."
                    />
                  ) : firstMeetingSignal.signals.pose.riskScore ===
                    DeepfakeRiskSignalRiskScore.MEDIUM ? (
                    <FormattedMessage
                      id="41b572be-ddf4-470c-bb2a-fbeef0a3f933"
                      defaultMessage="Analysis suggests that the signer's pose not change frequently during the meeting, which might be an indication signal that they are using face reenactment for impersonation."
                    />
                  ) : (
                    <FormattedMessage
                      id="9bf967e4-2a0d-4673-bb14-6ad673181b66"
                      defaultMessage="The signer's pose changes frequently, indicating that they did not use face reenactment"
                    />
                  )}
                  {selected === "face-reenactment" && (
                    <ul className={Styles.moreInfoList}>
                      <FormattedMessage
                        id="6a861ff1-759c-4e4f-a1e8-458106837c59"
                        tagName="li"
                        defaultMessage="Pose: {risk}"
                        values={{ risk: SCORE_LABELS[firstMeetingSignal.signals.pose.riskScore] }}
                      />
                      <FormattedMessage
                        id="8a653b54-4e69-4d77-8bcd-7bcf69bcc1db"
                        tagName="li"
                        defaultMessage="Jitter: {risk}"
                        values={{ risk: SCORE_LABELS[firstMeetingSignal.signals.jitter.riskScore] }}
                      />
                    </ul>
                  )}
                </>
              }
              selected={selected === "face-reenactment"}
              outerContent={<PoseComparison signal={firstMeetingSignal} />}
              onSelect={() =>
                setSelected((o) => (o === "face-reenactment" ? null : "face-reenactment"))
              }
            />
            <SignalItem
              riskScore={firstMeetingSignal.signals.photoIdSimilarity.riskScore}
              header={
                <FormattedMessage
                  id="fe999694-3fdc-4e83-9f74-9c35e6a24670"
                  defaultMessage="Video and ID facial similarity"
                />
              }
              content={
                <>
                  {firstMeetingSignal.signals.photoIdSimilarity.riskScore ===
                  DeepfakeRiskSignalRiskScore.HIGH ? (
                    <FormattedMessage
                      id="7cca0348-a87e-46d3-97f0-628267e09e28"
                      defaultMessage="Analysis indicates that the signer's face on the ID is overly similar to the face in the recording."
                    />
                  ) : firstMeetingSignal.signals.photoIdSimilarity.riskScore ===
                    DeepfakeRiskSignalRiskScore.MEDIUM ? (
                    <FormattedMessage
                      id="38aec929-4954-4b8d-a936-9c3d20a677c6"
                      defaultMessage="Analysis indicates that the signer's face on the ID is dissimilar to the face in the recording."
                    />
                  ) : (
                    <FormattedMessage
                      id="9f61a925-f2a9-4636-bf34-76707151c129"
                      defaultMessage="Analysis found that the signer and their ID are similar."
                    />
                  )}
                  {selected === "face-similarity" && (
                    <ul className={Styles.moreInfoList}>
                      <FormattedMessage
                        id="6a861ff1-759c-4e4f-a1e8-458106837c59"
                        tagName="li"
                        defaultMessage="ID / in Meeting Face Closest Similarity: {similarity}%"
                        values={{
                          similarity:
                            firstMeetingSignal.signals.photoIdSimilarity.similarityPercentage.toFixed(
                              2,
                            ),
                        }}
                      />
                      <FormattedMessage
                        id="8a653b54-4e69-4d77-8bcd-7bcf69bcc1db"
                        tagName="li"
                        defaultMessage="ID / in Meeting Face Similarity Standard Deviation: {std_dev}"
                        values={{
                          std_dev:
                            firstMeetingSignal.signals.photoIdSimilarity.similarityStandardDeviation.toFixed(
                              4,
                            ),
                        }}
                      />
                    </ul>
                  )}
                </>
              }
              selected={selected === "face-similarity"}
              outerContent={<IDComparison signal={firstMeetingSignal} />}
              onSelect={() =>
                setSelected((o) => (o === "face-similarity" ? null : "face-similarity"))
              }
            />
            <SignalItem
              riskScore={firstMeetingSignal.signals.imageQuality.riskScore}
              header={
                <FormattedMessage
                  id="a56f5621-c109-41b8-bfc1-1fc823eb3ef9"
                  defaultMessage="Liveness"
                />
              }
              selected={selected === "liveness"}
              onSelect={() => setSelected((o) => (o === "liveness" ? null : "liveness"))}
              content={
                <>
                  {firstMeetingSignal.signals.imageQuality.riskScore ===
                  DeepfakeRiskSignalRiskScore.HIGH ? (
                    <FormattedMessage
                      id="ddf60ba0-482c-49aa-8ba3-4d8cea2faeec"
                      defaultMessage='Analysis strongly indicates low image quality, a marker of signer low-"liveness".'
                    />
                  ) : firstMeetingSignal.signals.imageQuality.riskScore ===
                    DeepfakeRiskSignalRiskScore.MEDIUM ? (
                    <FormattedMessage
                      id="5737b7f3-d074-45f1-a36e-cf0eb4b9232b"
                      defaultMessage='Analysis indicates low image quality, a marker of signer low-"liveness".'
                    />
                  ) : (
                    <FormattedMessage
                      id="644293da-8ae3-48b3-950f-f3e741594a56"
                      defaultMessage="The signer's video passed liveness checks."
                    />
                  )}
                  {selected === "liveness" && (
                    <ul className={Styles.moreInfoList}>
                      <FormattedMessage
                        id="0f055ff4-b979-43c7-855b-c45da944262a"
                        tagName="li"
                        defaultMessage="Image quality: {risk}"
                        values={{
                          risk: SCORE_LABELS[firstMeetingSignal.signals.imageQuality.riskScore],
                        }}
                      />
                    </ul>
                  )}
                </>
              }
            />
          </ul>
        </div>
      )}
    </div>
  );
}

function RiskDetails(props: { transaction: { id: string } }) {
  const { data, loading } = useQuery(TransactionRiskQuery, {
    variables: { transactionId: props.transaction.id },
  });

  if (loading) {
    return (
      <div className={Styles.container}>
        <LoadingIndicator />
      </div>
    );
  }

  const transaction = data?.transaction;
  if (transaction?.__typename !== "OrganizationTransaction") {
    throw new Error(`Expected OrganizationTransaction, got ${transaction?.__typename}`);
  }
  return <LoadedDetails transaction={transaction} />;
}

export default memo(RiskDetails);
