import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState, useCallback, useMemo } from "react";

import { DetailsRow, DetailsRowHeader, BinaryStatusWithToggle } from "common/notary/details";
import Tooltip from "common/core/tooltip";
import { SelectInput, StyledSelectInput } from "common/form/inputs/select";
import { CustomFormattedDateTime } from "common/core/format/date";
import AlertMessage from "common/core/alert_message";
import Icon from "common/core/icon";
import ClickOutside from "common/core/click_outside";
import { useRawMutation } from "util/graphql/mutation";
import { useConfiguration, CAPACITY_STATUS_LABELS } from "admin_portal/notaries/util";
import {
  NotaryActivationStatus,
  type NotaryCapacityType,
  type NotaryLevels,
} from "graphql_globals";

import { SAVING_INDICATOR } from ".";
import type {
  NotaryCapacities as NotaryProfile,
  NotaryCapacities_capacities as NotaryCapacity,
} from "./index_fragment.graphql";
import UpdateNotarySkillsMutation from "../update_notary_skills_mutation.graphql";
import UpdateNotaryOnDemandCapacityMutation from "./update_notary_on_demand_capacity_mutation.graphql";

type OnDemandCapacity = Omit<NotaryCapacity, "type"> & {
  type: NotaryCapacityType.ON_DEMAND;
};

type Props = {
  notaryProfile: NotaryProfile;
  canUpdate: boolean;
  capacity: OnDemandCapacity;
};

const MESSAGES = defineMessages({
  statusLabel: {
    id: "34e2a752-c37d-4693-9d63-f9d10b1286f5",
    defaultMessage: "More details about notary on-demand capacity status",
  },
  trainingLabel: {
    id: "7a06e67f-3aec-4336-9dda-c8c8d146be78",
    defaultMessage: "More details about notary training status",
  },
});

function useUpdateStatus(notaryProfile: NotaryProfile, onDemandCapacity: OnDemandCapacity) {
  const statusChangeOptions = useMemo(() => {
    if (!onDemandCapacity.editable) {
      return [];
    }
    const possibleTransitions = [
      NotaryActivationStatus.REJECTED,
      NotaryActivationStatus.TERMINATED,
      NotaryActivationStatus.ACCEPTED,
    ];
    if (!possibleTransitions.includes(onDemandCapacity.status)) {
      possibleTransitions.push(onDemandCapacity.status);
    }
    return Object.entries(CAPACITY_STATUS_LABELS)
      .filter(([status]) => possibleTransitions.includes(status as NotaryActivationStatus))
      .map(([value, label]) => ({ value, label }));
  }, [onDemandCapacity]);
  const [updateStatusError, setUpdateStatusError] = useState<null | string>(null);
  const [updateNotaryOnDemandCapacityMutateFn, { loading }] = useRawMutation(
    UpdateNotaryOnDemandCapacityMutation,
  );
  const handleChange = useCallback(
    (newStatus: NotaryActivationStatus) => {
      setUpdateStatusError(null);
      if (newStatus === onDemandCapacity.status) {
        return;
      }
      updateNotaryOnDemandCapacityMutateFn({
        variables: {
          input: { notaryProfileId: notaryProfile.id, activationStatus: newStatus },
        },
      }).catch((error: Error) => {
        setUpdateStatusError(error.message);
      });
    },
    [notaryProfile, onDemandCapacity],
  );
  return {
    isSaving: loading,
    statusChangeOptions,
    updateStatusError,
    clearStatusError: () => setUpdateStatusError(null),
    handleChange,
  };
}

function useUpdateMortgageEnabled({ id: notaryProfileId }: NotaryProfile) {
  const [updateMortgageEnabledMutateFn, { loading }] = useRawMutation(UpdateNotarySkillsMutation);
  const handleChange = useCallback(
    (newValue: boolean) => {
      return updateMortgageEnabledMutateFn({
        variables: {
          input: {
            notaryProfileId,
            mortgageEnabled: newValue,
          },
        },
      });
    },
    [notaryProfileId, updateMortgageEnabledMutateFn],
  );
  return {
    isSaving: loading,
    handleChange,
  };
}

function useUpdateOndemandWitnessEnabled({ id: notaryProfileId }: NotaryProfile) {
  const [updateOndemandWitnessEnabledMutateFn, { loading }] = useRawMutation(
    UpdateNotarySkillsMutation,
  );
  const handleChange = useCallback(
    (newValue: boolean) => {
      return updateOndemandWitnessEnabledMutateFn({
        variables: {
          input: {
            notaryProfileId,
            odnwEnabled: newValue,
          },
        },
      });
    },
    [notaryProfileId, updateOndemandWitnessEnabledMutateFn],
  );
  return {
    isSaving: loading,
    handleChange,
  };
}

function useUpdateTrainingEnabled({ id: notaryProfileId }: NotaryProfile) {
  const [updateSkillsMutateFn, { loading }] = useRawMutation(UpdateNotarySkillsMutation);
  const handleChange = useCallback(
    (enabled: boolean) => {
      return updateSkillsMutateFn({
        variables: {
          input: {
            notaryProfileId,
            trainingEnabled: enabled,
          },
        },
      });
    },
    [notaryProfileId, updateSkillsMutateFn],
  );
  return {
    isSaving: loading,
    handleChange,
  };
}

function useLevelSelection({ id: notaryProfileId }: NotaryProfile) {
  const [updateLevelMutateFn, { loading }] = useRawMutation(UpdateNotarySkillsMutation);
  const handleChange = useCallback(
    (level: NotaryLevels) => {
      return updateLevelMutateFn({
        variables: {
          input: {
            notaryProfileId,
            notaryLevel: level,
          },
        },
      });
    },
    [notaryProfileId, updateLevelMutateFn],
  );
  return {
    isSaving: loading,
    handleChange,
  };
}

function OnDemandCapacityDetails({ notaryProfile, canUpdate, capacity }: Props) {
  const intl = useIntl();
  const trainingSelection = useUpdateTrainingEnabled(notaryProfile);
  const levelSelection = useLevelSelection(notaryProfile);
  const mortgageToggleSelection = useUpdateMortgageEnabled(notaryProfile);
  const ondemandWitnessToggleSelection = useUpdateOndemandWitnessEnabled(notaryProfile);
  const { levelsDropdownItems } = useConfiguration();
  const statusSelection = useUpdateStatus(notaryProfile, capacity);

  return (
    <>
      <DetailsRowHeader>
        <FormattedMessage
          id="1823f147-482f-4708-843d-25b36d00326a"
          defaultMessage="On-Demand Details"
        />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <>
            <FormattedMessage
              id="c274c4fd-8edf-4528-b125-ff2b6d871f55"
              defaultMessage="Capacity Status"
            />
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(MESSAGES.statusLabel)}
            >
              <FormattedMessage
                id="3aecb8e2-0700-4884-873f-1722ef20ea4d"
                defaultMessage="Notaries must be approved to take On-Demand meetings. Note: Notaries must also have a compliant profile that is not disabled."
              />
            </Tooltip>
            {statusSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <div>
          <StyledSelectInput
            disabled={!statusSelection.statusChangeOptions.length || !canUpdate}
            value={capacity.status}
            onChange={statusSelection.handleChange}
            items={statusSelection.statusChangeOptions}
            data-automation-id="notary-compliance-input-field"
            searchable={false}
            clearable={false}
          />
          {statusSelection.updateStatusError && (
            <AlertMessage kind="danger">
              <ClickOutside onClickOutside={statusSelection.clearStatusError}>
                <div>
                  {statusSelection.updateStatusError === "incomplete_capacity_requirements" ? (
                    <FormattedMessage
                      id="00dd1c64-2c6c-4a32-b0f6-2790ee5dc8dc"
                      defaultMessage={"Invalid profile fields: {invalidFields}"}
                      values={{ invalidFields: capacity.validation.invalidFields.join(", ") }}
                    />
                  ) : (
                    <FormattedMessage
                      id="01fa42d6-a76c-4eba-b479-9fea3403a3d6"
                      defaultMessage={"Invalid status update"}
                    />
                  )}
                </div>
              </ClickOutside>
            </AlertMessage>
          )}
        </div>
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <span id="training-toggle">
              <FormattedMessage
                id="5258f7a4-6e80-4363-b6d4-113e2b2494c6"
                defaultMessage="Training"
              />
            </span>
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(MESSAGES.trainingLabel)}
            >
              <FormattedMessage
                id="875d9acb-1ded-442c-b243-bc6b8a3f2c83"
                defaultMessage="Notary in training can only take meetings of Training organization"
              />
            </Tooltip>
            {trainingSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <BinaryStatusWithToggle
          aria-labelledby="training-toggle"
          automationId="notaryTrainingEnabled"
          onChange={canUpdate ? trainingSelection.handleChange : undefined}
          value={notaryProfile.trainingEnabled}
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <FormattedMessage id="822c8918-24da-40fd-9d3d-9490de50a5ab" defaultMessage="Level" />
            {levelSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <SelectInput
          disabled={!canUpdate}
          value={notaryProfile.notaryLevel}
          onChange={levelSelection.handleChange}
          items={levelsDropdownItems}
          data-automation-id="notary-level-input-field"
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <span id="mortgage-toggle">
              <FormattedMessage
                id="1af07edb-f2fe-4fbd-9dec-dd0ebb118614"
                defaultMessage="Mortgage Capable"
              />
            </span>
            {mortgageToggleSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <BinaryStatusWithToggle
          aria-labelledby="mortgage-toggle"
          automationId="mortgageEnabled"
          onChange={canUpdate ? mortgageToggleSelection.handleChange : undefined}
          value={notaryProfile.mortgageEnabled}
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <span id="odnw-toggle">
              <FormattedMessage
                id="df59fcdd-251b-401c-b772-da75aa17feef"
                defaultMessage="On-demand Witness Capable"
              />
            </span>
            {ondemandWitnessToggleSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <BinaryStatusWithToggle
          aria-labelledby="odnw-toggle"
          automationId="odnWitnessEnabled"
          onChange={canUpdate ? ondemandWitnessToggleSelection.handleChange : undefined}
          value={notaryProfile.ondemandWitnessingEnabled}
        />
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage id="8c1e84d8-0bd8-42da-92ae-3298e7f587c9" defaultMessage="NNA ID" />
        }
      >
        {notaryProfile.nnaId}
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage
            id="ade5959d-3b5c-4241-8a3f-e988dfc7736e"
            defaultMessage="NNA Background Check Expiration Date"
          />
        }
      >
        <CustomFormattedDateTime formatStyle="PPP" value={notaryProfile.backgroundCheckExpiry} />
      </DetailsRow>

      <DetailsRow
        label={
          <FormattedMessage
            id="8ba54005-bce5-49b3-b5ee-75f1f96d1142"
            defaultMessage="NSA Exam Expiration Date"
          />
        }
      >
        <CustomFormattedDateTime formatStyle="PPP" value={notaryProfile.nnaTrainingExpiry} />
      </DetailsRow>
    </>
  );
}

export default OnDemandCapacityDetails;
