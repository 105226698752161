import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

type Props = {
  onClose: () => void;
  onAccept: () => Promise<unknown>;
};

function RevokeUserCertModal(props: Props) {
  const { onClose, onAccept } = props;
  const [isLoading, setLoading] = useState(false);

  const handleAccept = () => {
    setLoading(true);
    onAccept().finally(() => setLoading(false));
  };

  return (
    <IllustrationModal
      className="AdminRevokeUserCertModal"
      title={
        <FormattedMessage
          id="930b68dd-1c41-4e1b-a838-0f6e3b135d1e"
          defaultMessage="Are you sure you want to revoke this user's certificate?"
        />
      }
      buttons={[
        <Button
          key="0"
          buttonColor="danger"
          variant="primary"
          automationId="revoke-user-cert-button"
          onClick={handleAccept}
          isLoading={isLoading}
        >
          <FormattedMessage id="7caa2457-0508-48a5-a6d7-c34159921f98" defaultMessage="Revoke" />
        </Button>,
        <Button
          key="1"
          buttonSize="large"
          buttonColor="dark"
          variant="tertiary"
          automationId="cancel-revoke-status-management"
          onClick={onClose}
        >
          <FormattedMessage id="093562fb-350b-45dc-b944-49942b9427d3" defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="be8dfb60-3a2c-4a3f-9421-0323db29a89b"
        defaultMessage="Revoking this user's certificate will force them to create a new one by re-verifying their identity."
      />
    </IllustrationModal>
  );
}

export default memo(RevokeUserCertModal);
