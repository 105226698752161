import { FormattedMessage } from "react-intl";
import { useNavigate, Outlet } from "react-router-dom";

import Dashboard from "common/dashboard";
import { Sidebar, SidebarTabLink, SidebarBackButton } from "common/sidebar";

function OrganizationFlagsWrapper() {
  const navigate = useNavigate();

  return (
    <Dashboard>
      <Sidebar
        title={
          <FormattedMessage
            id="75859c8c-c7ee-478f-a67b-533a1ef2a5ea"
            defaultMessage="Organization Flags"
          />
        }
        backButton={
          <SidebarBackButton
            onClick={() => {
              navigate("/");
            }}
          />
        }
      >
        <SidebarTabLink to={"/organization-flags/all"}>
          <FormattedMessage id="d881e995-16a2-44da-ae36-15de8325b2be" defaultMessage="All Flags" />
        </SidebarTabLink>
      </Sidebar>
      <Outlet />
    </Dashboard>
  );
}

export default OrganizationFlagsWrapper;
