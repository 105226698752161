import "common/form/form.scss";

import type { ComponentProps, ReactElement } from "react";
import { reduxForm, SubmissionError, type InjectedFormProps } from "redux-form";
import { useNavigate } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { useQuery, useMutation } from "util/graphql";
import compose from "util/compose";
import Modal from "common/modal";
import { composeValidators, getFormValues, getFormErrors } from "util/form";
import { validatePresence } from "validators/form";
import { validateEmailFormat } from "validators/account";
import { duplicateEmail } from "errors/account";
import { newPathWithPreservedSearchParams } from "util/location";

import NewAdminForm from "./form";
import CreateAdminMutation from "./create_admin_mutation.graphql";
import AdminListDashboardNewAdminQuery, {
  type AdminListDashboardNewAdmin_viewer as Viewer,
} from "./new_query.graphql";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  roles: { value: string }[] | null;
};
type LoadedProps = {
  viewer: Viewer;
  formValues: FormValues;
  formErrors: ComponentProps<typeof NewAdminForm>["formErrors"];
};
type InnerProps = LoadedProps & InjectedFormProps<FormValues>;

function NewAdminModal(props: InnerProps) {
  const navigate = useNavigate();
  const createAdminMutateFn = useMutation(CreateAdminMutation);

  const createAdmin = (values: FormValues) => {
    const roleSchedules = values.roles?.map((role) => ({ userRoleId: role.value })) || [];
    return createAdminMutateFn({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roleSchedules,
        },
      },
    })
      .then(() => {
        navigate(newPathWithPreservedSearchParams("/admin/records"));
      })
      .catch(() => {
        return Promise.reject(
          new SubmissionError({ email: duplicateEmail() as unknown as string }),
        );
      });
  };

  return (
    <Modal
      className="BusinessNewEmployee"
      title="Add an Admin"
      closeRoute={newPathWithPreservedSearchParams("/admin")}
    >
      <NewAdminForm
        handleSubmit={props.handleSubmit(createAdmin)}
        title="Add Admin"
        updatedTitle="Admin added"
        formErrors={props.formErrors}
        formValues={props.formValues}
        assignableRoles={props.viewer.user?.adminProfile?.assignableRoles || []}
      />
    </Modal>
  );
}

const Form = compose(
  reduxForm({
    form: "newAdmin",
    validate: composeValidators(
      validateEmailFormat({ field: "email", label: "Email" }),
      validatePresence({ field: "firstName", label: "First name" }),
      validatePresence({ field: "lastName", label: "Last name" }),
      validatePresence({ field: "email", label: "Email" }),
      validatePresence({ field: "roles", label: "At least one role" }),
    ),
  }),
  getFormValues("newAdmin"),
  getFormErrors("newAdmin"),
)(NewAdminModal as unknown as () => ReactElement) as unknown as (p: {
  viewer: Viewer;
}) => ReactElement;

function NewAdminModalContainer() {
  const { data, loading } = useQuery(AdminListDashboardNewAdminQuery);
  return loading ? <LoadingIndicator /> : <Form viewer={data!.viewer} />;
}

export default NewAdminModalContainer;
