import "common/app_frame/index.scss";
import "admin_portal/index.scss";
import "common/styles/dashboard.scss";

import type { ReactNode } from "react";

import AppFrame from "common/app_frame";
import UpdateAppBanner from "common/banners/update_app_banner";
import { HeaderNavStyle } from "common/app_frame/header/nav";
import { AccountMenu } from "common/account/menu";
import type { AccountMenuUser as User } from "common/account/menu/user_fragment.graphql";

type Props = {
  children: ReactNode;
  viewer: {
    user: null | User;
  };
};

// Eventually this could replace the notary/admin "panel" common
function AdminAppFrame({ viewer, children }: Props) {
  return (
    <AppFrame
      content={
        <HeaderNavStyle>
          <AccountMenu user={viewer.user} />
        </HeaderNavStyle>
      }
      banners={<UpdateAppBanner />}
    >
      {children}
    </AppFrame>
  );
}

export default AdminAppFrame;
