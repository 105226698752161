import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { EmailTextInput, TextInput } from "common/core/form/text";
import { defaultRequiredMessage, emailPatternValidation } from "common/core/form/error";
import Button from "common/core/button";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMutation } from "util/graphql";
import { useForm } from "common/core/form";
import { AutomaticFormRow } from "common/core/form/layout";
import { OrganizationAccountProvisionStatuses as ProvisionStatus } from "graphql_globals";

import Styles from "./index.module.scss";
import UpdateOrganizationOwnerEmail from "./update_organization_owner_email_mutation.graphql";

type Props = {
  ownerEmail: string;
  orgId: string;
  canProvisionOrg: boolean;
  provisionStatus: ProvisionStatus | null;
  refetch: () => void;
};

type FormValues = {
  email: string;
};

const MESSAGES = defineMessages({
  sendEmail: { id: "2a291dc5-82bf-45ae-93ac-899b3431450e", defaultMessage: "Send email" },
  email: { id: "87ef6937-054f-4170-b245-f295ac7056e5", defaultMessage: "Email" },
  saveEmail: { id: "25bd3b09-2dc1-4a50-b585-e9aada099c31", defaultMessage: "Save Email" },
  emailAddress: { id: "f49badfa-37db-4ea8-9767-f4aece210c08", defaultMessage: "Email Address" },
});

export function OwnerEmailAddress({
  ownerEmail,
  orgId,
  canProvisionOrg,
  provisionStatus,
  refetch,
}: Props) {
  const intl = useIntl();
  const [mutationStatus, setMutationStatus] = useState<null | "error" | "loading">(null);
  const [showEditEmail, setShowEditEmail] = useState(false);

  const updateOrganizationOwnerEmail = useMutation(UpdateOrganizationOwnerEmail);

  const form = useForm<FormValues>({
    defaultValues: { email: ownerEmail },
  });

  const save = async ({ email }: FormValues) => {
    setMutationStatus("loading");
    try {
      await updateOrganizationOwnerEmail({
        variables: {
          input: {
            organizationId: orgId,
            email,
          },
        },
      });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: "Email successfully updated",
      });
      setMutationStatus(null);
      setShowEditEmail(false);
      refetch();
    } catch {
      setMutationStatus("error");
    }
  };

  return (
    <>
      {showEditEmail ? (
        <form onSubmit={form.handleSubmit(save)}>
          <div className={Styles.flex}>
            <AutomaticFormRow<FormValues>
              aria-label={intl.formatMessage(MESSAGES.email)}
              fullWidth
              form={form}
              name="email"
              registerOptions={{
                required: defaultRequiredMessage(intl),
                pattern: emailPatternValidation(intl),
              }}
              as={EmailTextInput}
            />

            <Button
              aria-label={intl.formatMessage(MESSAGES.saveEmail)}
              className={Styles.button}
              key="save"
              type="submit"
              buttonColor="action"
              variant="primary"
              isLoading={mutationStatus === "loading"}
            >
              <FormattedMessage id="d5d47393-046e-4b8a-ab67-dcce8052a06e" defaultMessage="Save" />
            </Button>
            <Button
              buttonColor="action"
              variant="secondary"
              className={Styles.button}
              key="cancel"
              type="button"
              onClick={() => {
                setShowEditEmail(false);
                form.reset();
              }}
              isLoading={mutationStatus === "loading"}
            >
              <FormattedMessage id="558b43ca-427c-4e34-9ea4-401d5c18d7f3" defaultMessage="Cancel" />
            </Button>
          </div>
        </form>
      ) : (
        <div className={Styles.flex}>
          <TextInput
            disabled
            aria-invalid={false}
            aria-label={intl.formatMessage(MESSAGES.email)}
            value={ownerEmail}
          />
          {canProvisionOrg && provisionStatus && provisionStatus !== ProvisionStatus.COMPLETE && (
            <Button
              className={Styles.button}
              buttonColor="action"
              variant="secondary"
              onClick={() => setShowEditEmail(true)}
            >
              <FormattedMessage
                id="170e8e28-e601-4752-951c-b629b9e21429"
                defaultMessage="Edit email"
              />
            </Button>
          )}
        </div>
      )}

      {mutationStatus === "error" && <MutationErrorModal onClick={() => setMutationStatus(null)} />}
    </>
  );
}
