import { FormattedMessage, useIntl, type MessageDescriptor } from "react-intl";
import { type Dispatch, type SetStateAction, useCallback, useState } from "react";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";

import { type UseFormReturn } from "common/core/form";
import { useMutation } from "util/graphql";
import { Badge } from "common/core/badge";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { XML_ACCEPTED } from "util/uploader";
import { IconButton } from "common/core/button/icon_button";
import { Dropzone } from "common/core/dropzone";
import { FieldErrorMessage } from "common/core/form/error";
import Icon from "common/core/icon";
import { CardSection } from "common/core/card";
import Link from "common/core/link";

import Styles from "./index.module.scss";
import DeleteSamlProviderMutation from "./delete-saml-provider.mutation.graphql";
import { type SamlProvider_organization_Organization_samlProviders as SamlProvider } from "../saml_providers.query.graphql";

const MESSAGES = {
  deleteFailed: {
    id: "5b21f969-8223-4873-810d-6d48c430ca41",
    defaultMessage:
      "Failed to delete identity provider. If this issue persists please contact support",
  },
  deleteModalTitle: {
    id: "efc5eabd-0006-40ae-9105-74383b091684",
    defaultMessage: "Are you sure you want to delete {idpName}?",
  },
  cancel: {
    id: "a5e67cc0-4434-4b43-b5b6-2b9e26d1b152",
    defaultMessage: "Cancel",
  },
  delete: {
    id: "f785d826-1653-4883-8ed1-1478fe5f0468",
    defaultMessage: "Delete",
  },
  deleteModalDescription: {
    id: "e21170c3-6262-4b26-a906-01f7f6992190",
    defaultMessage:
      "This identity provider is not in use, so it may be deleted. This action cannot be undone.",
  },
  inUseDeleteModalTitle: {
    id: "a2080a8d-7064-4179-bc08-0df350e1bee7",
    defaultMessage: "You cannot delete this identity provider because it is in use.",
  },
  inUseDeleteModalDescription: {
    id: "50d2024e-f2c0-405c-b30d-64c2fadea993",
    defaultMessage:
      "To delete this, choose a different authentication policy option for the associated domains.",
  },
  dismiss: {
    id: "84a56021-1181-4076-8f22-2e440633d237",
    defaultMessage: "Dismiss",
  },
  deleteSuccess: {
    id: "5692e2ae-73f0-4e77-a79f-8672fc65e9ed",
    defaultMessage: "{idpName} has been deleted.",
  },
  metadataFieldLabel: {
    id: "483134ba-c28b-4c6a-afc2-b8367a4adfdf",
    defaultMessage: "Metadata upload (.xml file)",
  },
  deleteMetadataFileLabel: {
    id: "856c0770-03bd-47c5-b3c1-26516b082d01",
    defaultMessage: "Delete metadata file",
  },
  invalidCertificateError: {
    id: "b4b9abe6-cac4-4597-bcf8-84153499a863",
    defaultMessage: "Invalid certificate",
  },
  missingRequiredInfoError: {
    id: "7f30e0f3-325a-4410-bfef-51a0e5b62a14",
    defaultMessage: "Missing required info. Please verify Name and Metadata file are present",
  },
  idpAlreadyExists: {
    id: "d9a9fe3b-52e0-4d04-8dcb-9a69dd01244d",
    defaultMessage: "Identity provider already exists",
  },
  unknownError: {
    id: "695d242c-89dc-4ade-a142-92b8a40ad7f1",
    defaultMessage: "Unknown error. Please contact customer support if this issue persists.",
  },
};
export function SamlProviderBadge({
  samlProviderId,
  samlDomainsLength,
  className,
}: {
  samlProviderId: string;
  samlDomainsLength: number;
  className?: string;
}) {
  return (
    <>
      {samlDomainsLength > 0 ? (
        <Badge
          kind="success"
          withIcon="success-filled"
          data-automation-id={`${samlProviderId}-badge`}
          className={className}
        >
          <FormattedMessage
            id="d4c6281e-5a53-4dbf-9ed0-e09bf9a139c6"
            defaultMessage="In use ({domainsCount} {domainsCount, plural, one {domain} other {domains}})"
            values={{ domainsCount: samlDomainsLength }}
          />
        </Badge>
      ) : (
        <Badge
          kind="infoBlue"
          withIcon="info"
          data-automation-id={`${samlProviderId}-badge`}
          className={className}
        >
          <FormattedMessage id="e817f800-8b45-4cdb-a777-f077575bd980" defaultMessage="Not in use" />
        </Badge>
      )}
    </>
  );
}

export function DeleteIdpModal({
  idp,
  setShowDeleteModal,
  onDeleteSuccess,
}: {
  idp: SamlProvider;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  onDeleteSuccess: () => void;
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const deleteSamlProviderMutation = useMutation(DeleteSamlProviderMutation);
  const deleteSamlProvider = useCallback(() => {
    setIsLoading(true);
    return deleteSamlProviderMutation({
      variables: {
        input: {
          id: idp.id,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(MESSAGES.deleteSuccess, { idpName: idp.name }),
          position: "topCenter",
        });
        onDeleteSuccess();
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.deleteFailed),
          position: "topCenter",
        });
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
        setShowDeleteModal(false);
      });
  }, [idp, deleteSamlProviderMutation]);

  return idp.domains.length > 0 ? (
    <WorkflowModal
      autoFocus
      positionTop
      title={intl.formatMessage(MESSAGES.inUseDeleteModalTitle)}
      buttons={[
        <Button
          key="dismiss"
          buttonColor="action"
          variant="primary"
          onClick={() => setShowDeleteModal(false)}
        >
          {intl.formatMessage(MESSAGES.dismiss)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      {intl.formatMessage(MESSAGES.inUseDeleteModalDescription)}
    </WorkflowModal>
  ) : (
    <WorkflowModal
      autoFocus
      positionTop
      title={intl.formatMessage(MESSAGES.deleteModalTitle, { idpName: idp.name })}
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          onClick={() => setShowDeleteModal(false)}
        >
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          key="delete-idp"
          buttonColor="danger"
          variant="primary"
          isLoading={isLoading}
          onClick={() => deleteSamlProvider()}
        >
          {intl.formatMessage(MESSAGES.delete)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      {intl.formatMessage(MESSAGES.deleteModalDescription)}
    </WorkflowModal>
  );
}

export type FormValues = {
  idpName: string;
  metadataFile: File | null;
};

const METADATA_FILE = "metadataFile";

export function MetadataUpload({
  form,
  mutationError,
  disabled = false,
  titleDescription,
  fileLocation,
}: {
  form: UseFormReturn<FormValues>;
  mutationError: string | null;
  disabled?: boolean;
  titleDescription?: string | null;
  fileLocation?: string | null;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
    setValue,
    watch,
  } = form;
  const metadataFile = watch(METADATA_FILE);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue(METADATA_FILE, acceptedFiles[0], { shouldDirty: true });
    },
    [setValue],
  );

  const dropzoneState = useDropzone({
    accept: XML_ACCEPTED,
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  return (
    <>
      <h5
        className={classnames(Styles.metadataInputTitle, errors.metadataFile && Styles.textError)}
      >
        {intl.formatMessage(MESSAGES.metadataFieldLabel)}
      </h5>

      {titleDescription && <div className={Styles.titleDescription}>{titleDescription}</div>}

      {metadataFile ? (
        <>
          <div className={Styles.metadataFileLoaded}>
            <div className={Styles.metadataFileNameGroup}>
              {fileLocation ? (
                <Link href={fileLocation} className={Styles.metadataFileLink}>
                  <span className={Styles.metadataFileName}>{metadataFile.name}</span>
                  <Icon name="new-window" size="large" />
                </Link>
              ) : (
                <span className={Styles.metadataFileName}>{metadataFile.name}</span>
              )}
            </div>

            <IconButton
              name="delete"
              data-automation-id={"delete-metadata"}
              onClick={() => {
                setValue(METADATA_FILE, null);
              }}
              variant="tertiary"
              buttonColor="danger"
              buttonSize="condensed"
              label={intl.formatMessage(MESSAGES.deleteMetadataFileLabel)}
              disabled={disabled}
            />
          </div>
        </>
      ) : (
        <>
          <Dropzone
            label={intl.formatMessage(MESSAGES.metadataFieldLabel)}
            dropzoneState={dropzoneState}
            inputProps={form.register(METADATA_FILE, {
              required: true,
            })}
            disabled={disabled}
          />
          {errors.metadataFile && (
            <div>
              <FieldErrorMessage
                inputName={METADATA_FILE}
                message={
                  errors.metadataFile.message || (
                    <FormattedMessage
                      id="ec6caa10-3181-4480-83e0-b858fa48e6fc"
                      defaultMessage="Upload a file to continue"
                    />
                  )
                }
              />
            </div>
          )}
        </>
      )}
      {mutationError && (
        <CardSection>
          <div className={Styles.errorBox}>
            <Icon className={Styles.errorBoxIcon} name="doc-warning" />
            <span data-automation-id="mutation-error-message">{mutationError}</span>
          </div>
        </CardSection>
      )}
    </>
  );
}

export function handleSamlProviderMutationError(error: Error): MessageDescriptor {
  if (error.message.includes("invalid_certificate")) {
    return MESSAGES.invalidCertificateError;
  } else if (error.message.includes("missing_required_info")) {
    return MESSAGES.missingRequiredInfoError;
  } else if (error.message.includes("already_exists")) {
    return MESSAGES.idpAlreadyExists;
  }
  captureException(error);
  return MESSAGES.unknownError;
}
