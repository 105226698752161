import "common/form/form.scss";

import { useEffect, type ComponentProps, type ReactElement } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import classnames from "classnames";

import compose from "util/compose";
import SaveButton from "common/core/save_button";
import { getFormErrors, getFormValues } from "util/form";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import UpdateUserMutation from "./update_user_mutation.graphql";
import NameEmailTimezoneQuery, {
  type NameEmailTimezone_viewer as Viewer,
} from "./name_email_timezone_query.graphql";
import UpdateNameEmailTimezoneFields, {
  validationRules as nameEmailValidationRules,
} from "./update_name_email_timezone_fields";

type FormValues = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  timezone: string;
  phoneNumber: string;
  phoneCountryCode: string;
};
type Props = {
  className?: string;
  includeTimezoneField?: boolean;
};
type InjectedProps = Props &
  InjectedFormProps<FormValues> & {
    formValues: FormValues;
    formErrors: ComponentProps<typeof UpdateNameEmailTimezoneFields>["formErrors"];
  };
type InnerProps = InjectedProps & { viewer: Viewer };

function NameEmailTimezoneFormLoaded(props: InnerProps) {
  const user = props.viewer.user!;
  const updateUserMutateFn = useMutation(UpdateUserMutation);

  useEffect(() => {
    const { phone } = user;
    props.initialize({
      id: user.id,
      firstName: user.firstName || "",
      middleName: user.middleName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      timezone: user.timezone || "",
      phoneNumber: phone ? phone.number : "",
      phoneCountryCode: phone ? phone.countryCode : "1",
    });
  }, []);

  const save = (values: FormValues) => {
    return updateUserMutateFn({
      variables: {
        input: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          timezone: values.timezone,
          phone: values.phoneNumber
            ? { number: values.phoneNumber, countryCode: values.phoneCountryCode || "1" }
            : null,
        },
      },
    });
  };
  return (
    <section>
      <form
        className={classnames("Form", props.className)}
        onSubmit={props.handleSubmit(save)}
        data-automation-id="account-form"
      >
        <UpdateNameEmailTimezoneFields
          includeTimezoneField={props.includeTimezoneField}
          needsVerification={user.needsVerification}
          formName={props.form}
          user={user}
          formValues={props.formValues}
          formErrors={props.formErrors}
        />
        <SaveButton {...props} className="is-form" />
      </form>
    </section>
  );
}

function NameEmailTimezoneForm(props: InjectedProps) {
  const { data, loading } = useQuery(NameEmailTimezoneQuery);
  return loading ? (
    <LoadingIndicator />
  ) : (
    <NameEmailTimezoneFormLoaded {...props} viewer={data!.viewer} />
  );
}

export default compose(
  reduxForm<FormValues, Props>({
    form: "NameEmailTimezoneForm",
    validate: (values, props) => nameEmailValidationRules(values, props)(values),
    asyncBlurFields: ["email"],
  }),
  getFormValues("NameEmailTimezoneForm"),
  getFormErrors("NameEmailTimezoneForm"),
)(NameEmailTimezoneForm as () => ReactElement);
