import { FormattedMessage } from "react-intl";
import { useEffect, useMemo } from "react";

import { CardHeading } from "common/core/card";
import MultiSelectInput from "common/form/inputs/multi_select";
import { useMutation } from "util/graphql";
import { useForm, Controller } from "common/core/form";
import Button from "common/core/button";
import type {
  FullAdminOrganizationDetails_viewer as Viewer,
  FullAdminOrganizationDetails_organization_Organization_closingStates as ClosingState,
} from "admin_portal/company/details_query.graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import UpdateOrganizationStateEligibilities from "./update_org_state_eligibility_mutation.graphql";
import Styles from "./index.module.scss";

export default ({
  viewer: { usStates },
  closingStates,
  organizationId,
}: {
  viewer: Pick<Viewer, "usStates">;
  closingStates: ClosingState[];
  organizationId: string;
}) => {
  const updateOrganizationStateEligibilities = useMutation(UpdateOrganizationStateEligibilities);
  const initialEligibleStateIds = useMemo(() => {
    return closingStates.map((closingState) => closingState.usState.id, [] as string[]);
  }, [closingStates]);

  const form = useForm({
    defaultValues: {
      eligibleStateIds: initialEligibleStateIds,
    },
  });

  const {
    control,
    handleSubmit,
    formState,
    setValue,
    formState: { isSubmitted },
    getValues,
    watch,
    reset,
  } = form;
  const eligibleStateIds = watch("eligibleStateIds");
  const usStateListOptions =
    usStates?.map((state) => ({
      label: state!.name,
      value: state!.id,
    })) ?? [];

  useEffect(() => {
    if (isSubmitted) {
      // basically just resets the form so that the CTA button is disabled after successful submit
      reset(getValues());
    }
  }, [isSubmitted]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        updateOrganizationStateEligibilities({
          variables: {
            input: {
              organizationId,
              usStateIds: data.eligibleStateIds,
            },
          },
        })
          .then(() => {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              message: "Eligibilities saved successfully",
            });
          })
          .catch(() => {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.ERROR,
              message: "Eligibilities failed to save",
            });
          });
      })}
    >
      <CardHeading>
        <FormattedMessage
          id="e76f9df7-627a-4c47-92a0-81dbc3a9d9e2"
          defaultMessage="Underwriter Eligibilities"
        />
      </CardHeading>
      <Controller
        name="eligibleStateIds"
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultiSelectInput
            onChange={(updatedEligibleStateOptions: { label: string; value: string }[]) => {
              const updatedEligibleStateIds = updatedEligibleStateOptions.map(({ value }) => value);
              onChange(updatedEligibleStateIds);
            }}
            items={usStateListOptions}
            value={value}
          />
        )}
      />
      <div className={Styles.footer}>
        <Button
          variant="tertiary"
          buttonColor="action"
          disabled={eligibleStateIds.length === usStateListOptions.length}
          onClick={() => {
            setValue(
              "eligibleStateIds",
              usStateListOptions.map(({ value }) => value),
              {
                shouldDirty: true,
              },
            );
          }}
        >
          <FormattedMessage
            id="f592f7fa-28f8-4ad9-9039-aee5914545e5"
            defaultMessage="Add all states"
          />
        </Button>
        <Button
          disabled={!formState.isDirty}
          className={Styles.footerCta}
          type="submit"
          buttonColor="action"
          variant="primary"
        >
          <FormattedMessage id="01a12589-c69a-4d3f-a9fb-654e2a21a7c0" defaultMessage="Save" />
        </Button>
      </div>
    </form>
  );
};
