import "./index.scss";

import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  children: ReactNode;
  className?: string;
};

function AdminExperimentContent(props: Props) {
  const { children, className } = props;

  const cx = classnames("AdminExperimentContent", className);
  return <div className={cx}>{children}</div>;
}

export default AdminExperimentContent;
