import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import { AutomaticFormRow } from "common/core/form/layout";
import { useForm, type FieldValues } from "common/core/form";
import { TextInput } from "common/core/form/text";
import Button from "common/core/button";
import { Paragraph } from "common/core/typography";
import { ErrorMessage, defaultRequiredMessage } from "common/core/form/error";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { isGraphQLError } from "util/graphql/query";
import { b, useId } from "util/html";

import type { KeystoneTasks_viewer_keystoneTasks as KeystoneTask } from "./index_query.graphql";
import RunTask from "./run_task_mutation.graphql";
import Styles from "./form.module.scss";

export function TaskModal({ task }: { task: KeystoneTask }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const form = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const formId = useId();

  const runTask = useMutation(RunTask);

  function handleTaskModalClose() {
    navigate("/tasks", { replace: true });
  }

  function handleSubmit(formValues: FieldValues) {
    setIsSubmitting(true);
    setErrors([]);
    const { reason, ...values } = formValues;

    runTask({
      variables: {
        input: {
          identifier: task.identifier,
          params: values,
          reason,
        },
      },
    })
      .then(() => {
        pushNotification({
          message: (
            <FormattedMessage
              id="d46ae2e0-fe29-4efb-9c8a-f76ff793fa2e"
              defaultMessage="The task <b>{taskName}</b> was executed successfully."
              values={{ b, taskName: task.label }}
            />
          ),
        });
        handleTaskModalClose();
      })
      .catch((error) => {
        if (isGraphQLError(error)) {
          setErrors(error.graphQLErrors.map((e) => e.specifics!));
        } else {
          pushNotification({
            subtype: "ERROR",
            message: (
              <FormattedMessage
                id="c8959a2d-9071-4883-aedf-c98a270bd68c"
                defaultMessage="Something went wrong. Please try again."
              />
            ),
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <WorkflowModal
      closeBehavior={{
        tag: "with-button",
        onClose: handleTaskModalClose,
        disableClickOutside: true,
      }}
      title={task.label}
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          disabled={isSubmitting}
          onClick={handleTaskModalClose}
        >
          <FormattedMessage id="4d9cdcd8-cabb-4753-be35-ec4d8d69e3e3" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="submit"
          buttonColor="action"
          variant="primary"
          isLoading={isSubmitting}
          form={formId}
        >
          <FormattedMessage id="40af84d5-acef-4be6-bb08-cc84f1ccd8e9" defaultMessage="Submit" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <form id={formId} onSubmit={form.handleSubmit(handleSubmit)}>
        <Paragraph className={Styles.description}>{task.description}</Paragraph>

        {task.fields.map((field, index) => {
          switch (field.__typename) {
            case "KeystoneTasksTextField":
              return (
                <AutomaticFormRow
                  key={index}
                  label={field.label}
                  form={form}
                  name={field.identifier}
                  registerOptions={{
                    required: field.required ? defaultRequiredMessage(intl) : false,
                  }}
                  required={field.required}
                  as={TextInput}
                />
              );
            default:
              throw new Error(`Unknown field type: ${field.__typename}`);
          }
        })}

        <AutomaticFormRow
          label={
            <FormattedMessage
              id="ebb12304-7d32-4744-bf95-27dc43d559ab"
              defaultMessage="Reason for running this task"
            />
          }
          form={form}
          name="reason"
          as={TextInput}
          registerOptions={{
            required: defaultRequiredMessage(intl),
          }}
          required
        />

        {errors.map((error, index) => (
          <ErrorMessage key={index} message={error} />
        ))}
      </form>
    </WorkflowModal>
  );
}
