import { useState, useCallback } from "react";

type WaitControls = {
  waiting: boolean;
  startWaiting: <T = unknown>(promise: Promise<T>) => Promise<T>;
};

export function useAwaitPromise(): WaitControls {
  const [waiting, setWaiting] = useState(false);
  return {
    waiting,
    startWaiting: useCallback((promise) => {
      setWaiting(true);
      return promise.finally(() => {
        setWaiting(false);
      });
    }, []),
  };
}
