import { useIntl, defineMessages } from "react-intl";
import { useState, type ChangeEvent, useRef } from "react";

import { Textarea } from "common/form/inputs/textarea";
import Button from "common/core/button";
import { simpleAssetUpload } from "util/uploader";
import attachmentImage from "assets/images/attachment.svg";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import SmallLoader from "common/core/loading_indicator/small";

import Styles from "./index.module.scss";

const ACCEPTED_FILE_TYPES = "image/png,image/jpeg,application/pdf";

const messages = defineMessages({
  defaultNoteText: {
    id: "b73a3949-5a52-4e1d-89e4-4f817a295281",
    defaultMessage: "Enter new note",
  },
  cancel: {
    id: "8ed5f753-e0ec-48c1-b011-86dd051ec69c",
    defaultMessage: "Cancel",
  },
  save: {
    id: "961de06c-6678-4e35-bb11-9d314b2ce90e",
    defaultMessage: "Save",
  },
  errorUploading: {
    id: "0f9fd2ef-00cc-40d6-8c3c-330b66f1fd30",
    defaultMessage: "Failed to upload",
  },
});

type Props = {
  onClose: () => void;
  onSave: (noteText: string | null, attachments: Attachment[]) => Promise<unknown>;
};

type Attachment = {
  file: File;
} & (
  | {
      state: "uploading" | "error";
      key?: null;
    }
  | {
      key: string;
      state: "done";
    }
);

export default function AddNote({ onClose, onSave }: Props) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const [noteText, setNoteText] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const upsertAttachment = (attachment: Attachment) =>
    setAttachments((attachments) => [
      ...attachments.filter((a) => a.file.name !== attachment.file.name),
      attachment,
    ]);
  const removeAttachment = (attachment: Attachment) =>
    setAttachments((attachments) =>
      attachments.filter((a) => a.file.name !== attachment.file.name),
    );
  const handleUploadFileSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files![0];
    upsertAttachment({ state: "uploading", file });
    simpleAssetUpload({ asset: file })
      .then((key) => {
        upsertAttachment({ state: "done", file, key });
      })
      .catch(() => {
        upsertAttachment({ state: "error", file });
      });
  };
  const handleNoteSave = () => {
    setIsSubmitting(true);
    return onSave(noteText, attachments)
      .then(() => {
        setIsSubmitting(false);
        onClose();
      })
      .catch(() => setIsSubmitting(false));
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.note}>
        <div className={Styles.textEntry}>
          <Textarea
            placeholder={intl.formatMessage(messages.defaultNoteText)}
            value={noteText}
            onChange={(event) => setNoteText(event.target.value)}
            minRows={3}
          />
          <div className={Styles.clipper}>
            <input
              type="file"
              hidden
              accept={ACCEPTED_FILE_TYPES}
              ref={inputRef}
              onChange={handleUploadFileSelection}
            />
            <ActionButton onClick={() => inputRef.current!.click()}>
              <img alt="attachment" src={attachmentImage} />
            </ActionButton>
          </div>
        </div>
        {attachments.map((attachment, id) => (
          <div key={id} className={Styles.attachment}>
            <span>{attachment.file.name}</span>
            <div>
              <span>
                {attachment.state === "error" && intl.formatMessage(messages.errorUploading)}
              </span>
              {attachment.state === "uploading" && <SmallLoader />}
              <ActionButton onClick={() => removeAttachment(attachment)}>
                <Icon name="x" size="large" />
              </ActionButton>
            </div>
          </div>
        ))}
      </div>
      <div className={Styles.buttons}>
        <Button buttonColor="action" variant="secondary" onClick={onClose} disabled={isSubmitting}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          buttonColor="action"
          variant="primary"
          onClick={() => handleNoteSave()}
          isLoading={isSubmitting}
          disabled={attachments.some((a) => a.state !== "done")}
        >
          {intl.formatMessage(messages.save)}
        </Button>
      </div>
    </div>
  );
}
