import { useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import { SettingsPageWrapper } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";
import EncompassInstance from "common/encompass_instance";

import EncompassQuery, {
  type OrganizationEncompass_organization_Organization as Organization,
} from "./encompass_query.graphql";

export default function EncompassIntegration() {
  const organizationId = useParams().globalID!;
  const { data, loading } = useQuery(EncompassQuery, {
    variables: { organizationId },
  });
  const organization = data?.organization as Organization;
  return loading ? (
    <LoadingIndicator />
  ) : (
    <SettingsPageWrapper>
      <EncompassInstance organization={organization} isAdmin />
    </SettingsPageWrapper>
  );
}
