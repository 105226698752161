import { FormattedMessage } from "react-intl";

import { Paragraph } from "common/core/typography";

import { BackButtonOnlyFooter } from "./common";
import ProofCard from "../card";
import type { EmailScreen } from "./email";

export type ResetPasswordScreen = {
  type: "reset_password";
  email: string;
};

type Props = {
  onNextScreen: (screen: EmailScreen) => void;
  email: string;
};

export default function ResetPasswordScreenCard({ onNextScreen, email }: Props) {
  return (
    <ProofCard
      body={
        <Paragraph>
          <FormattedMessage
            id="4e6b10ff-b445-4140-86b6-55bc289ee1ea"
            defaultMessage="A link has been sent to your email to reset your password."
          />
        </Paragraph>
      }
      footer={<BackButtonOnlyFooter onClick={() => onNextScreen({ type: "email", email })} />}
    />
  );
}
