import "./analytics_header.scss";

import type { AdminAnalyticsHeader as Viewer } from "./analytics_header_fragment.graphql";

function AnalyticsHeader({ viewer }: { viewer: Viewer }) {
  const statistics = viewer.statistics!;
  const averageMeetingTimeThisWeekMins = Math.round(statistics.averageMeetingTimeThisWeek! / 60);
  return (
    <div className="AnalyticsHeader">
      <div className="AnalyticsHeader--container">
        <div className="AnalyticsHeader--section">
          <div className="AnalyticsHeader--section--total">
            <p className="AnalyticsHeader--section--total--count">
              {viewer.allMeetings.totalCount}
            </p>
            <p className="AnalyticsHeader--section--total--text">Meetings</p>
          </div>
          <div className="AnalyticsHeader--section--stats">
            <table className="AnalyticsHeader--section--stats--table">
              <tbody>
                <tr>
                  <td className="StatNumber">{viewer.meetingsWeek.totalCount}</td>
                  <td className="StatText">Meetings (week)</td>
                </tr>
                <tr>
                  <td className="StatNumber">{statistics.meetingsCanceledWithoutPayment}</td>
                  <td className="StatText">Canceled</td>
                </tr>
                <tr>
                  <td className="StatNumber">{statistics.meetingsKilled}</td>
                  <td className="StatText">Dead (technical issues)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="AnalyticsHeader--section">
          <div className="AnalyticsHeader--section--total">
            <p className="AnalyticsHeader--section--total--count">{statistics.paidMeetingsCount}</p>
            <p className="AnalyticsHeader--section--total--text">Meetings Complete</p>
          </div>
          <div className="AnalyticsHeader--section--stats">
            <table className="AnalyticsHeader--section--stats--table">
              <tbody>
                <tr>
                  <td className="StatNumber">{viewer.meetingsCompletedMonth.totalCount}</td>
                  <td className="StatText">Meetings Complete (month)</td>
                </tr>
                <tr>
                  <td className="StatNumber">{viewer.meetingsCompletedWeek.totalCount}</td>
                  <td className="StatText">Meetings Complete (week)</td>
                </tr>
                <tr>
                  <td className="StatNumber">{averageMeetingTimeThisWeekMins} mins</td>
                  <td className="StatText">Average call time this week</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsHeader;
