import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useDispatch } from "redux/util";
import AccountLocked from "assets/images/account/account-locked.svg";
import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { forgotPassword } from "redux/actions/authentication";

import { BackButton } from "./common";
import Styles from "./index.module.scss";
import type { EmailScreen } from "./email";
import type { ResetPasswordScreen } from "./reset_password";
import ProofCard from "../card";

type LoginAttemptsProps = {
  onNextScreen: (screen: ResetPasswordScreen | EmailScreen) => void;
  email: string;
};

export type LoginAttemptsExceededScreen = {
  type: "login_attempts_exceeded";
  email: string;
};

export default function LoginAttemptsExceededScreenCard({
  onNextScreen,
  email,
}: LoginAttemptsProps) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const newSearchParamsWithoutForbidden = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("forbidden");
    setSearchParams(newSearchParams, { replace: true });
  };
  return (
    <ProofCard
      body={
        <>
          <div className={Styles.mainHeading}>
            <img src={AccountLocked} alt="Account locked" />
            <Heading level="h1" textStyle="headingTwo">
              <FormattedMessage
                id="9b3ef3c1-dd28-4019-8cc7-a2dcfcd2eb15"
                defaultMessage="Check your email"
              />
            </Heading>
          </div>
          <Paragraph className={Styles.bodySection}>
            <FormattedMessage
              id="92ed7ce3-9077-4c53-86b3-07d63ce95a1e"
              defaultMessage="There have been too many unsuccessful login attempts. Use the password reset link in your email to unlock your account."
            />
          </Paragraph>

          <Button
            fullwidth
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            onClick={() => {
              dispatch(forgotPassword(email));
              onNextScreen({ type: "reset_password", email });
            }}
          >
            <FormattedMessage
              id="b71df70a-f710-41a2-aec1-385fef35431d"
              defaultMessage="Resend Link"
            />
          </Button>
        </>
      }
      footer={
        <div className={Styles.footerCentered}>
          <BackButton
            onClick={() => {
              newSearchParamsWithoutForbidden();
              onNextScreen({ type: "email", email });
            }}
          />
        </div>
      }
    />
  );
}
