import "./index.scss";

import type { ReactNode, ComponentProps } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

type ButtonProps = ComponentProps<typeof Button>;
type Props = {
  filters: ReactNode[];
  className?: string;
  onApply?: ButtonProps["onClick"];
  buttonDisabled?: ButtonProps["disabled"];
};

function FilterMenu({ filters, onApply, buttonDisabled, className }: Props) {
  return (
    <div className={classnames("FilterMenu", className)}>
      {filters.map((filter, index) => (
        <div key={index} className="FilterMenu--filter">
          {filter}
        </div>
      ))}
      <Button
        buttonColor="action"
        variant="primary"
        buttonSize="condensed"
        onClick={onApply}
        disabled={buttonDisabled}
        automationId="apply-button"
      >
        <FormattedMessage id="0e1f1b9d-d6f3-4fd6-a926-08107f775d5e" defaultMessage="Apply" />
      </Button>
    </div>
  );
}

export default FilterMenu;
