import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryCapacityType } from "graphql_globals";
import { SUPPORT_HOST } from "constants/support";

import OnDemandCapacityDetails from "./on_demand";
import ByotCapacityDetails from "./byot";
import PanelInfo from "./panel_info";
import OrganizationNotaryCapacityDetails from "./organization_notary";
import type { NotaryCapacities as NotaryProfile } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  notaryProfile: NotaryProfile;
  canUpdate: boolean;
};

export const SAVING_INDICATOR = (
  <div className={Styles.savingIndicator}>
    <FormattedMessage id="30d8602e-7517-4f3d-bca4-8719b7874459" defaultMessage="Saving..." />
  </div>
);

function NotaryCapacities({ notaryProfile, canUpdate }: Props) {
  const byotCapacity = notaryProfile.capacities.find(
    (c) => c.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
  ) as ComponentProps<typeof ByotCapacityDetails>["capacity"] | undefined;
  const onDemandCapacity = notaryProfile.capacities.find(
    (c) => c.type === NotaryCapacityType.ON_DEMAND,
  ) as ComponentProps<typeof OnDemandCapacityDetails>["capacity"] | undefined;
  const organizationCapacity = notaryProfile.capacities.find(
    (c) => c.type === NotaryCapacityType.ORGANIZATION_NOTARY,
  ) as ComponentProps<typeof OrganizationNotaryCapacityDetails>["capacity"] | undefined;
  return (
    <div className={Styles.notaryCapacitiesSection}>
      {!notaryProfile.capacities.length && (
        <p className={Styles.noCapacities}>
          <FormattedMessage
            id="9d5ca5a3-0a11-4081-8dff-35901ebef35d"
            defaultMessage="Not sure why this notary doesn’t have any capacities? For troubleshooting help, please see <link>this article</link>."
            values={{
              link: (text) => (
                <a
                  href={`${SUPPORT_HOST}/hc/en-us/articles/9013196926103-Troubleshooting-Notary-Capacities`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {text}
                </a>
              ),
            }}
          />
        </p>
      )}
      {byotCapacity && <ByotCapacityDetails capacity={byotCapacity} />}
      {onDemandCapacity && (
        <OnDemandCapacityDetails
          notaryProfile={notaryProfile}
          canUpdate={canUpdate}
          capacity={onDemandCapacity}
        />
      )}
      {organizationCapacity && (
        <OrganizationNotaryCapacityDetails capacity={organizationCapacity} />
      )}
      <PanelInfo notaryProfile={notaryProfile} />
    </div>
  );
}

export default NotaryCapacities;
