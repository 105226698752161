import { Navigate, useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";

import type { QualityControlDocumentBundle as Bundle } from "./quality_control_document_bundle_fragment.graphql";
import QualityControlMeetingQuery from "./quality_control_meeting_query.graphql";
import QualityControlForm from "./quality_control_form";
import QualityControlTransactionQuery from "./quality_control_transaction_query.graphql";

export function QualityControlFromMeeting() {
  const params = useParams();
  const { hasPermissionFor } = usePermissions();
  const meetingId = params.meetingId!;
  const notaryProfileId = params.notaryId!;
  const notPermitted = !hasPermissionFor("qualityControl");

  const { data, loading } = useQuery(QualityControlMeetingQuery, {
    variables: { meetingId, notaryProfileId },
    skip: notPermitted,
  });

  if (notPermitted) {
    return <Navigate to="/" />;
  }

  if (loading || data?.meeting?.__typename !== "Meeting" || !data.meeting.documentBundle) {
    return <LoadingIndicator />;
  }
  const bundle = data.meeting.documentBundle as Bundle;

  return <QualityControlForm bundle={bundle} notaryId={params.notaryId!} meetingId={meetingId} />;
}

export function QualityControlFromTransaction() {
  const params = useParams();
  const { hasPermissionFor } = usePermissions();
  const transactionId = params.transactionId!;
  const notaryProfileId = params.notaryId!;
  const notPermitted = !hasPermissionFor("qualityControl");

  const { data, loading } = useQuery(QualityControlTransactionQuery, {
    variables: { transactionId, notaryProfileId },
    skip: notPermitted,
  });

  if (notPermitted) {
    return <Navigate to="/" />;
  }

  if (
    loading ||
    data?.transaction?.__typename !== "OrganizationTransaction" ||
    !data.transaction.documentBundle
  ) {
    return <LoadingIndicator />;
  }
  const bundle = data.transaction.documentBundle as Bundle;

  return (
    <QualityControlForm bundle={bundle} notaryId={params.notaryId!} transactionId={transactionId} />
  );
}
