import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import { StandardTable } from "common/table";
import { SettingsHeader } from "common/settingsv2/common";
import Button from "common/core/button";
import TableRow from "common/table/row";
import WorkflowModal from "common/modals/workflow_modal";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMutation } from "util/graphql";
import { EncryptionKeyStatusesEnum } from "graphql_globals";

import FormattedKeyStatus from "./formated_key_status";
import Styles from "./index.module.scss";
import CreateMasterKeyMutation from "./create_master_key_mutation.graphql";
import ActivateMasterKeyMutation from "./activate_master_key.graphql";
import type { EncryptionKeysForOrganization_organization_Organization_encryptionKeys as MasterKey } from "./encryption_keys_query.graphql";

const MESSAGES = defineMessages({
  pageTitle: {
    id: "a23936c9-7e36-4367-831b-eb0324e35118",
    defaultMessage: "Master Keys",
  },
  kmsArn: {
    id: "b31999a1-e043-474b-bdb2-4b813da8bb6f",
    defaultMessage: "KMS ARN",
  },
  modalTitle: {
    id: "072e4ecb-addd-4809-a75a-5c361e2952d9",
    defaultMessage: "Metadata for {arn}",
  },
  status: {
    id: "82f21945-0a97-47ac-9744-9c0e866d5f00",
    defaultMessage: "Status",
  },
  close: {
    id: "06f0c6f7-09e5-420d-99a0-6167569b1c90",
    defaultMessage: "Close",
  },
  metadata: {
    id: "a0281121-7a73-46cb-8d93-f744992a6d95",
    defaultMessage: "Metadata",
  },
  dataKeys: {
    id: "682e4ef2-5fc6-40c9-b85a-b70fd4dbbdbd",
    defaultMessage: "Data Keys",
  },
  updatedAt: {
    id: "b2b942d8-d55a-4634-b8b1-ff1f8c0a1f4f",
    defaultMessage: "Updated At",
  },
  activate: {
    id: "3398d09a-11f6-482d-ad64-83e437c7fb03",
    defaultMessage: "Activate",
  },
});

type MasterKeysPageProps = {
  masterKeys: MasterKey[];
  onDataKeysSelect: (id: string) => void;
  organizationId: string;
};

function MasterKeysPage({ masterKeys, onDataKeysSelect, organizationId }: MasterKeysPageProps) {
  const intl = useIntl();
  const [createMasterKeyStatus, setCreateMasterKeyStatus] = useState<null | "loading" | "error">(
    null,
  );
  const [activateMasterKeyStatus, setActivateMasterKeyStatus] = useState<null | "error">(null);
  const [metadataModalKeyId, setMetadataModalKeyId] = useState<null | string>(null);
  const metadataModalKey =
    metadataModalKeyId && masterKeys.find((key) => key.id === metadataModalKeyId);
  const createMasterKey = useMutation(CreateMasterKeyMutation);
  const onCreateMasterKey = () => {
    setCreateMasterKeyStatus("loading");
    createMasterKey({
      variables: {
        input: {
          organizationId,
        },
      },
    })
      .then(() => {
        setCreateMasterKeyStatus(null);
      })
      .catch(() => {
        setCreateMasterKeyStatus("error");
      });
  };
  const activateMasterKey = useMutation(ActivateMasterKeyMutation);
  const onActivateMasterKey = (masterKeyId: string) => {
    activateMasterKey({
      variables: {
        input: {
          organizationId,
          masterKeyId,
        },
      },
    }).catch(() => {
      setActivateMasterKeyStatus("error");
    });
  };

  return (
    <>
      <SettingsHeader title={intl.formatMessage(MESSAGES.pageTitle)} />
      <div className={Styles.wrapperJustifyRight}>
        <Button
          buttonColor="action"
          variant="primary"
          isLoading={createMasterKeyStatus === "loading"}
          onClick={() => onCreateMasterKey()}
        >
          <FormattedMessage
            id="628f381e-3838-4543-ad20-04d1434fcdbb"
            defaultMessage="Add Master Key"
          />
        </Button>
      </div>
      <StandardTable
        headings={[
          intl.formatMessage(MESSAGES.kmsArn),
          intl.formatMessage(MESSAGES.status),
          intl.formatMessage(MESSAGES.updatedAt),
          "",
        ]}
      >
        {masterKeys.map((key) => (
          <TableRow
            key={key.kmsArn}
            cells={[
              { children: <span>{key.kmsArn}</span> },
              { children: <FormattedKeyStatus status={key.status} /> },
              { children: <span>{key.updatedAt}</span> },
              {
                children: (
                  <PopoutMenu aria-label="Master keys menu" placement="bottomRight">
                    {({ close }) => (
                      <>
                        <PopoutMenuItem
                          onClick={() => {
                            close();
                            setMetadataModalKeyId(key.id);
                          }}
                        >
                          {intl.formatMessage(MESSAGES.metadata)}
                        </PopoutMenuItem>
                        <PopoutMenuItem
                          onClick={() => {
                            close();
                            onDataKeysSelect(key.kmsArn);
                          }}
                        >
                          {intl.formatMessage(MESSAGES.dataKeys)}
                        </PopoutMenuItem>
                        {key.status === EncryptionKeyStatusesEnum.STAGED && (
                          <PopoutMenuItem
                            onClick={() => {
                              close();
                              onActivateMasterKey(key.id);
                            }}
                          >
                            {intl.formatMessage(MESSAGES.activate)}
                          </PopoutMenuItem>
                        )}
                      </>
                    )}
                  </PopoutMenu>
                ),
              },
            ]}
          />
        ))}
      </StandardTable>
      {metadataModalKey && (
        <WorkflowModal
          title={intl.formatMessage(MESSAGES.modalTitle, { arn: metadataModalKey.kmsArn })}
          buttons={[
            <Button
              key="V2Button-2"
              buttonColor="action"
              variant="primary"
              onClick={() => setMetadataModalKeyId(null)}
            >
              {intl.formatMessage(MESSAGES.close)}
            </Button>,
          ]}
        >
          {metadataModalKey.metadata}
        </WorkflowModal>
      )}
      {(createMasterKeyStatus === "error" || activateMasterKeyStatus === "error") && (
        <MutationErrorModal
          onClick={() => {
            setCreateMasterKeyStatus(null);
            setActivateMasterKeyStatus(null);
          }}
          message={
            <FormattedMessage
              id="5c213736-1158-4d70-bed9-e87ab0701b18"
              defaultMessage="Unable to perform action. Please try again or reach out to customer support."
            />
          }
        />
      )}
    </>
  );
}

export default MasterKeysPage;
