import type { ReactElement } from "react";
import classnames from "classnames";

import Link from "common/core/link";

import Styles from "../links.module.scss";

type NavButtonProps = {
  children: ReactElement;
  onClick: () => void;
  active?: boolean;
  className?: string;
  automationId?: string;
};

function NavButton(props: NavButtonProps) {
  const linkCx = classnames(
    Styles.link,
    props.active ? Styles.onActiveUnderline : Styles.onHoverUnderline,
    props.className,
  );

  return (
    <Link
      black
      underlined={false}
      className={linkCx}
      onClick={props.onClick}
      automationId={props.automationId}
    >
      {props.children}
    </Link>
  );
}

export { NavButton };
