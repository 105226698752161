import { memo, type ReactNode } from "react";
import { Link } from "react-router-dom";

import Icon from "common/core/icon";

type Props = {
  to: string;
  iconName: string;
  text: ReactNode;
  subText?: ReactNode;
  automationId?: string;
};

function AdminDashboardLink({ to, iconName, text, subText, automationId }: Props) {
  return (
    <li className="DashboardIndex-link DashboardIndex-link--multiline">
      <Link to={to} className="DashboardIndex-link-container" data-automation-id={automationId}>
        <div className="DashboardIndex-link-left">
          <Icon name={iconName} />
        </div>
        <div className="DashboardIndex-link-center">
          <span>{text}</span>
          {subText && <span>{subText}</span>}
        </div>
        <div className="DashboardIndex-link-right">
          <Icon name="caret-right" />
        </div>
      </Link>
    </li>
  );
}

export default memo(AdminDashboardLink);
