import { useSearchParams } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useEffect, useState, useCallback } from "react";
import { debounceTime, tap } from "rxjs";

import AdminToolSection from "admin_portal/tools/admin_tool_section";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import { Paragraph } from "common/core/typography";
import { Card } from "common/core/card";
import { NetworkStatus, useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useSubject } from "util/rxjs/hooks";
import { StyledTextInput } from "common/core/form/text";

import TaskLink from "./task_link";
import AdminTasksQuery from "./index_query.graphql";
import Styles from "./index.module.scss";
import { TaskModal } from "./form";

const MESSAGES = defineMessages({
  pageTitle: {
    id: "a441cf03-6e44-4c91-92a0-ff658c0c2a59",
    defaultMessage: "Tasks",
  },
  sectionTitle: {
    id: "6985fa9f-0699-4e6e-9e1f-c0f9eeff1b2d",
    defaultMessage: "Available tasks",
  },
  searchLabel: {
    id: "0fea827a-c9dc-4eb5-9633-4adf203df8cd",
    defaultMessage: "Search for a specific task",
  },
  issuesSection: {
    id: "5a7dc7c1-0f26-4a2e-9ee3-8fc3023f751a",
    defaultMessage: "Still having issues?",
  },
});

function useDebouncedValue<T>(initialValue: T, delay: number) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const value$ = useSubject<T>();
  useEffect(() => {
    const sub = value$.pipe(tap(setValue), debounceTime(delay)).subscribe(setDebouncedValue);
    return () => sub.unsubscribe();
  }, []);

  return [value, debouncedValue, useCallback((value: T) => value$.next(value), [])] as const;
}

export default function KeystoneTasks() {
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedValue("", 500);

  const { networkStatus, data, previousData } = useQuery(AdminTasksQuery, {
    variables: { searchTerm: debouncedSearchTerm },
  });
  const tasks = (data || previousData)?.viewer.keystoneTasks;

  const selectedTask = tasks?.find((task) => task.identifier === searchParams.get("taskId"));

  return (
    <AppFrameInnerContainer title={intl.formatMessage(MESSAGES.pageTitle)} closePath="/" fullWidth>
      {selectedTask && <TaskModal task={selectedTask} />}

      <AdminToolSection title={intl.formatMessage(MESSAGES.sectionTitle)}>
        <StyledTextInput
          className={Styles.search}
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          label={intl.formatMessage(MESSAGES.searchLabel)}
          aria-invalid={false}
        />

        {networkStatus === NetworkStatus.loading ? (
          <LoadingIndicator />
        ) : tasks?.length ? (
          tasks.map((task) => (
            <TaskLink
              key={task.identifier}
              id={task.identifier}
              description={task.description}
              label={task.label}
            />
          ))
        ) : (
          <Paragraph>
            <FormattedMessage
              id="c969360d-a0a6-483a-81c6-e0c9e6137016"
              defaultMessage="No tasks available"
            />
          </Paragraph>
        )}
      </AdminToolSection>

      <AdminToolSection title={intl.formatMessage(MESSAGES.issuesSection)}>
        <Card
          header={
            <FormattedMessage
              id="b490f4c1-5104-449a-b101-16d4a0c84066"
              defaultMessage="I don't see my task"
            />
          }
          collapsibleElement="icon"
        >
          <Paragraph>
            <FormattedMessage
              id="44dbb2d5-c14b-4f82-8c14-525cb875431b"
              defaultMessage="An on call engineer can create one for you! If you think you should have a task, and don't, raise a ticket with the on-call engineer."
            />
          </Paragraph>
        </Card>

        <Card
          header={
            <FormattedMessage
              id="0b138de4-fef5-4080-9d5e-fc5790b0633a"
              defaultMessage="Task failed"
            />
          }
          collapsibleElement="icon"
        >
          <Paragraph>
            <FormattedMessage
              id="ca6c0f3e-247a-4f96-82ad-0c8b40f10253"
              defaultMessage="Sometimes the task can't be completed with our standard tasks (e.g. notary has meetings when you try to remove commission number). If an issue persists after running a Keystone Task, raise the issue with on-call as you normally would. If there is an error, take a screenshot of the returned error message and include it in your issue."
            />
          </Paragraph>
        </Card>
      </AdminToolSection>
    </AppFrameInnerContainer>
  );
}
