import { defineMessages, type MessageDescriptor, FormattedMessage, useIntl } from "react-intl";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useState } from "react";
import classnames from "classnames";

import { Paragraph } from "common/core/typography";
import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import { DashboardContent, DashboardTitle } from "common/dashboard";
import { DOMAINS_PATH } from "common/proof_frame/path";
import { Badge } from "common/core/badge";
import { captureException } from "util/exception";
import { OptionBar } from "common/core/form/option";
import { Card, CardSection } from "common/core/card";
import { RadioGroup, RadioInput } from "common/core/form/option/radio_button";
import { useMutation } from "util/graphql";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { useId } from "util/html";
import { useQuery } from "util/graphql/query";
import LoadingIndicator from "common/core/loading_indicator";
import { formatDate } from "util/date";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import UpdateDomainSettingsMutation from "./update-domain-settings.mutation.graphql";
import LinkDomainToSamlProviderMutation from "./link_domain_to_saml_provider.graphql";
import { VerifiedDomainActionsButton } from "../verify_domain/verify_domain";
import { SsoOptions } from "./sso_options";
import OrganizationDomains, {
  type OrganizationDomains_organization_Organization_domainVerifications as DomainItemType,
  type OrganizationDomains_organization_Organization_samlProviders as SamlProvider,
} from "../organization_domains.query.graphql";
import Styles from "./index.module.scss";
import { useContextOrParamsOrgId } from "../../common";

const MESSAGES = defineMessages({
  edit: {
    id: "95beed16-75ba-4b38-84c2-fee6d41009b2",
    defaultMessage: "Edit",
  },
  cancel: {
    id: "287a06e8-e8d2-4c02-9cbb-db43bea7e132",
    defaultMessage: "Cancel",
  },
  save: {
    id: "8365b686-08b0-4ba7-bcd8-8a2fbbf1c8f1",
    defaultMessage: "Save",
  },
  lastChecked: {
    id: "c0957615-9b48-40ad-b8c6-208033d60ad3",
    defaultMessage: "Verification last checked on {date}",
  },
  authTitle: {
    id: "b001ecce-b2b5-47da-9555-f11921698a7d",
    defaultMessage: "Authentication and provisioning",
  },
  authTitleSubtext: {
    id: "e5af0733-59bf-445f-8995-be9b6a4f5994",
    defaultMessage:
      "Manage the security of your accounts with authentication and provisioning settings",
  },
  authSettings: {
    id: "95e05423-392e-4d0f-987f-b9f4bd10d322",
    defaultMessage: "Authentication settings",
  },
  authSettingsSubtext: {
    id: "901ba331-fd9c-43ce-81a2-9e5f4d896bea",
    defaultMessage:
      "Select the authentication method that users with your domain will use on Proof.",
  },
  noDomainAuth: {
    id: "94a53833-4dc9-45b7-9c6f-3d86f29976ba",
    defaultMessage: "Password-based authentication",
  },
  noDomainAuthSubtext: {
    id: "7e441660-80c4-4596-9adf-183fea5836c8",
    defaultMessage:
      "Authentication is based on users’ organization settings (password only, or password +MFA)",
  },
  ssoDomainAuth: {
    id: "e09957a7-6bf8-4a4b-a732-0b944ed1b88b",
    defaultMessage: "Domain-based single sign-on (SSO)",
  },
  ssoDomainAuthSubtext: {
    id: "cb70a12c-b9bc-49dc-9424-7e388c0abaa1",
    defaultMessage: "Requires setup of an identity provider, along with SAML configuration.",
  },
  ssoNoneSelectedError: {
    id: "7f358819-f832-4be7-b21c-aa447b13caea",
    defaultMessage:
      "You must have a valid identity provider configuration to choose domain-based SSO.",
  },
  provisioningPolicy: {
    id: "2ac7561b-6d02-4862-8818-923615f80a75",
    defaultMessage: "Provisioning policy",
  },
  provisioningPolicyDescription: {
    id: "60694b86-11ac-4c98-8d25-21c87e480a95",
    defaultMessage:
      "Determine how users with this domain will or will not be able to create accounts.",
  },
  allowUnmanaged: {
    id: "7272ba81-6e54-4b28-a90e-ac038aa3e3fb",
    defaultMessage: "Allow unmanaged account creation",
  },
  allowUnmanagedSubtext: {
    id: "348e7c03-43b4-4dac-87ae-3e978b059d6d",
    defaultMessage:
      "Users with this domain can create new organizations outside of your company structure.",
  },
  blockUnmanaged: {
    id: "b3c8c310-f908-418e-9afa-407f127ee424",
    defaultMessage: "Block unmanaged account creation",
  },
  blockUnmanagedSubtext: {
    id: "fcdd52b1-f650-4146-be18-a61a076e1eec",
    defaultMessage: "Users with this domain will be blocked from creating new accounts.",
  },
  changesSaved: {
    id: "71f04026-de90-40c7-ba86-ccf1575ec73a",
    defaultMessage: "Your changes have been saved!",
  },
  changesSavedSso: {
    id: "fc0ec52a-9f6f-4b67-97f9-e6c9df8f6187",
    defaultMessage:
      "Your changes have been saved! We recommend that you now test logging in with SSO.",
  },
  unknownError: {
    id: "0c4313b1-5eeb-4764-ba89-9685d40cbdc1",
    defaultMessage: "Unknown error. Please contact customer support if this issue persists.",
  },
});

function DomainPoliciesTitle({ domainItem }: { domainItem: DomainItemType }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const domainName = domainItem.domain.domain;

  return (
    <>
      <div>
        <Button
          key="back-to-domains"
          variant="tertiary"
          buttonColor="action"
          buttonSize="condensed"
          style={{ paddingLeft: "0" }}
          withIcon={{
            name: "arrow-left",
            placement: "left",
          }}
          onClick={() => navigate("..")}
        >
          <FormattedMessage
            id="950f3868-1d1a-42d5-9179-147868386c7e"
            defaultMessage="Back to domains"
          />
        </Button>
      </div>
      <DashboardTitle
        title={
          <div className={Styles.domainTitleContainer}>
            <span className={Styles.domainNameTitle}>{domainName}</span>
            <Badge kind="success" withIcon="checkmark">
              <FormattedMessage
                id="507be630-6a08-4bb2-90b8-c3e4000bf65c"
                defaultMessage="Verified"
              />
            </Badge>
          </div>
        }
        description={intl.formatMessage(MESSAGES.lastChecked, {
          date: formatDate(domainItem.lastCheckedAt!.toString()),
        })}
        buttons={<VerifiedDomainActionsButton buttonType="action" domainItem={domainItem} />}
      />
    </>
  );
}

function NoDomainBasedAuthOptions({
  preventUnmanagedSignups,
  setPreventUnmanagedSignups,
  isEditing,
}: {
  preventUnmanagedSignups: boolean;
  setPreventUnmanagedSignups: (value: boolean) => void;
  isEditing: boolean;
}) {
  const intl = useIntl();
  const radioGroupId = useId();
  const togglePreventUnmanagedSignups = () => {
    setPreventUnmanagedSignups(!preventUnmanagedSignups);
  };
  return (
    <div className={Styles.fadeIn}>
      <CardSection>
        <h5
          className={classnames(
            Styles.radioButtonsTitle,
            Styles.radioButtonsTitlePolicy,
            isEditing && Styles.radioButtonsTitleEnabled,
          )}
        >
          {intl.formatMessage(MESSAGES.provisioningPolicy)}
        </h5>
        <h6
          className={classnames(
            Styles.radioButtonsTitleSubtext,
            isEditing && Styles.radioButtonsTitleSubtextEnabled,
          )}
        >
          {intl.formatMessage(MESSAGES.provisioningPolicyDescription)}
        </h6>
      </CardSection>
      <CardSection>
        <RadioGroup aria-labelledby={radioGroupId}>
          <OptionBar
            label={
              <div className={Styles.optionBarTitle}>
                {intl.formatMessage(MESSAGES.allowUnmanaged)}
              </div>
            }
            subLabel={intl.formatMessage(MESSAGES.allowUnmanagedSubtext)}
            input={
              <RadioInput
                value={(!preventUnmanagedSignups).toString()}
                checked={!preventUnmanagedSignups}
                onChange={togglePreventUnmanagedSignups}
                disabled={!isEditing}
                data-automation-id={"allow-unmanaged-button"}
              />
            }
            disabled={!isEditing}
          />
          <OptionBar
            label={
              <div className={Styles.optionBarTitle}>
                {intl.formatMessage(MESSAGES.blockUnmanaged)}
              </div>
            }
            subLabel={intl.formatMessage(MESSAGES.blockUnmanagedSubtext)}
            input={
              <RadioInput
                value={preventUnmanagedSignups.toString()}
                checked={preventUnmanagedSignups}
                onChange={togglePreventUnmanagedSignups}
                disabled={!isEditing}
                data-automation-id={"block-unmanaged-button"}
              />
            }
            disabled={!isEditing}
          />
        </RadioGroup>
      </CardSection>
    </div>
  );
}

type AuthCardProps = {
  domainItem: DomainItemType;
  samlProviders: SamlProvider[];
  currentSamlProvider: SamlProvider | null;
  activeOrganizationId: string;
};

function AuthCard({
  domainItem,
  samlProviders,
  currentSamlProvider,
  activeOrganizationId,
}: AuthCardProps) {
  const intl = useIntl();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const initialUseSso = Boolean(currentSamlProvider);
  const [useSso, _setUseSso] = useState<boolean>(initialUseSso);
  const initialPreventUnmanagedSignups = domainItem.domain.preventUnmanagedSignups;
  const [preventUnmanagedSignups, setPreventUnmanagedSignups] = useState<boolean>(
    initialPreventUnmanagedSignups,
  );
  const initialSamlProviderId = currentSamlProvider?.id || samlProviders[0]?.id || null;
  const [samlProviderId, setSamlProviderId] = useState<string | null>(initialSamlProviderId);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<MessageDescriptor | null>(null);

  const setUseSso = (newState: boolean) => {
    _setUseSso(newState);
    setError(null);
  };
  const cancelEditing = () => {
    setIsEditing(false);
    setUseSso(initialUseSso);
    setPreventUnmanagedSignups(initialPreventUnmanagedSignups);
    setSamlProviderId(initialSamlProviderId);
    setError(null);
  };

  const linkDomainToSamlProvider = useMutation(LinkDomainToSamlProviderMutation);
  const submitSsoSettings = (samlProviderId: string) => {
    return () => {
      return linkDomainToSamlProvider({
        variables: {
          input: {
            samlProviderId,
            domainId: domainItem.domain.id,
          },
        },
      });
    };
  };

  const updateDomainSettings = useMutation(UpdateDomainSettingsMutation);
  const submitDomainSettings = () => {
    return updateDomainSettings({
      variables: {
        input: {
          domainId: domainItem.domain.id,
          preventUnmanagedSignups,
          disableSso: true,
        },
      },
    });
  };

  const onSave = () => {
    if (useSso && !samlProviderId) {
      setError(MESSAGES.ssoNoneSelectedError);
      return;
    }
    setIsLoading(true);
    const submit = useSso ? submitSsoSettings(samlProviderId!) : submitDomainSettings;
    const successMessage = useSso ? MESSAGES.changesSavedSso : MESSAGES.changesSaved;
    return submit()
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(successMessage),
          position: "topCenter",
        });
        setIsEditing(false);
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.unknownError),
          position: "topCenter",
        });
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card
      className={Styles.authCard}
      footer={
        isEditing && (
          <>
            <Button key="cancel" buttonColor="dark" variant="tertiary" onClick={cancelEditing}>
              {intl.formatMessage(MESSAGES.cancel)}
            </Button>
            <Button
              key="save"
              buttonColor="action"
              variant="primary"
              isLoading={isLoading}
              onClick={onSave}
              automationId={"save-button"}
            >
              {intl.formatMessage(MESSAGES.save)}
            </Button>
          </>
        )
      }
    >
      <CardSection>
        <div className={Styles.authTitleSection}>
          <span>
            <h3 className={Styles.authTitle}>{intl.formatMessage(MESSAGES.authTitle)}</h3>
            <h4 className={Styles.authTitleSubtext}>
              {intl.formatMessage(MESSAGES.authTitleSubtext)}
            </h4>
          </span>
          {!isEditing && !isAdminPortal && (
            <Button
              buttonColor="action"
              variant="secondary"
              onClick={() => setIsEditing(true)}
              automationId={"edit-button"}
            >
              {intl.formatMessage(MESSAGES.edit)}
            </Button>
          )}
        </div>
      </CardSection>
      <CardSection>
        <h5
          className={classnames(
            Styles.radioButtonsTitle,
            isEditing && Styles.radioButtonsTitleEnabled,
          )}
        >
          {intl.formatMessage(MESSAGES.authSettings)}
        </h5>
        <h6
          className={classnames(
            Styles.radioButtonsTitleSubtext,
            isEditing && Styles.radioButtonsTitleSubtextEnabled,
          )}
        >
          {intl.formatMessage(MESSAGES.authSettingsSubtext)}
        </h6>
      </CardSection>
      <CardSection>
        <div className={Styles.authRadioButtonsContainer}>
          <OptionBar
            className={Styles.authRadioButton}
            input={
              <RadioInput
                value={(!useSso).toString()}
                checked={!useSso}
                onChange={() => setUseSso(!useSso)}
                disabled={!isEditing}
                data-automation-id={"no-domain-auth-button"}
              />
            }
            label={
              <div className={Styles.optionBarTitle}>
                {intl.formatMessage(MESSAGES.noDomainAuth)}
              </div>
            }
            subLabel={intl.formatMessage(MESSAGES.noDomainAuthSubtext)}
            disabled={!isEditing}
          />
          <OptionBar
            className={Styles.authRadioButton}
            input={
              <RadioInput
                value={useSso.toString()}
                checked={useSso}
                onChange={() => setUseSso(!useSso)}
                disabled={!isEditing}
                data-automation-id={"sso-auth-button"}
              />
            }
            label={
              <div className={Styles.optionBarTitle}>
                {intl.formatMessage(MESSAGES.ssoDomainAuth)}
              </div>
            }
            subLabel={intl.formatMessage(MESSAGES.ssoDomainAuthSubtext)}
            disabled={!isEditing}
          />
        </div>
      </CardSection>
      <CardSection>
        <div
          className={classnames(
            Styles.authOptionsBox,
            useSso ? Styles.authOptionsBoxRight : Styles.authOptionsBoxLeft,
            error && Styles.authOptionsBoxError,
          )}
        >
          {useSso ? (
            <SsoOptions
              samlProviderId={samlProviderId}
              setSamlProviderId={setSamlProviderId}
              samlProviders={samlProviders}
              activeOrganizationId={activeOrganizationId}
              isEditing={isEditing}
            />
          ) : (
            <NoDomainBasedAuthOptions
              preventUnmanagedSignups={preventUnmanagedSignups}
              setPreventUnmanagedSignups={setPreventUnmanagedSignups}
              isEditing={isEditing}
            />
          )}
        </div>
        {error && (
          <Paragraph textColor="danger" className={Styles.authCardBoxError}>
            {intl.formatMessage(error)}
          </Paragraph>
        )}
      </CardSection>
    </Card>
  );
}

export function DomainPolicies() {
  const params = useParams();
  const domainName = params.domain!;
  const activeOrgId = useContextOrParamsOrgId();
  const { data, loading } = useQuery(OrganizationDomains, {
    variables: { organizationId: activeOrgId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data?.organization?.__typename}.`);
  }

  const matchingDomainItems = data.organization.domainVerifications.filter(
    (dv) => dv.domain.domain === domainName,
  );

  if (matchingDomainItems.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      `Found multiple domainVerifications (${matchingDomainItems.map((dv) => dv.id).toString()}) for domain ${domainName}`,
    );
  }

  const domainItem = matchingDomainItems.at(0);

  if (!domainItem) {
    return <Navigate to={DOMAINS_PATH} />;
  }

  const samlProviders = data.organization.samlProviders.filter((samlProvider: SamlProvider) => {
    // filter out org based saml providers
    return !(samlProvider.active && !samlProvider.domains.length);
  });

  const currentSamlProviders = samlProviders.filter((samlProvider: SamlProvider) => {
    return samlProvider.domains.some((domain) => domain.id === domainItem.domain.id);
  });

  if (currentSamlProviders.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      `Found multiple samlProviders (${currentSamlProviders.map((sp) => sp.id).toString()}) for one domain (${domainItem.id})`,
    );
  }

  const currentSamlProvider = currentSamlProviders[0] || null;

  return (
    <div className={Styles.subPage}>
      <DashboardContent>
        <DomainPoliciesTitle domainItem={domainItem} />
        <Hr className={Styles.hr} />
        <AuthCard
          domainItem={domainItem}
          samlProviders={samlProviders}
          currentSamlProvider={currentSamlProvider}
          activeOrganizationId={activeOrgId}
        />
      </DashboardContent>
    </div>
  );
}
