import { FormattedMessage } from "react-intl";

import { DetailsRow, DetailsRowHeader } from "common/notary/details";
import { NotaryActivationStatus } from "graphql_globals";

import type { NotaryCapacities as NotaryProfile } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  notaryProfile: NotaryProfile;
};

export default function PanelInfo({ notaryProfile }: Props) {
  const activePanels = notaryProfile.panelMemberships.filter(
    (membership) => membership.activationStatus === NotaryActivationStatus.ACCEPTED,
  );

  return (
    <>
      <DetailsRowHeader>
        <FormattedMessage
          id="32768dff-6605-4d8c-90c2-8b444a77cba7"
          defaultMessage="Panel Details"
        />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <div className={Styles.rowLabel}>
            <FormattedMessage
              id="8b232b37-e4dd-457b-a161-8f5be46092cd"
              defaultMessage="All Active Panels"
            />
          </div>
        }
      >
        <div>
          <ul>
            {activePanels.length ? (
              activePanels.map((activePanel) => <li key={activePanel.id}>{activePanel.name}</li>)
            ) : (
              <li>
                <FormattedMessage
                  id="277bd8c8-0299-48c5-9c4a-9863569468f6"
                  defaultMessage="No active panels"
                  tagName="em"
                />
              </li>
            )}
          </ul>
        </div>
      </DetailsRow>
    </>
  );
}
