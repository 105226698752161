import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import OrgBrandTheme from "common/core/brand/org_brand_theme";
import useOrgBrandUnauthed from "common/core/brand/get_org_brand_unauthed";
import type { AuthCode } from "common/account/google_signin_button";
import { getAuthenticationTypes } from "common/account/login/util";

import EmailScreenCard, {
  type FormValues as EmailSubmitValues,
  type EmailScreen,
} from "./proof/screens/email";
import PasswordScreenCard, {
  type FormValues as PasswordSubmitValues,
  type PasswordScreen,
  type MfaData,
} from "./proof/screens/password";
import SamlScreenCard, { type SSOScreen } from "./proof/screens/saml";
import ProofBackground from "./proof/background";
import ForgotPasswordScreenCard, {
  type ForgotPasswordScreen,
} from "./proof/screens/forgot_password";
import MfaScreenCard, { type MfaScreen } from "./proof/screens/mfa";
import LoginAttemptsExceededScreenCard, {
  type LoginAttemptsExceededScreen,
} from "./proof/screens/login_attempts_exceeded";
import ResetPasswordScreenCard, { type ResetPasswordScreen } from "./proof/screens/reset_password";

type LoadingScreen = {
  type: "loading";
  email: string;
};

export type Screen =
  | ResetPasswordScreen
  | ForgotPasswordScreen
  | EmailScreen
  | LoginAttemptsExceededScreen
  | SSOScreen
  | MfaScreen
  | PasswordScreen
  | LoadingScreen;
type LoginProps = {
  loginAttemptsExceeded: boolean;
  onGoogleSignIn: (authCode: AuthCode) => void;
  loginWithPassword: (
    values: EmailSubmitValues & PasswordSubmitValues,
  ) => Promise<MfaData | undefined>;
  onClearErrors: () => void;
  webThemeColor?: string | null;
  onSuccess: () => void;
  emailFromParams: string;
  hideEmailFooter?: boolean;
};

export const LOGIN_ATTEMPTS_EXCEEDED = "login_attempts_exceeded";

function Login(props: LoginProps) {
  const {
    onGoogleSignIn,
    loginWithPassword,
    loginAttemptsExceeded,
    onClearErrors,
    onSuccess: onMfaSuccess,
    emailFromParams,
    hideEmailFooter,
  } = props;
  const [searchParams] = useSearchParams();
  const [screen, setScreen] = useState<Screen>(
    emailFromParams ? { type: "loading", email: emailFromParams } : { type: "email" },
  );
  const handleNextScreen = (screen: Screen) => {
    onClearErrors();
    setScreen(screen);
  };
  const loginAttemptsExceededOrForbidden =
    loginAttemptsExceeded || searchParams.get("forbidden") === "true";
  useEffect(() => {
    if (loginAttemptsExceededOrForbidden) {
      handleNextScreen({ type: LOGIN_ATTEMPTS_EXCEEDED, email: screen.email! });
    }
  }, [loginAttemptsExceededOrForbidden, screen.email]);
  if (emailFromParams && screen.type === "loading" && !loginAttemptsExceededOrForbidden) {
    getAuthenticationTypes({ email: emailFromParams })
      .then((response) => {
        if (response.authenticationTypes.includes("sso")) {
          setScreen({
            type: "sso",
            email: emailFromParams,
            ssoProvider: response.ssoProvider!,
            passwordAuthEnabled: response.authenticationTypes.includes("password"),
          });
        } else {
          setScreen({ type: "password", password: "", email: emailFromParams });
        }
      })
      .catch(() => {
        setScreen({ type: "email" });
      });
  }
  switch (screen.type) {
    case "email":
      return (
        <EmailScreenCard
          email={screen.email}
          onGoogleSignIn={onGoogleSignIn}
          onPasswordLogin={loginWithPassword}
          onNextScreen={handleNextScreen}
          hideFooter={hideEmailFooter}
        />
      );
    case "password":
      return (
        <PasswordScreenCard
          onPasswordLogin={loginWithPassword}
          email={screen.email}
          onNextScreen={handleNextScreen}
        />
      );
    case "sso":
      return (
        <SamlScreenCard
          passwordAuthEnabled={screen.passwordAuthEnabled}
          onNextScreen={handleNextScreen}
          email={screen.email}
          ssoProvider={screen.ssoProvider}
        />
      );
    case "mfa":
      return (
        <MfaScreenCard
          email={screen.email}
          authOptions={screen.authOptions}
          onMfaSuccess={onMfaSuccess}
          onNextScreen={handleNextScreen}
          password={screen.password}
        />
      );
    case "forgot_password":
      return <ForgotPasswordScreenCard email={screen.email} onNextScreen={handleNextScreen} />;
    case "login_attempts_exceeded":
      return (
        <LoginAttemptsExceededScreenCard email={screen.email} onNextScreen={handleNextScreen} />
      );
    case "reset_password":
      return <ResetPasswordScreenCard onNextScreen={handleNextScreen} email={screen.email} />;
    case "loading":
      return <LoadingIndicator />;
  }
}

export default function ThemedLogin(props: LoginProps) {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transaction_id");
  const organizationName = searchParams.get("organization_name");
  const { brandLoading, brand } = useOrgBrandUnauthed(transactionId);
  if (brandLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OrgBrandTheme theme={brand ?? {}}>
      <ProofBackground brand={brand} organizationName={organizationName}>
        <Login {...props} />
      </ProofBackground>
    </OrgBrandTheme>
  );
}
