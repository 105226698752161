import type { ReactNode } from "react";

import Link from "common/core/link";
import Icon from "common/core/icon";

import Styles from "./dropdown_item.module.scss";

type Props = {
  automationId: string; // A data-automation-id to attach to the target div
  onClick: () => void;
  children: ReactNode;
  isButton?: boolean;
  className?: string;
};

export function HeaderDropdownItemIcon({ name }: { name: string }) {
  return <Icon className={Styles.dropdownItemIcon} name={name} />;
}

export function HeaderDropdownItem({
  children,
  onClick,
  automationId,
  isButton,
  className,
}: Props) {
  return (
    // Note: We do not use role=menuitem, or keyboard navigation in this component to avoid focus being trapped
    // in the menu and also to avoid the need to handle complex keyboard interactions associated with the menu role. See more
    // here: https://adrianroselli.com/2017/10/dont-use-aria-menu-roles-for-site-nav.html
    <li className={className}>
      {isButton ? (
        <button
          data-automation-id={automationId}
          onClick={onClick}
          type="button"
          className={Styles.dropdownItem}
        >
          {children}
        </button>
      ) : (
        <Link
          underlined={false}
          automationId={automationId}
          onClick={onClick}
          black
          className={Styles.dropdownItem}
        >
          {children}
        </Link>
      )}
    </li>
  );
}
