import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import {
  GetZendeskFieldValue,
  GetZendeskTransactionValue,
  GetZendeskUserIdValue,
  ZendeskTagField,
  zendeskFieldValDelimiter,
  type ZendeskTransactionProps,
} from "common/support/zendesk_helper";
import Link from "common/core/link";
import AppFrameInnerContainer from "common/app_frame/inner_container";

import Styles from "./index.module.scss";

export default function AdminSupportTicketLink() {
  const [navUrl, setNavUrl] = useState<string>("");
  const [transactionList, setTransactionList] = useState<ZendeskTransactionProps[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [params] = useSearchParams();
  // the zendesk ticketId is passed as a query var with id=xxx on the support-ticket path
  // const ticketId = params.get("id")
  // the tags=... query value is a space separated list of tags in the form field_name:field_val
  const tagTokens = params.get("tags")?.split(" ");

  useEffect(() => {
    const tagVals: {
      transactions: ZendeskTransactionProps[];
      userId: string;
    } = {
      transactions: [],
      userId: "",
    };
    for (const t of tagTokens ?? []) {
      if (t.includes(zendeskFieldValDelimiter)) {
        const { field, val } = GetZendeskFieldValue(t);
        if (field === ZendeskTagField.TRANSACTION) {
          tagVals.transactions.push(GetZendeskTransactionValue(val));
        } else if (field === ZendeskTagField.USER_ID) {
          const valAsUserId = GetZendeskUserIdValue(val);
          if (valAsUserId) {
            tagVals.userId = valAsUserId;
          }
        }
      } else {
        const tAsUserId = GetZendeskUserIdValue(t);
        if (tAsUserId) {
          tagVals.userId = tAsUserId;
        }
      }
    }

    if (tagVals.transactions.length === 1) {
      const { id, orgId } = tagVals.transactions[0];
      setNavUrl(
        orgId
          ? `/companies/${orgId}/transactions/${id}/summary`
          : `/tools?transactionSearch=${id}&userSearch=${tagVals.userId || ""}`,
      );
    } else if (tagVals.transactions.length) {
      setTransactionList(tagVals.transactions);
      setUserId(tagVals.userId);
    } else if (tagVals.userId) {
      setNavUrl(`/users/${tagVals.userId}`);
    } else {
      setNavUrl("/tools");
    }
  }, []);

  return (
    <>
      {transactionList.length || userId ? null : <LoadingIndicator />}
      {navUrl ? (
        <Navigate to={navUrl} replace />
      ) : (
        <AppFrameInnerContainer
          title={
            <FormattedMessage
              id="106ddd91-3cd3-4af2-9ca8-bce87355330a"
              defaultMessage="Redirect to..."
            />
          }
          closePath="/"
        >
          <div className={Styles.linksHolder}>
            {userId && (
              <span className={Styles.linkHolder}>
                <Link to={`/users/${userId}`}>{userId}</Link>
              </span>
            )}
            {transactionList.map((t) => (
              <span key={t.id} className={Styles.linkHolder}>
                <FormattedMessage
                  id="a0dd5525-a950-4037-addd-de19ddeee81c"
                  defaultMessage="<link>{hasOrg, select, true{} other{Search }}{id}</link>"
                  values={{
                    id: t.id,
                    hasOrg: Boolean(t.orgId),
                    link: (text) => (
                      <Link
                        to={
                          t.orgId
                            ? `/companies/${t.orgId}/transactions/${t.id}/summary`
                            : `/tools?transactionSearch=${t.id}&userSearch=${userId || ""}`
                        }
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              </span>
            ))}
          </div>
        </AppFrameInnerContainer>
      )}
    </>
  );
}
