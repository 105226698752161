import { FormattedMessage } from "react-intl";

import { Paragraph } from "common/core/typography";
import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";

import Styles from "./index.module.scss";

export default function TosMessage({ size }: { size?: "defaultSize" | "large" | "small" }) {
  return (
    <Paragraph data-automation-id="tos-message" className={Styles.tosMessage} size={size}>
      <FormattedMessage
        id="255e0638-932f-46c7-ae64-c7c0b99530c3"
        defaultMessage="By clicking 'Continue', or signing up with your Google account, you are agreeing to the {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}."
        values={{
          termsOfUse: <UserTermsOfService underlined />,
          privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
        }}
      />
    </Paragraph>
  );
}
