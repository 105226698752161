import { createContext, useContext, useState, type ReactNode } from "react";
import { produce } from "immer";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";

import { usePermissions } from "common/core/current_user_role";

import { type ProofReasonCodes_viewer_proofReasonCodes as ProofReasonCode } from "./proof_reason_codes_query.graphql";

export { type ProofReasonCode };

export type ReasonCodeEdit = {
  enabled?: boolean;
  // Other edits types will go here as well
};

function useProofReasonCodeContextValue() {
  const { hasPermissionFor } = usePermissions();
  const canView = hasPermissionFor("viewProofReasonCodes");
  const canEdit = hasPermissionFor("editProofReasonCodes");
  const [edits, setEdits] = useState<Record<string, ReasonCodeEdit | undefined>>({});
  const [originals, setOriginals] = useState<Record<string, ProofReasonCode> | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [reasonCodeData, setReasonCodeData] = useState<ProofReasonCode[] | null>(null);
  const [loadingData, setLoadingData] = useState(true);

  // Helper function
  function removeEdit(proofReasonCodeId: string, fieldName: keyof ReasonCodeEdit) {
    setEdits(
      produce((edits) => {
        const edit = edits[proofReasonCodeId];
        if (!edit) {
          return;
        }
        delete edit[fieldName];

        // If there are no edits, remove the edit object from the state
        if (Object.keys(edit).length === 0) {
          delete edits[proofReasonCodeId];
        }
      }),
    );
  }

  // Helper function
  function addEdit<K extends keyof ReasonCodeEdit>(
    proofReasonCodeId: string,
    fieldName: K,
    newValue: ReasonCodeEdit[K],
  ) {
    setEdits(
      produce((edits) => {
        edits[proofReasonCodeId] ||= {};
        edits[proofReasonCodeId][fieldName] = newValue;
      }),
    );
  }

  // Helper function
  function setEdit<K extends keyof ReasonCodeEdit>(
    proofReasonCodeId: string,
    fieldName: K,
    newValue: ReasonCodeEdit[K],
  ) {
    if (!originals) {
      return;
    }
    const originalValue = originals[proofReasonCodeId][fieldName];
    if (isEqual(newValue, originalValue)) {
      removeEdit(proofReasonCodeId, fieldName);
    } else {
      addEdit(proofReasonCodeId, fieldName, newValue);
    }
  }

  function setOriginalCodes(codes: ProofReasonCode[] | undefined, loading: boolean) {
    const newCodeMap = codes
      ? codes.reduce<Record<string, ProofReasonCode>>((prev, code) => {
          prev[code.id] = code;
          return prev;
        }, {})
      : null;
    setReasonCodeData(codes ?? null);
    setLoadingData(loading);
    setOriginals(newCodeMap);
    setEdits({});
  }

  function setEnabled(proofReasonCodeId: string, newValue: boolean) {
    return setEdit(proofReasonCodeId, "enabled", newValue);
  }

  function filterCodes(searchTerm: string) {
    setSearchFilter(searchTerm);
  }

  function discardAllEdits() {
    setEdits({});
  }

  return {
    canView,
    canEdit,
    edits,
    originals,
    searchFilter,
    reasonCodeData,
    loadingData,
    setOriginalCodes,
    setEnabled,
    discardAllEdits,
    filterCodes,
  };
}

type ProofReasonCodeContextValue = ReturnType<typeof useProofReasonCodeContextValue>;
const ProofReasonCodeContext = createContext<ProofReasonCodeContextValue | null>(null);

export function ProofReasonCodeProvider({ children }: { children: ReactNode }) {
  const value = useProofReasonCodeContextValue();
  return (
    <ProofReasonCodeContext.Provider value={value}>{children}</ProofReasonCodeContext.Provider>
  );
}

export function useProofReasonCodes() {
  const value = useContext(ProofReasonCodeContext);
  if (!value) {
    throw new Error(
      "useProofReasonCodes called without being wrapped in <ProofReasonCodeProvider>",
    );
  }
  return value;
}
