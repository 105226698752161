import "./analytics_page.scss";
import "common/styles/data_table.scss";

import { Link } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import {
  MeetingEndedState,
  OrganizationTransactionVariant,
  ParticipantTypes,
} from "graphql_globals";
import { formatDurationStrict, LongFormattedDateTime } from "common/core/format/date";
import Icon from "common/core/icon";
import UserFullName from "common/user/user_full_name";
import { MailtoLink } from "common/core/mailto_link";
import { newPathWithPreservedSearchParams } from "util/location";
import { usePermissions } from "common/core/current_user_role";

import type {
  AdminAnalyticsPageMeetings as MeetingConnection,
  AdminAnalyticsPageMeetings_edges_node as Meeting,
  AdminAnalyticsPageMeetings_edges_node_participants_SignerIdentity as SignerIdentity,
} from "./analytics_page_fragment.graphql";

type Props = {
  pageNumber: number;
  meetings: MeetingConnection;
};

const MEETING_STATE_LABELS = defineMessages<MeetingEndedState>({
  [MeetingEndedState.NOT_COMPLETED]: {
    id: "4c9ae5b9-83a3-49dc-a8d9-a8a7dd9f636f",
    defaultMessage: "Ongoing",
  },
  [MeetingEndedState.KILLED]: {
    id: "af7e6eb6-f3c3-4735-9310-d8c8825cb30a",
    defaultMessage: "Dropped",
  },
  [MeetingEndedState.CUSTOMER_CANCELLED]: {
    id: "36b055af-d1d8-4ce8-b64b-e4a0edf9c800",
    defaultMessage: "Customer cancelled",
  },
  [MeetingEndedState.NOTARY_CANCELLED_NO_CHARGE]: {
    id: "a0678e2e-8dc1-4051-bac2-201f5fe7c558",
    defaultMessage: "Notary cancelled",
  },
  [MeetingEndedState.NOTARY_CANCELLED_WITH_CHARGE]: {
    id: "57089cda-14b1-4b13-bcb1-355e990c2160",
    defaultMessage: "Notary cancelled",
  },
  [MeetingEndedState.COMPLETED]: {
    id: "47a0fdaf-9e25-41b8-b0ca-ea513440e5b0",
    defaultMessage: "Finished",
  },
});

function duration(meeting: Meeting) {
  return formatDurationStrict({ from: meeting.startedAt, to: meeting.endedAt });
}

function AnalyticsPage({ pageNumber, meetings: { edges } }: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();

  if (!edges.length) {
    return <div className="AdminAnalytics-message">No meetings matched the filter criteria.</div>;
  }

  return (
    <table className="table AnalyticsNotarizationsTable">
      <thead>
        <tr>
          <th className="noselect ellipsis">Notary Name</th>
          <th className="noselect ellipsis">Primary Signer Name</th>
          <th className="noselect ellipsis">Primary Signer Email</th>
          <th className="noselect ellipsis">Meeting Type</th>
          <th className="noselect ellipsis">Organization</th>
          <th className="noselect">Platform</th>
          <th className="noselect">Date</th>
          <th className="noselect">Call Time</th>
          <th className="noselect">Meeting State</th>
        </tr>
      </thead>

      <tbody>
        {edges.map(({ node: meeting }) => {
          const { id, publicNotaryDetails, startedAt, organizationName, platform, participants } =
            meeting;

          const signerIdentities = participants!.filter((p) => {
            return p!.participantType === ParticipantTypes.SIGNER;
          }) as SignerIdentity[];
          const customerIdentity = signerIdentities.find((signerIdentity) => {
            return signerIdentity.author!.id === signerIdentity.customer!.id;
          });
          const customerInformation = customerIdentity?.customerInformation;

          const orgName = organizationName || "";

          const { endedState } = meeting;
          const statusClass = classnames("status", {
            "status--success": endedState === MeetingEndedState.COMPLETED,
          });
          const statusText = intl.formatMessage(MEETING_STATE_LABELS[endedState]);

          return (
            <tr key={id}>
              <td className="ellipsis">
                <UserFullName user={publicNotaryDetails} />
              </td>
              <td className="ellipsis">
                {customerInformation && <UserFullName user={customerInformation} />}
              </td>
              <td className="ellipsis">
                <MailtoLink emailAddress={customerInformation?.email || ""} />
              </td>
              <td>
                {meeting.organizationTransaction.transactionVariant ===
                OrganizationTransactionVariant.PROOF ? (
                  <FormattedMessage
                    id="40e53a4c-81c8-426a-a402-032ca6a1c1bf"
                    defaultMessage="Trusted referee"
                  />
                ) : (
                  <FormattedMessage
                    id="c5ddaff9-d7ac-47aa-a9b3-bb7edccd5ce5"
                    defaultMessage="Notary"
                  />
                )}
              </td>
              <td title={orgName} className="ellipsis">
                {orgName}
              </td>
              <td>{platform}</td>
              <td>
                <LongFormattedDateTime value={startedAt} />
              </td>
              <td>{duration(meeting)}</td>
              <td className="link-append">
                {hasPermissionFor("meetingDetails") && (
                  <>
                    <span className={statusClass}>{statusText}</span>
                    <Link
                      to={newPathWithPreservedSearchParams(
                        `/analytics/page/${pageNumber}/${id}/notary`,
                      )}
                    >
                      Details <Icon className="icon" name="caret-right" />
                    </Link>
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default AnalyticsPage;
