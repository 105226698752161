import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Button from "common/core/button";
import Link from "common/core/link";
import WorkflowModal from "common/modals/workflow_modal";
import { useForm, useFieldArray } from "common/core/form";
import { TextInput } from "common/core/form/text";
import {
  isAriaInvalid,
  FieldErrorMessage,
  defaultRequiredMessage,
  emailPatternValidation,
} from "common/core/form/error";
import { useMutation } from "util/graphql";
import { IconButton } from "common/core/button/icon_button";

import type { CommunicationSettingsEditEmail_node_Organization as Organization } from "../index.query.graphql";
import SendPreviewEmailMutation from "./send_preview_email.mutation.graphql";
import Styles from "./test_email.module.scss";

const MESSAGES = defineMessages({
  emailPlaceholder: {
    id: "ee2bd17a-5c58-450f-a3ed-e73433e24d63",
    defaultMessage: "Email Address",
  },
  removeEmail: {
    id: "30232642-a223-4914-b8a9-2dedc3b64fef",
    defaultMessage: "Remove email",
  },
});

type FormValues = {
  recipients: { email: string }[];
};

export function useTestEmailInteraction({
  data,
  organization,
}: {
  organization: Organization;
  data: {
    subject: string;
    body: string;
    payload: string;
  };
}) {
  const [sending, setSending] = useState(false);
  const SendPreviewEmailMutateFn = useMutation(SendPreviewEmailMutation);

  const handleSend = (recipients: string[]) => {
    setSending(true);

    return SendPreviewEmailMutateFn({
      variables: {
        input: {
          organizationId: organization.id,
          emailSubject: data.subject,
          emailBody: data.body,
          payload: JSON.parse(data.payload) as NotarizeScalarHash,
          recipients,
        },
      },
    }).then(() => {
      setSending(false);
    });
  };

  return {
    sending,
    sendTestEmail: handleSend,
  };
}

export function Modal({
  onCancel,
  onConfirm,
  loading,
}: {
  onCancel: () => void;
  onConfirm: (recipients: { email: string }[]) => void;
  loading: boolean;
}) {
  const intl = useIntl();
  const {
    handleSubmit: submit,
    control,
    register,
    formState,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      recipients: [{ email: "" }],
    },
  });
  const { errors, isValid } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const handleSubmit = (formValues: FormValues) => {
    onConfirm(formValues.recipients);
  };

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="4b5b0a16-178f-4eff-b4fa-7a125c5088f6"
          defaultMessage="Send a test email"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel-test-email"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="bd1f0204-f108-457f-aeda-b132da996162" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="confirm-test-email"
          onClick={() => {
            if (isValid) {
              const values = getValues();
              handleSubmit(values);
            }
          }}
          isLoading={loading}
        >
          <FormattedMessage id="e7bfcfee-b357-4773-8eb1-94cbc2ec3db4" defaultMessage="Send" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="eda13bde-2ddd-4ddc-9c1a-6a97db539f3b"
        defaultMessage="Enter one email address per field."
      />
      <form className={Styles.form} onSubmit={submit(handleSubmit)}>
        {fields.map((recipient, i) => (
          <div key={recipient.id}>
            <div className={Styles.formRow}>
              <TextInput
                className={Styles.input}
                placeholder={intl.formatMessage(MESSAGES.emailPlaceholder)}
                aria-invalid={isAriaInvalid(errors.recipients?.[i])}
                {...register(`recipients.${i}.email`, {
                  required: true,
                  pattern: emailPatternValidation(intl),
                })}
              />
              {i !== 0 && (
                <IconButton
                  name="x"
                  onClick={() => {
                    remove(i);
                  }}
                  variant="tertiary"
                  buttonColor="danger"
                  buttonSize="condensed"
                  label={intl.formatMessage(MESSAGES.emailPlaceholder)}
                />
              )}
            </div>
            {errors.recipients?.[i] && (
              <div className={Styles.errorMessage}>
                <FieldErrorMessage
                  inputName={`recipient.${i}.email`}
                  message={errors.recipients[i].email?.message || defaultRequiredMessage(intl)}
                />
              </div>
            )}
          </div>
        ))}
      </form>
      <Link
        onClick={() => {
          append({ email: "" });
        }}
      >
        <FormattedMessage
          id="f112e681-2ae8-4fd8-a799-b3f8440bc958"
          defaultMessage="+ Add email address"
        />
      </Link>
    </WorkflowModal>
  );
}
