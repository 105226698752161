import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { useCopy } from "util/clipboard";
import TooltipOverlay from "common/core/tooltip/overlay";
import PopoutMenuItem from "common/core/popout_menu/item";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import PopoutMenu from "common/core/popout_menu";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import { type OrganizationDomains_organization_Organization_domainVerifications as DomainItemType } from "../organization_domains.query.graphql";
import Styles from "../index.module.scss";
import VerifyDomainMutation from "./verify_domain.mutation.graphql";

const MESSAGES = defineMessages({
  copyRecord: {
    id: "70576058-edf5-4a41-94fb-d2e8fe68de05",
    defaultMessage: "Copy {recordType} record",
  },
  reVerifyDomain: {
    id: "7b8dce26-e2fa-40c7-9ca1-d1bc6b20e233",
    defaultMessage: "Re-verify domain",
  },
  verifyErrorTitle: {
    id: "7e0bbbd7-a0f0-4a00-a7c3-e9932c4586f8",
    defaultMessage: "Domain verification unsuccessful.",
  },
  actions: {
    id: "bc261f8d-1a31-4967-9ab9-7cdd8bb0e12b",
    defaultMessage: "Actions",
  },
  verifySuccessTitle: {
    id: "3bbf7b47-89d8-4799-a7b8-646a5defb8e4",
    defaultMessage: "Success! Your domain was verified.",
  },
  keystoneVerifySuccessTitle: {
    id: "739a0534-6414-4eef-bc2d-fda70d30840f",
    defaultMessage: "Success! The domain was verified.",
  },
  verifySuccessMessage: {
    id: "27dac57c-5e0a-4d25-b15f-55cee6a3b1d5",
    defaultMessage: "You can now use this domain to configure domain-based policies.",
  },
  keystoneVerifySuccessMessage: {
    id: "beb0dcdb-7530-4ebe-a883-0597d96a9250",
    defaultMessage: "The customer can now use this domain to configure domain-based policies.",
  },
  unknownError: {
    id: "fef360cd-04e3-40c1-81f9-0fb5dc063c7a",
    defaultMessage: "Unknown error. Please contact customer support if this issue persists.",
  },
  verifyErrorMessage: {
    id: "59b165b8-40a6-4d5b-9a96-fbe0a54c1e1f",
    defaultMessage:
      "Please check you have entered the {recordType} record into your DNS exactly as it shows below. If you just made the change, it can take up to 72 hours for the DNS to update. Please try again later.",
  },
  keystoneVerifyErrorMessage: {
    id: "ed5146ec-0f6e-4767-a3f6-3daac6ca760c",
    defaultMessage:
      "Please check that the customer has entered the {recordType} record into their DNS exactly as it shows below. If they just made the change, it can take up to 72 hours for the DNS to update. Please try again later.",
  },
  copied: {
    id: "b8b19f07-12f2-4d01-ad45-60f2f254529c",
    defaultMessage: "Copied!",
  },
  domainActions: {
    id: "e5b79fa2-f956-46e8-8e39-9d1ecd834c0a",
    defaultMessage: "Domain Actions",
  },
});

/** reusable hook to call VerifyDomainMutation  **/
export const useVerifyDomainMutation = (
  domainItem: DomainItemType,
): { verifyDomain: () => void; verifyDomainLoading: boolean } => {
  const intl = useIntl();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  // errorPolicy: "all" is needed as the mutation can return errors and an update to Last Checked time
  const verifyDomainMutation = useMutation(VerifyDomainMutation, { errorPolicy: "all" });
  const [verifyDomainLoading, setVerifyDomainLoading] = useState(false);

  const verifyDomain = useCallback(() => {
    setVerifyDomainLoading(true);
    return verifyDomainMutation({
      variables: {
        input: {
          domainVerificationId: domainItem.id,
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          if (
            ["invalid_state", "dns_record_content_not_found", "dns_record_not_found"].includes(
              response.errors[0].message,
            )
          ) {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.WARNING,
              title: intl.formatMessage(MESSAGES.verifyErrorTitle),
              message: intl.formatMessage(
                isAdminPortal ? MESSAGES.keystoneVerifyErrorMessage : MESSAGES.verifyErrorMessage,
                {
                  recordType: domainItem.recordType,
                },
              ),
              position: "topCenter",
            });
          } else {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.ERROR,
              message: intl.formatMessage(MESSAGES.unknownError),
              position: "topCenter",
            });
            captureException(response.errors[0]);
          }
        } else {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
            title: intl.formatMessage(
              isAdminPortal ? MESSAGES.keystoneVerifySuccessTitle : MESSAGES.verifySuccessTitle,
            ),
            message: intl.formatMessage(
              isAdminPortal ? MESSAGES.keystoneVerifySuccessMessage : MESSAGES.verifySuccessMessage,
            ),
            position: "topCenter",
          });
        }
      })
      .finally(() => {
        setVerifyDomainLoading(false);
      });
  }, [domainItem]);

  return { verifyDomain, verifyDomainLoading };
};

/** button with popout menu actions for a verified domain  **/
export function VerifiedDomainActionsButton({
  buttonType,
  domainItem,
}: {
  buttonType: "kebab" | "action";
  domainItem: DomainItemType;
}) {
  const intl = useIntl();
  const { copy, recentlyCopied } = useCopy();
  const { verifyDomain, verifyDomainLoading } = useVerifyDomainMutation(domainItem);
  const recordContent = `${domainItem.recordName}=${domainItem.recordContent}`;
  const domainName = domainItem.domain.domain;
  const kebabButton = buttonType === "kebab";
  return (
    <PopoutMenu
      placement="bottomRight"
      automationId={`${domainName}-popout-menu`}
      target={
        <Button
          className={kebabButton ? Styles.kebabButton : undefined}
          variant="secondary"
          buttonColor="action"
          isLoading={verifyDomainLoading}
          automationId={`domain-actions-button`}
        >
          {kebabButton ? (
            <Icon name="kebab-menu" size="large" />
          ) : (
            <>
              {intl.formatMessage(MESSAGES.actions)}
              <Icon className={Styles.caretDownIcon} name="caret-down" />
            </>
          )}
          {recentlyCopied && (
            <TooltipOverlay
              className={Styles.toolTip}
              id="copied"
              trigger="click"
              placement="top"
              size="mini"
            >
              {intl.formatMessage(MESSAGES.copied)}
            </TooltipOverlay>
          )}
        </Button>
      }
    >
      {({ close }) => (
        <>
          <PopoutMenuItem
            data-automation-id={`${domainName}-copy-record-item`}
            onClick={() => {
              close();
              copy(recordContent);
            }}
          >
            {intl.formatMessage(MESSAGES.copyRecord, { recordType: domainItem.recordType })}
          </PopoutMenuItem>
          <PopoutMenuItem
            data-automation-id={`${domainName}-re-verify`}
            onClick={() => {
              close();
              verifyDomain();
            }}
          >
            {intl.formatMessage(MESSAGES.reVerifyDomain)}
          </PopoutMenuItem>
        </>
      )}
    </PopoutMenu>
  );
}
