import { Component } from "react";

import { AsyncJobStatus } from "graphql_globals";

const POLLING_INTERVAL = 10_000;

function isJobPending(asyncJob) {
  return asyncJob.status === AsyncJobStatus.PENDING;
}

function getJobs(props) {
  return props.organization.asyncJobs || [];
}

function asyncJobsEnhancer(WrappedComponent) {
  return class AsyncJobs extends Component {
    state = {
      asyncJobErrors: [],
    };

    componentDidMount() {
      if (getJobs(this.props).some(isJobPending)) {
        this.onPendingJobs();
      }
    }

    componentDidUpdate(prevProps) {
      const currentAsyncJobs = getJobs(prevProps);
      const nextAsyncJobs = getJobs(this.props);

      const currentPropsHasPendingJobs = (currentAsyncJobs || []).some(isJobPending);
      const nextPropsHasPendingJobs = (nextAsyncJobs || []).some(isJobPending);
      // If we've finished all the jobs
      if (currentPropsHasPendingJobs && !nextPropsHasPendingJobs) {
        this.onFinishedJobs();
      }
      // We should start polling for the new jobs if we have new pending jobs
      if (!currentPropsHasPendingJobs && nextPropsHasPendingJobs) {
        this.onPendingJobs();
      }

      // Need to analyze all of the individual jobs to figure out if we have new failures
      (currentAsyncJobs || []).forEach((currentAsyncJob) => {
        const nextAsyncJob = find(nextAsyncJobs, { id: currentAsyncJob.id });
        if (
          currentAsyncJob.status === AsyncJobStatus.PENDING &&
          (nextAsyncJob.status === AsyncJobStatus.PARTIALLY_COMPLETED ||
            nextAsyncJob.status === AsyncJobStatus.FAILED)
        ) {
          this.onPartiallyFinishedJob(nextAsyncJob);
        }
      });
    }

    saveErrors = (errors) => {
      this.setState((state) => ({ asyncJobErrors: (state.asyncJobErrors || []).concat(errors) }));
    };

    clearErrors = () => {
      this.setState({ asyncJobErrors: [] });
    };

    // Called when we go from 0 to more than 0 pending jobs
    onPendingJobs = () => {
      if (this.asyncJobsPollHandle) {
        return;
      }
      this.asyncJobsPollHandle = setInterval(() => {
        // Refetch the data and then let componentWillReceiveProps deal with the new data
        this.props.refetch();
      }, POLLING_INTERVAL);
    };

    // Called when we finish or partially finish ALL the jobs
    onFinishedJobs = () => {
      if (!this.asyncJobsPollHandle) {
        return;
      }
      clearInterval(this.asyncJobsPollHandle);
      this.asyncJobsPollHandle = null;
    };

    // Called when we partially finish a SINGLE job
    onPartiallyFinishedJob = (partiallyFinishedJob) => {
      this.saveErrors(partiallyFinishedJob.errors);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          pendingAsyncJobs={getJobs(this.props).filter(isJobPending)}
          asyncJobErrors={this.state.asyncJobErrors}
          dismissAsyncJobErrors={this.clearErrors}
        />
      );
    }
  };
}

export default asyncJobsEnhancer;
