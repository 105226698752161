import PropTypes from "prop-types";
import { Field } from "redux-form";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

import ProfileSectionHeader from "common/settings/profile_section_header";
import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";
import SetPassword from "common/account/fields/set_password";
import { validatePresence, validateIf } from "validators/form";
import { composeValidators } from "util/form";
import { validatePassword } from "validators/account";

import Styles from "./update_password_fields.module.scss";

export const validationRules = (_values) =>
  composeValidators(
    // format
    validateIf({
      field: "password",
      condition: (password) => password,
      validation: validatePassword({
        field: "password",
      }),
    }),

    // presence
    validatePresence({ field: "currentPassword", label: "Current Password" }),
    validatePresence({ field: "password", label: "New Password" }),
  );

const messages = defineMessages({
  updatePassword: {
    id: "a6dad182-8df7-41e1-94e7-1c8d32a09ca0",
    description: "updatePassword",
    defaultMessage: "Update Password",
  },
  currentPassword: {
    id: "15a6678d-7610-4ea9-be32-2ec0fba94196",
    description: "currentPassword",
    defaultMessage: "Current Password",
  },
  typeOldPassword: {
    id: "16f699ec-b87f-4d3d-9339-bfb32db1bcf1",
    description: "typeOldPassword",
    defaultMessage: "Type old password to confirm",
  },
  newPassword: {
    id: "0804b233-3190-4423-9674-1e6c97aea552",
    description: "newPassword",
    defaultMessage: "New Password",
  },
});

function UpdatePasswordFields({ passwordSet, ssoEnforced, googleEnforced, intl }) {
  const disabled = ssoEnforced || googleEnforced;

  return (
    <div>
      <ProfileSectionHeader>{intl.formatMessage(messages.updatePassword)}</ProfileSectionHeader>

      {!disabled && passwordSet && (
        <>
          <p id="update-password-instructions" className={Styles.info}>
            <FormattedMessage
              id="86fea583-c3d1-4780-8435-5bf1d90490fd"
              defaultMessage="You will need to login after changing your password."
            />
          </p>
          <FormGroup fields={["currentPassword"]}>
            <label htmlFor="currentPassword">{intl.formatMessage(messages.currentPassword)}</label>
            <Field
              id="currentPassword"
              name="currentPassword"
              type="password"
              component="input"
              placeholder={intl.formatMessage(messages.typeOldPassword)}
              data-automation-id="current-password-field"
            />
            <FormGroupErrors fields={["currentPassword"]} />
          </FormGroup>
        </>
      )}

      {ssoEnforced && (
        <p className={Styles.info}>
          <FormattedMessage
            id="3080d957-441c-432b-81b6-636ec20daa9c"
            defaultMessage="Your credentials are managed by your organization."
          />
        </p>
      )}
      {googleEnforced && (
        <p className={Styles.info}>
          <FormattedMessage
            id="8ab43279-f4c3-4fbd-ba59-951f3c264b2e"
            defaultMessage="Your credentials are managed through your Google account."
          />
        </p>
      )}

      {!disabled && (
        <FormGroup fields={["password"]}>
          <label htmlFor="password">{intl.formatMessage(messages.newPassword)}</label>
          <Field
            aria-describedby="update-password-instructions"
            name="password"
            component={SetPassword}
          />
        </FormGroup>
      )}
    </div>
  );
}

UpdatePasswordFields.displayName = "UpdatePasswordFields";

UpdatePasswordFields.propTypes = {
  passwordSet: PropTypes.bool.isRequired,
  ssoEnforced: PropTypes.bool.isRequired,
  googleEnforced: PropTypes.bool.isRequired,
};

export default injectIntl(UpdatePasswordFields);
