import { Component } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Select from "common/form/inputs/select";
import { CardHeading } from "common/core/card";
import { useMutation } from "util/graphql";

import UpdateTitleAgencyUsStatesMutation, {
  type UpdateTitleAgencyUsStates,
  type UpdateTitleAgencyUsStatesVariables,
} from "./update_title_agency_us_states_mutation.graphql";

type Props = {
  organization: { id: string; usStates: ({ id: string } | null)[] | null };
  viewer: { usStates: ({ id: string; name: string | null } | null)[] | null };
  updateUsStatesMutateFn: ReturnType<
    typeof useMutation<UpdateTitleAgencyUsStates, UpdateTitleAgencyUsStatesVariables>
  >;
};
type BaseProps = Omit<Props, "updateUsStatesMutateFn">;
type State = {
  canPersistUsStates: boolean;
  usStatesChangeInProgress: boolean;
  usStateChangeError: null | string;
  selectedUsStates: string[];
};

/** This component is responsible for updating the US states that a Title Agency operates in */
class TitleAgencyUsStatesSelection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      canPersistUsStates: false,
      usStatesChangeInProgress: false,
      usStateChangeError: null,
      selectedUsStates: (props.organization.usStates || []).map((state) => state!.id),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const newStates = (this.props.organization.usStates || []).map((state) => state!.id);
    const oldStates = (prevProps.organization.usStates || []).map((state) => state!.id);
    if (
      newStates.length !== oldStates.length ||
      !newStates.every((newState, index) => newState === oldStates[index])
    ) {
      this.setState({ selectedUsStates: newStates });
    }
  }

  onUsStatesChange = (selectedUsStates: State["selectedUsStates"]) => {
    this.setState({ selectedUsStates, canPersistUsStates: true });
  };

  persistUsStates = () => {
    const { organization, viewer, updateUsStatesMutateFn } = this.props;
    const { selectedUsStates } = this.state;

    this.setState({ usStatesChangeInProgress: true, usStateChangeError: null });
    updateUsStatesMutateFn({
      variables: {
        input: {
          organizationId: organization.id,
          usStateIds: selectedUsStates.includes("-1")
            ? viewer.usStates!.map((usState) => usState!.id)
            : selectedUsStates,
        },
      },
    })
      .then(() => {
        this.setState({ usStatesChangeInProgress: false, canPersistUsStates: false });
      })
      .catch((error: { graphQLErrors?: { specifics: string | null }[] }) => {
        this.setState({
          usStateChangeError:
            error.graphQLErrors?.[0]?.specifics || "Could not update recording US states",
          usStatesChangeInProgress: false,
        });
      });
  };

  render() {
    const { canPersistUsStates, usStatesChangeInProgress, usStateChangeError, selectedUsStates } =
      this.state;

    const usStatesList = [
      { label: "All States", value: "-1" },
      ...this.props.viewer.usStates!.map((usState) => ({
        label: usState!.name,
        value: usState!.id,
      })),
    ];
    return (
      <>
        <CardHeading>
          <FormattedMessage
            id="01ac13fa-55f6-4199-bce2-f3d0b71704b7"
            defaultMessage="States of Operation"
          />
        </CardHeading>
        <Select
          items={usStatesList}
          placeholder="US States"
          onChange={this.onUsStatesChange}
          value={selectedUsStates}
          autosize={false}
          multi
        />
        {usStateChangeError && (
          <div className="Form-row validation-failed">
            <div className="validation-message">{usStateChangeError}</div>
          </div>
        )}
        {canPersistUsStates && (
          <Button
            buttonColor="action"
            variant="primary"
            onClick={this.persistUsStates}
            disabled={usStatesChangeInProgress}
          >
            Save States of Operation
          </Button>
        )}
      </>
    );
  }
}

function WrappedTitleAgencyUsStatesSelection(props: BaseProps) {
  return (
    <TitleAgencyUsStatesSelection
      {...props}
      updateUsStatesMutateFn={useMutation(UpdateTitleAgencyUsStatesMutation)}
    />
  );
}

export default WrappedTitleAgencyUsStatesSelection;
