import { useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import { SettingsPageWrapper } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";
import ReswareInstance from "common/resware_instance";

import ReswareQuery, {
  type OrganizationResware_organization_Organization as Organization,
} from "./resware_query.graphql";

export default function ReswareIntegration() {
  const organizationId = useParams().globalID!;
  const { data, loading } = useQuery(ReswareQuery, {
    variables: { organizationId },
  });
  const organization = data?.organization as Organization;

  return loading ? (
    <LoadingIndicator />
  ) : (
    <SettingsPageWrapper>
      {/* REAL-9063 update isProof to true */}
      <ReswareInstance isProof={false} organization={organization} />
    </SettingsPageWrapper>
  );
}
