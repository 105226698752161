import {
  memo,
  useCallback,
  useEffect,
  useState,
  type ReactNode,
  type SyntheticEvent,
  type ChangeEvent,
} from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { differenceInSeconds, differenceInMinutes } from "date-fns";
import { Link } from "react-router-dom";

import { MeetingQueueEnum } from "graphql_globals";
import { userFullName } from "util/user";
import { Portal } from "util/html";
import { TextInput } from "common/core/form/text";
import { encodeSearchParams } from "util/location";
import { NetworkStatus, useQuery } from "util/graphql";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";

import MeetingRequestDetailsModal from "./details_modal";
import SearchModal from "./search_modal";
import AdminMeetingRequestsQuery, {
  type AdminMeetingRequests_viewer as Viewer,
  type AdminMeetingRequests_viewer_notaries_edges as NotaryUserEdge,
  type AdminMeetingRequests_viewer_notaries_edges_node as NotaryUser,
  type AdminMeetingRequests_viewer_queriedMeetingRequests_edges as MeetingRequestEdge,
  type AdminMeetingRequests_viewer_queriedMeetingRequests_edges_node as MeetingRequest,
} from "./index_query.graphql";
import Styles from "./index.module.scss";

type CondensedQueue = "real" | "retail" | "business" | "other";
type MeetingRequestItemProps = {
  now: Date;
  meetingRequest: MeetingRequest;
  onClick: (req: MeetingRequestItemProps["meetingRequest"]) => void;
};
type NotaryItemProps = {
  user: NotaryUser;
};
type Props = {
  viewer: Viewer;
  refetch: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "35b97ff6-df2c-455f-b7f7-d9dc88f2bac2",
    defaultMessage: "Search by user, transaction, bundle, or organization ID",
  },
});

function groupNotariesByTier(notaries: NotaryUserEdge[]) {
  const grouped = notaries.reduce((accum, { node: notaryUser }) => {
    const { tier } = notaryUser.notaryProfile!;
    const current = accum.get(tier);
    if (current) {
      current.push(notaryUser);
    } else {
      accum.set(tier, [notaryUser]);
    }
    return accum;
  }, new Map<number, NotaryUser[]>());
  return Array.from(grouped.entries()).sort((a, b) => a[0] - b[0]);
}

function groupMeetingRequestsByQueue(meetingRequests: MeetingRequestEdge[]) {
  const grouped = meetingRequests.reduce((accum, { node: meetingRequest }) => {
    const { queue } = meetingRequest;
    const condensed: CondensedQueue =
      queue === MeetingQueueEnum.DEFAULT
        ? "retail"
        : queue === MeetingQueueEnum.BUSINESS
          ? "business"
          : "real"; // TITLE or LENDER
    const current = accum.get(condensed);
    if (current) {
      current.push(meetingRequest);
    } else {
      accum.set(condensed, [meetingRequest]);
    }
    return accum;
  }, new Map<CondensedQueue, MeetingRequest[]>());
  return Array.from(grouped.entries()).sort((a, b) => a[0].localeCompare(b[0]));
}

function MeetingRequestItem({ meetingRequest, now, onClick }: MeetingRequestItemProps) {
  return (
    <button type="button" className={Styles.actionRow} onClick={() => onClick(meetingRequest)}>
      <FormattedMessage
        id="928dae9e-f31f-4977-ac5a-0405337407d1"
        defaultMessage="{requestedBy} <ago>{minutes, plural, one{1 minute} other{# minutes}} ago</ago>"
        values={{
          requestedBy: meetingRequest.requestedBy,
          ago: (msg: ReactNode[]) => <span className={Styles.ago}>{msg}</span>,
          minutes: Math.max(0, differenceInMinutes(now, new Date(meetingRequest.createdAt))),
        }}
      />
    </button>
  );
}

const MemoizedMeetingRequestItem = memo(MeetingRequestItem);

function NotaryItem({ user }: NotaryItemProps) {
  const search = user.email ? encodeSearchParams(new URLSearchParams({ email: user.email })) : "";
  return (
    <Link className={Styles.actionRow} to={`/notary?${search}`}>
      {userFullName(user)}
    </Link>
  );
}

const MemoizedNotaryItem = memo(NotaryItem);

function useNow() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);
  return now;
}

function MeetingRequestsContent({ viewer, refetch }: Props) {
  const intl = useIntl();
  const [refreshTime, setRefreshTime] = useState<null | Date>(new Date());
  const [idSearch, setIdSearch] = useState("");
  const [openSearchModalId, setOpenSearchModalId] = useState<null | string>(null);
  const [openMeetingRequestId, setOpenMeetingRequestId] = useState<null | string>(null);
  const now = useNow();
  const handleMeetingRequestClick = useCallback(
    (req: MeetingRequest) => setOpenMeetingRequestId(req.id),
    [],
  );
  const handleSearchSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setOpenSearchModalId(idSearch);
  };
  const handleCloseDetails = useCallback(() => setOpenMeetingRequestId(null), []);
  const handleCloseSearch = useCallback(() => setOpenSearchModalId(null), []);
  const notariesByTier = groupNotariesByTier(viewer.notaries.edges);
  const meetingRequestsByQueue = groupMeetingRequestsByQueue(viewer.queriedMeetingRequests.edges);
  return (
    <div className={Styles.container}>
      <div className={Styles.sectionHeader}>
        <form onSubmit={handleSearchSubmit}>
          <TextInput
            value={idSearch}
            aria-invalid={false}
            placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => setIdSearch(evt.target.value)}
          />
          <button type="submit" hidden />
        </form>
        <FormattedMessage
          id="04b9a274-77e0-4923-b8bb-987754b438ea"
          defaultMessage="Actively Queued Signers ({number})"
          values={{ number: viewer.queriedMeetingRequests.totalCount }}
          tagName="h2"
        />
        <div className={Styles.rightButton}>
          {refreshTime && (
            <FormattedMessage
              id="2594484d-1b52-4c1f-bacb-a304e16eb498"
              defaultMessage="Refreshed {secAgo} seconds ago."
              values={{ secAgo: Math.max(0, differenceInSeconds(now, refreshTime)) }}
              tagName="span"
            />
          )}
          <Button
            buttonColor="action"
            variant="primary"
            isLoading={!refreshTime}
            automationId="queued-signers-refresh-button"
            onClick={() => {
              setRefreshTime(null);
              refetch().finally(() => {
                setRefreshTime(new Date());
              });
            }}
          >
            <FormattedMessage id="dd4e618e-809a-47e4-a5e4-d94fff374790" defaultMessage="Refresh" />
          </Button>
        </div>
      </div>
      <div className={Styles.requests}>
        {meetingRequestsByQueue.map(([queue, meetingRequests]) => {
          return (
            <section key={queue}>
              <FormattedMessage
                id="41dc703d-ccf3-49ae-ba4f-de255841c74d"
                defaultMessage="{queue, select, real {Real Estate} retail {Retail} business {Business} other {Other}}"
                values={{ queue }}
                tagName="h3"
              />
              <div className={Styles.sectionListing}>
                {meetingRequests.map((meetingRequest) => (
                  <MemoizedMeetingRequestItem
                    key={meetingRequest.id}
                    meetingRequest={meetingRequest}
                    onClick={handleMeetingRequestClick}
                    now={now}
                  />
                ))}
              </div>
            </section>
          );
        })}
      </div>

      <div className={Styles.sectionHeader}>
        <FormattedMessage
          id="2ea9ab91-07af-4ce5-873e-6f59febc949c"
          defaultMessage="Available Notaries ({number})"
          values={{ number: viewer.notaries.totalCount }}
          tagName="h2"
        />
      </div>
      <div className={Styles.notaries}>
        {notariesByTier.map(([tier, notaryUsers]) => {
          return (
            <section key={tier}>
              <FormattedMessage
                id="5535c06f-d714-4ca8-8b6a-6aa05bb56986"
                defaultMessage="Tier {tier}"
                values={{ tier }}
                tagName="h3"
              />
              <div className={Styles.sectionListing}>
                {notaryUsers.map((notaryUser) => (
                  <MemoizedNotaryItem key={notaryUser.id} user={notaryUser} />
                ))}
              </div>
            </section>
          );
        })}
      </div>

      {openMeetingRequestId && (
        <Portal>
          <MeetingRequestDetailsModal id={openMeetingRequestId} onClose={handleCloseDetails} />
        </Portal>
      )}

      {openSearchModalId && (
        <Portal>
          <SearchModal id={openSearchModalId} onClose={handleCloseSearch} />
        </Portal>
      )}
    </div>
  );
}

function MeetingRequestsRoot() {
  const { networkStatus, data, refetch } = useQuery(AdminMeetingRequestsQuery);
  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }
  const viewer = data?.viewer;
  if (!viewer) {
    throw new Error(`Missing viewer with ${networkStatus}`);
  }
  return <MeetingRequestsContent viewer={viewer} refetch={refetch} />;
}

export default memo(MeetingRequestsRoot);
