import type { DateConstraint } from "common/dashboard/filter_dropdown/common";
import {
  deserializeDetailedStatus,
  deserializeTransactionTypes,
} from "common/dashboard/filter/deserializers";
import { serializeSet, serializerFactory } from "common/dashboard/filter/serializers";
import type {
  OrganizationTransactionDetailedStatus,
  MortgageTransactionType,
} from "graphql_globals";

export function adminDashboardTransactionDeserializer(queryArgs: URLSearchParams) {
  const page = Number(queryArgs.get("page"));
  const query = queryArgs.get("query");
  const detailedStatuses = deserializeDetailedStatus(queryArgs.get("detailedStatuses"));
  const transactionTypes = deserializeTransactionTypes(queryArgs.get("transactionTypes"));
  const dateConstraint = queryArgs.get("dateConstraint") as DateConstraint;

  return {
    page,
    query,
    detailedStatuses,
    transactionTypes,
    dateConstraint,
  };
}

const adminDashboardTransactionSerializeMap = {
  page: (arg: number | null) => (arg ? String(arg) : null),
  query: (arg: string | null) => arg || null,
  detailedStatuses: (arg: Set<OrganizationTransactionDetailedStatus> | null) => serializeSet(arg),
  transactionTypes: (arg: Set<MortgageTransactionType> | null) => serializeSet(arg),
  dateConstraint: (arg: DateConstraint | null) => (arg as string) || null,
};

export const adminDashboardSerializer = serializerFactory(adminDashboardTransactionSerializeMap);
