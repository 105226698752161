import type { ReactNode } from "react";
import classnames from "classnames";

import { useMatchScreenClass } from "common/core/responsive";

import type { ViewerDefaultAppFrame_user as User } from "../default_fragment.graphql";
import Styles from "./nav.module.scss";
import NavLinks from "./links";
import MinimalNav from "./dropdown_menus/minimal_nav";

type Props = {
  user: User;
};

type StyleProps = {
  children: ReactNode;
  className?: string;
};

export function HeaderNavStyle({ children, className }: StyleProps) {
  return (
    <nav className={classnames(className ? `${className} ${Styles.nav}` : Styles.nav)}>
      {children}
    </nav>
  );
}

export default function HeaderNav({ user }: Props) {
  const isMedium = useMatchScreenClass("xs", "sm", "md");

  return (
    <nav className={classnames(Styles.nav, Styles.defaultNav)}>
      {isMedium ? <MinimalNav user={user} /> : <NavLinks user={user} />}
    </nav>
  );
}
