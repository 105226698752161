import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

import ReviewDiffs from "./review_diffs";

type Props = {
  isOpen: boolean;
  onCommit: () => void;
  onCancel: () => void;
};

export default function ReviewModal({ isOpen, onCommit, onCancel }: Props) {
  if (!isOpen) {
    return null;
  }

  const buttons = [
    <Button variant="primary" buttonColor="action" key="commit" onClick={onCommit}>
      <FormattedMessage id="8874a7a2-0e14-4413-8d0e-457de255f20b" defaultMessage="Save changes" />
    </Button>,
    <Button variant="tertiary" key="cancel" onClick={onCancel}>
      <FormattedMessage id="67dfddc0-148e-4112-ae48-6d66d8b22412" defaultMessage="Cancel" />
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="352d7566-009a-4117-9a7e-d7ee73801cdb"
          defaultMessage="Review changes"
        />
      }
      buttons={buttons}
    >
      <ReviewDiffs />
    </WorkflowModal>
  );
}
