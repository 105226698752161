import "./dropdown.scss";
import classnames from "classnames";
import type { ReactNode } from "react";

import { useId } from "util/html";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { useMatchScreenClass } from "common/core/responsive";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";

type Props = {
  namespace: string; // A unique namespace, will be the className on the root node.
  title: ReactNode; // The text to be displayed in the header.
  titleSecondary?: ReactNode; // A secondary line of text (de-emphasized).
  icon?: string; // The icon to display before the title.
  automationId: string; // A data-automation-id to attach to the target div
  withCaret?: boolean; // Choose to include a caret after the title.
  alertCount?: number; // An alert bubble with count,
  children: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
};

export function HeaderDropdown({
  children,
  namespace,
  title,
  titleSecondary,
  icon,
  automationId,
  withCaret,
  alertCount = 0,
  onToggle,
  isOpen,
}: Props) {
  const isSmall = useMatchScreenClass("xs", "sm");
  const caretDirection = isOpen ? "up" : "down";
  const id = useId();

  return (
    // Note: We do not use role=menu or keyboard navigation in this component to avoid focus being trapped
    // in the menu and also to avoid the need to handle complex keyboard interactions associated with the menu role. See more
    // here: https://adrianroselli.com/2017/10/dont-use-aria-menu-roles-for-site-nav.html
    <div className="HeaderDropdown">
      <ActionButton
        aria-expanded={isOpen}
        aria-controls={id}
        color="dark"
        className={classnames("HeaderDropdown--button", namespace)}
        onClick={onToggle}
        automationId={automationId}
      >
        <div className="HeaderDropdown--target">
          {alertCount > 0 && <span className="HeaderDropdown--target--alert-count" />}
          {icon && <img className="HeaderDropdown--target--icon" src={icon} alt={icon} />}
          <div className="HeaderDropdown--target--name-container">
            {isSmall && CURRENT_PORTAL === APPS.CUSTOMER ? (
              <Icon className="HeaderDropdown--target--name-container--icon" name="question" />
            ) : (
              <>
                <span className={`ellipsis name ${SENSITIVE_CLASS}`}>{title}</span>
                {titleSecondary && (
                  <span className="ellipsis name secondary">{titleSecondary}</span>
                )}
              </>
            )}
          </div>
          {withCaret && (
            <Icon className="HeaderDropdown--target--caret" name={`caret-${caretDirection}`} />
          )}
        </div>
      </ActionButton>
      <ul onClick={onToggle} hidden={!isOpen} className="HeaderDropdown--list" id={id}>
        {children}
      </ul>
    </div>
  );
}
