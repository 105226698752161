import { FormattedMessage } from "react-intl";

import { usePermissions } from "common/core/current_user_role";

import { ProofReasonCodeProvider } from "./context";
import ProofReasonCodeLoader from "./proof_reason_code_loader";
import ActionButtons from "./action_buttons";
import CodeFilter from "./code_filter";
import ProofReasonCodeTable from "./proof_reason_code_table";

export function ProofReasonCodes() {
  // keep canView out of the context because we use it at this root level
  const { hasPermissionFor } = usePermissions();
  const canView = hasPermissionFor("viewProofReasonCodes");

  if (!canView) {
    return (
      <div>
        <FormattedMessage
          id="e9b86eac-2be4-473c-9ea6-0a9b18df504f"
          defaultMessage="You do not have permission to view this page."
        />
      </div>
    );
  }

  return (
    <div>
      <ProofReasonCodeProvider>
        <ProofReasonCodeLoader />
        <ActionButtons />
        <CodeFilter />
        <ProofReasonCodeTable />
      </ProofReasonCodeProvider>
    </div>
  );
}
