import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useMutation } from "util/graphql";

import CommunicationSettingsEditEmailGraph, {
  type CommunicationSettingsEditEmail as Data,
  type CommunicationSettingsEditEmailVariables as Variables,
  type CommunicationSettingsEditEmail_node_Organization as Organization,
} from "../index.query.graphql";
import DeleteEmailTemplateMutation from "./delete_email_template.mutation.graphql";

export function useRevertInteraction({ organization }: { organization: Organization }) {
  const emailTemplate = organization.organizationBrand!.emailTemplate;
  const canDelete = emailTemplate.__typename === "EmailTemplate";

  const [submitting, setSubmitting] = useState(false);
  const deleteEmailTemplateMutateFn = useMutation(DeleteEmailTemplateMutation);
  const handleRevert = useCallback(() => {
    if (!canDelete || submitting) {
      throw new Error("Revert callback called in a theoretically-impossible scenario");
    }

    setSubmitting(true);
    return deleteEmailTemplateMutateFn({
      variables: {
        input: {
          emailTemplateId: emailTemplate.id,
        },
      },
      update(cacheProxy, { data }) {
        const variables = { organizationId: organization.id, emailName: emailTemplate.name };
        const { node, ...other } = cacheProxy.readQuery<Data, Variables>({
          query: CommunicationSettingsEditEmailGraph,
          variables,
        })!;
        if (node?.__typename !== "Organization") {
          throw new Error(`Expected an organization, got ${node?.__typename}`);
        }
        if (!data?.deleteEmailTemplate?.defaultEmailTemplate) {
          throw new Error("Missing newly created default email template");
        }
        cacheProxy.writeQuery({
          query: CommunicationSettingsEditEmailGraph,
          variables,
          data: {
            ...other,
            node: {
              ...node,
              organizationBrand: {
                ...node.organizationBrand,
                emailTemplate: data.deleteEmailTemplate.defaultEmailTemplate,
              },
            },
          },
        });
      },
    }).finally(() => {
      setSubmitting(false);
    });
  }, [submitting, emailTemplate]);

  return {
    reverting: submitting,
    revert: handleRevert,
  };
}

export function Modal({
  onCancel,
  onConfirm,
  loading,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="259771c9-47e3-4836-9014-4e8a50d7ea20"
          defaultMessage="Are you sure you want to revert to the Notarize default?"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel-publish"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="8e8dd095-ea1e-4887-8a45-d5de35151bb7" defaultMessage="No, Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="confirm-publish"
          onClick={onConfirm}
          isLoading={loading}
        >
          <FormattedMessage
            id="f91c5525-1cca-4c3d-9872-3288f7416749"
            defaultMessage="Yes, Revert to Default"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="0528c509-18ad-4b71-bc88-e2dbade2499e"
        defaultMessage="This email template will be returned to the Notarize default. All customizations will be lost."
      />
    </WorkflowModal>
  );
}
