import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { SearchField } from "common/core/search_field";
import Button from "common/core/button";
import Select from "common/form/inputs/select";
import { Query } from "util/graphql/query";
import { Mutation } from "util/graphql/mutation";

import TemplateSearchQuery from "./template_query.graphql";
import CloneTemplateToOrganization from "./clone_template_to_organization.graphql";

class TemplateCopier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateSearchTerm: props.initTemplateSearchTerm,
      destinationOrganization: props.initDestinationOrganization,
    };
  }

  onSearchAndShowTemplates = () => {
    const { templateSearchTerm, destinationOrganization } = this.state;
    this.props.setSearchParams({ destinationOrganization, templateSearchTerm });
  };

  renderTemplateOption = (template) => {
    return (
      <div className={`AdminTemplateCopier--source-template--option`}>
        <div className={`AdminTemplateCopier--source-template--display-name`}>
          {template.name} ({template.id})
        </div>
      </div>
    );
  };

  renderDestinationTemplateOption = (template) => {
    return (
      <div className={`AdminTemplateCopier--dest-template--option`}>
        <div className={`AdminTemplateCopier--dest-template--display-name`}>
          {template.name} ({template.id})
        </div>
      </div>
    );
  };

  clone = async (organizationId, sourceTemplateId, destTemplateId) => {
    await this.props.cloneTemplateToOrganization({
      variables: {
        input: {
          id: organizationId,
          srcTemplateId: sourceTemplateId,
          destTemplateId,
        },
      },
    });
  };

  render() {
    const {
      viewer: { queriedTemplates, queriedDestinationTemplates, organizations },
      isLoading,
    } = this.props;
    const { templateSearchTerm, destinationOrganization, destinationTemplate, sourceTemplate } =
      this.state;

    let queriedTemplatesDisplay = <p className="no-templates-message">No templates found</p>;

    if (queriedTemplates?.totalCount) {
      const items = queriedTemplates.edges.map(({ node }) => ({
        ...node,
        value: node.id,
        label: node.name,
      }));

      queriedTemplatesDisplay = (
        <div className="display">
          <h4>Source Template</h4>
          <Select
            items={items}
            optionRenderer={this.renderTemplateOption}
            onChange={(sourceTemplate) => this.setState({ sourceTemplate })}
            value={sourceTemplate}
            valueRenderer={this.renderTemplateOption}
            autosize={false}
            disabled={false}
            automationId="admin-template-search-template-selector"
            searchable
          />
        </div>
      );
    }

    const organizationNode = organizations?.edges?.[0]?.node;
    const organizationNameDisplay = (
      <h4>
        {organizationNode
          ? `Clone to organization: ${organizationNode.name}`
          : "Cannot find organization"}
      </h4>
    );

    let templatesInDestinationDisplay = (
      <p className="no-templates-message">No templates exist in the target organization</p>
    );

    if (queriedDestinationTemplates && queriedDestinationTemplates.totalCount > 0) {
      const items = queriedDestinationTemplates.edges.map(({ node }) => ({
        ...node,
        value: node.id,
        label: node.name,
      }));

      templatesInDestinationDisplay = (
        <div className="display">
          <Select
            items={items}
            optionRenderer={this.renderDestinationTemplateOption}
            onChange={(destinationTemplate) => this.setState({ destinationTemplate })}
            value={destinationTemplate}
            valueRenderer={this.renderDestinationTemplateOption}
            autosize={false}
            disabled={false}
            searchable
          />
        </div>
      );
    }

    return (
      <div className="TemplateCopier">
        <div className="search-bar">
          <SearchField
            className="search-field"
            onChange={(q) => this.setState({ templateSearchTerm: q.value })}
            onSearch={this.onSearchAndShowTemplates}
            value={templateSearchTerm}
            placeholder="Search source template e.g. organization GID, template GID, name."
            aria-label="Search for source template"
            noIcon
          />
          <SearchField
            className="search-field"
            onChange={(q) => this.setState({ destinationOrganization: q.value })}
            onSearch={this.onSearchAndShowTemplates}
            value={destinationOrganization}
            placeholder="Destination organization GID"
            aria-label="Search for destination organization by GID"
            noIcon
          />
          <Button
            buttonColor="action"
            variant="primary"
            onClick={this.onSearchAndShowTemplates}
            isLoading={isLoading}
            disabled={!(templateSearchTerm && destinationOrganization)}
            automationId="admin-template-search-button"
          >
            Search
          </Button>
        </div>
        {!isLoading && queriedTemplatesDisplay}
        {destinationOrganization && organizationNameDisplay}
        {!isLoading && templatesInDestinationDisplay}
        {sourceTemplate && organizationNode && (
          <Button
            buttonColor="action"
            variant="primary"
            onClick={() => this.clone(organizationNode.id, sourceTemplate, destinationTemplate)}
            isLoading={isLoading}
            automationId="admin-template-clone-button"
          >
            Clone
          </Button>
        )}
      </div>
    );
  }
}

TemplateCopier.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
    queriedTemplates: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            organization: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    queriedDestinationTemplates: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            organization: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    organizations: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
  isLoading: PropTypes.bool,
  initTemplateSearchTerm: PropTypes.string,
  initDestinationOrganization: PropTypes.string,
  cloneTemplateToOrganization: PropTypes.func,
  setSearchParams: PropTypes.func.isRequired,
};

function TemplateSearchContainer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const templateSearchTerm = searchParams.get("templateSearchTerm") || "";
  const destinationOrganization = searchParams.get("destinationOrganization") || "";
  return (
    <Mutation mutation={CloneTemplateToOrganization}>
      {(cloneTemplateToOrganization) => (
        <Query
          query={TemplateSearchQuery}
          variables={{ searchTerm: templateSearchTerm, destinationOrganization }}
          skip={!templateSearchTerm || !destinationOrganization}
        >
          {({ data, loading }) => (
            <TemplateCopier
              setSearchParams={setSearchParams}
              viewer={!loading && data ? data.viewer : {}}
              initTemplateSearchTerm={templateSearchTerm}
              initDestinationOrganization={destinationOrganization}
              isLoading={loading}
              cloneTemplateToOrganization={cloneTemplateToOrganization}
            />
          )}
        </Query>
      )}
    </Mutation>
  );
}

export default TemplateSearchContainer;
